import { useReducer } from "react";

interface inputData {
  key: string,
  label: string,
  value: string,
}
export interface SplitExamType {
  examInfo: Array<inputData>,
  editTags: Array<inputData>,
  series: Array<spiltSeries>,
  images: Array<spiltImage>,
  selectedSeriesIdx: number,
  compareEditTags: Array<inputData> | null,
  isEditing: boolean,
  isSelected: boolean,
  selectedOrder?: any
}

export interface spiltSeries {
  idx: number,
  image: string,
  imageLength: number,
  imageList: [],
  selectedImages: []
  seriesInstanceUID: string,
  selected: boolean,
}
export interface spiltImage {
  contentID: string
  idx: number
  image: string
  selected: boolean
  sopInstanceUID: string
  type: string
}
type State = SplitExamType;

export type Action =
  | { type: "SET_EXAM_INFO", data: Array<inputData> }
  | { type: "SET_EDIT_TAGS", data: Array<inputData>, isEditing: boolean }
  | { type: "CHANGE_ORDER_TAGS", compareEditTags: Array<inputData>, isSelected?: boolean, selectedOrder?: any }
  | { type: "SET_SPILT_LIST", series?: Array<spiltSeries>, images?: Array<spiltImage>, selectedSeriesIdx : number }
  | { type: "CLEAR_EXAM" };

const reducer = (state: State, action: Action): State => {
   switch (action.type) {
   case "SET_EXAM_INFO": {
      return {
         ...state,
         examInfo: action.data,
      };
   }
   case "SET_EDIT_TAGS": {
      return {
         ...state,
         editTags: action.data,
         isEditing: action.isEditing,
      };
   }
   case "CHANGE_ORDER_TAGS": {
      return {
         ...state,
         compareEditTags: action.compareEditTags,
         isSelected: action.isSelected ? action.isSelected : false,
         selectedOrder: action.selectedOrder ? action.selectedOrder : null,
      };
   }
   case "SET_SPILT_LIST": {
      return {
         ...state,
         series: action.series ? action.series : state.series,
         images: action.images ? action.images : state.images,
         selectedSeriesIdx: action.selectedSeriesIdx,
      };
   }
   case "CLEAR_EXAM": {
      return {
         examInfo: [],
         editTags: [],
         series: [],
         images: [],
         selectedSeriesIdx: -1,
         compareEditTags: null,
         isEditing: false,
         isSelected: false,
      };
   }
   default:
      throw new Error();
   }
};

const useSplitExam = () => {
   const [state, examDispatch] = useReducer(reducer, {
      examInfo: [],
      editTags: [],
      series: [],
      images: [],
      selectedSeriesIdx: -1,
      compareEditTags: null,
      isEditing: false,
      isSelected: false,
   });
   return {
      state,
      examDispatch,
   };
};

export default useSplitExam;
