import styled from "@emotion/styled";
import { Alert, AlertTitle, Box, Tab, Tabs } from "@mui/material";

export const StyledSettingBox = styled(Box)`
    width: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #2d333f;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const StyledSettingTitleWrapper = styled.div`
    background-color: #1d1e25;
    width: 100%;
    height: 50px;
    color: #fff;
    border-bottom: 1px solid #4c5667;
`;

export const StyledSettingTitle = styled.div`
    width: 85%;
    margin: 0;
    padding: 15px;
    float: left;
`;

export const StyledSettingWrapper = styled(Box)`
    justify-content: center;
    background-color: #252934;
    width: 100%;
    height: 100%;
`;

export const StyledTabWrapper = styled.div`
    height: calc(100vh - 219px);
    overflow-y: auto;
`;

export const StyledTab = styled(Tab)`
    color: #aaaaaa;
`;

export const StyledTabs = styled(Tabs)`
    & .MuiTabs-flexContainer {
        justify-content: center;
    }
`;

export const StyledSettingBtnWrappers = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 15px;
`;

export const StyledSettingBtnWrapper = styled.div`
    flex:1;
`;

export const StyledAlert = styled(Alert)`
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;
export const StyledAlertTitle = styled(AlertTitle)`
    font-size: 20px;
`;

export const StyledAlertContents = styled.div`
    font-size: 15px;
`;

export const StyledAlertButtonWrapper = styled.div`
    margin: 10px;
`;
