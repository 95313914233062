import { makeStyles } from "@mui/styles";

export const FormStyles = makeStyles({
   container: {
      flex: 1,
      width:"100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
   },
   info: {
      lineHeight: "2em",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
   },
   titleContainer: {
      width: "calc(100% - 10px)",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   titleContent: {
      width: "100%",
      height: "calc(100% - 20px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#252934",
      boxShadow: "0px 3px 9px -2px #000000",
      borderTopLeftRadius: "3px",
      borderTopRightRadius: "3px",
      color: "#aaaaaa",
      fontSize: "14px",
      fontWeight: "500",
   },
   infoContainer: {
      width: "100%",
      height: "calc(100% - 50px)",
      fontSize: "14px",
   },
   inputContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      color: "#aaaaaa",
      textAlign: "left",
      fontSize: "14px",
   },
   font: {
      width: "105px",
   },
   content: {
      width: "calc(100% - 105px)",
      wordBreak: "break-all",
   },
   selected: {
      borderColor: "#0087cb",
      borderStyle: "dashed",
      borderWidth: "2px",
      maxHeight: "100px",
      maxWidth: "100px",
   },
   notSelected: {
      maxHeight: "100px",
      maxWidth: "100px",
   },
   selectedBorder: {
      borderColor: "#0087cb",
      borderStyle: "dashed",
      borderWidth: "2px",
   },
});
