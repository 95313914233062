import styled from "@emotion/styled";

export const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background: #252934;
    margin: 0;
    padding: 0;
`;
export const StyledPasswordTitle = styled.div`
    //background-color: #252934;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #4c5667;
`;

export const StyledMyInfo = styled.p`
    margin: 0; 
    padding: 15px; 
    float: left;
`;

export const StyledPasswordContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const StyledWrapper = styled.div`
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 25px;
`;
