import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Collapse, IconButton, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GetContextMenuItemsParams, GridOptions, MenuItemDef } from "ag-grid-community";
import { useEventListener } from "usehooks-ts";
import { useSelector } from "react-redux";
import { StyledBox } from "../styles/EmotionStyles";
import { StyledContainer } from "../styles/CommonDialogStyles";
import { ButtonWrapper, MergeContainer, MergeInfo, MergeList, MergeTitle } from "./styles/MergeExamStyles";
import OkBtn from "../../button/OkBtn";
import CancelBtn from "../../button/CancelBtn";
import i18n from "../../../utils/i18n-utils";
import { CustomTooltip } from "../../../../public/resource/js/customTooltip";
import GridUtils from "../../../utils/grid-utils";
import store from "../../../redux/store";
import { CommonActionType } from "../../../redux/reducers/common";
import CommonUtils from "../../../../public/resource/js/utils/common";
import { is_esc } from "../../../dialog/common-keyboard-handler";
import TechnicianUtils from "../../../utils/technician-utils";
import { TechlistActionType } from "../../../redux/reducers/techlist";
import { useToastDispatch } from "../../../context/ToastContext";
import {
   StyledAlert,
   StyledAlertButtonWrapper,
   StyledAlertContents,
   StyledAlertTitle,
} from "../Setting/styles/SettingDalogStyles";
import { RelatedTechlistActionType } from "../../../redux/reducers/related-techlist";
import { RootState } from "../../../redux/reducers";

const width = 580;
const height = 405;

export default function MergeExam() {
   const dialogRef = useRef(null);
   const toastDispatch = useToastDispatch();
   const [open, setOpen] = useState<boolean>(true);
   const [rowData, setRowData] = useState<Array<any>>([]);
   const [alertOpen, setAlertOpen] = useState<boolean>(false);
   const [alertMessage, setAlertMessage] = useState<Array<string>>([]);
   const _selectedRows = useSelector((state: RootState) => state.techlist.row?.rows) || [];

   const [gridOptions, setGridOptions] = useState<GridOptions>({
      defaultColDef: {
         suppressMenu: true,
         sortable: true,
         resizable: true,
      },
      components: {
         customTooltip: CustomTooltip,
      },
      rowSelection: "single",
      overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">${i18n("label.noRecordsFound")}</span>`,
      onRowDoubleClicked: (e) => {
         changeBasedStudyList(e.node.data).then(() => {
            gridOptions.api?.forEachNode((node) => {
               node.setSelected(node.data.based);
            });
         });
      },
   });

   const columnDefs: ColDef[] = useMemo(() => [
      {
         headerName: "Based",
         field: "based",
         width: 60,
         cellRenderer: params => (params.value ? "<div class=\"check\">v</div>" : ""),
      },
      { headerName: "Count", field: "imageCount", width: 45, cellStyle: { "text-align": "right" } },
      { headerName: "No", field: "no", width: 40, cellStyle: { "text-align": "center" } },
      { headerName: "RS", field: "readingStatus", width: 50, cellStyle: { "text-align": "center" }, cellRenderer: params => GridUtils.rsCellRenderer(params.value) },
      { headerName: "EM", field: "isEmergency", width: 40, cellStyle: { "text-align": "center" }, cellRenderer: params => GridUtils.emCellRenderer(params.value) },
      { headerName: "Accession No", field: "accessionNumber", width: 90 },
      { headerName: "ID", field: "patientID", width: 90, cellStyle: { "text-align": "center" } },
      { headerName: "Name", field: "patientName", width: 90 },
      { headerName: "Sex", field: "patientSex", width: 40, cellStyle: { "text-align": "center" } },
      { headerName: "BirthDate", field: "patientBirthDate", width: 80, cellStyle: { "text-align": "center" } },
      { headerName: "StudyDate", field: "studyDtime", width: 138, cellStyle: { "text-align": "center" } },
      { headerName: "ReqDate", field: "requestDtime", width: 138, cellStyle: { "text-align": "center" } },
      { headerName: "StudyDesc", field: "studyDescription", width: 200 },
      { headerName: "StudyInstanceUID", field: "studyInstanceUID", width: 100 },
   ], []);

   /*
   *  https://www.ag-grid.com/react-data-grid/context-menu/
   * */
   // const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) : (string | MenuItemDef)[] => {
   //    if (!params.node) return [];
   //    params.node.setSelected(true, true);
   //    const result = [
   //       "separator",
   //       {
   //          name: i18n("label.delete"),
   //          action: () => {
   //             // 삭제기능
   //             console.log("merge Cancel -> delete row");
   //          },
   //       },
   //       "separator",
   //    ];
   //    return result;
   // }, []);

   /* 브라우저 닫기, 새로고침 */
   useEventListener("beforeunload", handleClose);

   useEffect(() => {
      if (_selectedRows.length > 0) {
         setRowData(_selectedRows);
         caseLockUpdate(true);
      }
   }, [_selectedRows]);

   function changeBasedStudyList(selectedRow: any) {
      return new Promise((resolve) => {
         const selectedRows = CommonUtils.cloneObject(_selectedRows);
         for (let i = 0; i < selectedRows.length; i++) {
            selectedRows[i].based = (selectedRow.id === selectedRows[i].id);
         }

         setRowData(selectedRows);
         resolve(true);
      });
   }

   function caseLockUpdate(flag: boolean) {
      // @ts-ignore
      const params = { caseIdList: _selectedRows.map(item => item.id) };
      TechnicianUtils.caseLockUpdate({ flag, list: params }).then((result) => {
         if (result) store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
         else openToast(i18n("msg.caseLockFail"), true);
      });
   }

   function openToast(msg: string, isErr: boolean) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   }

   function doOk() {
      let flag = false;

      for (let i = 0; i < rowData.length; i++) {
         if (rowData[i].based) {
            flag = true;
            break;
         }
      }

      const msg: Array<string> = [];
      let isVerified = false;

      for (let i = 0; i < rowData.length; i++) {
         const row = rowData[i];
         if (!CommonUtils.isEmptyValue(row.studyStatus) && row.studyStatus === "verified") {
            isVerified = true;
            break;
         }
      }

      if (isVerified) msg.push("Warning! One or more of the selected studies are verified.");
      msg.push("Studies will be merged into one study.");
      // msg.push("If there's a report on the other side, the report will be deleted.");
      msg.push("Do you want to proceed?");

      setAlertMessage(msg);
      setAlertOpen(true);
   }

   function doAlertOk() {
      const studyList = [];
      let baseId;

      for (let i = 0; i < rowData.length; i++) {
         studyList.push(rowData[i].id);
         if (rowData[i].based) baseId = rowData[i].id;
      }

      mergeStudyList(studyList, baseId).then((result) => {
         if (result) {
            store.dispatch({ type: TechlistActionType.REFRESH_AND_SELECT_ROWS, payload: [result] });
            store.dispatch({ type: RelatedTechlistActionType.REFRESH_ORDER, payload: true });
            openToast("Exams Merged.", false);
         }
         else {
            openToast("Merge Failed.", true);
         }
      });

      handleClose();
   }

   function close_if_esc(e: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(e)) {
         e.stopPropagation();
         handleClose();
      }
   }

   function handleClose() {
      // @ts-ignore
      window.dialog_closed();

      caseLockUpdate(false);
      setAlertOpen(false);
      setOpen(false);
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   function mergeStudyList(params: any, id: string) {
      return new Promise((resolve, reject) => {
         fetch(`/api/tech/studylist/merge/${id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         }).catch(err => reject(err));
      });
   }

   return (
      <>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.min.css"/>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css"/>
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css"/>
         <Modal
            open={open}
            onKeyDown={close_if_esc}
            ref={dialogRef}
         >
            <StyledBox
               width={width}
               height={height}
            >
               <StyledContainer>
                  <Collapse in={alertOpen}>
                     <StyledAlert sx={{ width: 430 }} severity="warning">
                        <StyledAlertTitle>Merge</StyledAlertTitle>
                        <StyledAlertContents>
                           {
                              alertMessage.map((message, index) => (
                                 <div key={index}>{message}</div>
                              ))
                           }
                        </StyledAlertContents>
                        <StyledAlertButtonWrapper style={{ textAlign: "center" }}>
                           <OkBtn onClick={doAlertOk} text={"Yes"}/>
                           <CancelBtn onClick={() => setAlertOpen(false)} text={"No"}/>
                        </StyledAlertButtonWrapper>
                     </StyledAlert>
                  </Collapse>
                  <MergeTitle>
                     <p style={{ margin: 0, padding: "15px", float: "left" }}>Merge</p>
                     <IconButton sx={{ width: 50, height: 50, float: "right", color: "#aaaaaa" }} onClick={handleClose}>
                        <Close/>
                     </IconButton>
                  </MergeTitle>

                  <MergeList className={"ag-theme-balham-dark"}>
                     <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        gridOptions={gridOptions}
                        // getContextMenuItems={getContextMenuItems}
                     />
                  </MergeList>

                  <MergeContainer>
                     <MergeInfo>(Please double-click a study on the list to base the merger on.)</MergeInfo>
                  </MergeContainer>
                  <ButtonWrapper>
                     <OkBtn onClick={doOk} text={"Merge"}/>
                     <CancelBtn onClick={handleClose} text={"Cancel"}/>
                  </ButtonWrapper>
               </StyledContainer>
            </StyledBox>
         </Modal>
      </>
   );
}
