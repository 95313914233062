import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
   Box,
   ButtonGroup,
   Dialog,
   DialogContent,
   DialogTitle,
   IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { AgGridReact } from "ag-grid-react";
import { GridOptions } from "ag-grid-community";
import { useEventListener } from "usehooks-ts";
import { useToastDispatch } from "../../../context/ToastContext";
import useDialogOpen from "../../../hooks/useDialogOpen";
import i18n from "../../../utils/i18n-utils";
import { TechlistActionType } from "../../../redux/reducers/techlist";
import { is_esc } from "../../../dialog/common-keyboard-handler";
import { CommonActionType } from "../../../redux/reducers/common";
import { DialogStyles } from "../styles/DialogStyles";
import Confirm, { ConfirmProps } from "../Confirm";
import store from "../../../redux/store";
import ModifySeriesList from "./ModifySeriesList";
import ModifyImageList from "./ModifyImageList";
import useModifyExam, { modifyImage, modifySeries } from "./hooks/useModifyExam";
import TechnicianUtils from "../../../utils/technician-utils";
import EditForm from "../SplitExam/EditForm";
import EditMultiLineForm from "./EditMultiLineForm";
import { RelatedTechlistActionType } from "../../../redux/reducers/related-techlist";
import OkBtn from "../../button/OkBtn";
import CancelBtn from "../../button/CancelBtn";

export default function ModifyExam(props: any) {
   const is = "modify-exam-dialog";
   const [open, setOpen] = useState(false);
   const { state, examDispatch } = useModifyExam();
   const [confirmDialog, setConfirmDialog] = useState<any>(null);
   const [selectedRow, setSelectedRow] = useState<any>(null);
   const [deleteSopInstanceUIDs, setDeleteSopInstanceUIDs] = useState(null);
   const [rowData, setRowData] = useState<any>(null);
   const toastAction = useToastDispatch();
   const dispatch = useDispatch();
   const [gridOptions, setGridOptions] = useState<GridOptions>({
      defaultColDef: {
         suppressMenu: true, // 메뉴 막기
      },
      rowDragManaged: true,
      columnDefs: createColumnDefs(),
      animateRows: true,
      overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">${i18n("label.noRecordsFound")}</span>`,
      rowSelection: "single",
      getContextMenuItems: (params):any => {
         if (!params.node) return;
         const result = [
            {
               name: i18n("label.copyPatientInfo"),
               action () {
                  const patientInfo: any = [];
                  patientInfo.push({ key:"patientId",        label:i18n("label.id"),        value: params.node.data.pid });
                  patientInfo.push({ key:"patientName",      label:i18n("label.name"),      value: params.node.data.name });
                  patientInfo.push({ key:"patientBirthDate", label:i18n("label.birthDate"), value: convertFormatyyyyMMDD(params.node.data.birthDate), type: "date" });
                  patientInfo.push({ key:"patientSex",    label:i18n("label.gender"),    value: params.node.data.sex });
                  examDispatch({ type:"SET_PATIENT_INFO", data: patientInfo });
               },
            },
         ];
         return result;
      },
   });

   // window beforeunload 이벤트 <= 기존 Worklist에서 처리하던 로직
   // #17789 modify exam edit 정보 update
   useEventListener("beforeunload", doClose);

   useDialogOpen();

   useEffect(() => {
      doOpen(props);
   }, [props]);

   function doOpen(selectedRow: any) {
      // get patient Info
      getExamInfo(selectedRow?.id).then((resultExam:any) => {
         setSelectedRow(resultExam);
         setSereisList(resultExam.exam[0]);
         getPatientInfo(resultExam);
         getStudyInfo(resultExam);
         getClinicalInfo(resultExam);

         if (resultExam.readingStatus === "3A") {
            store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });

            const message: ConfirmProps["message"] = {
               contents: [i18n("msg.modifyExamDialog.approvedCase.0"), i18n("msg.modifyExamDialog.approvedCase.1")],
               title: i18n("label.approvedCase"),
               // key: "approvedCase",
               ok: i18n("button.ok"),
               cancel: i18n("button.close"),
               onOk: () => { setOpen(true); caseLockUpdate(true, selectedRow.id); },
               onCancel: () => { setOpen(false); store.dispatch({ type: CommonActionType.CLOSE_DIALOG }); },
            };
            setConfirmDialog({ message, open:true });
         } else {
            setOpen(true);
            caseLockUpdate(true, selectedRow.id);
         }

         if (selectedRow) {
            findWorklistByPatientId(selectedRow.patientID).then((result:any) => {
               const rows = setPatientList(result);
               setRowData(rows);
            });
         }
      });
   }

   function doClose() {
      dialog_closed();
      caseUnlock({ caseIdList: [selectedRow.id] });
   }

   function setPatientList(patients: any) {
      const rows: any = [];
      for (let i = 0; i < patients.length; i++) {
         const row: any = {};
         const [modality] = patients[i].modality;
         const [bodypart] = patients[i].bodypart;
         row.modality = modality;
         row.bodypart = bodypart;
         row.pid = patients[i].patient._id;
         row.name = patients[i].patient.name;
         row.sex = patients[i].patient.sex;
         row.birthDate = patients[i].patient.birthDate;
         row.studyDate = patients[i].exam[0].studyDate;
         row.studyTime = patients[i].exam[0].studyTime;
         row.studyDescription = patients[i].studyDescription;
         row.count = patients[i].contentId.length;
         if (patients[i].readingStatus === "3A") {
            const approveDate = patients[i].opinionApprovedDtime;
            if (patients[i].opinion) {
               for (let j = 0; j < patients[i].opinion.length; j++) {
                  if (approveDate === patients[i].opinion[j].approveTime) {
                     row.radiology = patients[i].opinion[j].approver;
                  }
               }
            }
         } else {
            row.radiology = "";
         }
         rows.push(row);
      }
      return rows;
   }

   function convertFormatyyyyMMDD(date: any) {
      const inputDate = date;
      if (date.length > 7) {
         const result = `${inputDate.substr(0, 4)}-${inputDate.substr(4, 2)}-${inputDate.substr(6, 2)}`;
         return result;
      }
      return inputDate;
   }

   function setSereisList(resultThumbnail : any) {
      const result: any = {};
      const seriesArray = [];
      for (let i = 0; i < resultThumbnail.series.length; i++) {
         const series:any = {};
         series.idx = i;
         series.seriesDescription = resultThumbnail.series[i].seriesDescription;
         series.imageLength = resultThumbnail.series[i].image.length;
         series.seriesNumber = resultThumbnail.series[i].seriesNumber;
         series.displayOrder = resultThumbnail.series[i].displayOrder;
         series.seriesInstanceUID = resultThumbnail.series[i].seriesInstanceUID;
         if (resultThumbnail.series[i].fileType === "jpeg") {
            series.displayImage = resultThumbnail.series[i].image[0].image;
         } else {
            // eslint-disable-next-line no-undef
            series.displayImage = `${__CDN_URL__}/prefetch/download/image/${resultThumbnail.series[i].image[0].contentID}/large`;
         }
         series.imageList = resultThumbnail.series[i].image;
         series.selectedImages = [];

         seriesArray.push(series);
      }

      result.series = seriesArray;

      examDispatch({ type: "SET_MODIFY_LIST", series: seriesArray, selectedSeriesIdx: -1, selectedSeriesNumber: -1 });
   }

   function setImageList(seriesIndex: number, series?: any) {
      if (!selectedRow) {
         console.warn(is, "selectedRow is null.");
         return;
      }
      if (seriesIndex === null || seriesIndex === undefined) return;

      const newSeries: any = series || state.series;

      // Set Images
      const images = newSeries[seriesIndex].imageList.map((img:any, idx:number) => {
         const image:any = img;
         if (img.type === "jpeg") {
            image.image = img.image;
            image.inputType = "jpeg";
         } else if (img.type === "dcm") {
            image.image = `${__CDN_URL__}/prefetch/download/image/${img.contentID}/large`;
            image.inputType = "url";
         }
         image.idx = idx;
         image.contentID = img.contentID;
         image.sopInstanceUID = img.sopInstanceUID;
         const check = newSeries[seriesIndex].selectedImages.find((contentID:string) => contentID === img.contentID);
         if (newSeries.length > 1 && check) image.selected = true;
         return image;
      });
      examDispatch({ type: "SET_MODIFY_LIST", series: newSeries, images, selectedSeriesIdx: seriesIndex, selectedSeriesNumber: newSeries[seriesIndex].seriesNumber });
   }

   function getPatientInfo(ic: any) {
      const inputCase = ic;
      const patientInfo: any = [];
      const exam = inputCase.exam[0];
      if (inputCase.patient) {
         const { patient } = inputCase;
         patientInfo.push({ key:"patientId",     label:i18n("label.id"),    value: patient._id });
         patientInfo.push({ key:"patientName",   label:i18n("label.name"),  value: patient.name });
         if (patient.birthDate.length >= 8) {
            const birthDate = `${patient.birthDate.substring(0, 4)}-${
               patient.birthDate.substring(4, 6)}-${
               patient.birthDate.substring(6, 8)}`;
            patient.birthDate = birthDate;
         }
         patientInfo.push({ key:"patientBirthDate", label:i18n("label.birthDate"), value: patient.birthDate, type: "date" });
         patientInfo.push({ key:"patientSex",    label:i18n("label.gender"),    value: patient.sex });
      } else {
         patientInfo.push({ key:"patientId",        label:i18n("label.id"),        value: exam.patientID });
         patientInfo.push({ key:"patientName",      label:i18n("label.name"),      value: exam.patientName });
         patientInfo.push({ key:"patientBirthDate", label:i18n("label.birthDate"), value: exam.patientBirthDate, type: "date" });
         patientInfo.push({ key:"patientSex",    label:i18n("label.gender"),    value: exam.patientSex });
      }
      examDispatch({ type: "SET_PATIENT_INFO", data: patientInfo });
   }

   function getClinicalInfo(ic: any) {
      const inputCase = ic;
      const clinicalInfo: any = [];
      clinicalInfo.push({ key:"clinicalInfo", label:i18n("label.clinicalInfo"), value: inputCase.requestDesc });
      examDispatch({ type: "SET_CLINICAL_INFO", data: clinicalInfo });
   }

   function getStudyInfo(ic: any) {
      const inputCase = ic;
      const exam = inputCase.exam[0];
      const studyInfo: any = [];
      studyInfo.push({ key:"accessionNumber", label:i18n("label.accessionNo"), value: exam.accessionNumber });
      studyInfo.push({ key:"modality", label:i18n("label.modality"), value: inputCase.modality[0] });
      studyInfo.push({ key:"bodypart", label:i18n("label.bodypart"), value: inputCase.bodypart[0] });
      studyInfo.push({ key:"studyDate", label:i18n("label.studyDate"), value: convertFormatyyyyMMDD(exam.studyDate), type: "date" });
      if (exam.studyTime && exam.studyTime.length >= 6) {
         let hour = exam.studyTime.substring(0, 2);
         if (hour > 24 || hour === 24) hour -= 24;
         if (hour < 10 && hour.length === 1) hour = `0${hour}`;
         const studyTime = `${hour}:${exam.studyTime.substring(2, 4)}:${exam.studyTime.substring(4, 6)}`;
         studyInfo.push({ key:"studyTime",  label:i18n("label.studyTime"), value: studyTime, type: "time" });
      } else {
         studyInfo.push({ key:"studyTime",  label:i18n("label.studyTime"), value: exam.studyTime, type: "time" });
      }
      studyInfo.push({ key:"studyId",  label:i18n("label.studyId"), value: exam.studyID });
      studyInfo.push({ key:"studyDescription",  label:i18n("label.studyDescription"), value: exam.studyDescription });
      studyInfo.push({ key:"referringPhysicianName",  label:i18n("label.referringPhysiciansName"), value: exam.referringPhysicianName });
      studyInfo.push({ key:"studyInstanceUID",  label:i18n("label.studyInstanceUid"), value: exam.studyInstanceUID });
      examDispatch({ type: "SET_STUDY_INFO", data: studyInfo });
   }

   function createColumnDefs() {
      return [
         {
            headerName: i18n("label.modality"),
            field: "modality",
            suppressMenu: true,
            width: 90,
            cellStyle: { "text-align": "left" },
         },
         {
            headerName: i18n("label.bodypart"),
            field: "bodypart",
            suppressMenu: true,
            width: 100,
            cellStyle: { "text-align": "left" },
         },
         {
            headerName: i18n("label.id"),
            field: "pid",
            suppressMenu: true,
            width: 100,
            cellStyle: { "text-align": "left" },
         },
         {
            headerName: i18n("label.name"),
            field: "name",
            suppressMenu: true,
            width: 100,
            cellStyle: { "text-align": "left" },
         },
         {
            headerName: i18n("label.studyDate"),
            field: "studyDate",
            suppressMenu: true,
            width: 130,
            cellStyle: { "text-align": "left" },
         },
         {
            headerName: i18n("label.studyTime"),
            field: "studyTime",
            suppressMenu: true,
            width: 130,
            cellStyle: { "text-align": "left" },
         },
         {
            headerName: i18n("label.studyDesc"),
            field: "studyDescription",
            suppressMenu: true,
            width: 235,
            cellStyle: { "text-align": "left" },
         },
         {
            headerName: i18n("label.count"),
            field: "count",
            suppressMenu: true,
            width: 80,
            cellStyle: { "text-align": "left" },
         },
         {
            headerName: i18n("label.radiology"),
            field: "radiology",
            suppressMenu: true,
            width: 110,
            cellStyle: { "text-align": "left" },
         },
      ];
   }

   function patientInfoSetValue(data:any) {
      examDispatch({ type:"SET_PATIENT_INFO", data });
   }

   function clinicalInfoSetValue(data:any) {
      examDispatch({ type:"SET_CLINICAL_INFO", data });
   }

   function studyInfoSetValue(data:any) {
      examDispatch({ type:"SET_STUDY_INFO", data });
   }

   function clickSeries(idx: number, select: boolean) {
      if (select) {
         setImageList(idx);
      } else {
         resetImageList(idx);
      }
   }

   function resetImageList(seriesIndex: number) {
      const findIndex = state.series.findIndex((element: { idx: number; }) => element.idx === seriesIndex);
      const copyArray = [...state.series];
      if (findIndex !== -1) {
         copyArray[findIndex] = { ...copyArray[findIndex], selectedImages: [] };
      }
      examDispatch({ type: "SET_MODIFY_LIST", images: [], series: copyArray, selectedSeriesIdx: -1, selectedSeriesNumber: -1 });
   }

   function clickImage(images: any) {
      const findIndex = state.series.findIndex((element: { idx: number; }) => element.idx === state.selectedSeriesIdx);
      const copyArray = [...state.series];

      if (findIndex !== -1) {
         copyArray[findIndex] = { ...copyArray[findIndex], selectedImages: images, selected: true };
      }
      examDispatch({ type: "SET_MODIFY_LIST", series: copyArray, selectedSeriesIdx: state.selectedSeriesIdx, selectedSeriesNumber: state.selectedSeriesNumber });
   }

   function deleteSeries(images: any) {
      if (state.series.length === 1 && state.selectedSeriesIdx === 0) {
         toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.modifyExamDialog.removeSeriesWarning"), isErr:true });
      } else {
         const message: ConfirmProps["message"] = {
            contents: [i18n("msg.modifyExamDialog.removeSeries.0"), i18n("msg.modifyExamDialog.removeSeries.1")],
            title: i18n("label.removeSeries"),
            // key: "removeSeries",
            ok: i18n("button.yes"),
            cancel: i18n("button.no"),
            onOk: () => removeSeries(),
         };
         // message.params.onCancel = () => setOpen(false);
         setConfirmDialog({ message, open:true });
      }
   }

   function deleteImage(images: any) {
      if (state.series.length === 1 && (state.images.length === 1 || images.length === state.images.length) && state.selectedSeriesIdx === 0) {
         toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.modifyExamDialog.removeImageWarning"), isErr:true });
      } else {
         const message: ConfirmProps["message"] = {
            contents: [i18n("msg.modifyExamDialog.removeImage.0"), i18n("msg.modifyExamDialog.removeImage.1")],
            title: i18n("label.removeImage"),
            ok: i18n("button.yes"),
            cancel: i18n("button.no"),
            onOk: () => removeImage(),
         };
         // message.onCancel = () => setOpen(false);
         setConfirmDialog({ message, open:true });
      }
   }

   /**
    * 삭제된 이미지 리스트 추가
    * @param deleteSopInstanceUIDs
    */
   function addDeleteImages(deleteImageUIDs: any) {
      if (!deleteImageUIDs) return;
      const result: any = deleteSopInstanceUIDs || [];
      result.push(...deleteImageUIDs);
      setDeleteSopInstanceUIDs(result);
   }

   function removeSeries() {
      const { exam } = selectedRow;
      const selectedListIdx = state.series.filter((series: modifySeries) => series.selected).map((v:modifySeries) => v.idx);
      const image = exam[0].series.filter((series:modifySeries, i:number) => selectedListIdx.includes(i)).map((v: any) => v.image);
      const deleteImageUIDs = image.flatMap((arr:any) => arr.map((v: modifyImage) => v.sopInstanceUID));
      addDeleteImages(deleteImageUIDs);

      const newSeries: any = state.series;
      selectedListIdx.sort((a, b) => b - a).forEach(idx => newSeries.splice(idx, 1));
      newSeries.forEach((series:modifySeries, i:number) => {
         const temp = series;
         temp.idx = i;
         return temp;
      });
      // setSelectedRow({})
      examDispatch({ type: "SET_MODIFY_LIST", series: newSeries, images: [], selectedSeriesIdx: -1, selectedSeriesNumber: -1 });
   }

   function removeImage() {
      const { exam } = selectedRow;
      const selectedListIdx = state.images.filter((images: modifyImage) => images.selected).map((v:modifyImage) => v.idx);
      const images = exam[0].series[state.selectedSeriesIdx].image.filter((images: any, i:number) => selectedListIdx.includes(i));
      const deleteImageUIDs = images.flatMap((v:any) => v.sopInstanceUID);
      addDeleteImages(deleteImageUIDs);

      const newImages: any = state.images;
      selectedListIdx.sort((a, b) => b - a).forEach(idx => newImages.splice(idx, 1));
      newImages.forEach((image:modifyImage, i:number) => {
         const temp = image;
         temp.idx = i;
         return temp;
      });
      const newSeries: any = state.series;
      newSeries[state.selectedSeriesIdx].imageList = newImages;
      newSeries[state.selectedSeriesIdx].imageLength = newImages.length;
      newSeries[state.selectedSeriesIdx].selectedImages = [];

      examDispatch({ type: "SET_MODIFY_LIST",
         series: newSeries,
         images: newImages,
         selectedSeriesIdx : state.selectedSeriesIdx,
         selectedSeriesNumber :state.selectedSeriesNumber });
   }

   function doOk() {
      const message: ConfirmProps["message"] = {
         contents: [i18n("msg.modifyExamDialog.save.modifyExam.0"), i18n("msg.modifyExamDialog.save.modifyExam.1")],
         title: i18n("label.modifyExam"),
         // key: "modifyExam",
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: () => modifyExam(),
      };
      // message.params.onCancel = () => setOpen(false);
      setConfirmDialog({ message, open:true });
   }

   function setParamsObj(requestObj: any, dataObj: any) {
      const paramObj = dataObj;
      Object.keys(paramObj).forEach((v) => {
         if (paramObj[v].type) {
            switch (paramObj[v].type) {
            case "date": {
               paramObj[v].value = replaceAll(paramObj[v].value, ".", "");
               paramObj[v].value = replaceAll(paramObj[v].value, "-", "");
               break;
            }
            case "time": {
               paramObj[v].value = replaceAll(paramObj[v].value, ":", "");
               break;
            }
            default: {
               break;
            }
            }
         }
         // eslint-disable-next-line no-param-reassign
         requestObj[paramObj[v].key] = paramObj[v].value;
      });
      return requestObj;
   }

   function replaceAll(str: string, searchStr: string, replaceStr: string) {
      return str.split(searchStr).join(replaceStr);
   }

   function setModifiedSeries(modifiedRequest: any) {
      // 삭제된 시리즈를 서버에 보낼 파라미터에 저장
      // 시리즈의 삭제는 안의 이미지도 모두 삭제, 해당 케이스 processingStatus = temp, 케이스 생성
      const series = [];
      const contentId = [];
      const tempExam = state.series;
      for (let i = 0; i < tempExam.length; i++) {
         const seriesObj: any = {};
         const image = [];

         for (let j = 0; j < tempExam[i].imageList.length; j++) {
            if (tempExam[i].imageList[j].type === "jpeg") {
               const imageObj: any = {};
               imageObj.sopInstanceUID = "";
               imageObj.contentID = "";
               imageObj.fileType = "jpeg";
               contentId.push("");
               image.push(imageObj);
               seriesObj.seriesInstanceUID = "";
            } else {
               const imageObj: any = {};
               imageObj.sopInstanceUID = tempExam[i].imageList[j].sopInstanceUID;
               imageObj.contentID = tempExam[i].imageList[j].contentID;
               imageObj.fileType = "dcm";
               imageObj.instanceNumber = tempExam[i].imageList[j].instanceNumber;

               contentId.push(tempExam[i].imageList[j].contentID);
               image.push(imageObj);
               seriesObj.seriesInstanceUID = tempExam[i].seriesInstanceUID;
               seriesObj.seriesNumber = tempExam[i].seriesNumber;
            }
         }
         seriesObj.image = image;
         series.push(seriesObj);
      }
      // 기존 로직에서 파일 추가 기능이 있었음 => new exam
      // modifiedRequest.imageData = addFiles;

      // eslint-disable-next-line no-param-reassign
      modifiedRequest.series = series;
      // eslint-disable-next-line no-param-reassign
      modifiedRequest.contentId = contentId;
   }

   function modifyExam() {
      const modifiedRequest: any = {};
      modifiedRequest.id = selectedRow.id;
      setParamsObj(modifiedRequest, state.patientInfo);
      setParamsObj(modifiedRequest, state.clinicalInfo);
      setParamsObj(modifiedRequest, state.studyInfo);
      // Modify Exam창을 열때의 불러오는 저장된 Case의 이미지개수
      modifiedRequest.orgImageCount = selectedRow.contentId.length;
      modifiedRequest.deleteSopInstanceUIDs = deleteSopInstanceUIDs;
      setModifiedSeries(modifiedRequest);
      updateExamByObjectId(modifiedRequest).then((result:any) => {
         if (result.ok && result.status === 200) {
            result.json().then((item: any) => {
               getModifiedInfo(item.modifiedInfoId).then((response: any) => {
                  response.json().then((modifyInfo: any) => {
                     dispatch({ type: TechlistActionType.REFRESH_AND_SELECT_ROWS, payload: [{ id: item.id }, { id: modifyInfo.originCaseId }] });
                  });
               });
               if (item.isMatch) dispatch({ type: RelatedTechlistActionType.REDRAW_ORDER_ROWS, payload: [item.studies] });

               toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.techTabExam.modify.success"), isErr:false });
               handleClose();
            });
         } else if (result.status === 304) {
            const message: ConfirmProps["message"] = {
               contents: [i18n("msg.modifyExamDialog.save.responseWarning.0"), i18n("msg.modifyExamDialog.save.responseWarning.1")],
               title: i18n("label.error"),
               ok: i18n("button.close"),
            };
            // message.params.onCancel = () => setOpen(false);
            setConfirmDialog({ message, open:true });
         } else {
            toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.techTabExam.modify.fail"), isErr:true });
         }
      });
   }

   function modifySeries(newSeries: Array<modifySeries>) {
      examDispatch({ type: "SET_MODIFY_LIST", series: newSeries, images: [], selectedSeriesIdx: -1, selectedSeriesNumber: -1 });
   }

   function modifyImages(newImages: Array<modifyImage>) {
      const newSeries: any = state.series;
      newSeries[state.selectedSeriesIdx].imageList = newImages;
      newSeries[state.selectedSeriesIdx].displayImage = newImages[0].image;
      examDispatch({ type: "SET_MODIFY_LIST",
         series: newSeries,
         images: newImages,
         selectedSeriesIdx: state.selectedSeriesIdx,
         selectedSeriesNumber: state.selectedSeriesNumber });
   }

   function doClear() {
      setConfirmDialog(null);
   }

   const handleClose = (event?: any, reason?: string) => {
      if (reason === "backdropClick") {
         return;
      }
      caseLockUpdate(false, selectedRow.id);
      doClear();
      setOpen(false);
      dialog_closed();
   };

   function prevent_event_propagation(event: React.MouseEvent, reason?: string) {
      // event.stopPropagation();
   }

   function close_if_esc(event: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(event)) {
         handleClose();
         event.stopPropagation();
      }
   }

   function dialog_closed() {
      doClear();
      // @ts-ignore
      window.dialog_closed();
      dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   function getModifiedInfo(modifiedInfoId: any) {
      // if (!modifiedInfoId) return; -- type script 에러로 인한 주석 확인 필요
      return new Promise((resolve) => {
         fetch(`/api/case/modifyInfo/${modifiedInfoId}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
         }).then((response) => {
            if (response.ok) {
               resolve(response);
            } else {
               resolve(false);
            }
         });
      });
   }

   function caseLockUpdate(flag: boolean, id: string) {
      const params: any = {};
      params.caseIdList = [];
      params.caseIdList.push(id);

      TechnicianUtils.caseLockUpdate({ flag, list: params }).then((result) => {
         if (result) {
            // redraw selected technician rows
            dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
         } else {
            toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.caseLockFail"), isErr:true });
         }
      });
   }

   function getExamInfo(id: string) {
      return new Promise((resolve) => {
         fetch(`/api/exchange/worklist/idfilter/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse[0]);
               });
            }
            else {
               console.log("fail");
            }
         });
      });
   }

   // get Exam List By PatientID
   function findWorklistByPatientId(id: string) {
      return new Promise((resolve) => {
         fetch(`/api/exchange/worklist/patientId/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               console.error("get worklist by patientID is Failed");
            }
         });
      });
   }

   function updateExamByObjectId(exam: any) {
      return new Promise((resolve) => {
         fetch(`/api/case/${exam.id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(exam),
         }).then((response) => {
            resolve(response);
         }).catch((error) => {
            console.log(error);
         });
      });
   }

   function caseUnlock(params: any) {
      fetch(`/api/tech/studylist/caseUnlock`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt")!,
            "Content-Type": "application/json",
         },
         body: JSON.stringify(params),
         keepalive: true,
      });
   }

   const classes = DialogStyles();
   return (
      <>
         <Dialog
            open={open}
            onClose={handleClose}
            onClick={prevent_event_propagation}
            onKeyDown={close_if_esc}
            fullWidth
            maxWidth={"xl"}
            PaperProps={{ sx:{ height: "100%" } }}
         >
            <Box className={classes.container}>
               <DialogTitle className={classes.dialogTitle} component={"p"}>{i18n("label.modifyExam")}</DialogTitle>
               <IconButton sx={{ color: "#fff" }} onClick={handleClose}>
                  <Close/>
               </IconButton>
            </Box>
            <DialogContent dividers>
               <Box className={classes.rowDirection}>
                  <Box className={classes.rowDirectionWrapContainer}>
                     <Box sx={{ height: "70%", display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ width: "25%" }}>
                           <ModifySeriesList
                              title={i18n("label.series")}
                              data={state.series}
                              clickItem={clickSeries}
                              deleteItem={deleteSeries}
                              modifyItem={modifySeries}
                              selectedIndex={state.selectedSeriesIdx}/>
                        </Box>
                        <Box sx={{ width: "75%" }}>
                           <ModifyImageList
                              title={i18n("label.image")}
                              data={state.images}
                              clickItem={clickImage}
                              deleteItem={deleteImage}
                              modifyItem={modifyImages}
                              selectedSeriesNumber={state.selectedSeriesNumber}/>
                        </Box>
                     </Box>
                     { useMemo(() =>
                        <>
                           <Box sx={{
                              width: "100%",
                              height: "30%",
                           }}>
                              <Box className="class-container ag-theme-balham-dark" sx={{
                                 width: "calc(100% - 10px)",
                                 height: "100%",
                              }}>
                                 <AgGridReact
                                    gridOptions={gridOptions}
                                    rowData={rowData}
                                 />
                              </Box>
                           </Box>
                        </>, [rowData])
                     }
                  </Box>
                  <Box className={classes.formBoxInnerSmall}>
                     <Box sx={{
                        height: "calc(100% - 60px)",
                        width: "100%",
                     }}>
                        <Box sx={{
                           lineHeight: "2em",
                           height: "20%",
                        }}>
                           <EditForm title={i18n("label.patientInfo")} data={state.patientInfo} changeValue={patientInfoSetValue}/>
                        </Box>
                        <Box sx={{
                           lineHeight: "2em",
                           height: "20%",
                        }}>
                           <EditMultiLineForm title={i18n("label.clinicalInfo")} data={state.clinicalInfo} changeValue={clinicalInfoSetValue}/>
                        </Box>
                        <Box sx={{
                           lineHeight: "2em",
                           height: "60%",
                        }}>
                           <EditForm title={i18n("label.studyInfo")} data={state.studyInfo} changeValue={studyInfoSetValue}/>
                        </Box>
                     </Box>
                     <Box className={classes.buttonGroup} sx={{
                        height: "60px",
                     }}>
                        <OkBtn onClick={doOk} text={i18n("button.modify")}></OkBtn>
                        <CancelBtn onClick={handleClose} text={i18n("button.cancel")}/>
                     </Box>
                  </Box>
               </Box>
            </DialogContent>
         </Dialog>
         {
            confirmDialog
          && <Confirm
             message={confirmDialog.message}
             open={confirmDialog.open}
             inner={true}
             onClose={() => {
                setConfirmDialog({ ...confirmDialog, open:false });
             }}/>
         }
      </>
   );
}
