import React, { useEffect, useState } from "react";
import {
   Box,
   Checkbox,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControlLabel,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import {
   have_to_move_to_left,
   have_to_move_to_right,
   is_enter,
   is_esc,
} from "../../dialog/common-keyboard-handler";
import store from "../../redux/store";
import { CommonActionType } from "../../redux/reducers/common";
import useDialogOpen from "../../hooks/useDialogOpen";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { StyledFocusButton } from "./styles/EmotionStyles";

export interface ConfirmProps {
    open?: boolean,
    message: {
        title: string,
        contents: string[],
        ok: string,
        onOk?: (result?: ConfirmResult) => void,
        cancel?: string,
        onCancel?: (result?: ConfirmResult) => void,
        discard?: string,
        onDiscard?: (result?: ConfirmResult) => void,
        checkbox?: CheckboxProps,
    },
    onClose?: Function,
    inner?: boolean,
}

export interface ConfirmResult {
    checked: boolean
}

interface CheckboxProps {
    label: string,
    defaultChecked?: boolean
}

interface ButtonIndexInfo {
    [index: string] : any
    ok?: number;
    cancel?: number;
    discard?: number;
}
export default function Confirm(props: ConfirmProps) {
   const [open, setOpen] = useState(true);
   const [checkbox, setCheckbox] = useState<CheckboxProps | undefined>(undefined);
   const [checked, setChecked] = useState<boolean>(false);
   const [buttonCount, setButtonCount] = useState<number>(0);
   const [buttonIndexInfo, setButtonIndexInfo] = useState<ButtonIndexInfo>({});
   const [focusIndex, setFocusIndex] = useState<number>(0);
   useDialogOpen();

   useEffect(() => {
      setOpen(props.open ? props.open : open);
      if (props.message.checkbox) setCheckbox(props.message.checkbox);
      if (props.message) {
         let buttonCnt = 0;
         const btnIndex = {
            ok: -1,
            cancel: -1,
            discard: -1,
         };
         if (props.message.onOk) {
            btnIndex.ok = buttonCnt;
            buttonCnt++;
         }
         if (props.message.onDiscard) {
            btnIndex.discard = buttonCnt;
            buttonCnt++;
         }
         if (props.message.onCancel) {
            btnIndex.cancel = buttonCnt;
            buttonCnt++;
         }
         setButtonIndexInfo(btnIndex);
         setButtonCount(buttonCnt);
      }
   }, [props]);

   useDidMountEffect(() => {
      if (checkbox && checkbox.defaultChecked !== undefined) setChecked(checkbox.defaultChecked);
   }, [checkbox]);

   const handleClose = (event?: any, reason?: string) => {
      if (reason === "backdropClick") {
         return;
      }
      setOpen(false);
      if (typeof props.onClose === "function") {
         props.onClose();
      }
      dialog_closed();
   };

   function prevent_event_propagation(event: React.MouseEvent) {
      event.stopPropagation();
   }

   /*
    * Keyboard Event handler
    * */
   function handleKeyUp(e: React.KeyboardEvent<HTMLDivElement>) {
      if (have_to_move_to_right(e)) {
         setFocusIndex((prevState: number) => (prevState + 1) % buttonCount);
      } else if (have_to_move_to_left(e)) {
         setFocusIndex((prevState: number) => (prevState === 0 ? buttonCount - 1 : focusIndex - 1));
      } else if (is_enter(e)) {
         if (focusIndex === buttonIndexInfo.ok) setCallback(props.message.onOk);
         else if (focusIndex === buttonIndexInfo.cancel) setCallback(props.message.onCancel);
         else if (focusIndex === buttonIndexInfo.discard) setCallback(props.message.onDiscard);
         handleClose();
      } else if (is_esc(e)) {
         setCallback(props.message.onDiscard);
         handleClose();
      }

      e.stopPropagation();
   }

   function close_if_esc(event: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(event)) {
         handleClose();
         event.stopPropagation();
      }
   }

   function dialog_closed() {
      if (!props.inner) {
         // @ts-ignore
         window.dialog_closed();
         store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
      }
   }

   function setCallback(callback?: (result?: ConfirmResult) => void): void {
      if (typeof callback === "function") {
         if (checkbox) { // return 할 결과가 있다면
            callback({ checked });
         } else {
            callback();
         }
      }
   }

   function checkContents() {
      const { contents } = props.message;
      if (typeof contents === "string") {
         return <p>{contents}</p>;
      }
      return contents.map((item: any, idx: number) => <span key={idx} style={{ paddingBottom: "12px" }}>{item}</span>);
   }

   function matchesIndex(type: string) : number {
      return +(buttonIndexInfo[type] !== -1 && focusIndex === buttonIndexInfo[type]);
   }

   return (
      <Dialog
         open={open}
         onClose={handleClose}
         onClick={prevent_event_propagation}
         onKeyDown={close_if_esc}
         onKeyUp={handleKeyUp}
         fullWidth
         // maxWidth={"xs"}
         PaperProps={{ sx:{ backgroundColor: "#394451",
            height: "fit-content",
            width: "fit-content",
            minWidth: "375px",
            padding: "5px" } }} >
         <Box
            sx={{
               color: "#ccc",
               display: "flex",
               alignItems: "center",
               paddingLeft: "16px",
               paddingRight: "16px",
            }}
         >
            <Warning/>
            <DialogTitle sx={{ fontSize: "20px" }}>{props.message.title}</DialogTitle>
         </Box>
         <DialogContent sx={{
            background: "#394451",
         }}>
            <DialogContentText sx={{
               color: "#ccc",
               display: "flex",
               flexDirection: "column",
               alignItems: "left",
               paddingLeft: "15px",
               paddingRight: "15px",
            }}>
               {checkContents()}
               {checkbox
                && <FormControlLabel control={
                   <Checkbox value={checked} onChange={e => setChecked(e.target.checked)}/>
                } label={checkbox.label} />}
            </DialogContentText>
         </DialogContent>
         <DialogActions sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center" }}>
            {
               props.message.ok
             && <StyledFocusButton
                variant="contained"
                size={"medium"}
                onClick={() => { setCallback(props.message.onOk); handleClose(); }}
                focus={matchesIndex("ok")}
             >{props.message.ok}</StyledFocusButton>
            }
            {
               props.message.discard
             && <StyledFocusButton
                variant="contained"
                size={"medium"}
                onClick={() => { setCallback(props.message.onDiscard); handleClose(); }}
                focus={matchesIndex("discard")}
                sx={{
                   color: "#ccc",
                   background: "#4c5667",
                }}
             >{props.message.discard}</StyledFocusButton>
            }
            {
               props.message.cancel
             && <StyledFocusButton
                variant="contained"
                size={"medium"}
                onClick={() => { setCallback(props.message.onCancel); handleClose(); }}
                focus={matchesIndex("cancel")}
                sx={{
                   color: "#ccc",
                   background: "#4c5667",
                }}
             >{props.message.cancel}</StyledFocusButton>
            }
         </DialogActions>
      </Dialog>
   );
}
