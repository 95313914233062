export interface DDPSetting {
    id: string;
    userId?: string;
    ddp: DDP;
}

export interface DDP {
    idx: number;
    modality: string;
    bodypart: string;
    viewType: string;
    studyDescription: string;
    layout: DDPLayout;
    group: Array<DDPGroup>;
}

export interface DDPLayout {
    x: number;
    y: number;
}

export interface DDPGroup {
    groupType: string;
    seq: string;
    position: Array<DDPPosition>;
    studyObjectId: string;
}

export interface DDPPosition {
    groupPos: string;
    imagePos: string;
}

export interface Info {
    width: number;
    height: number;
    editMode?: EditMode;
    mouseDragCellIndex?: number;
    selectCell?: SelectCell;
    setPosition?: SetPosition;
    insertSetPosition?: InsertSetPosition;
}

export interface SelectCell {
    rows: number;
    columns: number;
    cells: Array<any>;
    selectedRow: number;
    selectedColumn: number;
    selectedIndex: number;
    backColor: string;
    borderColor: string;
    selectedBackColor: string;
    textFont: string;
    textColor: string;
}

export interface SetPosition {
    paddingWidth: number;
    gapHeight: number;
    buttonGapWidth: number;
    buttons: Array<any>;
    rows: number;
    columns: number;
    command: string;
    foreColor: string;
    backColor: string;
    borderColor: string;
    selectedBackColor: string;
    commandBackColors: any;
    textFont: string;
    textColor: string;
    drags: Array<number>;
    mouseDragCellIndex: number;
    cells: Array<any>;
    x?: number;
    y?: number;
}

export interface InsertSetPosition {
    buttons: Array<any>;
}

export enum EditMode {
    SELECT_CELL = 0,
    SET_POSITION = 1,
    SET_INSERT_POSITION = 2
}

export enum ButtonType {
    BUTTON = 0,
    RADIO = 1,
}
