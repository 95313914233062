import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, FormGroup, RadioGroup } from "@mui/material";
import { useSelector } from "react-redux";
import { SwatchesPicker } from "react-color";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import i18n from "../../../../utils/i18n-utils";
import {
   StyledColorPickerWrapper,
   StyledLabel,
   StyledSettingContentColumn,
   StyledSettingContentRow,
   StyledSliderInfo,
} from "./styles/SettomgFilmboxStyles";
import { StyledInfoIcon, StyledInfoWrapper } from "../../styles/EmotionStyles";
import { StyledCheckBoxWrapper } from "./styles/SettomgReportStyles";
import NumberTypeField from "../../../input/textfield/NumberTypeField";
import { StyledInputLabel } from "./styles/SettingDDPStyles";
import FilmboxWwlPreset from "./FilmboxWwlPreset";
import {
   ItemConfig,
   MagnifyConfig,
   SaveConfig,
   TabConfig,
   TextConfig,
   UserStyleType,
} from "../../../../types/UserStyleType";
import {
   StyledMenuItem, StyledRadio,
   StyledSelect,
   StyledSettingContainer,
   StyledSettingContents,
   StyledSettingWrapper,
} from "./styles/SettomgStyles";
import { RootState } from "../../../../redux/reducers";
import store from "../../../../redux/store";
import { CommonActionType } from "../../../../redux/reducers/common";
import { useToastDispatch } from "../../../../context/ToastContext";
import { StyledCheckBox } from "../../styles/CommonDialogStyles";

export default function SettingFilmbox() {
   const [saveConfig, setSaveConfig] = useState<SaveConfig>({
      multiSave: false,
      annotation: true,
      wwl: false,
      zoomPan: false,
      reorder: false,
      dialogOpen: true,
   });
   const [magnifyConfig, setMagnifyConfig] = useState<MagnifyConfig>({
      glassShape: "circle",
      glassSize: "300.0",
      imageScale: "2.0",
   });
   const [textConfig, setTextConfig] = useState<TextConfig>({
      font:  "arial",
      fontColor: "#ffffff",
      fontSize: "20",
   });
   const [tabConfig, setTabConfig] = useState<TabConfig>({
      tabType: "replaceTab",
      relatedStudyNum: 3,
   });
   const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
   const toastDispatch = useToastDispatch();
   const userConfig = useSelector((state : RootState) => state.common.userConfig);

   useEffect(() => {
      if (userConfig) {
         initFilmboxSetting(userConfig);
      }
   }, []);

   useEffect(() => {
      initFilmboxSetting(userConfig);
   }, [userConfig]);

   function openToast(msg: string, isErr: boolean) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   }

   function initFilmboxSetting(s?: any) {
      if (s && s.filmbox) {
         const { itemConfig } = s.filmbox;
         if (itemConfig) {
            if (itemConfig.saveConfig) setSaveConfig(itemConfig.saveConfig);
            if (itemConfig.magnifyConfig) setMagnifyConfig(itemConfig.magnifyConfig);
            if (itemConfig.tabConfig) setTabConfig(itemConfig.tabConfig);
            if (itemConfig.textConfig) setTextConfig(itemConfig.textConfig);
         }
      }
   }

   function closeOpenColorPicker() {
      if (openColorPicker) setOpenColorPicker(false);
   }

   function handleSaveConfigChange(e: React.ChangeEvent<HTMLInputElement>, type: string) {
      const value = e.target.checked;
      setSaveConfig(prevState => ({
         ...prevState,
         [type]: value,
      }));

      updateItemConfig({ saveConfig: { ...saveConfig, [type]: value } });
   }

   function handleMagnifyConfigChange(e: React.ChangeEvent<HTMLInputElement>, type: string) {
      const { value } = e.target;
      setMagnifyConfig(prevState => ({
         ...prevState,
         [type]: value,
      }));

      updateItemConfig({ magnifyConfig: { ...magnifyConfig, [type]: value } });
   }

   function handleTextConfigChange(e: any, type: string) {
      const value = (type !== "fontColor") ? e.target.value : e.hex;
      console.log(`value: ${value}`);
      setTextConfig(prevState => ({
         ...prevState,
         [type]: value,
      }));

      updateItemConfig({ textConfig: { ...textConfig, [type]: value } });

      if (type === "fontColor") {
         openToast("Completed saving font colors", false);
         closeOpenColorPicker();
      }
   }

   function handleTabConfigChange(e: React.ChangeEvent<HTMLInputElement>, type: string) {
      const { value } = e.target;
      setTabConfig(prevState => ({
         ...prevState,
         [type]: value,
      }));

      updateItemConfig({ tabConfig: { ...tabConfig, [type]: value } });
   }

   function updateItemConfig(itemConfig: ItemConfig) {
      new Promise<UserStyleType>((resolve, reject) => {
         fetch(`/api/user/option/itemconfig`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(itemConfig),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`status: ${response.status}, message: ${response.statusText}`));
            }
         }).catch(err => console.error("Message : ", err));
      }).then((result) => {
         if (result) {
            const { filmbox } = result;
            store.dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { ...userConfig, filmbox } });
         }
      });
   }

   return (
      <>
         {/* <StyledSettingContainer onClick={closeOpenColorPicker}> */}
         <StyledSettingContainer>
            {/* Setting Wrapper 1 */}
            <StyledSettingWrapper>
               <StyledSettingContents>
                  <StyledSettingContentRow>
                     <StyledLabel>{i18n("label.tabConfig.title")}</StyledLabel>
                     <StyledSettingContentColumn>
                        <StyledSettingContentRow>
                           <FormControl>
                              <RadioGroup
                                 style={{ display: "flex" }}
                                 aria-labelledby="radio-buttons-group-label"
                                 value={tabConfig.tabType}
                                 onChange={e => handleTabConfigChange(e, "tabType")}
                                 name="radio-buttons-group"
                                 row
                              >
                                 <FormControlLabel style={{ padding: "0", marginRight: "15px" }} value="replaceTab" control={<StyledRadio />} label={i18n("label.tabConfig.type.0")} />
                                 <FormControlLabel style={{ padding: "0", marginRight: "15px" }} value="addTab" control={<StyledRadio />} label={i18n("label.tabConfig.type.1")} />
                              </RadioGroup>
                           </FormControl>
                           <StyledInfoWrapper>
                              <StyledInfoIcon/>
                              <div className="view-info">
                                 • {i18n("label.tabConfig.typeDesc.0")} <br/>
                                 • {i18n("label.tabConfig.typeDesc.1")}
                              </div>
                           </StyledInfoWrapper>
                        </StyledSettingContentRow>
                     </StyledSettingContentColumn>
                  </StyledSettingContentRow>
               </StyledSettingContents>
            </StyledSettingWrapper>

            {/* Setting Wrapper 2 */}
            <StyledSettingWrapper>
               {/* 1 */}
               <StyledSettingContents>
                  <StyledSettingContents>
                     <StyledSettingContentRow>
                        <StyledLabel>{i18n("label.saveConfig.title")}</StyledLabel>
                        <StyledSettingContentColumn>
                           <StyledSettingContentRow>
                              <StyledCheckBoxWrapper>
                                 <FormGroup>
                                    <FormControlLabel control={
                                       <StyledCheckBox
                                          // sx={{}}
                                          onChange={e => handleSaveConfigChange(e, "multiSave")}
                                          checked={saveConfig.multiSave}
                                       />
                                    } label={i18n("label.saveConfig.multiSave")} />
                                 </FormGroup>
                              </StyledCheckBoxWrapper>

                              <StyledInfoWrapper>
                                 <StyledInfoIcon/>
                                 <div className="view-info">
                                    • {i18n("label.saveConfig.multiSaveDesc.0")} <br/>
                                    • {i18n("label.saveConfig.multiSaveDesc.1")} <br/>
                                 </div>
                              </StyledInfoWrapper>
                           </StyledSettingContentRow>
                           <StyledSettingContentRow>
                              <StyledCheckBoxWrapper
                                 style={{ marginLeft: "15px" }}
                              >
                                 <FormGroup>
                                    <FormControlLabel control={
                                       <StyledCheckBox
                                          onChange={e => handleSaveConfigChange(e, "annotation")}
                                          checked={saveConfig.annotation}
                                       />
                                    } label={i18n("label.saveConfig.annotation")} />
                                 </FormGroup>
                              </StyledCheckBoxWrapper>
                           </StyledSettingContentRow>
                           <StyledSettingContentRow>
                              <StyledCheckBoxWrapper
                                 style={{ marginLeft: "15px" }}
                              >
                                 <FormGroup>
                                    <FormControlLabel control={
                                       <StyledCheckBox
                                          onChange={e => handleSaveConfigChange(e, "zoomPan")}
                                          checked={saveConfig.zoomPan}
                                       />
                                    } label={"Zooming/Panning"} />
                                 </FormGroup>
                              </StyledCheckBoxWrapper>
                           </StyledSettingContentRow>
                           <StyledSettingContentRow>
                              <StyledCheckBoxWrapper
                                 style={{ marginLeft: "15px" }}
                              >
                                 <FormGroup>
                                    <FormControlLabel control={
                                       <StyledCheckBox
                                          onChange={e => handleSaveConfigChange(e, "wwl")}
                                          checked={saveConfig.wwl}
                                       />
                                    } label={i18n("label.saveConfig.wwl")} />
                                 </FormGroup>
                              </StyledCheckBoxWrapper>
                           </StyledSettingContentRow>
                           <StyledSettingContentRow>
                              <StyledCheckBoxWrapper
                                 style={{ marginLeft: "15px" }}
                              >
                                 <FormGroup>
                                    <FormControlLabel control={
                                       <StyledCheckBox
                                          onChange={e => handleSaveConfigChange(e, "reorder")}
                                          checked={saveConfig.reorder}
                                       />
                                    } label={i18n("label.saveConfig.reorder")} />
                                 </FormGroup>
                              </StyledCheckBoxWrapper>
                           </StyledSettingContentRow>
                           <StyledSettingContentRow>
                              <StyledCheckBoxWrapper
                                 style={{ paddingBottom: "0" }}
                              >
                                 <FormGroup>
                                    <FormControlLabel control={
                                       <StyledCheckBox
                                          onChange={e => handleSaveConfigChange(e, "dialogOpen")}
                                          checked={saveConfig.dialogOpen}
                                       />
                                    } label={i18n("label.saveConfig.dialogOpen")} />
                                 </FormGroup>
                              </StyledCheckBoxWrapper>
                           </StyledSettingContentRow>
                        </StyledSettingContentColumn>
                     </StyledSettingContentRow>
                  </StyledSettingContents>
               </StyledSettingContents>

               {/* 2 */}
               <StyledSettingContents>
                  <StyledSettingContentRow>
                     <StyledLabel>{i18n("menu.MagnifyConfig")}</StyledLabel>
                     <StyledSettingContentColumn style={{ width: "100%" }}>
                        <StyledSettingContentRow>
                           <StyledLabel style={{ marginRight: "10px" }}>{i18n("label.glassShape")}</StyledLabel>
                           <FormControl>
                              <RadioGroup
                                 style={{ display: "flex" }}
                                 aria-labelledby="radio-buttons-group-label"
                                 value={magnifyConfig.glassShape}
                                 onChange={e => handleMagnifyConfigChange(e, "glassShape")}
                                 name="radio-buttons-group"
                                 row
                              >
                                 <FormControlLabel style={{ padding: "0", marginRight: "15px" }} value="circle" control={<StyledRadio />} label={i18n("label.circle")} />
                                 <FormControlLabel style={{ padding: "0", marginRight: "15px" }} value="rect" control={<StyledRadio />} label={i18n("label.rectangle")} />
                              </RadioGroup>
                           </FormControl>
                        </StyledSettingContentRow>
                        <StyledSettingContentColumn>
                           <StyledSliderInfo>{i18n("label.glassSize")}</StyledSliderInfo>
                           <input value={+magnifyConfig.glassSize} onChange={e => handleMagnifyConfigChange(e, "glassSize")} id="glassSize" className="inputRange" style={{ width: "300px" }} min="100.0" max="1000.0" step="0.1" type="range"/>
                        </StyledSettingContentColumn>
                        <StyledSettingContentRow>
                           <StyledSliderInfo>{magnifyConfig.glassSize}</StyledSliderInfo>
                        </StyledSettingContentRow>
                        <StyledSettingContentColumn>
                           <StyledSliderInfo>{i18n("label.imageScale")}</StyledSliderInfo>
                           <input value={+magnifyConfig.imageScale} onChange={e => handleMagnifyConfigChange(e, "imageScale")} id="imageScale" className="inputRange" style={{ width: "300px" }} min="2.0" max="8.0" step="0.1" type="range"/>
                        </StyledSettingContentColumn>
                        <StyledSettingContentRow>
                           <StyledSliderInfo>{magnifyConfig.imageScale}</StyledSliderInfo>
                        </StyledSettingContentRow>
                     </StyledSettingContentColumn>
                  </StyledSettingContentRow>
               </StyledSettingContents>

               {/* 3 */}
               <StyledSettingContents>
                  <StyledSettingContentRow>
                     <StyledLabel>{i18n("menu.textConfig")}</StyledLabel>
                     <StyledSettingContentColumn style={{ width: "230px" }}>
                        <StyledSettingContentRow style={{ marginBottom: "10px" }}>
                           <NumberTypeField
                              label={i18n("label.size")}
                              shrink
                              value={textConfig.fontSize}
                              onChange={e => handleTextConfigChange(e, "fontSize")}
                              variant="standard"
                           />
                           <div>px</div>
                           {
                              openColorPicker
                                 ? <StyledColorPickerWrapper>
                                    <SwatchesPicker
                                       width={300}
                                       color={textConfig.fontColor}
                                       onChange={(e => handleTextConfigChange(e, "fontColor"))}
                                    />
                                 </StyledColorPickerWrapper>
                                 : null
                           }
                           <FormatColorFillIcon sx={{ width: 30, height: 30, color: textConfig.fontColor, cursor: "pointer" }} onClick={() => setOpenColorPicker(true)} style={{ marginLeft: 5 }}> {textConfig.fontColor}</FormatColorFillIcon>
                        </StyledSettingContentRow>
                        <FormControl variant="standard" sx={{ minWidth: 230 }}>
                           <StyledInputLabel id="select-view-standard">{i18n("label.font")}</StyledInputLabel>
                           <StyledSelect
                              id="select-view-standard"
                              value={textConfig.font}
                              onChange={e => handleTextConfigChange(e, "font")}
                           >
                              <StyledMenuItem value="arial">arial</StyledMenuItem>
                              <StyledMenuItem value="Roboto">Roboto</StyledMenuItem>
                              <StyledMenuItem value="Roboto Slab">Roboto Slab</StyledMenuItem>
                              <StyledMenuItem value="Roboto Condensed">Roboto Condensed</StyledMenuItem>
                              <StyledMenuItem value="Roboto Mono">Roboto Mono</StyledMenuItem>
                              <StyledMenuItem value="Noto Sans HK">Noto Sans HK</StyledMenuItem>
                              <StyledMenuItem value="Open Sans">Open Sans</StyledMenuItem>
                              <StyledMenuItem value="Open Sans Condensed">Open Sans Condensed</StyledMenuItem>
                              <StyledMenuItem value="Josefin Sans">Josefin Sans</StyledMenuItem>
                              <StyledMenuItem value="Montserrat">Montserrat</StyledMenuItem>
                              <StyledMenuItem value="Oswald">Oswald</StyledMenuItem>
                              <StyledMenuItem value="Ranga">Ranga</StyledMenuItem>
                              <StyledMenuItem value="Indie Flower">Indie Flower</StyledMenuItem>
                              <StyledMenuItem value="Poppins">Poppins</StyledMenuItem>
                              <StyledMenuItem value="Ubuntu">Ubuntu</StyledMenuItem>
                              <StyledMenuItem value="ZCOOL KuaiLe">ZCOOL KuaiLe</StyledMenuItem>
                              <StyledMenuItem value="Titillium Web">Titillium Web</StyledMenuItem>
                              <StyledMenuItem value="Lobster">Lobster</StyledMenuItem>
                              <StyledMenuItem value="Nanum Pen Script">Nanum Pen Script</StyledMenuItem>
                              <StyledMenuItem value="Pacifico">Pacifico</StyledMenuItem>
                              <StyledMenuItem value="Gloria Hallelujah">Gloria Hallelujah</StyledMenuItem>
                           </StyledSelect>
                        </FormControl>
                     </StyledSettingContentColumn>
                  </StyledSettingContentRow>
               </StyledSettingContents>

               {/* 4 */}
               <StyledSettingContents>
                  <StyledSettingContentRow>
                     <StyledLabel>{i18n("menu.wwlPreset")}</StyledLabel>
                     <StyledSettingContentColumn>
                        <FilmboxWwlPreset />
                     </StyledSettingContentColumn>
                  </StyledSettingContentRow>
               </StyledSettingContents>
            </StyledSettingWrapper>
         </StyledSettingContainer>
      </>
   );
}
