import React, { useEffect, useRef, useState } from "react";
import { Box, Modal } from "@mui/material";
import { StyledCloseIconButton, StyledKindButton } from "../styles/EmotionStyles";
import i18n from "../../../utils/i18n-utils";
import {
   StyledContainer, StyledSettingBox, StyledSettingBtnWrapper, StyledSettingBtnWrappers,
   StyledSettingTitle,
   StyledSettingTitleWrapper,
   StyledSettingWrapper, StyledTab, StyledTabs, StyledTabWrapper,
} from "./styles/SettingDalogStyles";
import SettingDDP from "./item/SettingDDP";
import SettingShortcut from "./item/SettingShortcut";
import SettingMacro from "./item/SettingMacro";
import SettingReport from "./item/SettingReport";
import SettingFilmbox from "./item/SettingFilmbox";
// import "../../../dialog/setting/setting-ddp";
// import "../../../dialog/setting/setting-shortcut";
// import "../../../dialog/setting/setting-macro";
// import "../../../dialog/setting/setting-report";
// import "../../../dialog/setting/setting-filmbox";
import { is_esc } from "../../../dialog/common-keyboard-handler";
import store from "../../../redux/store";
import { CommonActionType } from "../../../redux/reducers/common";
import CancelBtn from "../../button/CancelBtn";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

/*
* 참고: https://mui.com/material-ui/react-tabs/
* */
function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box>
               <div>{children}</div>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}`,
   };
}

export enum SettingDialogType {
   DDP = 0,
   SHORTCUT = 1,
   MACRO = 2,
   REPORT = 3,
   FILMBOX = 4
}

interface SettingDialogProps {
   open: boolean;
   type: SettingDialogType;
}

export default function SettingDialog(props: SettingDialogProps) {
   const dialogRef = useRef(null);
   const [open, setOpen] = useState<boolean>(true);
   const [tabValue, setTabValue] = useState<SettingDialogType>(SettingDialogType.DDP);

   useEffect(() => {
      if (props.type) setTabValue(props.type);
   }, [props.type]);

   useEffect(() => {
      if (props.open) {
         // @ts-ignore
         window.dialog_opened({ dialog:dialogRef.current, is_modal:true });

         setOpen(props.open);
      }
   }, [props.open]);

   function handleChange(event: React.SyntheticEvent, newValue: SettingDialogType) {
      setTabValue(newValue);
   }

   function closeDialog() {
      // @ts-ignore
      window.dialog_closed();

      setTabValue(SettingDialogType.DDP);
      setOpen(false);
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   function close_if_esc(e: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(e)) {
         closeDialog();
         e.stopPropagation();
      }
   }

   return (
      <>
         <Modal
            open={open}
            onKeyDown={close_if_esc}
            sx={{ width: "100%" }}
         >
            <div style={{ width: "100%" }}>
               <StyledSettingBox ref={dialogRef}>
                  <StyledContainer>
                     <StyledSettingTitleWrapper>
                        <StyledSettingTitle>
                           {i18n("label.setting")}
                        </StyledSettingTitle>
                        <StyledCloseIconButton onClick={closeDialog}/>
                     </StyledSettingTitleWrapper>

                     <Box sx={{ width: "100%" }}>
                        <StyledSettingWrapper sx={{ borderBottom: 1, borderColor: "divider" }}>
                           <StyledTabs
                              value={tabValue}
                              onChange={handleChange}
                           >
                              <StyledTab label={i18n("label.hangingProtocols")} {...a11yProps(0)} />
                              <StyledTab label={i18n("label.shortcut")} {...a11yProps(1)}/>
                              <StyledTab label={i18n("label.macro")} {...a11yProps(2)}/>
                              <StyledTab label={i18n("label.report")} {...a11yProps(3)}/>
                              <StyledTab label={i18n("label.filmbox")} {...a11yProps(4)}/>
                           </StyledTabs>
                        </StyledSettingWrapper>
                     </Box>

                     <StyledTabWrapper>
                        <TabPanel index={SettingDialogType.DDP} value={tabValue}>
                           <SettingDDP/>
                           {/* <setting-ddp id="settingDDP" modify-warning-dialog="[[$.settingSaveButtonOver]]"></setting-ddp> */}
                        </TabPanel>
                        <TabPanel index={SettingDialogType.SHORTCUT} value={tabValue}>
                           <SettingShortcut/>
                           {/* <setting-shortcut id="settingShortcut"></setting-shortcut> */}
                        </TabPanel>
                        <TabPanel index={SettingDialogType.MACRO} value={tabValue}>
                           <SettingMacro/>
                           {/* <setting-macro id="settingMacro"></setting-macro> */}
                        </TabPanel>
                        <TabPanel index={SettingDialogType.REPORT} value={tabValue}>
                           <SettingReport />
                           {/* <setting-report id="settingReport"></setting-report> */}
                        </TabPanel>
                        <TabPanel index={SettingDialogType.FILMBOX} value={tabValue}>
                           <SettingFilmbox />
                           {/* <setting-filmbox id="settingFilmbox"></setting-filmbox> */}
                        </TabPanel>
                     </StyledTabWrapper>

                     <StyledSettingBtnWrappers>
                        <StyledSettingBtnWrapper>
                           <CancelBtn onClick={closeDialog} text={i18n("button.close")}/>
                        </StyledSettingBtnWrapper>
                     </StyledSettingBtnWrappers>
                  </StyledContainer>
               </StyledSettingBox>
            </div>
         </Modal>
      </>
   );
}
