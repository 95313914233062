// When using TypeScript 4.x and above
import type {} from "@mui/lab/themeAugmentation";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/TextField" {
   interface TextFieldPropsSizeOverrides {
      full: true;
      large: true;
   }
}

declare module "@mui/material/DialogActions" {
   interface DialogActionsProps {
      align?: "center" | "left" | "right"
      backgroundcolor? : "darkBlue" | "lightBlue"
   }
}

declare module "@mui/material/DialogContent" {
   export interface DialogContentProps {
      align?: "center" | "left" | "right"
   }
}

export const globalTheme = createTheme({
   // https://mui.com/material-ui/customization/typography/#font-size
   typography: {
      fontFamily: [
         "Roboto",
         "Noto",
         "sans-serif",
         // "Noto Sans",
         // "Noto Sans KR",
      ].join(","),
      fontSize: 12,
      body1: {
         color: "#aaaaaa",
      },
   },
   components: {
      // Text Field variant = "outlined"
      MuiOutlinedInput: {
         styleOverrides: {
            root: {
               fontSize: 12,
               color: "#aaaaaa",
            },
         },
      },
      // Text Field variant = "filled"
      MuiFilledInput: {
         styleOverrides: {
            root: {
               fontSize: 14,
               color: "#aaaaaa",
            },
         },
      },
      // Dialog Title
      MuiDialogTitle: {
         styleOverrides: {
            root: {
               fontSize: 14,
               fontWeight: 100,
            },
         },
      },
      // Dialog Content
      MuiDialogContent: {
         styleOverrides: {
            root: {
               padding: "5px 5px 5px 5px",
               background: "#252934",
               height: "100%",
               overflow: "hidden",
            },
         },
         variants: [
            {
               props: { align: "left" },
               style: {
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
               },
            },
            {
               props: { align: "right" },
               style: {
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
               },
            },
            {
               props: { align: "center" },
               style: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               },
            },
         ],
      },
      // Dialog Actions
      MuiDialogActions: {
         variants: [
            {
               props: { align: "left" },
               style: {
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
               },
            },
            {
               props: { align: "right" },
               style: {
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
               },
            },
            {
               props: { align: "center" },
               style: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               },
            },
            {
               props: { backgroundcolor : "darkBlue" },
               style: {
                  background: "#252934",
               },
            },
            {
               props: { backgroundcolor : "lightBlue" },
               style: {
                  background: "#1d1e25",
               },
            },
         ],
      },
      // ListItemButton
      MuiListItemButton: {
         styleOverrides: {
            root: {
               "&:hover, &.Mui-selected, &.Mui-selected:hover": { // 선택 되어졌을때
                  backgroundColor: "#596072",
               },
            },
         },
      },
      // ImageListItemBar
      MuiImageListItemBar: {
         styleOverrides: {
            root: {
               textAlign: "center",
            },
            title: {
               whiteSpace: "normal",
               wordBreak: "break-all",
            },
         },
      },
      // Text Field
      MuiTextField: {
         styleOverrides: {
            root: {
               fontSize: 12,
               fontWeight: 100,
               color: "#aaaaaa",
               //  input - https://mui.com/material-ui/api/input/#css
               //  input label - https://mui.com/material-ui/api/input-label/#css
               "& label": {
                  color: "gray",
               },
               "& label.Mui-focused": {
                  color: "white",
               },
               "& .MuiInput-underline:before": {
                  borderBottomColor: "gray",
               },
               "& .MuiInput-underline:after": {
                  borderBottomColor: "black",
               },
               "& .MuiInput-input": {
                  color: "white",
               },
               // "&&" :{
               //    marginRight: "5px",
               // },
            },
         },
         variants: [
            {
               props: { variant: "standard", type: "text", size: "small" },
               style: {
                  width: "20%",
               },
            },
            {
               props: { variant: "standard", type: "text", size: "medium" },
               style: {
                  width: "50%",
               },
            },
            {
               props: { size: "large" },
               style: {
                  width: "75%",
               },
            },
            {
               props: { size: "full" },
               style: {
                  width: "100%",
               },
            },
            {
               props: { type: "report" },
               style: {
                  // height: "calc(100% - 23px)",
                  // width: "calc(100% - 20px)",
                  backgroundColor: "rgba(0, 0, 0, 0)", /* 투명 (opacity: 0) */
                  color: "#ccc",
                  padding: "0px",
                  border: "0px",
                  resize: "none",
                  outline: "none !important",
                  fontSize: "15px",
                  lineHeight: "1.5em",
                  fontFamily: "NotoSansCJK, NotoSansCJKLight",
               },
            },
            // {
            //    props: { type: "password" },
            //    style: {
            //       "& .MuiInput-input" : {
            //          textAlign: "center",
            //       },
            //    },
            // },
         ],
      },
      MuiSvgIcon: {
         styleOverrides: {
            root: {
               color: "#aaaaaa",
            },
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               "& .MuiButton-startIcon": {
                  marginRight: "4px",
               },
            },
         },
      },
      // Accordion
      MuiAccordion: {
         styleOverrides: {
            root: {
               backgroundColor: "#252934",
               "&.Mui-expanded": {
                  margin: "5px 0",
               },
               "& .Mui-expanded": {
                  "& .MuiTypography-root": {
                     color: "#f0f0f0",
                  },
               },
            },
         },
      },
      // Accordion Summary
      MuiAccordionSummary: {
         styleOverrides: {
            root: {
               "&.Mui-expanded": {
                  minHeight: "45px",
                  height: "45px",
               },
            },
         },
      },
      // Accordion Detail
      MuiAccordionDetails: {
         styleOverrides: {
            root: {
               padding: "4px 8px 8px 4px",
            },
         },
      },
      MuiFormControlLabel: {
         styleOverrides: {
            root: {
               "&.Mui-disabled": {
                  opacity: 0.65,
               },
            },
            label: {
               color: "#aaaaaa",
               "&.Mui-disabled": {
                  color: "#aaaaaa",
               },
            },
         },
      },
      // Tab
      MuiTabPanel: {
         styleOverrides: {
            root: {
               padding: "0px",
            },
         },
      },
   },
});
