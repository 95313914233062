import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-dropdown-menu/paper-dropdown-menu";
import "@polymer/paper-item/paper-item";
import "@polymer/paper-listbox/paper-listbox";
import "@polymer/neon-animation/neon-animations";
import "@polymer/paper-icon-button/paper-icon-button";
import "@vaadin/vaadin-icons/vaadin-icons";
import "@polymer/paper-tooltip/paper-tooltip";
import "@polymer/paper-input/paper-input";

import "ag-grid-polymer";

import moment from "moment-timezone";
import "../components/healthhub-confirm-dialog";
import notFoundImage from "../../public/resource/images/image_not_found.png";
import { is_esc } from "./common-keyboard-handler";

import mixinCommons from "../common-mixin";
import store from "../redux/store";
import {TechlistActionType} from "../redux/reducers/techlist";
import {CommonActionType} from "../redux/reducers/common";

/**
 * `techtab-new-exam-dialog`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class TechtabNewExamDialog extends mixinCommons(PolymerElement) {
   static get template() {
      return html`
         <style>
            ag-grid-polymer {
               height: 100%;
               width: 100%;
               color: #f1eaff !important;
               font-size: 12px !important;
               font-weight: normal !important;
               background-color: #252934 !important;
               font-family: NotoSansCJK, NotoSansCJKLight !important;
            }

            .ag-overlay-loading-wrapper {
               background-color: rgba(29, 30, 37, 0.5) !important;
            }

            ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
               background-color: rgba(0, 0, 0, 0.14);
               border-top-right-radius: 6px;
               border-bottom-right-radius: 6px;
            }

            ::-webkit-scrollbar-thumb {
               background-color: #6b6b6b;
               border-radius: 10px;
               background-clip: content-box;
               border: 3px solid rgba(255,255,255,0);
            }

            paper-icon-button {
               width: 30px;
               height: 30px;
               float: right;
               color: #aaaaaa;
            }

            paper-input {
               --paper-input-container-focus-color: #0087cb;
               --paper-input-container-input: {
                  color: #aaaaaa;
                  font-size: 15px;
               };
               --paper-input-container:{
                  padding: 0;
               };

               width: 220px;
            }

            paper-dropdown-menu, paper-listbox {
               --paper-input-container-focus-color: #0087cb;
               --paper-input-container-label: {
                  color: #aaaaaa;
               };
               --paper-input-container-input: {
                  text-align: center;
                  color: #aaaaaa;
               };

               width: 140px;
               color: #aaaaaa;
               background: #2d333f;
            }

            paper-item {
               --paper-item-min-height: 30px;
               --paper-item-focused: {
                  color: #0087cb;
               };
               font-size: 14px;
            }

            #okBtn {
               background: #0087cb;
            }

            #cancelBtn {
               background: #4c5667;
            }

            .btn {
               width: 122px;
               height: 35px;
               border: 0;
               font-size: 12pt;
               color: #ffffff;
               cursor: pointer;
               border-radius: 2px;
            }

            .classA {
               width: 100%;
               height: calc(100% - 60px);
               display: flex;
               align-items: center;
               justify-content: center;
               flex-direction: column;
            }
            .classB {
               width: 100%;
               height: 60px;
               display: flex;
               align-items: center;
               justify-content: space-evenly;
            }

            /* dialog size background */
            #techtabNewExamDialog {
               background: #2d333f;
               width: 1450px;
            }

            .container{
               display: flex;
               flex-direction: column;
               height: 100%;
               width: 100%;
               margin: 0;
               padding: 0;
               background-color: #2c333e;
               /*background-color: #2d333e;*/
            }

            .modified-exam-title {
               background-color: #252934;
               height: 45px;
               width: 100%;
               color: #fff;
               border-bottom: 1px solid #4c5667;
            }

            .modified-container {
               width: 100%;
               height: 100%;
               display: flex;
            }

            .class-orderlist{
               height: 100%;
               width: calc(100% - 350px);
            }

            .class-inform{
               color: #aaaaaa;
               height: 100%;
               width: 350px;
            }

            .class-clinical-info {
               line-height: 2em;
               width: calc(100% - 20px);
            }

            .class-study-info {
               line-height: 2em;
               width: calc(100% - 20px);
            }

            .class-orders{
               height: 585px;
               width: 100%;
               display: flex;
            }

            .class-list{
               height: 230px;
               width: 100%;
            }

            .class-series{
               height: 100%;
               width: 280px;
               display: flex;
               flex-direction: column;
               align-items: center;
            }

            .class-image{
               height: 100%;
               width: calc(100% - 280px);
               display: flex;
               flex-direction: column;
               align-items: center;
            }

            .class-serieslist{
               display: flex;
               flex-wrap: wrap;
               overflow: auto;
               height: 100%;
               width: 100%;
               max-width: 280px;
               padding-left: 3px;
            }

            .class-serieslist-series{
               height: 105px;
               width: 100px;
               margin: 10px;
               display: grid;
               text-align: center;
               color: #aaaaaa;
            }

            .class-image-list{
               display: flex;
               flex-wrap: wrap;
               /*overflow: auto;*/
               /*height: calc(100% - 5px);*/
               /*width: calc(100% - 50px);*/
               padding-left: 50px;
            }

            .class-imageList-image{
               height: 215px;
               width: max-content;
               margin: 10px;
               display: grid;
               text-align: center;
               color: #aaaaaa;
            }

            .patient-list{
               height: calc(100% - 30px);
               width: calc(100% - 20px);
               padding: 10px;
            }

            .class-title{
               width: calc(100% - 20px);;
               height: calc(100% - 20px);
               margin: 10px;
               background-color: #252934;
               box-shadow: 0px 3px 9px -2px #000000;
               border-top-left-radius: 3px;
               border-top-right-radius: 3px;
            }

            .middle-title{
               color: #aaaaaa;
               font-size: 18px;
               font-weight: 500;
               width: 100%;
               height: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
            }

            /*.class-order-btn{*/
            /*   display: flex;*/
            /*   margin-left: 170px;*/
            /*}*/
            /*.class-input{*/
            /*   display: flex;*/
            /*   margin-top:10px;*/
            /*}*/

            /*input{*/
            /*   margin-left: 20px;*/
            /*}*/
            /*input:focus{*/
            /*   color: transparent;*/
            /*}*/

            .textarea-clinicalInfo{
               height: 110px;
               width: 290px;
               background-color: rgba(0, 0, 0, 0.10);
               color: #aaa;
               padding: 5px;
               border: 0px;
               resize: none;
               outline: none !important;
               font-size: 14px;
               line-height: 1.5em;
            }

            .textarea-studyDesc{
               width: 220px;
               padding: 0px;
               /*border: 0px;*/
               resize: none;
               outline: none !important;
            }

            .class-input-description{
               color: #aaa;
               height: 12px;
               width: 100%;
               font-weight: bold;
               font-size: 12px;
               background-color:transparent;
               align-items: center;
               text-align: center;
               margin: 3px 0 0 0;
               border: 0px;
               padding: 0px;
               outline: none;
            }

            .class-patient-info{
               line-height: 2em;
               width: calc(100% - 20px);
            }

            .class-patientInfo{
               width: 100%;
            }
            .font{
               width: calc(100% - 220px);
               text-align: left;
               margin-right: 5px;
            }

            /* Removes the clear button from date inputs */
            input[type="date"]::-webkit-clear-button {
               display: none;
            }

            /* Removes the spin button */
            input[type="date"]::-webkit-inner-spin-button {
               display: none;
            }

            /* Always display the drop down caret */
            input[type="date"]::-webkit-calendar-picker-indicator {
               color: transparent;
               background: none;
               z-index: 1;
            }

            input[type="date"]:after {
               position: absolute;
               content: '\\f073';
               display: block;
               font-weight: 400;
               font-family: 'FontAwesome';
               right: 15px;
               pointer-events: none;
               color: hsla(0, 0%, 96%, .64);
            }

            /* A few custom styles for date inputs */
            input[type="date"] {
               appearance: none;
               -webkit-appearance: none;
               color: #aaaaaa;
               font-family: NotoSansCJK, NotoSansCJKLight;
               font-size: 15px;
               border-top: 0 !important;
               border-right: 0 !important;
               border-left: 0 !important;
               border-bottom:1px solid #7a7b7b !important;
               background:#2d333f;
               text-align: left;
               display: inline-block !important;
               visibility: visible !important;
               width: 220px;
            }

            input[type="date"], focus {
               color: #aaaaaa;
               box-shadow: none;
               -webkit-box-shadow: none;
               -moz-box-shadow: none;
            }

            .input-container {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: flex-end;
            }

            iron-autogrow-textarea {
               border-bottom: 1px solid var(--paper-input-container-color, var(--secondary-text-color));
               border-top: 0;
               border-left: 0;
               border-right: 0;
            }

         </style>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.css">
         <link rel="stylesheet" href="/resource/style/theme/healthhub-default.css">
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css">
         <paper-dialog id="techtabNewExamDialog" on-click="prevent_event_propagation" on-keydown="close_if_esc"  on-iron-overlay-opened="dialog_opened" on-iron-overlay-closed="dialog_closed" modal>
            <div class="container">
               <div class="modified-exam-title">
                  <p style="margin: 0; padding: 15px; float: left;">{{t("label.newExam")}}</p>
                  <paper-icon-button style="height: 50px; width: 50px;" on-click="doClose" icon="vaadin:close-small"></paper-icon-button>
               </div>
               <div class="modified-container">
                  <div class="class-orderlist">
                     <div class="class-orders">
                        <div class="class-series">
                           <div style="display: flex; height: 50px; width: 100%">
                              <div class="class-title">
                                 <div class="middle-title">{{t("label.series")}}</div>
                              </div>
                           </div>
                           <div style="background-color: #1f1f1ffc; height: calc(100% - 60px);width: calc(100% - 20px); margin: 0 10px 10px 10px">
                              <div class="class-serieslist" id="divSeriesList">
                                 <dom-repeat items="{{series}}">
                                    <template>
                                       <div class="class-serieslist-series" data-idx$="{{item.idx}}">
                                          <input value="{{item.idx}}" hidden>
                                          <div id="" style="position: relative; height: 100px; width: 100px; display: flex; align-items: center; justify-content: center;">
                                             <img src="{{item.image}}" id="seriesList" style="max-width: 100px; max-height: 100px;">
                                             <paper-icon-button id="removeSeriesBtn" on-click="removeSeries" style="bottom:0; right:0; width: 35px; height: 40px; color:#cecece; position: absolute; display: none;" icon="vaadin:trash"></paper-icon-button>
                                          </div>
                                          <input class="class-input-description" value="{{item.seriesDescription}}" readonly>
                                       </div>
                                    </template>
                                 </dom-repeat>
                                 <input id="seriesUploader" type="file" multiple accept="image/jpeg" style="display:none" />
                                 <paper-icon-button id="newSeriesBtn" style="top:580px; left: 230px; width: 35px; height: 40px; color:rgb(0,135,203); position: absolute;" icon="vaadin:plus-circle"></paper-icon-button>
                              </div>
                           </div>
                        </div>
                        <div class="class-image">
                           <div class="class-title" style="width: calc(100% - 20px); height: 30px; display: flex">
                              <div class="middle-title">{{t("label.image")}}</div>
                              <div class="class-order-btn">
                                 <div id="fontAsc" on-click="ascSort" readonly style="color: #aaaaaa; font-size: 14px; font-weight: 500; width: 110px; display: none; align-items: center; justify-content: center;">
                                    <paper-icon-button id="iconAscending" noink icon="vaadin:chevron-down-small" title="oldListSort" class="iconSortBtn" ></paper-icon-button>
                                    <div>{{t("label.descending")}}</div>
                                 </div>
                                 <div id="fontDsc" on-click="dscSort" readonly  style="color: #aaaaaa; font-size: 14px; font-weight: 500; width: 110px; display: flex; align-items: center; justify-content: center">
                                    <paper-icon-button id="iconDescending" noink icon="vaadin:chevron-up-small" title="oldListSort" class="iconSortBtn" ></paper-icon-button>
                                    <div>{{t("label.ascending")}}</div>
                                 </div>
                              </div>
                           </div>
                           <div style="background-color: #1f1f1ffc; height: calc(100% - 60px); width:calc(100% - 20px); overflow: auto;">
                              <div class="class-image-list" id="divImagelist">
                                 <dom-repeat items="{{images}}">
                                    <template>
                                       <div class="class-imageList-image">
                                          <div style="position: relative; height: 215px; width: max-content; ">
                                             <img src="{{item.image}}" id="imageList" draggable="true" style="height: 215px; width: auto">
                                             <paper-icon-button id="removeImageBtn" on-click="removeImage" style="bottom:0; right:0; width: 40px; height: 40px; position: absolute; display: none;" icon="vaadin:trash"></paper-icon-button>
                                          </div>
                                          <input class="class-input-description" value="{{item.idx}}" readonly>
                                       </div>
                                    </template>
                                 </dom-repeat>
                                 <input id="imageUploader" type="file" multiple accept="image/jpeg" style="display:none" />
                                 <paper-icon-button id="newImageBtn" style="top:580px; left: calc(100% - 400px); width: 35px; height: 40px; color:rgb(0,135,203); position: absolute; display: none;" icon="vaadin:plus-circle"></paper-icon-button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="class-list" hidden>
                        <div class="patient-list">
                           <ag-grid-polymer
                              class="ag-theme-balham-dark"
                              rowData="{{rowData}}"
                              columnDefs="{{columnDefs}}"
                              gridOptions="{{gridOptions}}"
                           ></ag-grid-polymer>
                        </div>
                     </div>
                  </div>
                  <div class="class-inform">
                     <div class="classA">
                        <div class="class-patient-info">
                           <div class="class-title" style="height: 30px;">
                              <div class="middle-title">{{t("label.patientInfo")}}</div>
                           </div>
                           <div class="class-patientInfo">
                              <div class="input-container" >
                                 <div class="font" >{{t("label.id")}} :</div><paper-input id="patientId" no-label-float ></paper-input>
                              </div>
                              <div class="input-container" >
                                 <div class="font" >{{t("label.name")}} :</div><paper-input id="patientName" no-label-float  ></paper-input>
                              </div>
                              <div class="input-container" >
                                 <div class="font" >{{t("label.birthDate")}} :</div>
                                 <!--                                        <paper-input id="patientBirthDate" style="margin-left: 50px" no-label-float  ></paper-input>-->
                                 <input class="class-input-date" type="date" id="patientBirthDate">
                              </div>
                              <div class="input-container" >
                                 <div class="font" >{{t("label.gender")}} :</div>
                                 <!--                                        <paper-input id="patientGender" style="margin-left: 66px;" no-label-float  ></paper-input>-->
                                 <paper-input id="patientGender" no-label-float></paper-input>
                                 <!--                              <paper-dropdown-menu id="patientGender" name="gender" style="margin-left: 66px; width: 147px; height: 30px" no-label-float>-->
                                 <!--                                 <paper-listbox slot="dropdown-content">-->
                                 <!--                                    <paper-item>F</paper-item>-->
                                 <!--                                    <paper-item>M</paper-item>-->
                                 <!--                                    <paper-item>O</paper-item>-->
                                 <!--                                 </paper-listbox>-->
                                 <!--                              </paper-dropdown-menu>-->
                              </div>
                           </div>
                        </div>
                        <div class="class-clinical-info" hidden>
                           <div class="class-title" style="height: 30px;">
                              <div class="middle-title">{{t("label.clinicalInfo")}}</div>
                           </div>
                           <textarea class="textarea-clinicalInfo" id="clinicalInfo"></textarea>
                        </div>
                        <div class="class-study-info">
                           <div class="class-title" style="height: 30px">
                              <div class="middle-title">{{t("label.studyInfo")}}</div>
                           </div>
                           <div class="class-study-info-content" style="height: calc(100% - 80px); width: 100%; overflow: auto">
                              <div class="input-container" >
                                 <div class="font">{{t("label.accessionNo")}} :</div><paper-input id="accessionNumber" no-label-float></paper-input>
                              </div>
                              <div class="input-container" >
                                 <div class="font">{{t("label.modality")}} :</div><paper-input id="modality" no-label-float></paper-input>
                              </div>
                              <div class="input-container" >
                                 <div class="font">{{t("label.bodypart")}} :</div><paper-input id="bodyPartExamined" no-label-float></paper-input>
                              </div>
                              <!--                                    <div class="class-input" >-->
                              <!--                                        &lt;!&ndash; modify&ndash;&gt;-->
                              <!--                                        <div class="font">StudyDate: </div>-->
                              <!--                                        <input class="class-input-date" type="date" id="studyDate">-->
                              <!--&lt;!&ndash;                                        <paper-input style="margin-left: 54px" id="studyDate" no-label-float></paper-input>&ndash;&gt;-->
                              <!--                                    </div>-->
                              <!--                                    <div class="class-input" >-->
                              <!--                                        <div class="font">StudyTime: </div><paper-input style="margin-left: 50px" id="studyTime" no-label-float></paper-input>-->
                              <!--                                    </div>-->
                              <!--                                    <div class="class-input" >-->
                              <!--                                        <div class="font">StudyID</div><paper-input label="StudyID" style="margin-left: 68px" id="studyId" no-label-float  ></paper-input>-->
                              <!--                                    </div>-->
                              <div class="input-container" >
                                 <div class="font">{{t("label.studyDescription")}} :</div>
                                 <iron-autogrow-textarea class="textarea-studyDesc" id="studyDescription"></iron-autogrow-textarea>
                              </div>
                              <div class="input-container">
                                 <div class="font">{{t("label.referringPhysiciansName")}} :</div><paper-input id="referringPhysicianName" no-label-float></paper-input>
                              </div>
                              <!--                                    <div class="class-input">-->
                              <!--                                        <div class="font">Study<br>Instance UID</div><paper-input label="Study Instance UID" id="studyInstanceUID" style="margin-left: 35px" no-label-float  ></paper-input>-->
                              <!--                                    </div>-->
                           </div>
                        </div>
                     </div>
                     <div class="classB">
                        <button id="okBtn" on-click="doOk" class="btn">{{t("button.save")}}</button>
                        &nbsp;
                        <button id="cancelBtn" on-click="doClose" class="btn">{{t("button.cancel")}}</button>
                     </div>
                  </div>
               </div>
            </div>
         </paper-dialog>
         <hh-confirm-dialog id="hhTechtabModifiedExamConfirmDialog"></hh-confirm-dialog>
      `;
   }

   static get properties() {
      return {
         images :{
            type:Array,
            value:[]
         },
         series :{
            type:Array,
            value:[]
         },
         exam:{
            type:Object,
            value:{}
         },
         modifiedRequest:{
            type:Object,
            value:{}
         },
         addFiles:{
            type:Array,
            value:[]
         },
         _selectedRows:{
            type:Array,
            value:[]
         },
         _selectedExam:{
            type:Object,
            value:{}
         },
         _draggedSeries:{
            type:Object,
            value:{}
         },
         _draggedImage:{
            type:Object,
            value:{}
         },
         _selectedSeriesIdx:{
            type:Object,
            value:{}
         },
         _selectedImageIdx:{
            type:Object,
            value:{}
         },
         _message:{
            type:Object,
            value:{}
         }
      };
   }

   constructor() {
      super();
      this.gridOptions = {
         defaultColDef: {
            suppressMenu: true,
            sortable: true
         },
         rowSelection: "single",
         overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">${this.t("label.noRecordsFound")}</span>`,
      };

      this.columnDefs = [
         {headerName: this.t("label.modality"), field: "modality", width: 90},
         {headerName: this.t("label.bodypart"), field: "bodypart", width: 100},
         {headerName: this.t("label.id"), field: "pid", width: 100},
         {headerName: this.t("label.name"), field: "name", width: 100},
         {headerName: this.t("label.studyDate"), field: "studyDate", width: 130},
         {headerName: this.t("label.studyTime"), field: "studyTime", width: 130},
         {headerName: this.t("label.studyDesc"), field: "studyDescription", width: 235},
         {headerName: this.t("label.count"), field: "count", width: 80},
         {headerName: this.t("label.radiology"), field: "radiology", width: 110}
      ];

      // grid ready
      this.gridOptions.onGridReady = (/* params */) => {
      };

      // row clicked
      this.gridOptions.onRowSelected = (/* e */) => {
      };

      // 오른쪽 마우스 : copy info
      // this.gridOptions.getContextMenuItems = (params) => {
      //    let result = [
      //       'separator',
      //       {
      //          name: 'Copy PatientInfo',
      //          action: function () {
      //             // console.log("copy selected raw info");
      //             _this.$.patientId.value = params.node.data.pid;
      //             _this.$.patientName.value = params.node.data.name;
      //             _this.$.patientBirthDate.value = params.node.data.birthDate;
      //             _this.$.patientGender.value = params.node.data.sex;
      //          }
      //       },
      //       'separator'
      //    ];
      //    return result;
      // }

      // row double clicked
      this.gridOptions.onRowDoubleClicked = (/* e */) => {
      };
   }

   ready() {
      super.ready();

      this.$.newSeriesBtn.addEventListener("click", () => {
         this.$.seriesUploader.click();
      });

      this.$.newImageBtn.addEventListener("click", () => {
         this.$.imageUploader.click();
      });

      this.$.seriesUploader.addEventListener("change", (e) => {
         const {files} = e.target;
         for(let i = 0; i < files.length; i++) {
            if(files[i].type !== "image/jpeg") {
               this.toastMessage(this.t("msg.newExamDialog.seriesUploader"), true);
               return;
            }
         }

         this.addSeries();
         this.readUploadFile(e.target);
      });

      this.$.imageUploader.addEventListener("change",(e) => {
         const {files} = e.target;
         for(let i = 0; i < files.length; i++) {
            if(files[i].type !== "image/jpeg") {
               this.toastMessage(this.t("msg.newExamDialog.imageUploader"), true);
               return;
            }
         }

         this.readUploadFile(e.target);
      });


      // confirm message
      this.$.hhTechtabModifiedExamConfirmDialog.addEventListener("confirm", (e) => {
         if(e.detail.key === "removeSeries"){
            // console.log("remove series");
            this.exam.series.splice(this._selectedSeriesIdx,1);
            this.getSereisList(this.exam);

            this.setModifiedSeries();
            this._selectedSeriesIdx = null;

            for(let i=0; i<this.$.divSeriesList.children.length -3; i++) {
               this.$.divSeriesList.children[i].children[1].children[0].style.borderStyle = "";
               this.$.divSeriesList.children[i].children[1].children[1].style.display ="none";
            }
            this.$.newImageBtn.style.display = "none";
         } else if(e.detail.key === "removeImage"){
            // console.log("remove image");
            // 삭제 하려는 이미지가 시리즈의 마지막 이미지이면, 해당 시리즈를 삭제한다.
            if(this.exam.series[this._selectedSeriesIdx].image.length === 1){
               this.exam.series.splice(this._selectedSeriesIdx,1);
               this.getSereisList(this.exam);

               this.setModifiedSeries();
               this._selectedSeriesIdx = null;

               for(let i=0; i<this.$.divSeriesList.children.length -3; i++) {
                  this.$.divSeriesList.children[i].children[1].children[0].style.borderStyle = "";
                  this.$.divSeriesList.children[i].children[1].children[1].style.display ="none";
               }
               this.$.newImageBtn.style.display = "none";
            } else {
               // if(Object.keys(_this.exam.series[_selectedSeriesIdx].image).length > 0) {
               if(this.exam.series[this._selectedSeriesIdx].image.length > 0) {
                  const deletedImage = this.exam.series[this._selectedSeriesIdx].image[this._selectedImageIdx];
                  this.exam.series[this._selectedSeriesIdx].image.splice(this._selectedImageIdx,1); // remove image obj in series arr
                  for(let i=0; i<this.addFiles.length; i++){
                     if(this.addFiles[i].name === deletedImage.originalName &&
                        this.addFiles[i].displayOrder === deletedImage.displayOrder){
                        this.addFiles.splice(i,1);
                        break;
                     }
                  }
                  this.getImageList(this._selectedSeriesIdx);
                  this.getSereisList(this.exam, true);
               }

               // 삭제된 이미지를 서버에 전송할 파라미터에 저장
               // 이미지 삭제는 해당 시리즈의 이미지만 삭제
               // case.exam[0].series[i].image[i]
               // if(Object.keys(_this.modifiedRequest).length > 0) {
               if(this.modifiedRequest.length > 0) {
                  this.modifiedRequest.series[this._selectedSeriesIdx].image.splice(this._selectedImageIdx,1);
                  this._selectedImageIdx = null;
               }

               for(let i=0; i<this.$.divImagelist.children.length -3; i++) {
                  if(this.$.divImagelist.children[i].className === "class-imageList-image"){
                     this.$.divImagelist.children[i].children[0].children[0].style.borderStyle = "";
                     this.$.divImagelist.children[i].children[0].children[1].style.display ="none";
                  }
               }

               if(this.exam.series[this._selectedSeriesIdx].image.length === 0){
                  this.exam.series.splice(this._selectedSeriesIdx,1);
                  this.getSereisList(this.exam);
                  this.setModifiedSeries();
                  this._selectedSeriesIdx = null;

                  for(let i=0; i<this.$.divSeriesList.children.length -3; i++) {
                     if(this.$.divImagelist.children[i].className === "class-imageList-image") {
                        this.$.divSeriesList.children[i].children[1].children[0].style.borderStyle = "";
                        this.$.divSeriesList.children[i].children[1].children[1].style.display = "none";
                     }
                  }
               }
            }

         } else if(e.detail.key === "newExam") {
            // createExamByObjectId parameter is modified values
            this.inputModifiedData();
            this.createExamByObjectId(this.modifiedRequest).then((result)=>{
               // result: {isMatch: false, isMerge: false}
               if (result) {
                  store.dispatch({ type: TechlistActionType.REFRESH_AND_SELECT_ROWS, payload: [{ id: "NewExam" }] }); // TODO: 구조 변경 필요
                  document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.techTabExam.new.success"), isErr: false} }));
                  this.doClose();
               } else {
                  document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.techTabExam.new.fail"), isErr: true} }));
               }
            });
         } else if(e.detail.key === "addSeries"){
            // console.log("add series");
            this.$.seriesUploader.click();
         }
      });


      this.$.divSeriesList.addEventListener("click", (e) => {
         if(this.series.length === 0 || e.target.id === "removeSeriesBtn" || e.target.id === "imageUploader" || e.target.id === "newSeriesBtn") return false;

         // clear selected image list
         this._selectedImageIdx = null;
         this.$.divImagelist.scrollTo(0,0);
         for(let i=0; i<this.$.divImagelist.children.length - 3; i++) {
            this.$.divImagelist.children[i].children[0].children[0].style.borderStyle = "";
            this.$.divImagelist.children[i].children[0].children[1].style.display ="none";
         }

         const el = e.path.find(el => el.classList && el.classList.contains("class-serieslist-series"));
         if(el && el.dataset && el.dataset.idx !== null && this._selectedSeriesIdx !== el.dataset.idx) {
            // if(e.path[2].children[0].value != null && (this._selectedSeriesIdx !== e.path[2].children[0].value)) {

            this.$.iconAscending.hidden = true;
            this.$.fontAsc.style.display = "none";
            this.$.iconDescending.hidden = false;
            this.$.fontDsc.style.display = "flex";

            // selected series index
            this._selectedSeriesIdx = el.dataset.idx;
            this.getImageList(el.dataset.idx);

            const seriesList = e.path.find(el => el.id && el.id === "divSeriesList");
            for(let i = 0; i < seriesList.children.length - 3; i++) {
               const series = seriesList.children[i];
               if(series.classList && series.classList.contains("class-serieslist-series")) {
                  // clicked image add border
                  if(series === el) {
                     series.children[1].style.borderStyle = "dashed";
                     series.children[1].style.borderWidth = "2px";
                     series.children[1].style.borderColor = "rgb(0, 135, 203)";
                     series.children[1].children[1].style.display = "";
                  } else {
                     series.children[1].style.borderStyle = "";
                     series.children[1].children[1].style.display = "none";
                  }
               }
            }

            // clicked image add border
            // if(e.path[2].className ==="class-serieslist-series"){
            //    for(let i=0; i<e.path[3].children.length - 3; i++){
            //       if(e.path[3].children[i].children[0].value === e.path[2].children[0].value) {
            //          e.path[3].children[i].children[1].style.borderStyle = "dashed";
            //          e.path[3].children[i].children[1].style.borderWidth = "2px";
            //          e.path[3].children[i].children[1].style.borderColor = "rgb(0, 135, 203)";
            //          e.path[3].children[i].children[1].children[1].style.display = "";
            //       }else{
            //          e.path[3].children[i].children[1].style.borderStyle = "";
            //          e.path[3].children[i].children[1].children[1].style.display = "none";
            //       }
            //    }
            // }
         } else if(this._selectedSeriesIdx != null) {

            this.$.iconAscending.hidden = true;
            this.$.fontAsc.style.display = "none";
            this.$.iconDescending.hidden = false;
            this.$.fontDsc.style.display = "flex";

            // clear image list
            this._selectedSeriesIdx = null;
            this.getImageList(null);

            if(e.path[3].className === "class-serieslist"){
               // clicked other
               for(let i=0; i<e.path[3].children.length - 3; i++){
                  e.path[3].children[i].children[1].style.borderStyle = "";
                  e.path[3].children[i].children[1].children[1].style.display = "none";
               }
            }else{
               // clicked other area
               for(let i=0; i<e.target.children.length; i++){
                  if(e.target.children[i].className === "class-serieslist-series"){
                     e.target.children[i].children[1].style.borderStyle = "";
                     e.target.children[i].children[1].children[1].style.display = "none";
                  }
               }
            }
         }
         return false; // prevent propagation
      });

      // drag start series-image event
      this.$.divSeriesList.addEventListener("dragstart",(e) => {
         const data = e.path[0].__dataHost.__data;
         this._draggedSeries.targetIndex = data.index;
         this._draggedSeries.selectedIndex = data.index;
         for(let i=0; i<e.currentTarget.children.length - 3; i++){
            e.currentTarget.children[i].children[1].style.borderStyle = "";
         }
      });

      // drag end series-image event
      this.$.divSeriesList.addEventListener("dragend",(e) => {
         if(this._draggedSeries.targetIndex !== this._draggedSeries.selectedIndex){

            const tempObj = this.series[this._draggedSeries.selectedIndex];
            this.series[this._draggedSeries.selectedIndex] = this.series[this._draggedSeries.targetIndex];
            this.series[this._draggedSeries.targetIndex] = tempObj;

            // modified exam
            const tempSeries = this.exam.series[this._draggedSeries.selectedIndex];
            this.exam.series[this._draggedSeries.selectedIndex] = this.exam.series[this._draggedSeries.targetIndex];
            this.exam.series[this._draggedSeries.targetIndex] = tempSeries;

            // modified files info
            for(let i=0; i<this.addFiles.length; i++){
               const file = this.addFiles[i]; // 추가된 파일
               for(let j=0; j<this.exam.series.length; j++){
                  const series = this.exam.series[j];
                  for(let k=0; k<series.image.length; k++){
                     const image = series.image[k];
                     if(file.name === image.originalName && this.addFiles[i].seriesIdx !== j){
                        // 기존 파일과 변경된 series 의 이미지들과 비교 후 변경된 series index로 변경
                        // console.log(`${file.name  }  b : ${this.addFiles[i].seriesIdx  }  a : ${j}`);
                        this.addFiles[i].seriesIdx = j;
                     }
                  } // image
               } // series
            } // addFiles

            // Original target
            e.currentTarget.children[this._draggedSeries.selectedIndex].children[0].value = this.series[this._draggedSeries.selectedIndex].idx;
            e.currentTarget.children[this._draggedSeries.selectedIndex].children[1].children[0].src = this.series[this._draggedSeries.selectedIndex].image;
            e.currentTarget.children[this._draggedSeries.selectedIndex].children[2].value = this.series[this._draggedSeries.selectedIndex].seriesDescription;

            // source target
            e.currentTarget.children[this._draggedSeries.targetIndex].children[0].value = this.series[this._draggedSeries.targetIndex].idx;
            e.currentTarget.children[this._draggedSeries.targetIndex].children[1].children[0].src = this.series[this._draggedSeries.targetIndex].image;
            e.currentTarget.children[this._draggedSeries.targetIndex].children[2].value = this.series[this._draggedSeries.targetIndex].seriesDescription;
         }

         // clear selected series image
         for(let i=0; i<e.currentTarget.children.length - 3; i++) e.currentTarget.children[i].children[1].style.borderStyle = "";
      });

      this.$.divSeriesList.addEventListener("dragover", (e) => {
         e.preventDefault();
         if(e.path[2].className ==="class-serieslist-series") {
            e.path[2].children[1].style.borderStyle = "dashed";
            e.path[2].children[1].style.borderWidth = "2px";
            e.path[2].children[1].style.borderColor = "rgb(0,135,203)";
         }
      });

      this.$.divSeriesList.addEventListener("dragenter", (e) => {
         if(e.path[0].className !== "class-serieslist" && e.path[0].className !== "class-serieslist-series"
            && Object.keys(e.path[0].__dataHost).length > 0){
            const data = e.path[0].__dataHost.__data;
            if(this._draggedSeries.selectedIndex !== data.index)this._draggedSeries.selectedIndex = data.index;
         }
      });

      this.$.divSeriesList.addEventListener("dragleave",(e) => {

         this.$.iconAscending.hidden = true;
         this.$.fontAsc.style.display = "none";
         this.$.iconDescending.hidden = false;
         this.$.fontDsc.style.display = "flex";

         // clear image list
         this._selectedSeriesIdx = null;
         this.getImageList(null);

         // clear css
         for(let i=0; i<e.currentTarget.children.length; i++) {
            if(e.currentTarget.children[i].className === "class-serieslist-series"){
               e.currentTarget.children[i].children[1].style.borderStyle = "";
               e.currentTarget.children[i].children[1].children[1].style.display ="none";
            }
         }
      });

      this.$.divImagelist.addEventListener("click", (e) => {
         if(this.images.length === 0 || e.target.id === "removeImageBtn" || e.target.id === "imageUploader" || e.target.id ==="newImageBtn") return false;

         const imageIdx =  e.srcElement.__dataHost.__data.index;// e.path[2].children[0].children[0].children[1].value - 1;
         if(e.path[2].children[1].value != null && (this._selectedImageIdx !== imageIdx)) {
            this._selectedImageIdx = imageIdx;

            // clicked image add border
            if(e.path[2].className ==="class-imageList-image"){
               for(let i=0; i<e.currentTarget.children.length -3; i++){
                  if(e.currentTarget.children[i].children[1].value === e.path[2].children[1].value) {
                     e.currentTarget.children[i].children[0].children[0].style.borderStyle = "dashed";
                     e.currentTarget.children[i].children[0].children[0].style.borderWidth = "3px";
                     e.currentTarget.children[i].children[0].children[0].style.borderColor = "rgb(0, 135, 203)";

                     e.currentTarget.children[i].children[0].children[1].style.display = "";
                  }else{
                     e.currentTarget.children[i].children[0].children[0].style.borderStyle = "";
                     e.currentTarget.children[i].children[0].children[1].style.display = "none";
                  }
               }
            }
         }else{
            // clear image list
            this._selectedImageIdx = null;
            for(let i=0; i<e.currentTarget.children.length - 3; i++){
               e.currentTarget.children[i].children[0].children[0].style.borderStyle = "";
               e.currentTarget.children[i].children[0].children[1].style.display = "none";
            }
         }
         return false; // prevent propagation
      });

      // drag start image event
      this.$.divImagelist.addEventListener("dragstart",(e) => {
         if(e.target.id === "imageList"){
            const data = e.path[0].__dataHost.__data;
            this._draggedImage.targetIndex = data.index;
            this._draggedImage.selectedIndex = data.index;
         }
      });

      // drag end image event
      this.$.divImagelist.addEventListener("dragend",(e) => {
         if(this._draggedImage.targetIndex !== this._draggedImage.selectedIndex){
            const tempObj = this.images[this._draggedImage.selectedIndex];
            this.images[this._draggedImage.selectedIndex] = this.images[this._draggedImage.targetIndex];
            this.images[this._draggedImage.targetIndex] = tempObj;

            // Original target
            e.currentTarget.children[this._draggedImage.selectedIndex].children[1].value = this.images[this._draggedImage.selectedIndex].idx;
            e.currentTarget.children[this._draggedImage.selectedIndex].children[0].children[0].src = this.images[this._draggedImage.selectedIndex].image;

            // source targe
            e.currentTarget.children[this._draggedImage.targetIndex].children[1].value = this.images[this._draggedImage.targetIndex].idx;
            e.currentTarget.children[this._draggedImage.targetIndex].children[0].children[0].src = this.images[this._draggedImage.targetIndex].image;
         }

         // save exam
         const tempImage = this.exam.series[this._selectedSeriesIdx].image[this._draggedImage.targetIndex];
         this.exam.series[this._selectedSeriesIdx].image[this._draggedImage.targetIndex] = this.exam.series[this._selectedSeriesIdx].image[this._draggedImage.selectedIndex];
         this.exam.series[this._selectedSeriesIdx].image[this._draggedImage.selectedIndex] = tempImage;

         // init first image in Series list
         this.getSereisList(this.exam, true);

         for(let i=0; i<e.currentTarget.children.length -3; i++){
            e.currentTarget.children[i].children[0].children[0].style.borderStyle = "";
            e.currentTarget.children[i].children[0].children[1].style.display = "none";
         }
      });

      this.$.divImagelist.addEventListener("dragover", (e) => {
         e.preventDefault();
         for(let i=0; i<e.currentTarget.children.length - 3; i++){
            e.currentTarget.children[i].children[0].children[0].style.borderStyle = "";
            e.currentTarget.children[i].children[0].children[1].style.display = "none";
         }
         if(e.path[2].className ==="class-imageList-image") {
            e.path[2].children[0].children[0].style.borderStyle = "dashed";
            e.path[2].children[0].children[0].style.borderColor = "rgb(0,135,203)";
            e.path[2].children[0].children[0].style.borderWidth = "3px";
         }
      });

      this.$.divImagelist.addEventListener("dragenter", (e) => {
         if(e.path[0].className !== "class-image-list" && e.path[0].id ==="imageList" && Object.keys(e.path[0].__dataHost.__data).length > 0){
            const data = e.path[0].__dataHost.__data;
            if(this._draggedImage.selectedIndex !== data.index)this._draggedImage.selectedIndex = data.index;
         }
      });

      this.$.divImagelist.addEventListener("dragleave", (e) => {
         for(let i=0; i<e.currentTarget.children.length - 3; i++){
            e.currentTarget.children[i].children[0].children[0].style.borderStyle = "";
            e.currentTarget.children[i].children[0].children[1].style.display = "none";
         }
      });
   }// ready

   close_if_esc(e) {
      e.stopPropagation();
      if(is_esc(e)) {
         this.doClose();
      }
   }

   prevent_event_propagation(e) {
      e.stopPropagation();
   }

   dialog_opened() {
      window.dialog_opened({dialog:this.$.techtabNewExamDialog, is_modal:true});
   }

   dialog_closed() {
      window.dialog_closed();
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   doOpen(selectedRow) {
      // console.log("new exam dialog");
      this.inputDate(selectedRow);
      this.createExam(selectedRow);
      this.$.techtabNewExamDialog.open();
   }

   getSereisList(resultThumbnail, isNotInitImageList){

      const seriesArray = [];
      for(let i=0; i<resultThumbnail.series.length; i++){
         const series = {};
         series.idx = i;
         series.seriesDescription = resultThumbnail.series[i].seriesDescription;
         series.image = resultThumbnail.series[i].image[0].image;
         seriesArray.push(series);
      }

      this.series = seriesArray;
      if(!isNotInitImageList) this.images = [];
   }

   getImageList(selectedSeriesIdx){
      if(selectedSeriesIdx != null){
         const tempImagesArr = []; // images arr
         const imagesArr = this.exam.series[selectedSeriesIdx].image;
         if(imagesArr.length > 0){
            for(let i=0; i<imagesArr.length; i++){
               const images = {};
               images.image = imagesArr[i].image;
               images.type = "jpeg";
               images.idx = i+1;
               tempImagesArr.push(images);
            }
         }

         this.images = tempImagesArr;
         this.$.newImageBtn.style.display = "";
      }else{
         this.images = [];
         this.$.newImageBtn.style.display = "none";
      }
   }

   inputDate(io){
      const inputOrder = io;
      const tempBirthDate = inputOrder.patientBirthDate;
      this.$.patientId.value = inputOrder.patientID;
      this.$.patientName.value = inputOrder.patientName;
      if (inputOrder.patientBirthDate && inputOrder.patientBirthDate.length >= 8) {
         const birthDate = `${inputOrder.patientBirthDate.substring(0,4) }-${ inputOrder.patientBirthDate.substring(4,6) }-${ inputOrder.patientBirthDate.substring(6,8)}`;
         inputOrder.patientBirthDate = birthDate;
      }
      this.$.patientBirthDate.value = inputOrder.patientBirthDate;
      inputOrder.patientBirthDate = tempBirthDate;

      this.$.patientGender.value = inputOrder.patientSex;
      this.$.accessionNumber.value = inputOrder.accessionNumber;
      this.$.modality.value = inputOrder.modality;
      this.$.bodyPartExamined.value = inputOrder.bodyPartExamined;

      this.$.studyDescription.value = inputOrder.studyDescription;

      if(inputOrder.clinicalInfo) this.$.clinicalInfo.value = inputOrder.clinicalInfo;
      // this.$.studyInstanceUID.value = inputOrder.studyInstanceUID; // NOT HAVE NEW CASE
   }

   createExam(inputOrder){
      const exam = {};
      // exam.studyInstanceUID = inputOrder.studyInstanceUID;
      exam.studyInstanceUID = "";
      exam.accessionNumber = inputOrder.accessionNumber;
      exam.patientID = inputOrder.patientID;
      exam.patientNmae = inputOrder.patientName;
      exam.patientBirthDate = inputOrder.patientBirthDate;
      exam.patientSex = inputOrder.patientSex;
      if(inputOrder.modality) exam.modality = inputOrder.modality;
      if(inputOrder.bodyPartExamined) exam.bodyPartExamined = inputOrder.bodyPartExamined;
      if(inputOrder.studyDescription) exam.studyDescription = inputOrder.studyDescription;
      exam.studyDate = "";
      exam.studyTime = "";
      // exam.institutionalDepartmentName = "facilityId -> name"
      if(inputOrder.requestPhysician) exam.referringPhysicianName = inputOrder.requestPhysician;

      // let series = new Object();
      // series.studyInstanceUID = inputOrder.studyInstanceUID;
      // series.seriesNumber = 0;
      // series.seriesDescription = "";
      // series.displayOrder = 0;
      // series.image = new Array();
      const seriesArr = [];
      // seriesArr.push(series);
      exam.series = seriesArr;

      this.exam = exam;
      this._selectedExam = this.exam;
   }

   inputModifiedData(){
      this.modifiedRequest.id = "new";
      this.modifiedRequest.patientId = this.$.patientId.value;
      this.modifiedRequest.patientName = this.$.patientName.value;

      // const regExp = /^[0-9]*$/;
      // if(!regExp.test(this.$.patientBirthDate.value)){
      if(this.$.patientBirthDate.value.includes("-")){
         const regBirthDate = this.$.patientBirthDate.value.replace(/-/gi, "");
         this.modifiedRequest.patientBirthDate = regBirthDate;
      }else{
         this.modifiedRequest.patientBirthDate = this.$.patientBirthDate.value;
      }
      this.modifiedRequest.patientSex = this.$.patientGender.value;
      this.modifiedRequest.clinicalInfo = this.$.clinicalInfo.value;
      this.modifiedRequest.accessionNumber = this.$.accessionNumber.value;
      if(this.$.modality.value){
         this.modifiedRequest.modality = this.$.modality.value;
      }else{
         this.modifiedRequest.modality = "";
      }
      if(this.$.bodyPartExamined.value) {
         this.modifiedRequest.bodypart = this.$.bodyPartExamined.value;
      }else{
         this.modifiedRequest.bodypart = "";
      }
      this.modifiedRequest.studyDate = moment().format("YYYYMMDD");
      this.modifiedRequest.studyTime = moment().format("HHmmss");
      this.modifiedRequest.studyDescription = this.$.studyDescription.value;
      this.modifiedRequest.referringPhysicianName = this.$.referringPhysicianName.value;
      // this.modifiedRequest.studyId = this.$.studyId.value;
      // this.modifiedRequest.studyInstanceUID = this.$.studyInstanceUID.value;
      // this.modifiedRequest.studyInstanceUID = "";

      for(let i=0; i<this.addFiles.length; i++){
         if(this.addFiles[i].image){
            const base64 = this.addFiles[i].image.split(",")[1];
            this.addFiles[i].image = base64;
         }else{
            this.addFiles[i].split(i,1);
         }

      }
      this.modifiedRequest.imageData = this.addFiles;
      this.setModifiedSeries();
   }

   setModifiedSeries(){
      // 삭제된 시리즈를 서버에 보낼 파라미터에 저장
      // 시리즈의 삭제는 안의 이미지도 모두 삭제, 해당 케이스 processingStatus = temp, 케이스 생성
      // case.exam[0].sereis, case.exam[0].series.seriesInstanceUID (Series KEY)]
      const series = [];
      const tempExam = this.exam;
      for(let i=0; i<tempExam.series.length; i++){
         const seriesObj = {};
         const image = [];

         for(let j=0; j<tempExam.series[i].image.length; j++){
            const imageObj = {};
            imageObj.sopInstanceUID = "";
            imageObj.contentID = "";
            imageObj.fileType = "jpeg";
            image.push(imageObj);
            seriesObj.seriesInstanceUID = "";
         }
         seriesObj.image = image;
         series.push(seriesObj);
      }
      this.modifiedRequest.series = series;
      this.exam.contentId = [];
   }

   doClear(){
      this.series = [];
      this.images = [];
      this.addFiles = [];
      this.$.patientId.value = "";
      this.$.patientName.value = "";
      this.$.patientBirthDate.value = "";
      this.$.patientGender.value = "";
      this.$.clinicalInfo.value = "";
      this.$.accessionNumber.value = "";
      this.$.modality.value = "";
      this.$.bodyPartExamined.value = "";
      // this.$.studyDate.value = "";
      // this.$.studyTime.value = "";
      this.$.studyDescription.value = "";
      this.$.referringPhysicianName.value = "";
      // this.$.studyId.value = "";
      // this.$.studyInstanceUID.value = "";
      this.$.newImageBtn.style.display = "none";
   }

   doClose() {
      this.doClear();
      this.$.techtabNewExamDialog.close();
   }

   doOk(){
      if(this.series.length > 0){
         if(this.series.length ===1 && this.series[0].image === notFoundImage) {
            // console.log("하나 이상의 이미지를 업로드 하셔야 합니다.");
            this.toastMessage("You must upload more than one image",true);
            return false;
         }

         const _message = {};
         _message.contents = [this.t("msg.newExamDialog.newExam")];
         _message.title = this.t("label.newExam");
         _message.key = "newExam";
         _message.ok = this.t("button.yes");
         _message.cancel = this.t("button.no");
         this.$.hhTechtabModifiedExamConfirmDialog.doCustomSize(180,300);
         this.$.hhTechtabModifiedExamConfirmDialog.doOpen(_message);
      }else if(this.series.length === 0){
         // console.log("하나 이상의 시리즈를 생성하셔야 합니다.")
         this.toastMessage(this.t("msg.newExamDialog.newExamWarning"),true);
         return false;
      }
      return false; // prevent propagation
   }

   newSeries(){
      // 새로운 시리즈 정보를 입력하는 dialog..
      // add Exam.series
      // console.log("add series");
      // _message.contents = ["Do you really want Add New Series?"];
      // _message.title = "Add Series";
      // _message.ok = "Yes";
      // _message.cancel = "No";
      // this.$.hhTechtabModifiedExamConfirmDialog.doCustomSize(160,320);
      // this.$.hhTechtabModifiedExamConfirmDialog.doOpen(_message);
      // _message = {};
      this.addSeries();
   }

   addSeries(){
      // series Instance UID
      // study Instance UID -> dcm4che를 이용하여 RIS에서 생성
      // RIS 의 dcm4che 는 ver2 이기 때문에, Ray의 dcm4che 에서도 같은 방식인지 확인
      // dcm4che2는 prefix 값만 지정하고 나머지는 새로 생성하는 방식

      const tempSeriesArr = this.series;
      const tempSeries = {};
      tempSeries.idx = this.series.length;
      tempSeries.seriesDescription = "";
      // tempSeries.image = "public/resource/images/image_not_found.png";
      tempSeries.image = notFoundImage;
      tempSeriesArr.push(tempSeries);

      const series = {};
      series.studyInstanceUID = "";
      series.fileType = "jpeg";
      series.seriesDescription = "";
      series.seriesNumber = this.series.length;
      series.displayOrder = 1;
      series.image = [];

      this._selectedExam.series.push(series);

      this.series = [];
      this.series = tempSeriesArr;

      // 새로운 이미지 추가(시리즈에는 이미지가 한개 이상이 무조건 추가)
      this._selectedSeriesIdx = this.series.length -1;
   }

   removeSeries(/* e */){
      // console.log("remove series");
      // const seriesIdx = parseInt(this._selectedSeriesIdx, 10)+1;
      const _message = {};
      _message.contents = [this.t("msg.newExamDialog.removeSeries.0"), this.t("msg.newExamDialog.removeSeries.1")];
      _message.title = this.t("label.removeSeries");
      _message.key = "removeSeries";
      _message.ok = this.t("button.yes");
      _message.cancel = this.t("button.no");
      this.$.hhTechtabModifiedExamConfirmDialog.doCustomSize(185,345);
      this.$.hhTechtabModifiedExamConfirmDialog.doOpen(_message);
   }

   removeImage(/* e */){
      // console.log("remove image");
      // let idx = 0;
      // if(e.path[2].className != null && e.path[2].className ==="class-imageList-image"){
      //    idx = 2;
      // }else if(e.path[4].className != null && e.path[4].className ==="class-imageList-image"){
      //    idx = 4;
      // }

      const _message = {};
      _message.contents = [this.t("msg.newExamDialog.removeImage.0"), this.t("msg.newExamDialog.removeImage.1")];
      _message.title = this.t("label.removeImage");
      _message.key = "removeImage";
      _message.ok = this.t("button.yes");
      _message.cancel = this.t("button.no");
      this.$.hhTechtabModifiedExamConfirmDialog.doCustomSize(185,345);
      this.$.hhTechtabModifiedExamConfirmDialog.doOpen(_message);
   }

   // sort
   ascSort(){
      this.$.iconAscending.hidden = true;
      this.$.fontAsc.style.display = "none";
      this.$.iconDescending.hidden = false;
      this.$.fontDsc.style.display = "flex";
      this.sort();
   }

   dscSort(){
      this.$.iconAscending.hidden = false;
      this.$.fontAsc.style.display = "flex";
      this.$.iconDescending.hidden = true;
      this.$.fontDsc.style.display = "none";
      this.sort();
   }

   sort(){
      const temp = [];
      for(let i=1; i<=this.images.length; i++){
         temp.push(this.images[this.images.length - i]);
      }
      this.images = temp;
   }

   // patient list selected rows
   setRefferalPatientRows(/* rows */){
      // const _selectedRows = rows;
   }

   /**
    * Read Files
    *
    * Create by BohyunJang on 2020-01-27 오후 4:26
    * */
   readUploadFile(input) {
      const temp = [];
      const tempFile = [];
      let cnt = 0;
      for(let i=0; i<input.files.length; i++){
         tempFile.push(input.files[i]);
         // eslint-disable-next-line no-loop-func
         this.getBase64(input.files[i]).then((result) =>{
            const file = result;
            file.idx = i+1;
            file.seriesIdx = this._selectedSeriesIdx;
            file.displayOrder = this._selectedExam.series[this._selectedSeriesIdx].image.length;
            temp.push(file);
            if(cnt === (input.files.length -1)){
               const tempSeriesArr = [];
               const tempSeries = {};
               if(!this.series || this.series.length === 0){
                  // new series && image
                  tempSeries.idx = 0;
                  tempSeries.seriesDescription = "";
                  tempSeries.image = temp;
                  tempSeries.file = temp[0].image;
                  tempSeriesArr.push(tempSeries);

                  const series = {};
                  series.studyInstanceUID = this.exam.studyInstanceUID;
                  series.seriesNumber = 1;
                  series.seriesDescription = "";
                  series.displayOrder = 1;
                  series.fileType = "jpeg";

                  const imageArr = [];
                  for(let i=0; i<temp.length; i++){
                     const image = {};
                     image.studyInstanceUID = this.exam.studyInstanceUID;
                     image.type = "jpeg";
                     image.originalName = temp[i].name;
                     image.displayOrder = i+1;
                     image.image = temp[i].image;
                     imageArr.push(image);
                  }
                  series.image = imageArr;
                  // _this.exam.series = [series];

               }else{
                  // add image to selected series
                  for(let j=0; j<temp.length; j++){
                     // add display image
                     const image = {};
                     image.image = temp[j].image;
                     image.type = "jpeg";
                     image.idx = this.images.length + j;
                     this.images.push(image);
                  }

                  if(this.series[this._selectedSeriesIdx].image.includes(notFoundImage)
                     || this.series[this._selectedSeriesIdx].image.includes("data:image/jpeg;base64")){

                     // add exam.series.image
                     const image = {};
                     image.studyInstanceUID = this.exam.studyInstanceUID;
                     image.type = "jpeg";
                     image.originalName = temp[i].name;
                     image.displayOrder = this.exam.series[this._selectedSeriesIdx].image.length+1;
                     image.image = temp[i].image;
                     this._selectedExam.series[this._selectedSeriesIdx].image.push(image);
                     this.getSereisList(this._selectedExam);
                     this.getImageList(this._selectedSeriesIdx);
                  }
               }

               for(let j=0; j<temp.length; j++){
                  this.addFiles.push(temp[j]);
               }
               // this.$.imageUploader.value = "";
               // eslint-disable-next-line no-param-reassign
               input.value = "";
            }
            cnt++;
         });
      }
   }

   /**
    * File To Base64
    *
    * Create by BohyunJang on 2020-01-27 오후 4:26
    * */
   getBase64(file) {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            const tempObj = {};
            tempObj.image = reader.result;
            tempObj.name = file.name;
            resolve(tempObj);
         };
         reader.onerror = (error) => {
            reject(error);
         };
      });
   }

   // get Eaxm By ObjectId
   getExamInfo(id) {
      return new Promise((resolve) => {
         fetch(`/api/exchange/worklist/idfilter/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse[0]);
               });
            }
            else {
               resolve(false);
            }
         });
      });
   }

   createExamByObjectId(exam){
      return new Promise( (resolve, reject) => {
         fetch(`/api/case/${exam.id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(exam)
         }).then( (response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            }
            else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         }).catch(err => reject(err));
      });
   }

   toastMessage(message, isErr){
      const detail = {};
      detail.msg = message;
      detail.isErr = isErr;
      this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
   }
}

window.customElements.define("techtab-new-exam-dialog", TechtabNewExamDialog);
