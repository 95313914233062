import React, { useEffect, useState } from "react";
import { Alert, Button, Snackbar, SnackbarOrigin } from "@mui/material";
import commonuUtils from "../../../public/resource/js/utils/common";

interface ToastProps {
   open: boolean;
   msg?: string;
}
interface State extends SnackbarOrigin {
   open: boolean;
   msg?: string;
}

export default function DownloadToast(Props: ToastProps) {
   const [state, setState] = useState<State>({
      open: !commonuUtils.isEmptyValue(Props.msg) ? Props.open : false,
      vertical: "bottom",
      horizontal: "left",
      msg: Props.msg,
   });
   const { vertical, horizontal, open, msg } = state;

   const handleClose = () => {
      setState({ ...state, open: false });
   };

   // Prop이 바뀔때 리렌더링
   useEffect(() => {
      // State의 경우에는 불변이기 때문에 setState로 바꾸어 주지 않으면 state는 변화가 없음
      // Prop을 가지고 state를 쓰는 경우 prop이 바뀔때 state를 update 해주어야 한다?
      if (!Props.open || commonuUtils.isEmptyValue(Props.msg)) {
         setState({ ...state, open: false });
      } else {
         setState({ ...state, open: true, msg: Props.msg });
      }
   }, [Props]);

   return (
      <React.Fragment>
         <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            key={vertical + horizontal}
         >
            <Alert sx={{ bgcolor: "rgba(0, 0, 0, .8)", color: "#aaa", whiteSpace: "pre-wrap" }} icon={false} >
               { msg }
               <Button color={"warning"} onClick={handleClose}>Close now!</Button>
            </Alert>
         </Snackbar>
      </React.Fragment>
   );
}
