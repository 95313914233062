import React from "react";
import { format } from "date-fns";
import { Box } from "@mui/material";
import { FormStyles } from "./styles/FormStyle";
import TextTypeField from "../../input/textfield/TextTypeField";
import { CustomDatePicker } from "../../picker/CustomDatePicker";
import { CustomTimePicker } from "../../picker/CustomTimePicker";

type inputData = {
   key: string,
   label: string,
   value: string,
}

interface InfoFormProps {
  title: string,
  data: Array<inputData>
  changeValue?: Function,
}

export default function EditForm(props: InfoFormProps) {
   const { data, changeValue } = props;

   function onChange(e: any, key: string, type?: string) {
      const copyObj = [...data];
      const idx = data.findIndex((obj:any) => obj.key === key);
      let value;

      switch (type) {
      case "date": {
         value = e;
         break;
      }
      case "time": {
         value = e;
         break;
      }
      default: {
         value = e.target.value;
      }
      }
      if (idx !== -1) {
         copyObj[idx] = { ...copyObj[idx], value };
      }
      if (changeValue) {
         changeValue(copyObj);
      }
   }

   const classes = FormStyles();
   return (
      <>
         {/* <Box className={classes.container}> */}
         <Box className={classes.info}>
            <Box className={classes.titleContainer}>
               <Box className={classes.titleContent}>{props.title}</Box>
            </Box>
            <Box className={classes.infoContainer} sx={{ overflowY: "auto", overflowX: "hidden" }}>
               { data && data.map((obj:any, index:number) => (
                  <Box className={classes.inputContainer} key={index}>
                     <Box className={classes.font}>{obj.label} :</Box>
                     {obj.type === "date"
                          && <CustomDatePicker
                             className={classes.content}
                             value={obj.value}
                             valueFormat={"yyyy.MM.dd"}
                             onChange={(e: any) => onChange(e, obj.key, obj.type)}
                          />
                     }
                     {obj.type === "time"
                          && <CustomTimePicker
                             className={classes.content}
                             value={obj.value}
                             valueFormat={"HH:mm:ss"}
                             onChange={(e: any) => onChange(e, obj.key, obj.type)}
                          />
                     }
                     {!obj.type
                          && <TextTypeField
                             className={classes.content}
                             size={"large"}
                             variant={"standard"}
                             InputProps={{ sx:{ width: "95%" } }}
                             value={obj.value}
                             multiline
                             onChange={e => onChange(e, obj.key)}/>
                     }
                  </Box>
               ))}
            </Box>
         </Box>
         {/* </Box> */}
      </>
   );
}
