import React from "react";
import TextField from "@mui/material/TextField";
import { DefaultTextFieldTypes } from "./types/DefaultTextFieldTypes";

export default function TextTypeField(props: DefaultTextFieldTypes): JSX.Element {
   const { shrink, placeholder, inputProps, ...textFieldProps } = props;
   return (
      <TextField
         {...textFieldProps}
         type={"text"}
         autoComplete={"off"}
         InputLabelProps={{ shrink }}
         inputProps={{ ...inputProps, placeholder }}
      />
   );
}
