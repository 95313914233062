import { WWL, WWLPreset } from "../components/dialog/Setting/item/FilmboxWwlPreset";
import { MacroFunction, ShortcutsSetting } from "../components/dialog/Setting/item/types/SettingShortcutTypes";
import { Preset } from "../components/dialog/Setting/item/types/SettingMacroTypes";
import { UserStyleType } from "../types/UserStyleType";
import { DDP, DDPSetting } from "../components/dialog/Setting/item/types/SettingDDPTypes";

/*
* https://stackblitz.com/edit/react-api-service?file=Api.service.js
* */

const getUserStyle = () : Promise<UserStyleType> => new Promise<UserStyleType>((resolve, reject) => {
   fetch(`/api/user/option/style`, {
      method: "GET",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
      },
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((result) => {
            resolve(result);
         });
      } else {
         reject(new Error(`${response.status} ${response.statusText}`));
      }
   });
});

/**
 * function createDDPSetting
 * Ray API 호출 ( createDDPSetting )
 * ddp 설정 정보 저장
 *
 * Create by 김민정 on 2018-04-04 오전 11:53
 * @param {Object} ddp
 * @return {DDPSetting} result
 * */
/* DDP */
const createDDPSetting = (ddp: DDP) : Promise<DDPSetting> => new Promise<DDPSetting>((resolve) => {
   const param = { request : ddp };
   fetch("/api/user/option/ddp", {
      method: "POST",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(param.request),
   }).then((response) => {
      if (!response.ok) {
         // TODO 예외처리 방안
         console.log("ddpSetting create fail");
      }
      else {
         response.json().then((result) => {
            resolve(result);
         });
      }
   });
});

const getUserDDPsByModality = (modality: string) : Promise<Array<DDPSetting> | null> => new Promise<Array<DDPSetting> | null>((resolve, reject) => {
   fetch(`/api/user/option/ddps?modality=${encodeURIComponent(modality)}`, {
      method: "GET",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((result) => {
            resolve(result);
         });
      }
      else if (response.status === 204) {
         resolve(null);
      }
   }).catch(err => reject(err));
});

/**
 * function getUserDDPByUserId
 * Ray API 호출 ( getUserDDPByUserId )
 * 해당 유저의 ddp 설정 리스트 조회
 *
 * Create by 김민정 on 2018-04-24 오전 10:45
 * @param {}
 * @return {Object} data
 * */
const getUserDDPByUserId = () : Promise<Array<DDPSetting> | null> => new Promise<Array<DDPSetting> | null>((resolve, reject) => {
   fetch("/api/user/option/ddps", {
      method: "GET",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
      },
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((data) => {
            resolve(data);
         });
      }
      // HTTP 상태코드(204) 반환 - 필터 리스트 사이즈 0
      else if (response.ok && response.status === 204) {
         resolve(null);
      }
      // TODO 예외처리 방안
      else {
         console.log("getUserDDPByUserId fail");
      }
   }).catch(err => reject(err));
});

/**
 * function updateUserDDPById
 * Ray API 호출 ( updateUserDDPById )
 * ddp설정 변경
 *
 * Create by 김민정 on 2018-05-24 오후 1:04
 * @return {}
 * @param id
 * @param ddp
 * */
const updateUserDDPById = (id: string, ddp: DDP) : Promise<DDPSetting> => new Promise<DDPSetting>((resolve) => {
   const param = { request : ddp };
   fetch(`/api/user/option/ddp/${id}`, {
      method: "PATCH",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(param.request),
   }).then((response) => {
      if (response.ok && response.status === 201) {
         response.json().then((data) => {
            resolve(data);
         });
      }
      // TODO 예외처리 방안
      else {
         console.log("fail");
      }
   });
});

/**
 * function deleteUserDDPByUserId
 * Ray API 호출 ( deleteUserDDPByUserId )
 * 사용자 ddp 설정 삭제
 *
 * Create by 김민정 on 2018-04-25 오후 9:14
 * @param {String} id
 * @return {Boolean} response.ok
 * */
const deleteUserDDPByUserId = (id: string) : Promise<any> => new Promise((resolve) => {
   fetch(`/api/user/option/ddp/${id}`, {
      method: "DELETE",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
      },
   }).then((response) => {
      resolve(response.ok);
   });
});

/**
 * function getDefaultDDPsByModality
 * Modality별 DefaultDDP 조회
 *
 * Create by minjeong on 23/09/2019 6:24 오후
 * */
const getDefaultDDPsByModality = async (modality: string) : Promise<Array<DDPSetting>> => new Promise((resolve) => {
   fetch(`/api/default/ddps?modality=${encodeURIComponent(modality)}`, {
      method: "GET",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
      },
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((ddps) => {
            resolve(ddps);
         });
      } else {
         console.log("getDefaultDDPsByModality fail");
      }
   });
});

/* Shortcut */
const updateWorklistShortcuts = (shortcut: ShortcutsSetting) : Promise<ShortcutsSetting | null> => new Promise<ShortcutsSetting | null>((resolve, reject) => {
   const param = { request: shortcut.worklistFunction };
   fetch(`/api/user/option/shortcut/worklist/${shortcut.id}`, {
      method: "PATCH",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(param.request),
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((result) => {
            resolve(result);
         });
      }
   }).catch(err => reject(err));
});

/* Shortcut */
const updateFilmboxShortcuts = (shortcut: ShortcutsSetting) : Promise<ShortcutsSetting | null> => new Promise<ShortcutsSetting | null>((resolve, reject) => {
   const param = { request: shortcut.filmboxFunction };
   fetch(`/api/user/option/shortcut/filmbox/${shortcut.id}`, {
      method: "PATCH",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(param.request),
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((result) => {
            resolve(result);
         });
      }
   }).catch(err => reject(err));
});

/* Shortcut, Macro */
const getUserShortcutByUserId = () : Promise<ShortcutsSetting> => new Promise<ShortcutsSetting>((resolve, reject) => {
   fetch("/api/user/option/shortcuts", {
      method: "GET",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
      },
   }).then((response: Response) => {
      if (response.ok && response.status === 200) {
         response.json().then((result) => {
            resolve(result);
         });
      } else {
         // // this.gridOptions.api.setRowData(null);
         // _shortcut = defaultShortcutSetting();
         // this.createShortcut(this._shortcut);
         // rowData = _shortcut.worklistFunction;
         reject(new Error(`status: ${response.status}, message: ${response.statusText}`));
      }
   }).catch(err => reject(err));
});

/* Macro */
const getPreformByUserId = () : Promise<Array<Preset> | null> => new Promise<Array<Preset> | null>((resolve) => {
   fetch("/api/user/option/preforms", {
      method: "GET",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
      },
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((result) => {
            resolve(result);
         });
      } else if (response.status !== 204) {

      } else {
         // _preform = null;
         // rowData = [];
         // resolve(_preform);
      }
   });
});

/* Macro */
const createMacroShortcuts = (inputMacro: MacroFunction) : Promise<ShortcutsSetting | null> => new Promise<ShortcutsSetting | null>((resolve) => {
   fetch("/api/user/option/shortcut/macro", {
      method: "POST",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(inputMacro),
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((httpResponse: ShortcutsSetting) => {
            resolve(httpResponse);
         });
      }
   });
});

/* Macro */
const updateMacroShortcuts = (macro: MacroFunction, id: string) : Promise<ShortcutsSetting | null> => new Promise<ShortcutsSetting | null>((resolve) => {
   fetch(`/api/user/option/shortcut/macro/${id}`, {
      method: "PATCH",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(macro),
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((httpResponse: ShortcutsSetting) => {
            resolve(httpResponse);
         });
      }
   });
});

/* Macro */
const deleteMacroShortcuts = (seqNo: number) : Promise<ShortcutsSetting | null> => new Promise<ShortcutsSetting | null>((resolve) => {
   fetch(`/api/user/option/shortcut/macro/${seqNo}`, {
      method: "delete",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
      },
   }).then((response) => {
      if (response.ok) {
         response.json().then((httpResponse: ShortcutsSetting) => {
            resolve(httpResponse);
         });
      }
   });
});

/* FilmboxWwlPreset */
const getUserWWLPreset = async () : Promise<WWL> => new Promise<WWL>((resolve, reject) => {
   const user = JSON.parse(localStorage.getItem("user") || "{}");
   fetch(`/api/user/option/${user.id}/wwl`, {
      method: "GET",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((res) => {
            resolve(res);
         });
      } else {
         reject(new Error("get WWL Preset is fail"));
      }
   }).catch(err => reject(err));
});

/* FilmboxWwlPreset */
const getColFilter = (col: any) : Promise<Array<any>> => new Promise<Array<any>>((resolve, reject) => {
   fetch(`/api/exchange/worklist/colfilter/${col}`, {
      method: "GET",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
   }).then((response) => {
      if (response.ok && response.status === 200) {
         response.json().then((json) => {
            resolve(json);
         });
      } else {
         reject();
      }
   }).catch(err => reject(err));
});

/* FilmboxWwlPreset */
const createPreset = (row: WWLPreset) : Promise<WWL> => new Promise<WWL>((resolve) => {
   fetch("/api/user/option/wwl", {
      method: "POST",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(row),
   }).then((response) => {
      if (response.ok) {
         response.json().then((httpResponse: WWL) => {
            resolve(httpResponse);
         });
      }
   }).catch(err => console.error(err));
});

/* FilmboxWwlPreset */
const updatePreset = (row: WWLPreset) : Promise<WWL> => new Promise<WWL>((resolve) => {
   fetch(`/api/user/option/wwl/${row.idx}`, {
      method: "PATCH",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(row),
   }).then((response) => {
      if (response.ok) {
         response.json().then((httpResponse: WWL) => {
            resolve(httpResponse);
         });
      }
   }).catch(err => console.error(err));
});

/* FilmboxWwlPreset */
const deletePreset = async (row: WWLPreset) : Promise<WWL> => new Promise<WWL>((resolve) => {
   fetch(`/api/user/option/wwl/${row.modality}/${row.idx}`, {
      method: "DELETE",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
   }).then((response) => {
      if (response.ok) {
         /* TODO */
         // // filmbox wwlpreset new, old sync 및 dropDown 메뉴 set
         // if(window.props && window.props.setPreset) window.props.setPreset();
         // else {
         //    this.setWWLList();
         //    if(window.filmbox && window.filmbox.get()) {
         //       window.postMessage({event: "SET_PRESET"}, document.location.href);
         //    }
         // }
         response.json().then((httpResponse: WWL) => {
            resolve(httpResponse);
         });
      }
   }).catch(err => console.error(err));
});

const updateGridHeader = (headerArr: any, type: string) => {
   // const type = "orderFilm";
   fetch(`/api/user/option/gridheader/${type}`, {
      method: "PATCH",
      headers: {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(headerArr),
   }).then((response) => {
      if (!response.ok) {
         console.debug(new Error(`${response.status} ${response.statusText}`));
      }
   });
};

export default {
   getUserStyle,

   /* DDP */
   createDDPSetting,
   getUserDDPsByModality,
   getUserDDPByUserId,
   updateUserDDPById,
   deleteUserDDPByUserId,
   getDefaultDDPsByModality,

   /* Shortcut */
   updateWorklistShortcuts,
   updateFilmboxShortcuts,
   /* Macro */
   getPreformByUserId,
   createMacroShortcuts,
   updateMacroShortcuts,
   deleteMacroShortcuts,

   /* Shortcut, Macro */
   getUserShortcutByUserId,

   /* Report */
   // updateSplitLayout,

   /* FilmboxWwlPreset */
   getUserWWLPreset,
   createPreset,
   updatePreset,
   deletePreset,
   getColFilter,

   /*  */
   updateGridHeader,
};
