import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { FormControlLabel, IconButton, Modal, RadioGroup } from "@mui/material";
import { Close } from "@mui/icons-material";
import { StyledBox } from "../styles/EmotionStyles";
import { StyledBtnWrappers, StyledContainer } from "../styles/CommonDialogStyles";
import { Content, ContentsWrapper, InputWrapper, Title } from "./styles/BoneAgeReportDialogStyles";
import i18n from "../../../utils/i18n-utils";
import TextTypeField from "../../input/textfield/TextTypeField";
import store from "../../../redux/store";
import { CommonActionType } from "../../../redux/reducers/common";
import { StyledRadio } from "../Setting/item/styles/SettomgStyles";
import OkBtn from "../../button/OkBtn";
import CancelBtn from "../../button/CancelBtn";
import { useToastDispatch } from "../../../context/ToastContext";
import { closeIfEsc } from "../../../utils/fleader-common";
import CommonUtils from "../../../../public/resource/js/utils/common";

const width = 500;
const height = 720;

interface BoneAgeReportDialogProps {
   detail: {
      row: any;
      aiList: any;
   }
   open: boolean;
}

interface BoneAgeReportDialogStateValid {
   patientBirthDay: boolean;
   patientSex: boolean;
   dadHeight: boolean;
   momHeight: boolean;
   childHeight: boolean;
   childWeight: boolean;
   childLastYear: boolean;
   clinicName: boolean;
   isMenarche: boolean;
}

interface BoneAgeReportDialogState {
   patientBirthDay: string;
   patientSex: string;
   dadHeight: string;
   momHeight: string;
   childHeight: string;
   childWeight: string;
   childLastYear: string;
   clinicName: string;
   checkType: string;
   isMenarche: boolean;
}

export default function BoneAgeReportDialog(props: BoneAgeReportDialogProps) {
   const toastDispatch = useToastDispatch();
   const ref: any = {
      patientBirthDay: useRef<HTMLInputElement>(null),
      clinicName: useRef<HTMLInputElement>(null),
      dadHeight: useRef<HTMLInputElement>(null),
      momHeight: useRef<HTMLInputElement>(null),
      childHeight: useRef<HTMLInputElement>(null),
      childWeight: useRef<HTMLInputElement>(null),
      childLastYear: useRef<HTMLInputElement>(null),
      patientSex: useRef<HTMLInputElement>(null),
   };

   const [open, setOpen] = useState<boolean>(false);
   const [state, setState] = useState<BoneAgeReportDialogState>({
      patientBirthDay: "",
      patientSex: "",
      dadHeight: "",
      momHeight: "",
      childHeight: "",
      childWeight: "",
      childLastYear: "",
      clinicName: "",
      checkType: "false",
      isMenarche: false,
   });

   const [stateValid, setStateValid] = useState<BoneAgeReportDialogStateValid>({
      clinicName: true,
      patientBirthDay: true,
      patientSex: true,
      dadHeight: true,
      momHeight: true,
      childHeight: true,
      childWeight: true,
      childLastYear: true,
      isMenarche: true,
   });
   useEffect(() => {
      if (props.detail) {
         const { row } = props.detail;
         setState(prevState => ({
            ...prevState,
            patientBirthDay: /^[0-9]{8}$/.test(row.patientBirthDate) ? row.patientBirthDate : "",
            patientSex: row.patientSex,
            clinicName: CommonUtils.escapeRegex(row.requestHospital),
         }));
      }
   }, [props.detail]);

   useEffect(() => {
      if (props.open) setOpen(props.open);
   }, [props.open]);

   useEffect(() => {
      if (state.patientSex === "M") {
         setState(prevState => ({
            ...prevState,
            isMenarche: false,
         }));
      }
   }, [state.patientSex]);

   function openToast(msg: string, isErr: boolean) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   }

   function handleClose() {
      // @ts-ignore
      window.dialog_closed();

      setOpen(false);
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   function doSend() {
      if (!checkValid(state.patientBirthDay, "patientBirthDay")) return;
      if (!checkValid(state.patientSex, "patientSex")) return;
      if (!checkValid(state.clinicName, "clinicName")) return;
      if (!checkValid(state.dadHeight, "dadHeight")) return;
      if (!checkValid(state.momHeight, "momHeight")) return;
      if (!checkValid(state.childHeight, "childHeight")) return;
      if (!checkValid(state.childWeight, "childWeight")) return;

      const { row, aiList } = props.detail;
      const { studyDtime } = row;
      const studyDate = studyDtime ? studyDtime.replace(/[^0-9]/g, "").substring(0, 8) : null;

      const addParams = {
         caseID: row.id,
         gender: state.patientSex,
         patientID: row.patientID,
         patientName: row.patientName,
         dobr: state.patientBirthDay,
         studyDate,
         dHeight: state.dadHeight,
         mHeight: state.momHeight,
         cHeight: state.childHeight,
         lGrowth: state.childLastYear,
         menarche: state.isMenarche,
         childWeight: state.childWeight,
         clinicName: state.clinicName,
      };

      // api 호출
      if (parent?.window?.opener?.filmbox && parent?.window?.opener?.filmbox.get()) parent.window.opener.aiStudyRequest(row?.id, aiList?.applicationId, "", [], aiList?.aiType, JSON.stringify(addParams));
      // @ts-ignore
      else window.aiStudyRequest(row?.id, aiList?.applicationId, "", [], aiList.aiType, JSON.stringify(addParams));

      handleClose();
   }

   function checkValid(value: string, field: string, isDoSend: boolean = true) {
      let isValid = true;
      switch (field) {
      case "patientBirthDay": {
         const regex = isDoSend ? /^[0-9]{8}$/ : /^[0-9]{1,8}$/;
         isValid = regex.test(value);
         break;
      }
      // number type
      case "dadHeight":
      case "momHeight":
      case "childHeight":
      case "childWeight":
      case "childLastYear": {
         isValid = /^[0-9]+$/.test(value);
         break;
      }
      case "patientSex": {
         isValid = value === "F" || value === "M";
         break;
      }
      case "clinicName": {
         isValid = CommonUtils.escapeRegex(value) === value;
         break;
      }
      default:
         isValid = !!value;
         break;
      }

      if (!isValid && isDoSend) {
         ref[field].current.focus();
         openToast(i18n(`msg.boneage.invalid.${field}`), true);
      }

      setStateValid(prevState => ({
         ...prevState,
         [field]: isValid || value.length > 1,
      }));

      return isValid;
   }

   function handleStateChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: string) {
      const { value } = event.target;
      const isValid = checkValid(value, field, false);

      if (isValid || value.length === 0) {
         setState(prevState => ({
            ...prevState,
            [field]: value,
         }));
      }
   }

   return (
      <>
         <Modal
            open={open}
            // @ts-ignore
            onKeyDown={e => closeIfEsc(e, handleClose)}
         >
            <StyledBox
               width={width}
               height={height}
               sx={{ background: "#2d333f", borderTop: "5px #0087cb solid" }}
            >
               <StyledContainer>
                  <Title>
                     <p style={{ margin: 0, padding: 10, float: "left", width: "80%" }}>Boneage Report</p>
                     <IconButton sx={{ width: 50, height: 50, float: "right", color: "#aaaaaa" }} onClick={handleClose}>
                        <Close/>
                     </IconButton>
                  </Title>
                  <ContentsWrapper>
                     <Content>
                        <InputWrapper>
                           <div>{i18n("label.id")} :</div>
                           <TextTypeField
                              value={props.detail.row.patientID || ""}
                              inputProps={{ readOnly: true, fontSize: 10 }}
                              variant="standard"
                              shrink
                           />
                        </InputWrapper>
                        <InputWrapper>
                           <div>{i18n("label.name")} :</div>
                           <TextTypeField
                              value={props.detail.row.patientName || ""}
                              inputProps={{ readOnly: true, fontSize: 10 }}
                              variant="standard"
                              shrink
                           />
                        </InputWrapper>
                        <InputWrapper>
                           <div>{i18n("label.birthDate")} :</div>
                           <TextTypeField
                              inputRef={ref.patientBirthDay}
                              value={state.patientBirthDay}
                              onChange={e => handleStateChange(e, "patientBirthDay")}
                              error={!stateValid.patientBirthDay}
                              inputProps={{ fontSize: 10 }}
                              required={true}
                              variant="standard"
                              shrink
                           />
                        </InputWrapper>
                        <InputWrapper>
                           <div>{i18n("label.gender")} :</div>
                           <RadioGroup
                              ref={ref.patientSex}
                              value={state.patientSex}
                              onChange={e => handleStateChange(e, "patientSex")}
                              row
                           >
                              <FormControlLabel style={{ padding: "0", marginRight: "15px" }} value="F" control={<StyledRadio />} label={"F (Female)"} />
                              <FormControlLabel style={{ padding: "0", marginRight: "15px" }} value="M" control={<StyledRadio />} label={"M (Male)"} />
                           </RadioGroup>
                        </InputWrapper>
                     </Content>

                     <Content>
                        <TextTypeField
                           inputRef={ref.clinicName}
                           value={state.clinicName}
                           onChange={e => handleStateChange(e, "clinicName")}
                           style={{ width: "100%", marginBottom: 20 }}
                           inputProps={{ style: { fontSize: "15px" } }}
                           error={!stateValid.clinicName}
                           required={true}
                           variant="standard"
                           shrink
                           label={i18n("label.clinic")}
                           placeholder={i18n("label.clinicPlaceholder")}
                        />
                        <TextTypeField
                           inputRef={ref.dadHeight}
                           value={state.dadHeight}
                           onChange={e => handleStateChange(e, "dadHeight")}
                           style={{ width: "100%", marginBottom: 20 }}
                           inputProps={{ style: { fontSize: "15px" } }}
                           error={!stateValid.dadHeight}
                           required={true}
                           variant="standard"
                           shrink
                           label={i18n("label.dadHeight")}
                           placeholder={i18n("label.heightPlaceholder")}
                        />

                        <TextTypeField
                           inputRef={ref.momHeight}
                           value={state.momHeight}
                           onChange={e => handleStateChange(e, "momHeight")}
                           style={{ width: "100%", marginBottom: 20 }}
                           inputProps={{ style: { fontSize: "15px" } }}
                           error={!stateValid.momHeight}
                           required={true}
                           variant="standard"
                           shrink
                           label={i18n("label.momHeight")}
                           placeholder={i18n("label.heightPlaceholder")}
                        />

                        <TextTypeField
                           inputRef={ref.childHeight}
                           value={state.childHeight}
                           onChange={e => handleStateChange(e, "childHeight")}
                           style={{ width: "100%", marginBottom: 20 }}
                           inputProps={{ style: { fontSize: "15px" } }}
                           error={!stateValid.childHeight}
                           required={true}
                           variant="standard"
                           shrink
                           label={i18n("label.childHeight")}
                           placeholder={i18n("label.heightPlaceholder")}
                        />

                        <TextTypeField
                           inputRef={ref.childWeight}
                           value={state.childWeight}
                           onChange={e => handleStateChange(e, "childWeight")}
                           style={{ width: "100%", marginBottom: 20 }}
                           inputProps={{ style: { fontSize: "15px" } }}
                           error={!stateValid.childWeight}
                           required={true}
                           variant="standard"
                           shrink
                           label={i18n("label.childWeight")}
                           placeholder={i18n("label.weightPlaceholder")}
                        />

                        <TextTypeField
                           inputRef={ref.childLastYear}
                           value={state.childLastYear}
                           onChange={e => handleStateChange(e, "childLastYear")}
                           style={{ width: "100%", marginBottom: 20 }}
                           inputProps={{ style: { fontSize: "15px" } }}
                           error={!stateValid.childLastYear}
                           variant="standard"
                           shrink
                           label={i18n("label.childHeightLastYear")}
                           placeholder={i18n("label.heightPlaceholder")}
                        />

                        <InputWrapper>
                           <div>{i18n("label.menarche")}:</div>
                           <RadioGroup
                              value={state.isMenarche}
                              onChange={e => handleStateChange(e, "isMenarche")}
                              row
                           >
                              <FormControlLabel style={{ padding: "0", marginRight: "15px" }} value="true" disabled={state.patientSex === "M"} control={<StyledRadio />} label={i18n("label.yes")} />
                              <FormControlLabel style={{ padding: "0", marginRight: "15px" }} value="false" disabled={state.patientSex === "M"} control={<StyledRadio />} label={i18n("label.no")} />
                           </RadioGroup>
                        </InputWrapper>
                     </Content>
                     <StyledBtnWrappers>
                        <OkBtn onClick={doSend}  text={i18n("button.send")}/>
                        <CancelBtn onClick={handleClose} text={i18n("button.close")}/>
                     </StyledBtnWrappers>
                  </ContentsWrapper>
               </StyledContainer>
            </StyledBox>
         </Modal>
      </>
   );
}
