import React, { useEffect, useMemo, useRef, useState } from "react";
import {
   Box,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   IconButton,
   Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { GridOptions, RowDoubleClickedEvent } from "ag-grid-community";
import { useDispatch } from "react-redux";
import { TechlistActionType } from "../../../redux/reducers/techlist";
import DateFilterUtils from "../../../utils/date-filter-utils";
import GridUtils from "../../../utils/grid-utils";
import TechnicianUtils from "../../../utils/technician-utils";
import { is_esc } from "../../../dialog/common-keyboard-handler";
import { CommonActionType } from "../../../redux/reducers/common";
import i18n from "../../../utils/i18n-utils";
import InfoForm from "./InfoForm";
import ExamImageList from "./ExamImageList";
import ExamSeriesList from "./ExamSeriesList";
import EditForm from "./EditForm";
import useSplitExam from "./hooks/useSplitExam";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useToastDispatch } from "../../../context/ToastContext";
import usePrevState from "../../../hooks/usePrevState";
import { DialogStyles } from "../styles/DialogStyles";
import { RelatedTechlistActionType } from "../../../redux/reducers/related-techlist";
import useDialogOpen from "../../../hooks/useDialogOpen";
import Confirm, { ConfirmProps } from "../Confirm";
import OkBtn from "../../button/OkBtn";
import CancelBtn from "../../button/CancelBtn";

import BaseOrderGrid from "../../grid/BaseOrderGrid";

export default function SplitExam(props: any) {
   const is = "split-exam-dialog";
   const [open, setOpen] = useState(false);
   const { state, examDispatch } = useSplitExam();
   const [_selectedRow, _setSelectedRow] = useState<any>(null);
   const [orderDefaultFilter, setOrderDefaultFilter] = useState<any>(null);
   const [filters, setFilters] = useState<any>(null);
   const [confirmDialog, setConfirmDialog] = useState<any>(null);
   const prevEditTags = usePrevState(state.editTags);
   const toastAction = useToastDispatch();
   const dispatch = useDispatch();
   useDialogOpen();

   useEffect(() => {
      doOpen(props);
      getOrderFilters().then((result) => {
         setFilters(result);
      });
   }, []);

   useDidMountEffect(() => {
      setSeriesList();
      examDispatch({ type: "SET_EXAM_INFO", data: getBaseExamInfo() });
      examDispatch({ type: "SET_EDIT_TAGS", data: getBaseEditTags(), isEditing: false });
      if (_selectedRow != null) {
         setOrderDefaultFilter(createOrderDefaultFilter(_selectedRow));
      }
      setOpen(true);
   }, [_selectedRow]);

   useDidMountEffect(() => {
      if (state.isEditing) {
         if (!compareObjects(state.editTags, state.compareEditTags!)) {
            const params:any = {};
            params.onOk = state.isSelected
               ? () => examDispatch({ type: "SET_EDIT_TAGS", data: state.compareEditTags!, isEditing: false })
               : () => examDispatch({ type: "SET_EDIT_TAGS", data: getBaseEditTags(), isEditing: false });
            params.onDiscard = () => examDispatch({ type: "SET_EDIT_TAGS", data: prevEditTags, isEditing: false });
            params.onCancel = () => examDispatch({ type: "SET_EDIT_TAGS", data: prevEditTags, isEditing: false });
            openWarningSelectOrderDialog(params, state.isSelected);
         }
      } else if (state.isSelected) {
         examDispatch({ type: "SET_EDIT_TAGS", data: state.compareEditTags!, isEditing: false });
      } else {
         examDispatch({ type: "SET_EDIT_TAGS", data: getBaseEditTags(), isEditing: false });
      }
   }, [state.compareEditTags]);

   function doOpen(selectedRow: any) {
      getExamInfo(selectedRow?.id).then((result:any) => {
         _setSelectedRow(result);
      });
   }

   function getOrderFilters() {
      return new Promise((resolve, reject) => {
         fetch(`/api/tech/orderlist/filters`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((result) => {
                  resolve(result);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   function compareObjects(obj1:any = {}, obj2:any = {}) {
      const sort1 = Object.keys(obj1).sort().reduce((obj:any, key) => {
         /* eslint-disable no-param-reassign */
         obj[key] = obj1[key];
         return obj;
      }, {});
      const sort2 = Object.keys(obj2).sort().reduce((obj:any, key) => {
         /* eslint-disable no-param-reassign */
         obj[key] = obj2[key];
         return obj;
      }, {});
      return JSON.stringify(sort1) === JSON.stringify(sort2); // json.stringify 객체 비교는 순서도 동일해야 함
   }

   function setSeriesList() {
      if (!_selectedRow) {
         console.warn(is, "selectedRow is null.");
         return;
      }

      const { exam } = _selectedRow;
      // Set Series
      const result = exam[0].series.map((srs: any, idx: any) => {
         const series: any = {};
         if (srs.fileType === "jpeg") {
            series.image = srs.image[0].image;
         } else {
            // eslint-disable-next-line no-undef
            series.image = `${__CDN_URL__}/prefetch/download/image/${srs.image[0].contentID}/large`;
         }
         series.idx = idx;
         series.imageLength = srs.image.length;
         series.seriesInstanceUID = srs.seriesInstanceUID;
         series.selectedImages = [];
         return series;
      });

      // 예외적으로 시리즈가 한개인 경우 시리즈 선택
      if (result && result.length === 1) {
         examDispatch({ type: "SET_SPILT_LIST", series: result, selectedSeriesIdx: 0 });
         setImageList(0, result);
      } else {
         examDispatch({ type: "SET_SPILT_LIST", series: result, selectedSeriesIdx: -1 });
      }
      return result;
   }

   function setImageList(seriesIndex: number, series?: any) {
      if (!_selectedRow) {
         console.warn(is, "selectedRow is null.");
         return;
      }
      if (seriesIndex === null || seriesIndex === undefined) return;

      const { exam } = _selectedRow;
      const newSeries: any = series || state.series;
      // 시리즈를 새로 선택하는 경우 모두 선택, 기존에 선택된 이미지가 있는 경우 기존 선택된 이미지 유지
      // 예외적으로 시리즈가 한개인 경우 모두 선택하지 않도록 함(시리즈가 한개인 경우는 이미지를 선택해야 하기 때문)
      if (newSeries.length > 1 && (newSeries[seriesIndex].selectedImages || []).length === 0) {
         const contentIds = exam[0].series[seriesIndex].image.map((img:any) => img.contentID);
         newSeries[seriesIndex].selectedImages = [...contentIds];
      }
      // Set Images
      const images = exam[0].series[seriesIndex].image.map((img:any, idx:number) => {
         const image:any = {};
         if (img.type === "jpeg") {
            image.image = img.image;
            image.type = "jpeg";
         } else if (img.type === "dcm") {
            image.image = `${__CDN_URL__}/prefetch/download/image/${img.contentID}/large`;
            image.type = "url";
         }
         image.idx = idx;
         image.contentID = img.contentID;
         image.sopInstanceUID = img.sopInstanceUID;
         const check = newSeries[seriesIndex].selectedImages.find((contentID:string) => contentID === img.contentID);
         if (newSeries.length > 1 && check) image.selected = true;
         return image;
      });
      examDispatch({ type: "SET_SPILT_LIST", series: newSeries, images, selectedSeriesIdx: seriesIndex });
   }

   function clickSeries(idx: number, select: boolean) {
      if (select) {
         setImageList(idx);
      } else {
         resetImageList(idx);
      }
   }

   function clickImage(event: React.KeyboardEvent, images: any) {
      const findIndex = state.series.findIndex((element: { idx: number; }) => element.idx === state.selectedSeriesIdx);
      const copyArray = [...state.series];

      if (findIndex !== -1) {
         copyArray[findIndex] = { ...copyArray[findIndex], selectedImages: images, selected: true };
      }
      examDispatch({ type: "SET_SPILT_LIST", series: copyArray, selectedSeriesIdx: state.selectedSeriesIdx });
   }

   function editImageList(idx: number) {
      setImageList(idx);
   }

   function resetImageList(seriesIndex: number) {
      const findIndex = state.series.findIndex((element: { idx: number; }) => element.idx === seriesIndex);
      const copyArray = [...state.series];
      if (findIndex !== -1) {
         copyArray[findIndex] = { ...copyArray[findIndex], selectedImages: [] };
      }
      examDispatch({ type: "SET_SPILT_LIST", images: [], series: copyArray, selectedSeriesIdx: -1 });
   }

   function splitExam() {
      if (!_selectedRow) return;

      let containsAll = true; // check contains all images
      const contentId:any = []; // selected content ids
      const series:any = []; // selected series/image
      const deleteSopInstanceUIDs:any = []; // delete images
      const { id, exam } = _selectedRow;

      exam[0].series.forEach((srs:any, i:number) => {
         const { seriesInstanceUID, selectedImages } = state.series[i];

         // @ts-ignore
         containsAll = containsAll && srs.image.map((img:any) => img.contentID).every((key: any) => selectedImages.includes(key));
         contentId.push(...selectedImages);

         // @ts-ignore
         const image = srs.image.filter(img => selectedImages.includes(img.contentID)).map(({ contentID, sopInstanceUID }) => ({ contentID, sopInstanceUID }));
         if (image.length > 0) series.push({ seriesInstanceUID, image });
      });

      if (series.length === 0) {
         toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.splitExamDialog.save.selectedEmpty"), isErr:true });
         return;
      }

      // check containsAll
      if (containsAll) {
         toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.splitExamDialog.save.selectedAll"), isErr:true });
         return;
      }

      const order = state.isSelected ? state.selectedOrder : {};
      const editTags:any = {};
      state.editTags.forEach((obj) => { editTags[obj.key] = obj.value; });

      const modifiedRequestData = { id, contentId, series, deleteSopInstanceUIDs, orderId: order.id, ...editTags };
      // console.log("modifiedRequestData", modifiedRequestData);
      // update exam
      updateExamByObjectId(modifiedRequestData).then((result:any) => {
         if (result.ok && result.status === 200) {
            result.json().then((rows:any) => {
               dispatch({ type: TechlistActionType.REFRESH_AND_SELECT_ROWS, payload: rows });
               dispatch({ type: RelatedTechlistActionType.REFRESH_ORDER, payload: true });
            });
            toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.splitExamDialog.save.success"), isErr:false });
            handleClose();
         } else {
            toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.splitExamDialog.save.fail"), isErr:true });
         }
      }).catch((err) => {
         toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.splitExamDialog.save.fail"), isErr:true });
         console.error("Split Exam failed. [", err, "]");
      });
   }

   function dateComparator(filterLocalDateAtMidnight:any, cellValue:any) {
      const dateAsString = cellValue;
      if (dateAsString == null) return 0;
      const dateParts = dateAsString.split("/");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(day, month, year);
      if (cellDate < filterLocalDateAtMidnight) return -1;
      if (cellDate > filterLocalDateAtMidnight) return 1;
      return 0;
   }

   function createOrderColumnDefs() {
      const columns = [
         { headerName: i18n("label.gridHeader.name.selected"),
            field: "selected",
            width: 60,
            suppressMenu: true,
            filter: false,
            sortable: false,
            resizable: false,
            cellStyle: { textAlign: "center" },
            cellRenderer: (params: any) => {
               if (params.value === "selected") return "<iron-icon style=\"color: #0087cb;\" icon=\"vaadin:check-square-o\"></iron-icon>";
               return "<iron-icon style=\"color: #4c5667;\" icon=\"vaadin:thin-square\"></iron-icon>";
            },
         },
         { headerName: i18n("label.gridHeader.name.accessionNo"),
            field: "accessionNumber",
            width: 90,
            headerTooltip: i18n("label.gridHeader.tooltip.accessionNo"),
            filter: "agTextColumnFilter",
            filterParams: { filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true },
            floatingFilterComponentParams: { suppressFilterButton: true },
         },
         { headerName: i18n("label.gridHeader.name.id"),
            field: "patientID",
            width: 80,
            headerTooltip: i18n("label.gridHeader.tooltip.id"),
            filter: "agTextColumnFilter",
            // , floatingFilterComponent: "agTextColumnFilter"
            filterParams: { filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true },
            floatingFilterComponentParams: { suppressFilterButton: true },
            cellStyle: { textAlign: "center" },
         },
         { headerName: i18n("label.gridHeader.name.name"),
            field: "patientName",
            width: 85,
            headerTooltip: i18n("label.gridHeader.tooltip.name"),
            filter: "agTextColumnFilter",
            filterParams: { filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true },
            floatingFilterComponentParams: { suppressFilterButton: true },
         },
         { headerName: i18n("label.gridHeader.name.modality"),
            field: "modality",
            width: 60,
            headerTooltip: i18n("label.gridHeader.tooltip.modality"),
            filter: "agSetColumnFilter",
            filterParams: { applyButton: true, clearButton: true, values: filters?.modality },
         },
         { headerName: i18n("label.gridHeader.name.bodyPart"),
            field: "bodyPartExamined",
            width: 80,
            headerTooltip: i18n("label.gridHeader.tooltip.bodyPart"),
            filter: "agSetColumnFilter",
            filterParams: { applyButton: true, clearButton: true, values: filters?.bodypart },
         },
         { headerName: i18n("label.gridHeader.name.order"),
            field: "studyDescription",
            width: 200,
            headerTooltip: i18n("label.gridHeader.tooltip.order"),
            filter: "agTextColumnFilter",
            filterParams: { filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true },
            floatingFilterComponentParams: { suppressFilterButton: true },
         },
         { headerName: i18n("label.gridHeader.name.scheduledDate"),
            field: "scheduledDtime",
            width: 150,
            headerTooltip: i18n("label.gridHeader.tooltip.scheduledDate"),
            filter: "agDateColumnFilter",
            floatingFilterComponent: "customDateFloatingFilter",
            filterParams: { applyButton: true, clearButton: true, filterOptions: ["equals", "lessThanOrEqual", "greaterThanOrEqual", "inRange", DateFilterUtils.relativeDateFilterOption], suppressAndOrCondition: true, browserDatePicker: true, comparator: dateComparator },
         },
         { headerName: i18n("label.gridHeader.name.orderedDate"),
            field: "requestDtime",
            width: 150,
            headerTooltip: i18n("label.gridHeader.tooltip.orderedDate"),
            filter: "agDateColumnFilter",
            floatingFilterComponent: "customDateFloatingFilter",
            filterParams: { applyButton: true, clearButton: true, filterOptions: ["equals", "lessThanOrEqual", "greaterThanOrEqual", "inRange", DateFilterUtils.relativeDateFilterOption], suppressAndOrCondition: true, browserDatePicker: true, comparator: dateComparator },
         },
      ];

      return GridUtils.changeFilterParams(columns);
   }

   function createOrderDefaultFilter({ patient, exam }:any) {
      const filter = {
         patientID: {},
         scheduledDtime: {},
      };
      if (patient._id) {
         const pidFilter = {
            filter: patient._id,
            filterType: "text",
            type: "contains",
         };
         filter.patientID = pidFilter;
      }
      const formatStudyDate = formatDateFilter(exam[0].studyDate);
      if (formatStudyDate) {
         const dateFilter = {
            dateFrom: formatStudyDate,
            dateTo: formatStudyDate,
            type: "equals",
            filterType: "date",
         };
         filter.scheduledDtime = dateFilter;
      }

      return filter;
   }

   function getSelectedOrder(gridOptions: GridOptions) {
      let order;
      gridOptions.api?.forEachNode((node) => {
         if (node.isSelected()) order = node.data;
      });
      return order;
   }

   function openWarningSelectOrderDialog(params:any, select = true) {
      const { onOk, onDiscard, onCancel } = params;
      const message: ConfirmProps["message"] = {
         contents: select ? [i18n("msg.splitExamDialog.warning.selectOrder.0")] : [i18n("msg.splitExamDialog.warning.deselectOrder.0")],
         title: i18n("label.warning"),
         ok: select ? i18n("button.overwrite") : i18n("button.initialize"),
         discard: i18n("button.keep"),
         cancel: i18n("button.cancel"),
         onOk,
         onDiscard,
         onCancel,
      };
      // dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, message, open: true } });
      // dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, message, open: true } });
      setConfirmDialog({ message, open:true });
   }

   function doubleClickOrderEvent(data: any, gridOptions: GridOptions, compareEditTags:any, selectedOrder:any, setEditTags = true) {
      gridOptions.api?.forEachNode((node) => {
         if (data.id === node.id) {
            if (node.data.selected === "selected") {
               node.setDataValue("selected", "");
               // set editTags
               if (setEditTags) examDispatch({ type: "CHANGE_ORDER_TAGS", compareEditTags });
            } else {
               node.setDataValue("selected", "selected");
               if (setEditTags) examDispatch({ type: "CHANGE_ORDER_TAGS", compareEditTags, selectedOrder, isSelected: true });
            }
         } else {
            node.setDataValue("selected", "");
         }
      });
   }

   function getBaseEditTags(study = _selectedRow) {
      const editTags: any = [];
      if (study) {
         const { modality, bodypart, exam } = study;
         editTags.push({ key:"modality", label:i18n("label.modality"), value: modality[0] });
         editTags.push({ key:"bodypart", label:i18n("label.bodypart"), value: bodypart[0] });
         editTags.push({ key:"studyDescription", label:i18n("label.studyDesc"), value: exam[0].studyDescription });
         editTags.push({ key:"accessionNumber", label:i18n("label.accessionNo"), value: exam[0].accessionNumber });
         editTags.push({ key:"studyInstanceUID", label:i18n("label.studyInstanceUid"), value: editTags.studyInstanceUID ? editTags.studyInstanceUID : exam[0].studyInstanceUID });
      }
      return editTags;
   }

   function getBaseExamInfo(study = _selectedRow) {
      const examInfo: any = [];
      if (study) {
         const { patient, modality, bodypart, exam } = study;
         examInfo.push({ key:"id", label:i18n("label.id"), value: patient._id });
         examInfo.push({ key:"name", label:i18n("label.name"), value: patient.name });
         examInfo.push({ key:"studyDate", label:i18n("label.studyDate"), value: formatDate(exam[0].studyDate) });
         examInfo.push({ key:"modality", label:i18n("label.modality"), value: modality[0] });
         examInfo.push({ key:"bodypart", label:i18n("label.bodypart"), value: bodypart[0] });
         examInfo.push({ key:"studyDescription", label:i18n("label.studyDesc"), value: exam[0].studyDescription });
         examInfo.push({ key:"accessionNumber", label:i18n("label.accessionNo"), value: exam[0].accessionNumber });
      }
      return examInfo;
   }

   function getEditTagsByOrder(order: any) {
      const editTags:any = [];
      if (order) {
         const { modality, bodypart, studyDescription, accessionNumber } = order;
         const studyInstanceUID = state.editTags.filter((obj:any) => obj.key === "studyInstanceUID")[0].value;
         editTags.push({ key:"modality", label:i18n("label.modality"), value: modality });
         editTags.push({ key:"bodypart", label:i18n("label.bodypart"), value: bodypart });
         editTags.push({ key:"studyDescription", label:i18n("label.studyDesc"), value: studyDescription });
         editTags.push({ key:"accessionNumber", label:i18n("label.accessionNo"), value: accessionNumber });
         editTags.push({ key:"studyInstanceUID", label:i18n("label.studyInstanceUid"), value: studyInstanceUID });
      }
      return editTags;
   }

   function getExamInfo(id: string) {
      return new Promise((resolve) => {
         fetch(`/api/exchange/worklist/idfilter/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse[0]);
               });
            }
            else {
               console.warn("split-exam-dialog", "Get Exam Info Failed.");
            }
         });
      });
   }

   function updateExamByObjectId(exam:any) {
      return new Promise((resolve) => {
         fetch(`/api/tech/split/${exam.id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(exam),
         }).then((response) => {
            resolve(response);
         }).catch((error) => {
            console.log(error);
         });
      });
   }

   function doClear() {
      examDispatch({ type: "CLEAR_EXAM" });
      setConfirmDialog(null);
   }

   function caseLockUpdate(flag:any) {
      const params:any = {};
      params.caseIdList = [_selectedRow.id];

      TechnicianUtils.caseLockUpdate({ flag, list: params }).then((result) => {
         if (result) {
            // redraw selected technician rows
            dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
         } else {
            document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: { msg: i18n("msg.caseLockFail"), isErr: true } }));
         }
      });
   }

   function formatDate(date:string) {
      if (!date || date.length < 8) return date;
      return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
   }

   function formatDateFilter(date:string) {
      if (!date || date.length < 8) return null;
      return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
   }

   const handleClose = (event?: any, reason?: string) => {
      if (reason === "backdropClick") {
         return;
      }
      doClear();
      setOpen(false);
      dialog_closed();
   };

   function prevent_event_propagation(event: React.MouseEvent) {
      event.stopPropagation();
   }

   function close_if_esc(event: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(event)) {
         handleClose();
         event.stopPropagation();
      }
   }

   function dialog_closed() {
      doClear();
      // @ts-ignore
      window.dialog_closed();
      dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   function onRowDoubleClicked(e: RowDoubleClickedEvent, gridOptions: GridOptions) {
      const selectedOrder = getSelectedOrder(gridOptions);
      const compareEditTags = selectedOrder ? getEditTagsByOrder(selectedOrder) : getBaseEditTags();
      doubleClickOrderEvent(e.node, gridOptions, compareEditTags, selectedOrder);
   }

   function editTagSetValue(data: any) {
      examDispatch({ type: "SET_EDIT_TAGS", data, isEditing: true });
   }

   const classes = DialogStyles();
   return (
      <>
         <Dialog
            open={open}
            onClose={handleClose}
            onClick={prevent_event_propagation}
            onKeyDown={close_if_esc}
            fullWidth
            PaperProps={{ sx:{ height: "100%" } }}
            maxWidth={"xl"}>
            <Box className={classes.container}>
               <DialogTitle className={classes.dialogTitle} component={"p"}>Split Exam</DialogTitle>
               <IconButton sx={{ color: "#fff" }} onClick={handleClose}>
                  <Close/>
               </IconButton>
            </Box>
            <DialogContent>
               <Box className={classes.rowDirection}>
                  <Box className={classes.rowDirectionWrapContainer}>
                     <Box sx={{ height: "70%", display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ width: "40%" }}>
                           <ExamSeriesList
                              title={i18n("label.series")}
                              data={state.series}
                              clickItem={clickSeries}
                              changeItem={editImageList}
                              selectedIndex={state.selectedSeriesIdx}/>
                        </Box>
                        <Box sx={{ width: "60%" }}>
                           <ExamImageList
                              title={i18n("label.image")}
                              data={state.images}
                              clickItem={clickImage}
                              selectedIndex={state.selectedSeriesIdx}/>
                        </Box>
                     </Box>
                     { useMemo(() =>
                        <>
                           <Box sx={{
                              width: "100%",
                              height: "30%",
                           }}>
                              <Box className="class-container ag-theme-balham-dark" sx={{
                                 width: "calc(100% - 10px)",
                                 height: "100%",
                              }}>
                                 <BaseOrderGrid type="popup"
                                    columnDefs={createOrderColumnDefs}
                                    filter={orderDefaultFilter}
                                    contextMenu={() => {}}
                                    onRowDoubleClicked={onRowDoubleClicked}/>
                              </Box>
                           </Box>
                        </>, [orderDefaultFilter])
                     }
                  </Box>
                  <Box className={classes.formBoxInnerSmall}>
                     <Box sx={{
                        height: "calc(100% - 60px)",
                        width: "100%",
                     }}>
                        <Box sx={{
                           lineHeight: "2em",
                           height: "55%",
                        }}>
                           <InfoForm title={i18n("label.examInfo")} data={state.examInfo}/>
                        </Box>
                        <Box sx={{
                           lineHeight: "2em",
                           height: "45%",
                        }}>
                           <EditForm title={i18n("label.editTags")} data={state.editTags} changeValue={editTagSetValue}/>
                        </Box>
                     </Box>
                  </Box>
               </Box>
            </DialogContent>
            <DialogActions className={classes.dialogBottomSpace} sx={{ backgroundColor: "#2d333f" }}>
               <Typography>{i18n("label.splitExamDialog.order.notice")}</Typography>
               <Box className={classes.buttonGroup} sx={{ height: "60px" }}>
                  <OkBtn onClick={splitExam} text={i18n("button.splitExam")}></OkBtn>
                  <CancelBtn onClick={handleClose} text={i18n("button.cancel")}/>
               </Box>
            </DialogActions>
         </Dialog>
         {
            confirmDialog
            && <Confirm
               message={confirmDialog.message}
               open={confirmDialog.open}
               inner={true}
               onClose={() => setConfirmDialog({ ...confirmDialog, open:false })}/>
         }
      </>
   );
}
