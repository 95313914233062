import React, { useEffect, useState } from "react";
import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { spiltSeries } from "./hooks/useSplitExam";
import { FormStyles } from "./styles/FormStyle";
import { ImageListStyles } from "./styles/ImageListStyle";

interface CustomImageListProps {
  title: string,
  data: Array<spiltSeries>,
  clickItem(idx:number, select: boolean): void
  selectedIndex: number,
  changeItem(idx:number): void,
}

export default function ExamSeriesList(props: CustomImageListProps) {
   const { data } = props;
   const [isSelected, setIsSelected] = useState(false);

   const clickItem = (idx:number) => {
      data[idx].selected = !data[idx].selected;
      setIsSelected(!isSelected);
      props.clickItem(idx, data[idx].selected);
   };

   const changeItem = (event:React.MouseEvent<SVGSVGElement, MouseEvent>, idx:number) => {
      event.stopPropagation();
      props.changeItem(idx);
   };

   const formStyles = FormStyles();
   const imageListStyles = ImageListStyles();
   return (
      data
      && <>
         <div className={formStyles.titleContainer}>
            <div className={formStyles.titleContent}>{props.title}</div>
         </div>
         <div className={imageListStyles.examSeriesContainer}>
            <ImageList
               className={imageListStyles.listContainer}>
               {data.map((item: any) => (
                  <ImageListItem key={item.idx}
                     className={imageListStyles.listItem}
                     onClick={() => clickItem(item.idx)}>
                     <img
                        className={item.selected ? formStyles.selected : formStyles.notSelected}
                        src={item.image}
                        alt={`${item.idx}(${item.selectedImages.length}/${item.imageLength})`}
                        loading="lazy" />
                     {item.selected
                    && <Edit
                       className={imageListStyles.editIcon}
                       onClick={e => changeItem(e, item.idx)}/>}
                     {(item.selected && item.idx === props.selectedIndex)
                    && <div
                       className={imageListStyles.dot}
                    >●</div>
                     }
                     <ImageListItemBar
                        title={`${item.idx}(${item.selectedImages.length}/${item.imageLength})`}
                        position="below" />
                  </ImageListItem>
               ))}
            </ImageList>
         </div>
      </>
   );
}
