import React, { useRef, useState } from "react";
import { Modal } from "@mui/material";
import store from "../../redux/store";
import { CommonActionType } from "../../redux/reducers/common";
import { StyledBox, StyledCloseIconButton, StyledKindButton } from "./styles/EmotionStyles";
import { is_esc } from "../../dialog/common-keyboard-handler";
import {
   StyledContainer,
   StyledContentsWrapper,
   StyledFooter,
   StyledTitle,
   StyledTitleWrapper,
} from "./styles/CommonDialogStyles";
import OkBtn from "../button/OkBtn";

export default function SupportDialog() {
   const dialogRef = useRef(null);
   const [open, setOpen] = useState<boolean>(true);

   function close_if_esc(e: React.KeyboardEvent) {
      if (is_esc(e)) closeDialog();
   }

   function closeDialog() {
      setOpen(false);
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   return (
      <>
         <Modal
            open={open}
            onKeyDown={close_if_esc}
         >
            <StyledBox
               width={300}
               height={350}
               ref={dialogRef}
            >
               <StyledContainer>
                  <StyledTitleWrapper>
                     <StyledTitle>Support Center</StyledTitle>
                     <StyledCloseIconButton
                        onClick={closeDialog}
                     />
                  </StyledTitleWrapper>

                  <StyledContentsWrapper>
                     <h2>Request or Question</h2>
                     <h4>Deployment & Service Team</h4>
                     <h4>HealthHub Co.,Ltd.</h4>
                     <h3>M +82 70-8823-4414</h3>
                     <h3>E <a href="mailto:DS@healthhub.kr">DS@healthhub.kr</a></h3>
                  </StyledContentsWrapper>

                  <StyledFooter>
                     <OkBtn onClick={closeDialog} text={"Ok"}/>
                     {/* <StyledKindButton onClick={closeDialog}>OK</StyledKindButton> */}
                  </StyledFooter>
               </StyledContainer>
            </StyledBox>
         </Modal>
      </>
   );
}
