import { makeStyles } from "@mui/styles";

export const DicomSendStyles = makeStyles(() => ({
   container: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "760px",
      height: "550px",
      background: "#2d333f",
      borderTop: "5px #0087cb solid",
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingTop: "40px",
   },
}));
