import { useRef } from "react";
import useDidMountEffect from "./useDidMountEffect";

export default function useDialogOpen() {
   const dialogRef = useRef(null);
   useDidMountEffect(() => {
      // @ts-ignore
      window.dialog_opened({ dialog:dialogRef.current, is_modal:true });
   }, [dialogRef.current]);
}
