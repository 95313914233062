import React from "react";
import { TextField } from "@mui/material";
import { NumberTypeFieldTypes } from "./types/NumberTypeFieldTypes";

export default function NumberTypeField(props: NumberTypeFieldTypes):JSX.Element {
   // Received `true` for a non-boolean attribute 에러로 인해 새로 정의한 custom attribute는 따로 처리
   const { max, min, shrink, placeholder, inputProps, ...textFieldProps } = props;
   return (
      <TextField
         {...textFieldProps}
         type={"number"}
         autoComplete={"off"}
         inputProps={{ ...inputProps, inputMode: "numeric", pattern: "[0-9]*", max, min, placeholder }}
         InputLabelProps={{ shrink }}
      />
   );
}
