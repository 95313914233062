import React, { useEffect, useRef } from "react";
import { useIsMounted } from "usehooks-ts";

/*
첫 렌더링때 state가 설정되는 것으로 보고 useEffect가 실행될 때가 있다.
=> useEffect 첫 렌더링 시 함수 호출 막기
 */
const useDidMountEffect = (func: any, deps: any) => {
   const didMount = useRef(false);

   useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
   }, deps);
};

export default useDidMountEffect;
