import styled from "@emotion/styled";
import AppsIcon from "@mui/icons-material/Apps";
import { Button } from "@mui/material";

/* .expand-set-group */
export const StyledExpandSetGroup = styled.div`
    width: 100%;
    border: 1px solid #4c5667;
    padding-bottom: 5px;
    margin-bottom: 10px;
    
`;

/* .expand-settings */
export const StyledExpandSettingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    //background-color: #262934;
    
`;
/* .expand-settings */
export const StyledExpandSetting = styled.div`
    width: 160px;
    padding: 5px;
`;

// export const StyledInputLabel = styled(InputLabel)`
export const StyledInputLabel = styled.label`
    font-size: 5pt;
    color: #aaaaaa;
`;

/* .ddp-set-group */
export const StyledDdpSettingGroup = styled.div`
    width: 100%;
    border: 1px solid #4c5667;
    padding-bottom: 5px;
`;
/* .ddp-settings */
export const StyledDdpSettingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    //background-color: #262934;
`;
export const StyledDdpSetting = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    padding: 5px;
    align-items: center;
    justify-content: center;
`;

export const StyledLayoutSetting = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    padding: 5px;
    justify-content: center;
`;
// export const StyledCanvas = styled.canvas`
//     width: 320px;
//     height: 200px;
// `

/* settingIcon */
export const StyledSettingIcon = styled(AppsIcon)`
    position: relative;
    padding: 0;
    width: 20px;
    height: 20px;
    color: #aaaaaa;
    top: 18px;
    right: 28px;
    cursor: pointer;
    &:hover {
        color: #2baab1;
    }
`;

export const StyledButtonGroup = styled.div`
    display: flex;
`;

export const StyledContextText = styled.div`
    flex: 1;
    font-style: italic;
    font-size: 10pt;
    color: #d4d4d4;
    margin: 5px 0;
    text-align: left;
`;

export const StyledContextButtonWrapper = styled.div`
    flex: 1;
    text-align: right;
    padding: 5px;
    margin: 5px 0;
`;

export const StyledGridGroup = styled.div`
    min-height: 180px;
    height: calc(100vh - 750px);
    border: 1px solid #4c5667;
    padding: 5px;
`;

export const StyledLabel = styled.p`
    padding: 0  10px;
    margin: 5px 0 0 5px;
    font-size: 10pt;
    color: #aaaaaa;
`;

export const StyledMgButton = styled(Button)`
    background: #4c5667;
    height: 30px;
    border: 0;
    font-size: 10pt;
    color: #fff;
    cursor: pointer;
    border-radius: 2px;
    margin: 5px;
    &:hover {
        background: #fff;
        color: #4c5667;
    }
`;
