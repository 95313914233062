import { useReducer } from "react";

interface inputData {
  key: string,
  label: string,
  value: string,
  type?: string,
}
export interface ModifyExamType {
  patientInfo: Array<inputData>,
  studyInfo: Array<inputData>,
  clinicalInfo: Array<inputData>,
  series: Array<modifySeries>,
  images: Array<modifyImage>,
  selectedSeriesIdx: number,
  selectedOrder?: any
  selectedSeriesNumber: number
}

export interface modifySeries {
  idx: number,
  displayImage: string,
  imageLength: number,
  seriesNumber: number,
  displayOrder: any,
  seriesInstanceUID: string,
  imageList: Array<any>,
  selectedImages: []
  selected: boolean,
}
export interface modifyImage {
  contentID: string
  idx: number
  image: string
  selected: boolean
  sopInstanceUID: string
  type: string
}
type State = ModifyExamType;

export type Action =
  | { type: "SET_PATIENT_INFO", data: Array<inputData> }
  | { type: "SET_STUDY_INFO", data: Array<inputData> }
  | { type: "SET_CLINICAL_INFO", data: Array<inputData> }
  | { type: "SET_MODIFY_LIST", series?: Array<modifySeries>, images?: Array<modifyImage>, selectedSeriesIdx : number, selectedSeriesNumber: number }
  | { type: "CLEAR_EXAM" };

const reducer = (state: State, action: Action): State => {
   switch (action.type) {
   case "SET_PATIENT_INFO": {
      return {
         ...state,
         patientInfo: action.data,
      };
   }
   case "SET_STUDY_INFO": {
      return {
         ...state,
         studyInfo: action.data,
      };
   }
   case "SET_CLINICAL_INFO": {
      return {
         ...state,
         clinicalInfo: action.data,
      };
   }
   case "SET_MODIFY_LIST": {
      return {
         ...state,
         series: action.series ? action.series : state.series,
         images: action.images ? action.images : state.images,
         selectedSeriesIdx: action.selectedSeriesIdx,
         selectedSeriesNumber : action.selectedSeriesNumber,
      };
   }
   case "CLEAR_EXAM": {
      return {
         patientInfo: [],
         studyInfo: [],
         clinicalInfo: [],
         series: [],
         images: [],
         selectedSeriesIdx: -1,
         selectedSeriesNumber: -1,
      };
   }
   default:
      throw new Error();
   }
};

const useModifyExam = () => {
   const [state, examDispatch] = useReducer(reducer, {
      patientInfo: [],
      studyInfo: [],
      clinicalInfo: [],
      series: [],
      images: [],
      selectedSeriesIdx: -1,
      selectedSeriesNumber: -1,
   });
   return {
      state,
      examDispatch,
   };
};

export default useModifyExam;
