import React, { useEffect, useState } from "react";
import { Box, IconButton, ImageList, ImageListItem } from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import { newSeries } from "./hooks/useNewExam";
import { FormStyles } from "../SplitExam/styles/FormStyle";
import { ImageListStyles } from "../SplitExam/styles/ImageListStyle";
import CommonUtils from "../../../../public/resource/js/utils/common";

interface CustomImageListProps {
  title: string,
  data: Array<newSeries>,
  selectedIndex: number,
  clickItem(idx:number, select: boolean): void
  deleteItem(idx: number): void,
  modifyItem(series: Array<newSeries>): void,
  addFile(event:any): void
}

export default function ExamSeriesList(props: CustomImageListProps) {
   const [data, setData] = useState(props.data);
   const [isSelected, setIsSelected] = useState(false);
   const [dragEnterIdx, setDragEnterIdx] = useState(-1);

   useEffect(() => {
      // console.log("newExam series===> ", props.data);
      setData(props.data);
   }, [props.data]);

   const clickItem = (e: any, idx:number) => {
      data.map((value, index) => {
         const temp = value;
         if (index === idx) temp.selected = true;
         else temp.selected = false;
         return temp;
      });
      setIsSelected(!isSelected);
      props.clickItem(idx, data[idx].selected);
   };

   const deleteItem = (event:React.MouseEvent<SVGSVGElement, MouseEvent>, idx:number) => {
      event.stopPropagation();
      props.deleteItem(idx);
   };

   const addFile = (event:React.ChangeEvent) => {
      props.addFile(event);
   };

   function dragStart(e: any, idx: number) {
      if (!data[idx].selected) {
         data[idx].selected = !data[idx].selected;
         props.clickItem(idx, data[idx].selected);
      }
   }

   function dragOver(e: any, idx: number) {
      e.preventDefault();
      if (!data[idx].selected) {
         if (idx > props.selectedIndex) {
            e.target.style.borderRight = "2px solid rgb(0,135,203)";
         } else {
            e.target.style.borderLeft = "2px solid rgb(0,135,203)";
         }
      }
   }

   function dragleave(e: any, idx: number) {
      setBoaderNone(e, idx);
   }

   function dragEnd(e: any, idx: number) {
      const selected = data.filter(value => value.selected);
      const selectedList = selected.flatMap(data => data.idx);
      if (!selectedList.includes(dragEnterIdx)) {
         const startIdx = selected.reduce((prev, current) => ((prev.idx < current.idx) ? prev : current)).idx;
         const nIndex = getDraggedNewIndex(startIdx, dragEnterIdx, selectedList, data.length);
         setData(nIndex);
         props.modifyItem(nIndex);
      }
   }

   function onDrop(e: any, idx: number) {
      setBoaderNone(e, idx);
   }

   function setBoaderNone(e: any, idx: number) {
      e.target.style.borderStyle = "";
   }

   function getDraggedNewIndex(startIndex:number, selectedIndex:number, selectedIndexList: Array<any>, length: number): Array<newSeries> {
      const nIndex = [];
      for (let i = 0; i < length; i++) {
         if (selectedIndex === i) {
            if (startIndex > selectedIndex) {
               for (let j = 0; j < selectedIndexList.length; j++) {
                  data[selectedIndexList[j]].idx = nIndex.length;
                  nIndex.push(data[selectedIndexList[j]]);
               }
               data[i].idx = nIndex.length;
               nIndex.push(data[i]);
            } else {
               data[i].idx = nIndex.length;
               nIndex.push(data[i]);
               for (let j = 0; j < selectedIndexList.length; j++) {
                  data[selectedIndexList[j]].idx = nIndex.length;
                  nIndex.push(data[selectedIndexList[j]]);
               }
            }
         } else if (!selectedIndexList.includes(i)) {
            data[i].idx = nIndex.length;
            nIndex.push(data[i]);
         }
      }
      return nIndex;
   }

   // Drag 후 Drop 하는 태그에서 발생하는 이벤트
   function dragEnter(e: any, idx: number) {
      setDragEnterIdx(idx);
   }

   const formStyles = FormStyles();
   const imageListStyles = ImageListStyles();
   return (
      data
      && <>
         <div className={formStyles.titleContainer}>
            <div className={formStyles.titleContent}>{props.title}</div>
         </div>
         <div className={imageListStyles.examSeriesContainer}>
            <ImageList
               className={imageListStyles.listContainer}>
               {data.map((item: any) => (
                  <Box
                     key={item.idx}
                     className={CommonUtils.joinClassName(imageListStyles.listItem, item.selected ? formStyles.selected : formStyles.notSelected)}
                     onClick={e => clickItem(e, item.idx)}>
                     <ImageListItem>
                        <img
                           onDragStart={(e) => { dragStart(e, item.idx); }}
                           onDragOver={(e) => { dragOver(e, item.idx); }}
                           onDragLeave={(e) => { dragleave(e, item.idx); }}
                           onDragEnd={(e) => { dragEnd(e, item.idx); }}
                           onDragEnter={(e) => { dragEnter(e, item.idx); }}
                           onDrop={(e) => { onDrop(e, item.idx); }}
                           src={item.displayImage}
                           alt={`${item.idx})`}
                           loading="lazy"/>
                        {item.selected
                          && <Delete
                             className={imageListStyles.deleteIcon}
                             onClick={e => deleteItem(e, item.idx)}/>}
                     </ImageListItem>
                  </Box>
               ))}
            </ImageList>
            <IconButton
               aria-label="upload picture"
               component="label"
               sx={{
                  bottom: "0",
                  float: "right",
                  position: "sticky",
               }}>
               <input
                  hidden
                  accept="image/jpeg"
                  type="file"
                  onChange={e => addFile(e)}
               />
               <AddCircle sx={{ color: "#0087cb !important" }}/>
            </IconButton>
         </div>
      </>
   );
}
