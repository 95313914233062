import React, { MouseEventHandler, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { ButtonBaseProps } from "@mui/material/ButtonBase/ButtonBase";

export type BtnProps = ButtonBaseProps & {
   text?: string | undefined;
   onClick?: MouseEventHandler | undefined;
   color?: "blue" | "grey";
};

const greyBtnStyle = {
   fontSize: "14px",
   padding: "8px 35px",
   margin: "10px",
   backgroundColor: "rgb(76, 86, 103)",
   color: "#eee",
   "&:hover": {
      backgroundColor: "#ccc",
      color: "rgb(76, 86, 103)",
   },
};

const blueBtnStyle = {
   fontSize: "14px",
   padding: "8px 35px",
   margin: "10px",
   backgroundColor: "sky blue",
   "&:hover": {
      backgroundColor: "light blue",
   },
};

const CustomBtn = (props: BtnProps) => {
   const { onClick, text, color, ...buttonProps } = props;

   // 다른 색상의 Style을 입힐 경우 수정 필요
   const style = (color === "blue") ? blueBtnStyle : greyBtnStyle;

   return (
      <Button
         {...buttonProps}
         variant="contained"
         onClick={onClick}
         sx={style}>{text}
      </Button>
   );
};

export default CustomBtn;
