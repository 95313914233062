import { KeyboardEventHandler } from "react";
import { is_esc } from "../dialog/common-keyboard-handler";
import store from "../redux/store";
import { CommonActionType } from "../redux/reducers/common";

function prevent_event_propagation(event: React.MouseEvent) {
   event.stopPropagation();
}

function close_if_esc(event: React.KeyboardEvent<HTMLDivElement>, closeCallback?: Function) {
   if (is_esc(event)) {
      event.stopPropagation();
      if (closeCallback) closeCallback(event);
   }
}

function dialog_closed() {
   // @ts-ignore
   window.dialog_closed();
   store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
}

export const preventEventPropagation = (event: any) => prevent_event_propagation(event);
export const closeIfEsc:KeyboardEventHandler<HTMLDivElement> = (event: React.KeyboardEvent<HTMLDivElement>, closeCallback?: Function | undefined) => close_if_esc(event, closeCallback);
export const dialogClosed = () => dialog_closed();
