import styled from "@emotion/styled";
import NumberTypeField from "../../../../input/textfield/NumberTypeField";

/* .container */
export const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
`;
/* .class-WWLDialog */
export const StyledWWL = styled.div`
    //background: #2d333f;
    width: 460px;
    height: 513px;
`;

/* .menuItems-title */
export const StyledTitleWrapper = styled.div`
    background-color: #252934;
    height: 50px;
    width: 100%;
    color: #fff;
    border-bottom: 1px solid #4c5667;
`;

export const StyledTitleLabel = styled.p`
    margin: 0; padding: 15px; float: left;
    font-size: 10pt;
    color: #aaaaaa;
`;

/* class-WWL-context */
export const StyledWWLContextWrapper = styled.div`
    width: 100%;
    height: 30%;
`;
/* wrap */
export const StyledWWLContext = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    padding-bottom: 3px;
    height: 40px;
`;
/* .text-font */
export const StyledLabel = styled.div`
    width: 55px;
    padding: 0 7px;
    font-size: 10pt;
    color: #aaaaaa;
    margin: 5px 5px 0 5px;
`;

export const StyledNumberTypeField = styled(NumberTypeField)`
    width: 100px;
    color: #aaaaaa;
    & .MuiInput-input {
        text-align: center;
    }
`;

/* .contextText */
export const StyledContextText = styled.div`
    flex: 1;
    font-style: italic;
    font-size: 8pt;
    color: #d4d4d4;
    margin: 5px 0;
    text-align: left;
    margin: 0 0 0 78px;
`;
export const StyledShortcutLabel = styled.div`
    width: 50px;
    padding: 0 0px;
    font-size: 13pt;
    color: #aaaaaa;
    margin: 10px 15px 0 0px;
    text-align: center;
`;

/* .class-WWL-list */
export const StyledWwlList = styled.div`
    height: 280px;
    width: 96%;
    margin: 10px 10px 15px 10px;
`;
/* .class-list-content */
export const StyledWwlListContent = styled.div`
    height: 100%;
    width: 100%;
`;
/* .classWWLBtn */
export const StyledWwlButtonWrapper = styled.div`
    text-align: center;
    margin: 15px 0px 10px 0px;
    height: 40px;
    flex: 1
`;
