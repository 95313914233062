import styled from "@emotion/styled";

/* .text-font-shortcut */
export const StyledContextTextShortcut = styled.div`
    width: 50px;
    padding: 0 0px;
    font-size: 13pt;
    color: #aaaaaa;
    margin: 10px 15px 0 0px;
    text-align: center;
`;

/* .text-input-validation */
export const StyledContextInputValidation = styled.div`
    font-style: italic;
    font-size: 10pt;
    color: #d4d4d4;
    margin: 5px 55px 0 5px;
    text-align: left;
`;
/* .contextBtn */
export const StyledContextButtonWrapper = styled.div`
    text-align: right;
    padding: 5px;
    margin: 5px;
    display: flex;
`;

/* .text-font-shortcut */
export const StyledTextFontShortcut = styled.div`
    width: 170px;
    padding: 0 0px;
    font-size: 12pt;
    color: #aaaaaa;
    margin: 10px 0 0 0;
    text-align: left;
`;
