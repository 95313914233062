import React, { ChangeEvent, useRef, useState } from "react";
import { Modal } from "@mui/material";
import i18n from "../../utils/i18n-utils";
import store from "../../redux/store";
import { CommonActionType, DialogType } from "../../redux/reducers/common";
import { is_enter, is_esc } from "../../dialog/common-keyboard-handler";
import {
   StyledMyInfo, StyledPasswordContainer,
   StyledTitleContainer,
   StyledWrapper,
} from "./styles/PasswordDialogStyles";
import PasswordTypeField from "../input/textfield/PasswordTypeField";
import { useToastDispatch } from "../../context/ToastContext";
import {
   StyledBox,
   StyledCloseIconButton,
   StyledKindButton,
} from "./styles/EmotionStyles";
import OkBtn from "../button/OkBtn";
import CancelBtn from "../button/CancelBtn";

export default function PasswordDialog() {
   const toastDispatch = useToastDispatch();
   const dialogRef = useRef(null);
   const [open, setOpen] = useState<boolean>(true);
   const [width, setWidth] = useState<number>(440);
   const [height, setHeight] = useState<number>(330);
   const [password, setPassword] = useState<string>("");

   function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      const { value } = event.target;
      setPassword(value);
   }

   function handleKeyUp(e: React.KeyboardEvent) {
      if (is_esc(e)) closeDialog();
      else if (is_enter(e)) checkPassword();
   }

   function closeDialog() {
      setOpen(false);
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   function openToast(msg: string) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr: true });
   }

   function checkPassword() {
      if (!password) {
         openToast(i18n("msg.userInfo.validate.password.empty"));
         return;
      }

      getValidationPassword().then((result) => {
         if (result === 204) {
            setPassword("");
            openToast(i18n("msg.userInfo.validate.password.fail"));
         } else {
            store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.USERINFO_DIALOG, message: result, open: true } });
         }
      });
   }

   function getValidationPassword() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/userInfo/userPassword?password=${encodeURIComponent(password)}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((data) => {
                  resolve(data);
               });
            } else if (response.status === 204) {
               resolve(response.status);
            } else {
               reject(new Error("message : getValidationPassword fail"));
            }
         });
      });
   }

   return (
      <>
         <Modal
            id="password-dialog"
            open={open}
            onKeyDown={handleKeyUp}
         >
            <StyledBox
               width={width}
               height={height}
               ref={dialogRef}
            >
               <StyledTitleContainer>
                  <StyledMyInfo> {i18n("label.myInfo")}</StyledMyInfo>
                  <StyledCloseIconButton onClick={closeDialog}/>
               </StyledTitleContainer>
               <StyledPasswordContainer>
                  <StyledWrapper>
                     <PasswordTypeField
                        label={i18n("label.enterPassword")}
                        shrink
                        variant="standard"
                        size={"small"}
                        sx={{ width: "100%" }}
                        // textAlign={"center"}
                        value={password || ""}
                        onChange={handleChange}
                     />
                  </StyledWrapper>
                  <StyledWrapper>
                     <OkBtn onClick={checkPassword} text={"Ok"}/>
                     <CancelBtn onClick={closeDialog} text={"Cancel"}/>
                     {/* <StyledKindButton kind={"ok"} onClick={checkPassword}>OK</StyledKindButton> */}
                     {/* <StyledKindButton kind={"cancel"} onClick={closeDialog}>Cancel</StyledKindButton> */}
                  </StyledWrapper>
               </StyledPasswordContainer>
            </StyledBox>
         </Modal>
      </>
   );
}
