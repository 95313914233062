/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";
import i18n from "../../utils/i18n-utils";
import "../../../public/ag-license";
import "@vaadin/vaadin-icons";
import store from "../../redux/store";
import { CommonActionType } from "../../redux/reducers/common";
import {
   prevent_event_propagation,
   have_to_move_to_left,
   have_to_move_to_right,
   is_enter,
   is_esc,
} from "../../dialog/common-keyboard-handler";
import {
   StyledBox,
   StyledFocusButton,
   StyledWarnIcon,
} from "./styles/EmotionStyles";
import {
   StyledButtonWrapper,
   StyledContentsWrapper,
   StyledTextFontContent,
   StyledTextFontTitle,
   StyledTitleWrapper,
} from "./styles/ReportConfirmDialogStyles";

/*
* TODO
*  Props, Status 정리
*  Toast Message 출력
*   - hh-report.js React 변환시 가능
*
* */
interface ReportConfirmDialogProps {
   message: {
      params: object;
      title: string;
      contents: string[];
      readingStatus: string;
      saveStatus: string;
      callback(btn : string) : void;
      cancelCallback() : void;
   };
}

interface ReportConfirmDialogState {
   [propName: string]: any;
}

export default function ReportConfirmDialog(props : ReportConfirmDialogProps) {
   const dialogRef = useRef(null);
   const [open, setOpen] = useState<boolean>(true);
   const [width, setWidth] = useState<number>(500);
   const [height, setHeight] = useState<number>(200);
   const [focusIndex, setFocusIndex] = useState<number>(0);
   const [focusList, setFocusList] = useState<boolean[]>([true, false, false]);
   const [message, setMessage] = useState<any>({
      ...props.message,
      readingStatus: "",
      saveStatus: "",
      contents: [i18n("msg.opinion.confirm.notSaved")],
      title: i18n("label.approvalConfirmation"),
      btn: "",
   });

   /*
   * focus Hook
   * */
   useEffect(() => {
      setFocusList((prevState: boolean[]) => prevState.map((item: boolean, index: number) => index === focusIndex));
   }, [focusIndex]);

   /*
   * Test Print Hook
   * */
   useEffect(() => {
      console.log(`message: ${JSON.stringify(message, null, 1)}`);
   }, [message]);

   /*
   * Keyboard Event handler
   * */
   function handleKeyUp(e: React.KeyboardEvent<HTMLDivElement>) {
      if (have_to_move_to_right(e)) {
         setFocusIndex((prevState: number) => (prevState + 1) % 3);
      } else if (have_to_move_to_left(e)) {
         setFocusIndex((prevState: number) => (prevState  === 0 ? focusList.length - 1 : focusIndex - 1));
      } else if (is_enter(e)) {
         console.log(`enter index: ${focusIndex}`);
         if (focusIndex === 0) doButton("approve");
         else if (focusIndex === 1) doButton("save");
         else if (focusIndex === 2) doButton("discard");
      } else if (is_esc(e)) {
         doButton("discard");
      }

      e.stopPropagation();
   }

   function doButton(type: string) {
      console.log(`doButton: ${type} !`);
      const { action = () => Promise.resolve(), callback = () => {}, params } = message;
      action(params).then((result: any) => {
         Object.assign(message.params, { btn: type });
         callback(result, params);
      });
      if (type === "discard" && message.cancelCallback) message.cancelCallback({ detail: message });

      setOpen(false);
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   return (
      <>
         <Modal
            id="confirm-dialog"
            open={open}
            onClick={prevent_event_propagation}
            onKeyDown={prevent_event_propagation}
            onKeyPress={prevent_event_propagation}
            onKeyUp={handleKeyUp}
            onFocus={prevent_event_propagation}
         >
            <StyledBox
               width={width}
               height={height}
               ref={dialogRef}
            >
               <StyledTitleWrapper>
                  <StyledWarnIcon />
                  <StyledTextFontTitle>{message.title}</StyledTextFontTitle>
               </StyledTitleWrapper>
               <StyledContentsWrapper>
                  {
                     message.contents.map((content: string, index: number) => <StyledTextFontContent key={index}>{content}</StyledTextFontContent>)
                  }
               </StyledContentsWrapper>

               <StyledButtonWrapper>
                  <StyledFocusButton
                     id="approveBtn"
                     tabIndex={2}
                     onClick={() => doButton("approve")}
                     focus={+focusList[0]}
                  >Approve/Addendum</StyledFocusButton>
                  <StyledFocusButton
                     id="saveBtn"
                     tabIndex={1}
                     onClick={() => doButton("save")}
                     focus={+focusList[1]}
                  >Save</StyledFocusButton>
                  <StyledFocusButton
                     id="discardBtn"
                     tabIndex={0}
                     onClick={() => doButton("discard")}
                     focus={+focusList[2]}
                  >Discard</StyledFocusButton>
               </StyledButtonWrapper>
            </StyledBox>
         </Modal>
      </>
   );
}
