import { makeStyles } from "@mui/styles";

export const ImageListStyles = makeStyles({
   listContainer: {
      display: "flex !important", // material ui에서 강제 생성하는 css에 의해 처리
      flexWrap: "wrap",
      justifyContent: "center",
      backgroundColor: "#1f1f1ffc",
      alignContent: "flex-start",
      overflowY: "auto",
      position: "relative",
      flexFlow: "row nowrap",
      height: "100%",
      // padding: "10px 10px 10px 10px !important",
   },
   examImageContainer: {
      width: "calc(100% - 10px)",
      // height: "calc(100% - 10px)",
      height: "calc(100% - 50px)",
      backgroundColor: "#1f1f1ffc",
   },
   examSeriesContainer: {
      width: "calc(100% - 10px)",
      // height: "10%",
      height: "calc(100% - 50px)",
      backgroundColor: "#1f1f1ffc",
   },
   listItem: {
      width: "100px",
      height: "100px",
      margin: "3px 3px 0px 3px",
   },
   bigListItem: {
      width: "auto",
      height: "215px",
      margin: "3px 3px 0px 3px",
      position: "relative",
   },
   positionStatic: {
      position:"static !important" as any,
   },
   editIcon: {
      top: "80px",
      right: "0",
      position: "absolute",
   },
   deleteIcon: {
      top: "80px",
      right: "0",
      position: "absolute",
      color: "#0087cb !important",
   },
   dot: {
      position: "absolute",
      color: "#aaa",
      top: "5px",
      right: "0",
      fontWeight: 500,
   },
   absoluteText: {
      position: "absolute",
      top: "10px",
      left: "20px",
      color: "#aaa",
      flex: "none",
   },
});
