import styled from "@emotion/styled";

/* .content-row */
export const StyledSettingContentRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 35px;
`;
/* .content-row */
export const StyledSettingContentColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
/* .font */
export const StyledLabel = styled.p`
    width: 100px;
    padding: 0 5px;
    font-size: 10pt;
    color: #aaa;
    
`;

export const StyledSliderInfo = styled.div`
    margin-left: 5px;
    color: #aaaaaa;
    font-size: 10pt;
`;

export const StyledColorPickerWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 45%;
    z-index: 2;
`;
