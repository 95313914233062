import i18next from "i18next";

export default class ReportUtils {

   static makeAlReadyRequestMessage(params) {

      if (params === "approve") {

      } else if (params === "save") {

      } else {

      }
   }

   static openPopupPrintReport(selectedRows = [], selectedImageReports = []) {
      let print = window.opener ? window.opener.reportPrint : window.reportPrint;

      if (selectedRows.length === 0)
         throw new Error("Please select from work list.");
      else if (selectedRows.find(row => row.readingStatus !== "3A"))
         throw new Error("Please select only that the reading has been completed.");

      if (print && print.closed === false)
         print.close();

      print = window.open("/printbox", "print", "width=1000, height=1265,top=40,left=400, resizable=yes,toolbar=no,status=0,location=no,menubar=no,scrollbars=0,dependent=yes");
      const struct = {};
      struct.selectdList = selectedRows;
      const imageList = selectedImageReports.filter(imageReport => imageReport.id);
      if (imageList.length > 0)
         struct.imageList = imageList;
      print.struct = struct;
      print.focus();

      if (window.opener) window.opener.reportPrint = print;
      else window.reportPrint = print;

      // store.dispatch({ type: CommonActionType.SET_POPUP_REPORT_PRINT, payload: print });
   }

   static getSelectedRowsCaseInfo(ids = []) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/list?ids=${ids.join(",")}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
            },
      }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   static getIdFiltersByRows(ids = []) {
      return new Promise((resolve) => {
         return fetch(`/api/exchange/worklist/idfilter?ids=${ids}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   static getOpinionByWeever(id) {
      return new Promise((resolve) => {
         fetch(`/api/opinion/weever/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
            },
      }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               resolve("failed");
            }
         });
      });
   }

   static getAllAddendumByWeever(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/opinion/addendums/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            }
            else if (response.status === 204) {
               resolve(null);
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }
}
