import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import {
   ColDef,
   ColumnMovedEvent,
   ColumnResizedEvent,
   ColumnVisibleEvent,
   GetContextMenuItemsParams,
   GridOptions,
   GridReadyEvent,
   IServerSideDatasource,
   MenuItemDef,
   RowClickedEvent,
   RowDoubleClickedEvent,
   RowSelectedEvent,
   ServerSideStoreType,
} from "ag-grid-community";
import { useSelector } from "react-redux";
import GridUtils from "../../utils/grid-utils";
import { CustomDateComponent } from "../../../public/resource/js/custom-date-component";
import { CustomDateFloatingComponent } from "../../../public/resource/js/custom-date-floating-component";
import i18n from "../../utils/i18n-utils";
import DateFilterUtils from "../../utils/date-filter-utils";
import { RootState } from "../../redux/reducers";
import { useToastDispatch } from "../../context/ToastContext";
import store from "../../redux/store";
import { CommonActionType, CustomContextMenuType, DialogActionType, DialogType } from "../../redux/reducers/common";
import { TechlistActionType, TechlistRow } from "../../redux/reducers/techlist";
import { RelatedTechlistActionType } from "../../redux/reducers/related-techlist";
import CommonUtils from "../../../public/resource/js/utils/common";
import TechnicianUtils from "../../utils/technician-utils";

export default function BaseOrderGrid(props: any) {
   const category = useSelector((state: RootState) => state.common.category);
   const _utcOffset = utcCheck();
   let _selectedRows: any[] = [];
   const _selectedTechlist = useSelector((state: RootState) => state.techlist.row);
   let _altKey = false;
   const columnDefs: ColDef[] = props.columnDefs();
   let isDisabledMatch = true;
   let isDisabledUnMatch = true;
   let matchMsgCode: string;
   const createdRelativeDateFilter = {
      scheduledDtime: false,
      requestDtime: false,
   };
   const appliedDateFilters: any = {};
   const redrawOrderIds = useSelector((state: RootState) => state.relatedTechlist.redrawOrderIds);
   const refreshOrder = useSelector((state: RootState) => state.relatedTechlist.refreshOrder);
   let componentType: string;
   const customContextMenuState = useSelector((state: RootState) => state.common.customContextMenu);
   const toastAction = useToastDispatch();
   const [gridOptions, setGridOptions] = useState<GridOptions>({
      defaultColDef: {
         suppressMenu: true,
         sortable: true,
         resizable: true,
         floatingFilter: true,
         filterParams: {
            newRowsAction: "keep",
         },
         suppressKeyboardEvent: params => GridUtils.disableRowDeselectionBySpace(params),
      },
      rowModelType: "serverSide",
      serverSideStoreType: ServerSideStoreType.Partial,
      animateRows: true,
      components: {
         agDateInput: CustomDateComponent,
         customDateFloatingFilter: CustomDateFloatingComponent,
      },
      rowSelection: "single",
      sideBar: {
         toolPanels: [
            {
               id: "columns",
               labelDefault: i18n("label.showHideColumns"),
               labelKey: "columns",
               iconKey: "columns",
               toolPanel: "agColumnsToolPanel",
               toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressSideButtons: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
               },
            },
         ],
      },
      // todo: ag grid 버전업으로 인한 변경 사항 체크 필요
      postProcessPopup: (params) => {
         // if (params.type !== "columnMenu") return;
         if (params.type !== "floatingFilter") return;

         const columnId = params.column?.getId();
         const { ePopup } = params;

         if (columnId === "scheduledDtime" || columnId === "requestDtime") {
            let count = 0;
            const interval = setInterval(() => {
               if (!createdRelativeDateFilter[columnId]) {
                  const filterBody = ePopup.querySelector(".ag-filter-body");
                  const filterButton = ePopup.querySelector(".ag-filter-apply-panel");
                  if (filterBody && filterButton) {
                     clearInterval(interval);

                     DateFilterUtils.renderRelativeDateFilter(ePopup, () => {
                        // const floatingFilter = $.gridOrderList.querySelector(".ag-popup"); // 팝업이 한번 닫힌 후 ePopup 에는 하위 element 값이 없어서 다시 불러줌
                        const filterModel = getFilterModelIncludeDateFilter();
                        DateFilterUtils.applyRelativeDateFilter(ePopup, columnId, filterModel, (filterModel: any) => {
                           setFilter(filterModel);
                        });
                     }, () => {
                        // const floatingFilter = $.gridOrderList.querySelector(".ag-popup"); // 팝업이 한번 닫힌 후 ePopup 에는 하위 element 값이 없어서 다시 불러줌
                        const filterModel = getFilterModelIncludeDateFilter();
                        DateFilterUtils.clearRelativeDateFilter(ePopup, columnId, filterModel, (filterModel: any) => {
                           setFilter(filterModel);
                        });
                     }, () => {
                        const filterInstance = gridOptions.api!.getFilterInstance(columnId);
                        DateFilterUtils.setDateFilterComponent(ePopup, filterInstance, appliedDateFilters[columnId]);
                     });
                     createdRelativeDateFilter[columnId] = true;
                  }
               } else {
                  const customFilter = ePopup.querySelector(".relative-date-filter-body");
                  const customButton = ePopup.querySelector(".relative-date-filter-button");
                  const applyButton = ePopup.querySelector(".ag-filter-apply-panel-button");
                  const relativeDateFilterInput = ePopup.querySelector(".relative-date-filter-input");
                  const relativeDateFilterList = ePopup.querySelector(".relative-date-filter-list");
                  if (customFilter && customButton && applyButton && relativeDateFilterInput && relativeDateFilterList) {
                     clearInterval(interval);

                     const filterInstance = gridOptions.api!.getFilterInstance(columnId);
                     DateFilterUtils.setDateFilterComponent(ePopup, filterInstance, appliedDateFilters[columnId]);
                  }
               }
               if (createdRelativeDateFilter[columnId] || count > 100) {
                  clearInterval(interval);
               }
               count++;
            }, 100);
         }
      },
      overlayNoRowsTemplate: `<span style="font-size: 13px">${i18n("label.noRecordsFound")}</span>`,
      cacheBlockSize: 50,
      tooltipShowDelay: 0,
      navigateToNextCell: params => navigateToNextCell(params),
      onRowClicked: e => onRowClicked(e),
      onRowSelected: evt => onRowSelected(evt),
      onRowDoubleClicked: (e) => { props.onRowDoubleClicked(e, gridOptions); },
      getContextMenuItems: params => getContextMenuItems(params),
      // todo
      // onFilterModified: (e) => {
      //    const columnId = e.column.getId();
      //    if (columnId === "scheduledDtime" || columnId === "requestDtime") {
      //       const floatingFilter = $.gridOrderList.querySelector(".ag-popup");
      //       const filterInstance = gridOptions.api!.getFilterInstance(e.column.colId);
      //       DateFilterUtils.setDateFilterComponent(floatingFilter, filterInstance);
      //    }
      // },
      onFilterChanged: (v) => {
         const filterModel = gridOptions.api!.getFilterModel();

         // global applied date filter 정리
         Object.entries(appliedDateFilters)
            .forEach(([columnId, {
               type: globalType,
               dateFrom: globalDateFrom,
            }]:any) => {
               // - global date filter 에는 있는데 현재 적용된 filter model 에 없거나
               // - 적용된 filter model 에 있는데 global filter 와 타입이 맞지 않거나
               // - global filter 에 today 인데 적용된 filter 와 date 가 다른 경우
               if (!filterModel[columnId]
                || (filterModel[columnId] && filterModel[columnId].type !== globalType)
                || (filterModel[columnId] && filterModel[columnId].type === "equals" && !moment(filterModel[columnId].dateFrom)
                   .isSame(moment(globalDateFrom)))) {
                  delete appliedDateFilters[columnId];
               }
            });
      },
      onColumnMoved(event: ColumnMovedEvent) {
         gridOptions.onDragStopped = () => {
            const tempArr = gridOptions.columnApi!.getAllGridColumns();

            const headerArr = tempArr?.reduce((acc: any[], column) => {
               acc.push(column.getColDef());
               return acc;
            }, []);

            updateGridHeader(headerArr);
         };
      },
      onColumnResized(event: ColumnResizedEvent) {
         if (event.finished) {
            const tempArr = gridOptions.columnApi!.getAllGridColumns();

            const headerArr = tempArr?.reduce((acc: any[], column) => {
               const newColDef = column.getColDef();
               newColDef.width = column.getActualWidth();
               acc.push(newColDef);
               return acc;
            }, []);

            updateGridHeader(headerArr);
         }
      },
      onColumnVisible(event: ColumnVisibleEvent) {
         const tempArr = gridOptions.columnApi!.getAllGridColumns();

         const headerArr = tempArr?.reduce((acc: any[], column) => {
            const isHide = column.isVisible() ? Boolean(false) : Boolean(true);
            const newColDef = column.getColDef();
            newColDef.hide = isHide;
            acc.push(newColDef);
            return acc;
         }, []);

         updateGridHeader(headerArr);
      },
      // todo
      // onCellKeyDown(event: any) {
      //    const {
      //       code,
      //       ctrlKey,
      //       metaKey,
      //    } = event.event;
      //    const { userAgent } = window.navigator;
      //    const [isWindow, isMac] = [/Windows/.test(userAgent), /Mac OS/.test(userAgent)];
      //
      //    // #16447 [HWP-UT-W-001] Worklist 단축키 관련
      //    if (isWindow && ctrlKey || isMac && metaKey) {
      //       const centerViewPort = gridOptions.api!.gridPanel.eCenterViewport;
      //
      //       if (code === "ArrowLeft") centerViewPort.scrollLeft = 0;
      //       if (code === "ArrowRight") centerViewPort.scrollLeft = centerViewPort.scrollWidth;
      //    }
      // },
   });

   const onGridReady = useCallback((params: GridReadyEvent) => {
      params.api!.setServerSideDatasource(enterpriseDatasource());
   }, []);

   useEffect(() => {
      document.addEventListener("click", () => {
         if (customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });
   }, []);

   useEffect(()  => {
      // if (props.contextMenu != null)
      setGridOptions({ ...gridOptions, getContextMenuItems: props.contextMenu });
   }, [props.contextMenu]);

   useEffect(() => {
      if (props?.filter) setFilter(props.filter);
   }, [props.filter]);

   useEffect(() => {
      onSelectTechlist(_selectedTechlist);
   }, [_selectedTechlist]);

   useEffect(() => {
      onSelectCategory();
   }, [category]);

   useEffect(() => {
      changeRefreshOrder(refreshOrder);
   }, [refreshOrder]);

   useEffect(() => {
      changeRedrawOrderIds(redrawOrderIds);
   }, [redrawOrderIds]);

   function onSelectTechlist(selected: TechlistRow | undefined) {
      if (category !== 1) return;

      getOrderMatchStatusCode(); // context match status 재설정

      if (!selected) return;
      const {
         detail: row,
         rows,
         altKey,
      } = selected;

      if (!altKey) {
         // tech row 선택시 matching 된 order 선택
         if (row?.isMatch === true) {
            selectedRowToMatched(row);
         } else {
            clearSelectedRow(); // diabledSelectedRows();
         }
      }
   }

   function onSelectCategory() {
      purgeEnterpriseCache();
      clearSelectedRow();
   }

   function updateGridHeader(headerArr: any) {
      const type = "orderFilm";
      fetch(`/api/user/option/gridheader/${type}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt")!,
            "Content-Type": "application/json",
         },
         body: JSON.stringify(headerArr),
      })
         .then((response) => {
            if (!response.ok) {
               console.debug(new Error(`${response.status} ${response.statusText}`));
            }
         });
   }

   function getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         })
            .then((response) => {
               if (response.ok && response.status === 200) {
                  response.json()
                     .then((rows) => {
                        resolve(rows);
                     });
               } else {
                  reject(new Error(`${response.status} ${response.statusText}`));
               }
            });
      });
   }

   function enterpriseDatasource(): IServerSideDatasource {
      // let index = 0;
      return {
         getRows: (rowParams) => {
            const params = rowParams;
            const { filterModel } = params.request;

            // if (index === 0) {
            //    getOrderFilters()
            //      .then((result: any) => {
            //         _filters = result;
            //
            //         getUserStyle()
            //           .then((result: any) => {
            //              if (result.grid && result.grid.orderFilm && result.grid.orderFilm.length > 0) {
            //                 const { orderFilm } = result.grid;
            //                 columnDefs = GridUtils.mergeFilterParams(orderFilm, createColumnDefs());
            //                 params.api.setColumnDefs(columnDefs);
            //              } else {
            //                 columnDefs = createColumnDefs();
            //                 params.api.setColumnDefs(columnDefs);
            //                 updateGridHeader(createColumnDefs());
            //              }
            //              setDefaultFilterModel();
            //           })
            //           .catch((err) => {
            //              console.info(err);
            //              columnDefs = createColumnDefs();
            //              params.api.setColumnDefs(columnDefs);
            //              setDefaultFilterModel();
            //           });
            //      });
            // }

            params.request.filterModel = conversionFilter(filterModel);
            getOrderlist(params)
               .then((result: any) => {
                  // if (index === 1) {
                  //    // applyFilter();
                  //    // setDefaultFilterModel();
                  // }
                  params.success({
                     rowData: result.rows || [],
                     rowCount: result.lastRow,
                  });
                  if ((result.rows || []).length === 0) {
                     params.api.showNoRowsOverlay();
                  } else {
                     params.api.hideOverlay();
                  }
               })
               .catch((err) => {
                  console.error(err);
                  params.fail();
               });
            // index++;
         },
      };
   }

   function onRowClicked(e: RowClickedEvent) {
      // @ts-ignore
      _altKey = e.event?.altKey;
   }

   function onRowSelected(evt: RowSelectedEvent) {
      _selectedRows = evt.api.getSelectedRows();
      if (evt.node.isSelected()) {
         if (!isPopup()) {
            store.dispatch({
               type: RelatedTechlistActionType.SELECT_ORDER,
               payload: {
                  row: evt.data,
                  rows: _selectedRows,
                  altKey: _altKey,
               },
            });
         }
      } else if (_selectedRows.length === 0) {
         if (!isPopup()) store.dispatch({ type: RelatedTechlistActionType.CLEAR_ORDER });
      }

      _altKey = false;
   }

   function onRowDoubleClicked(e: RowDoubleClickedEvent) {
   }

   function setRightSelectedRows(row: any) {
      if (row.node) {
         // console.log("-> [order] setRightSelectedRows")
         // #16041 마우스 우클릭으로 row가 선택되게끔 추가
         row.node.setSelected(true, true);

         // #16192
         // onRowSelected 이벤트 보다 먼저 타기 때문에 우클릭으로 row 선택시 _selectedRows 값 세팅
         // store.dispatch -> onRowSelected 에서 처리
         _selectedRows = [row.node.data];
      }
   }

   function getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
      // #16192
      // 우클릭으로 row 선택시 onRowSelected 이벤트만 타고 onRowClicked 이벤트는 타지 않는다.
      // onRowSelected에서는 보조key를 잡을 수 없어 ( alt, ctrl, shift ) 멀티선택 불가

      // if ((_selectedRows??[]).length > 0) {
      //    // #16192 우클릭으로 다른 row를 선택했을시 선택값을 초기화 한다.
      //    const {id} = _selectedRows[0];
      //    if (id !== params.node.data.id) dispatchEveni18n(new CustomEveni18n("studyCountClearEvent"));
      // }
      // - 아래 setRightSelectedRows 이벤트에서 select 이벤트롤 태우기 때문에 studyCountClearEvent 은 필요없음 (2022.04.25)
      // - setRightSelectRow -> onRowSelected -> store.dispatch(selected order) -> grid-study -> onSelectOrder -> select matched study or clear selected rows

      setRightSelectedRows(params);

      // Match, Unmatch Contextmenu
      getOrderMatchStatusCode();
      const result = [
         {
            name: i18n("label.match"),
            disabled: isDisabledMatch,
            action: () => {
               match();
            },
         },
         {
            name: i18n("label.unmatch"),
            disabled: isDisabledUnMatch,
            action: () => {
               unMatch();
            },
         },
         "separator",
         {
            name: i18n("label.newExam"),
            action: () => {
               newExam();
            },
         },
         "separator",
         "csvExport",
      ];

      if (customContextMenuState !== undefined) {
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: CustomContextMenuType.SYSTEM });
      }

      return result;
   }

   function dateFilterSearch(amount: number, unit: string) {
      const scheduledDtime = DateFilterUtils.getDateFilterModel(amount, unit);
      setFilter({ "scheduledDtime": scheduledDtime });
   }

   function setDefaultFilterModel() {
      dateFilterSearch(3, "days");
   }

   function purgeEnterpriseCache() {
      gridOptions.api!.refreshServerSideStore({ route: [], purge: true });
   }

   function dateComparator(filterLocalDateAtMidnight: Date, cellValue: any) {
      const dateAsString = cellValue;
      if (dateAsString == null) return 0;
      const dateParts = dateAsString.splii18n("/");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(day, month, year);
      if (cellDate < filterLocalDateAtMidnight) return -1;
      if (cellDate > filterLocalDateAtMidnight) return 1;
      return 0;
   }

   function psCellRenderer(params: { value: string; }) {
      if (params.value === "F") return "F";
      if (params.value === "M") return "M";
      return "O";
   }

   function mtCellRenderer(params: { value: boolean; }) {
      if (params.value === true) return "<div class='check'>M</div>";
      return "<div class='normal'>N</div>";
   }

   function getOrderlist(params: any) {
      return new Promise((resolve, reject) => {
         // Object.entries(params.request.filterModel)
         //    .map(m => m[1])
         //    .filter(m => m.filterType === "date")
         //    .map((m) => {
         //       if(m.dateFrom) Object.assign(m, {dateFrom: formatDate(m.dateFrom, "YYYY-MM-DD")});
         //       if(m.dateTo) Object.assign(m, {dateTo: formatDate(m.dateTo, "YYYY-MM-DD")});
         //       return m;
         //    });

         fetch(`/api/tech/orderlist`, {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(params.request),
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  if (httpResponse.rows.length > 0) {
                     for (let i = 0; i < httpResponse.rows.length; i++) {
                        const localTime = moment(httpResponse.rows[i].requestDtime).add(_utcOffset, "h").toDate();
                        // eslint-disable-next-line no-param-reassign
                        httpResponse.rows[i].requestDtime = moment(localTime).format("YYYY-MM-DD HH:mm:ss");

                        if (httpResponse.rows[i].scheduledDtime) {
                           const localScheduledDtime = moment(httpResponse.rows[i].scheduledDtime).add(_utcOffset, "h").toDate();
                           // eslint-disable-next-line no-param-reassign
                           httpResponse.rows[i].scheduledDtime = moment(localScheduledDtime).format("YYYY-MM-DD HH:mm:ss");
                        }
                     }
                     const _startRow = params.request.startRow;
                     let cnt = _startRow;
                     // eslint-disable-next-line no-restricted-syntax
                     for (const row of httpResponse.rows) {
                        cnt++;
                        row.no = cnt;
                     }
                  }
                  resolve(httpResponse);
               });
            } else {
               reject(new Error("Orderlist loading error."));
            }
         });
      });
   }

   function getFilter() {
      return gridOptions.api!.getFilterModel();
   }

   function setFilter(filterModel: any) {
      // set global date filters
      Object.entries(filterModel).forEach(([key, value] : any) => {
         const { filterType, type, amount, unit, isRelative } = value;
         if (filterType === "date" && type === "inRelativeRange") { // relative range
            // eslint-disable-next-line no-param-reassign
            filterModel[key].dateFrom = DateFilterUtils.getRelativeDate(amount, unit);
            // eslint-disable-next-line no-param-reassign
            filterModel[key].dateTo = DateFilterUtils.getToday();
            appliedDateFilters[key] = value;
         } else if (filterType === "date" && type === "equals" && isRelative) { // today
            // eslint-disable-next-line no-param-reassign
            filterModel[key].dateFrom = DateFilterUtils.getToday();
            // eslint-disable-next-line no-param-reassign
            filterModel[key].dateTo = DateFilterUtils.getToday();
            appliedDateFilters[key] = value;
         }
      });

      // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
      try {
         gridOptions.api!.setFilterModel(filterModel);
      } catch (e) {}
   }

   function setColumnDefs(Columns: any) {
      try {
         gridOptions.api!.setColumnDefs(Columns);
      } catch (e) {}
   }

   function reloadFilter() {
      const filterModel = getFilterModelIncludeDateFilter();
      setFilter(filterModel);
   }

   function clearSelectedRow() {
      gridOptions.api!.deselectAll();
   }

   function utcCheck() {
      const now = new Date();
      const _zone = moment.tz.guess();
      const timeOffset = moment(now.getTime()).tz(_zone);
      // @ts-ignore
      return (timeOffset._offset / 60);
   }

   function getOrderFilters() {
      return new Promise((resolve, reject) => {
         fetch(`/api/tech/orderlist/filters`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json()
                  .then((result) => {
                     resolve(result);
                  });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   function conversionFilter(filterModel: any) {
      // let newObj = {};
      const newObj = JSON.parse(JSON.stringify(filterModel));

      if (newObj.scheduledDtime) {
         newObj.scheduledDtime.dateFrom = moment(newObj.scheduledDtime.dateFrom).unix() * 1000;
         if (newObj.scheduledDtime.dateTo === "null null") {
            newObj.scheduledDtime.dateTo = null;
         } else {
            newObj.scheduledDtime.dateTo = moment(newObj.scheduledDtime.dateTo).unix() * 1000;
         }
      }

      if (newObj.requestDtime) {
         newObj.requestDtime.dateFrom = moment(newObj.requestDtime.dateFrom).unix() * 1000;
         if (newObj.requestDtime.dateTo === "null null") {
            newObj.requestDtime.dateTo = null;
         } else {
            newObj.requestDtime.dateTo = moment(newObj.requestDtime.dateTo).unix() * 1000;
         }
      }

      if (newObj.isMatch) {
         if (newObj.isMatch.values.find((value: string) => value === "(M) Matched")) newObj.isMatch.values[0] = Boolean(true);
         if (newObj.isMatch.values.find((value: string) => value === "(NM) Not Matched")) newObj.isMatch.values[0] = Boolean(false);
      }

      return newObj;
   }

   function changeRefreshOrder(refresh = false) {
      if (!refresh) return;
      reloadFilter();
      if (!isPopup()) store.dispatch({ type: RelatedTechlistActionType.REFRESH_ORDER, payload: false });
   }

   function changeRedrawOrderIds(orderIds: string[] | undefined) {
      if (!orderIds) return;
      orderIds.forEach(id => redrawOrderById(id));
   }

   /** order grid redraw */
   function redrawOrderById(orderId: string) {
      if (CommonUtils.isEmptyValue(orderId)) return;

      fetch(`/api/tech/order/${orderId}`, {
         method: "GET",
         headers: {
            "Authorization": localStorage.getItem("jwt")!,
            "Content-Type": "application/json",
         },
      }).then((response) => {
         if (response.ok) {
            response.json().then((order) => {
               gridOptions.api!.forEachNode((rowNode) => {
                  const { data } = rowNode;
                  if (data.id === order.id) {
                     data.isMatch = order.isMatch;
                     data.studies = order.studies;
                     gridOptions.api!.redrawRows({ rowNodes: data });
                  }
               });
            });
         }
      });
   }

   function navigateToNextCell(params: any) {
      let previousCell = params.previousCellPosition;
      const suggestedNextCell = params.nextCellPosition;

      const KEY_UP = 38;
      const KEY_DOWN = 40;
      const KEY_LEFT = 37;
      const KEY_RIGHT = 39;

      switch (params.key) {
      case KEY_DOWN:
         previousCell = params.previousCellPosition;
         gridOptions.api!.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex + 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_UP:
         previousCell = params.previousCellPosition;
         gridOptions.api!.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex - 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_LEFT:
      case KEY_RIGHT:
         return suggestedNextCell;
      default:
         throw new Error("this will never happen, navigation is always one of the 4 keys above");
      }
   }

   function selectedRowToMatched(params: any) {
      const selectedStudyRow = params;
      // study 선택시 matching 된 order 를 찾아 선택
      // 이미 matching 된 order 가 선택되어 있다면 return
      if (gridOptions.api!.getSelectedRows().map(r => r.id).includes(selectedStudyRow.studies)) return;

      let lastSelectedRowIndex;
      gridOptions.api!.forEachNode((node) => {
         if (node.isSelected()) node.setSelected(false);
         if (node.data !== undefined && node.data.isMatch) {
            if (node.data.studies.includes(selectedStudyRow.id)) {
               node.setSelected(true);
               lastSelectedRowIndex = node.rowIndex;
            }
         }
      });
      if (lastSelectedRowIndex) gridOptions.api!.ensureIndexVisible(lastSelectedRowIndex);
   }

   /**
    * #14374
    * Contextmenu의 Match, Unmatch 활성화 및 비활성화 상태 리턴
    * return   N  -> Match, Unmatch가 비활성화 상태
    *          U  -> Unmacth만 활성화 상태
    *          M  -> Match만 활성화 상태, 1:n 매칭칭
    *          MS -> Match만 활성화 상태, 같은 Match group에 Unmatch된 Study를 추가할때
    *          MD -> Match만 활성화 상태, 그외의 Order, Study를 Match할때
    */
   function getOrderMatchStatusCode() {
      const rows = _selectedTechlist?.rows ?? [];
      matchMsgCode = "N";
      if (isIncludedVerified(rows)) matchMsgCode = "W";

      // Order의 row가 하나도 선택되지 않은 상태에서는 Match/Unmatch가 비활성화 된다.
      if ((_selectedRows ?? []).length === 0) {
         // console.log("---->1");
         isDisabledMatch = true;
         isDisabledUnMatch = true;
         matchMsgCode += ",N";
         return;
      }

      // 선택한 Study가 같은 group일때 Unmatch만 활성화
      if (Object.keys(rows).length > 1 && isSameStudyMatchGroup()) {
         // console.log("---->10");
         isDisabledMatch = true;
         isDisabledUnMatch = false;
         matchMsgCode += ",U";
         return;
      }

      // Match할 대상 study가 선택이 안되었을때
      if (Object.keys(rows).length === 0) {
         if (!CommonUtils.isEmptyValue(_selectedRows[0].isMatch) && _selectedRows[0].isMatch) {
            // console.log("---->2");
            isDisabledMatch = true;
            isDisabledUnMatch = false;
            matchMsgCode += ",U";
            return;
         }
         // console.log("---->3");
         isDisabledMatch = true;
         isDisabledUnMatch = true;
         matchMsgCode += ",N";
         return;
      }

      // 같은 Match group이 산택됐을때 또는 Order에서는 Match를 선택했지만 Study에서는 찾을수 없을때
      if (isSameMatchGroup(false)) {
         // console.log("---->4");
         isDisabledMatch = true;
         isDisabledUnMatch = false;
         matchMsgCode += ",U";
         return;
      }

      // Match만 활성화 상태
      isDisabledMatch = false;
      isDisabledUnMatch = true;

      // 같은 Match group에 Unmatch된 study를 추가할때
      // eslint-disable-next-line no-lonely-if
      // if (isSameMatchGroup(true)) {
      //    console.log("---->5");
      //    matchMsgCode += ",MS";
      //    return;
      // }

      // 사용자에게 보여줄 메시지 분기
      // 1:N의 일반적인 Match 메시지
      if (Object.keys(rows).length > 0 && isAllUnmatch(rows)
        && Object.keys(_selectedRows).length > 0 && !_selectedRows[0].isMatch) {
         // console.log("---->6");
         matchMsgCode += ",M";
         return;
      }

      // 그외
      // console.log("---->7");
      matchMsgCode += ",MD";
   }

   /**
    * Study, Order에서 선택되어 있는 row의 Match가 같은 그룹의 Match인지 체크
    * @param chk Boolean
    * @return boolean
    */
   function isSameMatchGroup(chk: boolean) {
      let isSame = true;
      if ((_selectedRows ?? [][0]) && _selectedRows[0].isMatch) {
         // const orderMatchId = _selectedRows[0].studies[0];
         const orderMatchId = _selectedRows[0].id;
         // eslint-disable-next-line no-restricted-syntax
         const rows = _selectedTechlist?.rows ?? [];
         rows.forEach((study: any) => {
            if (isSame && orderMatchId !== study.studies) isSame = false;
         });
         // for (const study of rows) {
         //    // if (study.isMatch) {
         //    if (isSame && orderMatchId !== study.studies) isSame = false;
         //    // } else if (!chk) isSame = false;
         // }
      } else {
         isSame = false;
      }
      return isSame;
   }

   /**
    *
    * 한개 이상의 study를 선택했을때 선택한 study가 같은 그룹인지 체크
    * @return {boolean}
    */
   function isSameStudyMatchGroup() {
      let isSame = true;
      let baseMatchId = "";
      let orderId = "";
      if (Object.keys(_selectedRows ?? []).length === 1) {
         orderId = _selectedRows[0].id;
      }

      const rows:any = _selectedTechlist?.rows ?? [];
      if (CommonUtils.isEmptyObject(rows)) isSame = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const study of rows) {
         if (!CommonUtils.isEmptyValue(study.isMatch) && study.isMatch) {
            if (CommonUtils.isEmptyValue(baseMatchId)) {
               baseMatchId = study.studies;
            } else if (baseMatchId !== study.studies) {
               isSame = false;
            } else if (baseMatchId !== orderId) isSame = false;
         } else {
            // eslint-disable-next-line no-return-assign
            return isSame = false;
         }
      }

      return isSame;
   }

   /**
    * 선택된 row가 모두 Unmatch상태인지 체크
    * @param rows
    * @return Boolean
    */
   function isAllUnmatch(rows: any) {
      let isUnmatch = true;
      if (CommonUtils.isEmptyObject(rows)) return false;
      // eslint-disable-next-line no-restricted-syntax
      for (const row of rows) {
         if (row.isMatch) isUnmatch = false;
      }
      return isUnmatch;
   }

   /**
    * Verified 된 Study를 포함하고 있는지 체크
    * @param rows
    * @return {boolean}
    */
   function isIncludedVerified(rows: any) {
      let isVerified = false;
      if (CommonUtils.isEmptyObject(rows)) return false;
      // eslint-disable-next-line no-restricted-syntax
      for (const row of rows) {
         if (!CommonUtils.isEmptyValue(row.studyStatus) && row.studyStatus === "verified") {
            // eslint-disable-next-line no-return-assign
            return isVerified = true;
         }
      }
      return isVerified;
   }

   /**
    * 적용된 DateFilter 정보를 반영한 Grid FilterModel
    * @returns {{[p: string]: any} | void}
    */
   function getFilterModelIncludeDateFilter() {
      const filterModel = gridOptions.api!.getFilterModel();
      Object.keys(appliedDateFilters).forEach((key) => {
         if (filterModel[key]) filterModel[key] = appliedDateFilters[key];
      });
      return filterModel;
   }

   function match() {
      const studyRows = _selectedTechlist?.rows;
      const [orderRow] = _selectedRows;
      const match = () => {
         TechnicianUtils.match(studyRows, orderRow).then((result) => {
            if (result) {
               redrawOrderById(orderRow?.id);
               // #20261 기존 matched study인 경우 unmatch 되기 때문에 redraw 처리
               studyRows?.filter(study => study.studies).forEach(study => redrawOrderById(study.studies));
               if (!isPopup()) store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
               toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.match.success"), isErr:false });
            } else {
               toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.match.fail"), isErr:true });
            }
         });
      };
      const message = {
         contents: TechnicianUtils.makeMatchUnMatchMessage({ matchMsgCode }),
         title: i18n("label.match"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: match,
      };
      if (!isPopup()) store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.MATCH, message, open: true } });
   }

   function unMatch() {
      const studyRows = _selectedTechlist?.rows;
      const [orderRow] = _selectedRows;
      const unMatch = () => {
         TechnicianUtils.unMatch(studyRows, orderRow).then((result) => {
            if (result) {
               redrawOrderById(orderRow?.id);
               if (!isPopup()) store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
               toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.unmatch.success"), isErr:false });
            } else {
               toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.unmatch.fail"), isErr:true });
            }
         });
      };
      const message = {
         contents: TechnicianUtils.makeMatchUnMatchMessage({ matchMsgCode }),
         title: i18n("label.unmatch"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: unMatch,
      };
      if (!isPopup()) store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.UN_MATCH, message, open: true } });
   }

   function newExam() {
      const [orderRow] = _selectedRows;
      const message = {
         detail: orderRow,
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.NEW_EXAM_DIALOG, actionType: DialogActionType.NEW_EXAM, message, open: true } });
   }

   function isPopup() {
      return componentType === "popup";
   }

   return (
      <>
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css"></link>
         <div className="class-container ag-theme-balham-dark" style={{
            width: "100%",
            height: "100%",
         }}>
            <AgGridReact
               gridOptions={gridOptions}
               onGridReady={onGridReady}
               columnDefs={columnDefs}
               getContextMenuItems={props.contextMenu}
            />
         </div>
      </>
   );
}
