import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";
import {
   StyledMyInfo,
   StyledPasswordTitle,
   StyledTitleContainer,
   StyledWrapper,
} from "./styles/PasswordDialogStyles";
import { is_esc } from "../../dialog/common-keyboard-handler";
import store from "../../redux/store";
import { CommonActionType } from "../../redux/reducers/common";
import i18n from "../../utils/i18n-utils";
import TextTypeField from "../input/textfield/TextTypeField";
import { useToastDispatch } from "../../context/ToastContext";
import { StyledBox, StyledCloseIconButton, StyledKindButton } from "./styles/EmotionStyles";
import OkBtn from "../button/OkBtn";
import CancelBtn from "../button/CancelBtn";

interface UserInfoDialogProps {
    message: UserInfoState
}

interface MemberOf {
    groupId: string,
    alias: string,
    status: string
}

interface UserInfoState {
    id: string,
    name: string,
    engName?: string,
    email: string,
    password: string,
    phoneNo: string,
    phoneNumber: string,
    licenseNo: string,
    spacialNo: string,
    facilityName: string,
    facility: Array<string>,
    memberOf: Array<MemberOf>
}

export default function UserInfoDialog(props: UserInfoDialogProps) {
   const toastDispatch = useToastDispatch();
   const dialogRef = useRef(null);
   const [open, setOpen] = useState<boolean>(true);
   const [width, setWidth] = useState<number>(970);
   const [height, setHeight] = useState<number>(616);
   const [state, setState] = useState<UserInfoState>({
      id: "",
      name: "",
      email: "",
      password: "",
      engName: "",
      phoneNo: "",
      phoneNumber: "",
      licenseNo: "",
      spacialNo: "",
      facilityName: "",
      facility: [],
      memberOf: [],
   });

   useEffect(() => {
      setState({
         ...props.message,
         phoneNumber: props.message.phoneNo.replace("+82", "0"),
      });
   }, [props]);

   /*
    * Test Print Hook
    * */
   useEffect(() => {
      console.log(`state: ${JSON.stringify(state, null, 2)}`);
   }, []);

   function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, stateField: string) {
      const { value } = event.target;
      setState({ ...state, [stateField]: value });
   }

   function doOk() {
      updateUserById()?.then((result) => {
         if (!result) openToast("Error update user info!", true);
         else openToast("Update user info!", false);
      }).catch((e) => {
         openToast(`Error update user info[${e.message}]!`, true);
      }).finally(() => {
         closeDialog();
      });
   }

   function handleKeyUp(e: React.KeyboardEvent) {
      if (is_esc(e)) closeDialog();
   }

   function closeDialog() {
      setOpen(false);
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   function openToast(msg: string, isErr: boolean) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   }

   function updateUserById() {
      if (!state.password) {
         openToast(i18n("msg.userInfo.validate.password.empty"), true);
         return;
      }
      return new Promise((resolve) => {
         const params = {
            request: {
               ...state,
               phoneNo: state.phoneNumber.replace("0", "+82"),
            },
         };

         console.log(`params ${JSON.stringify(params, null, 2)}`);
         fetch(`/api/user/userInfo/${state.id}`, {
            method: "PATCH",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify(params.request),
         }).then((response) => {
            if (response.ok && response.status === 201) {
               response.json().then((data) => {
                  resolve(true);
               });
            } else {
               console.log("fail");
            }
         });
      });
   }

   return (
      <>
         <Modal
            id="user-info"
            onKeyUp={handleKeyUp}
            open={open}
         >
            <StyledBox
               width={width}
               height={height}
               ref={dialogRef}
               style={{ width: "calc(100% - 80px)", maxWidth: width }}
            >
               <StyledTitleContainer>
                  <StyledPasswordTitle>
                     <StyledMyInfo> {i18n("label.myInfo")}</StyledMyInfo>
                     <StyledCloseIconButton onClick={closeDialog}/>
                  </StyledPasswordTitle>
               </StyledTitleContainer>

               <StyledWrapper>
                  <TextTypeField
                     label={"Name"}
                     shrink
                     variant="standard"
                     size={"small"}
                     sx={{ width: "100%", marginBottom: "10px" }}
                     value={state.name || ""}
                     inputProps={{ readOnly: true }}
                  />
                  <TextTypeField
                     label={"EngName"}
                     shrink
                     variant="standard"
                     size={"small"}
                     sx={{ width: "100%", marginBottom: "10px" }}
                     value={state.engName || ""}
                     onChange={e => handleChange(e, "engName")}
                  />
                  <TextTypeField
                     label={"Email"}
                     shrink
                     variant="standard"
                     size={"small"}
                     sx={{ width: "100%", marginBottom: "10px" }}
                     value={state.email || ""}
                     inputProps={{ readOnly: true }}
                  />
                  <TextTypeField
                     label={"Password"}
                     shrink
                     variant="standard"
                     size={"small"}
                     sx={{ width: "100%", marginBottom: "10px" }}
                     value={state.password || ""}
                     onChange={e => handleChange(e, "password")}
                  />
                  <TextTypeField
                     label={"PhoneNo"}
                     shrink
                     variant="standard"
                     size={"small"}
                     sx={{ width: "100%", marginBottom: "10px" }}
                     value={state.phoneNumber || ""}
                     onChange={e => handleChange(e, "phoneNumber")}
                  />
                  <TextTypeField
                     label={"DoctorNo"}
                     shrink
                     variant="standard"
                     size={"small"}
                     sx={{ width: "100%", marginBottom: "10px" }}
                     value={state.licenseNo || ""}
                     inputProps={{ readOnly: true }}
                  />
                  <TextTypeField
                     label={"SpecialNo"}
                     shrink
                     variant="standard"
                     size={"small"}
                     sx={{ width: "100%", marginBottom: "10px" }}
                     value={state.spacialNo || ""}
                     inputProps={{ readOnly: true }}
                  />
               </StyledWrapper>

               <StyledWrapper>
                  <OkBtn onClick={doOk} text={"Ok"}/>
                  <CancelBtn onClick={closeDialog} text={"Cancel"}/>
                  {/* <StyledKindButton kind={"ok"} onClick={doOk}>OK</StyledKindButton> */}
                  {/* <StyledKindButton kind={"cancel"} onClick={closeDialog}>Cancel</StyledKindButton> */}
               </StyledWrapper>
            </StyledBox>
         </Modal>
      </>
   );
}
