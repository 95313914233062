import styled from "@emotion/styled";

export const StyledTitleWrapper = styled.div`
    display: flex;
    height: 0;
    padding: 24px;
`;

export const StyledContentsWrapper = styled.div`
    padding-left: 30px;
    padding-top: 10px;
`;

export const StyledButtonWrapper = styled.div`
    text-align: center;
    padding: 10px;
`;

export const StyledTextFontTitle = styled.p`
    color: #ccc;
    font-size: 20px;
    display: flex;
    margin: 3px 0 0 10px;
`;

export const StyledTextFontContent = styled.p`
    color: #ccc;
    font-size: 15px;
`;
