import React, { createContext, Dispatch, useContext, useReducer } from "react";

type State = {
   open: boolean;
   msg?: string;
   isErr?: boolean;
   isWarn?: boolean
}

type Action =
  | {type: "SET_OPEN", open: boolean}
  | {type: "SET_MSG", msg: string}
  | {type: "SET_ERROR", isErr: boolean}
  | {type: "SET_WARNING", isWarn: boolean}
  | {type: "SET_TOAST", open: boolean, msg: string, isErr?: boolean, isWarn?: boolean}
  | {type: "CLOSE"};

// 디스패치를 위한 타입 (Dispatch 를 리액트에서 불러올 수 있음), 액션들의 타입을 Dispatch 의 Generics로 설정
type ToastDispatch = Dispatch<Action>;

const ToastStateContext = createContext<State | null>(null);
const ToastDispatchContext = createContext<ToastDispatch | null>(null);

function reducer(state: State, action: Action) {
   switch (action.type) {
   case "SET_OPEN":
      return {
         ...state,
         open: action.open,
      };
   case "SET_MSG":
      return {
         ...state,
         msg: action.msg,
      };
   case "SET_ERROR":
      return {
         ...state,
         isErr: action.isErr,
      };
   case "SET_WARNING":
      return {
         ...state,
         isWarn: action.isWarn,
      };
   case "SET_TOAST":
      return {
         open: action.open,
         msg: action.msg,
         isErr: action.isErr === undefined ? false : action.isErr,
         isWarn: action.isWarn === undefined ? false : action.isWarn,
      };
   case "CLOSE":
      return {
         ...state,
         open: false,
      };
   default: throw new Error("Unhandled action");
   }
}

interface Props {
   children: JSX.Element | JSX.Element[];
}

function ToastProvider({ children }: Props): JSX.Element {
   const [state, dispatch] = useReducer(reducer, {
      open: false,
      msg: undefined,
      isErr: false,
      isWarn: false,
   });

   return (
      <ToastDispatchContext.Provider value={dispatch}>
         <ToastStateContext.Provider value={state}>
            {children}
         </ToastStateContext.Provider>
      </ToastDispatchContext.Provider>
   );
}

// custom hook
function useToastState(): State {
   const context = useContext(ToastStateContext);
   if (!context) {
      throw new Error("useToastState should be used within ToastProvider");
   }
   return context as State;
}

function useToastDispatch() {
   const context = useContext(ToastDispatchContext);
   if (!context) {
      throw new Error("useToastActions should be used within ToastProvider");
   }
   return context;
}
export { ToastProvider, useToastState, useToastDispatch };
