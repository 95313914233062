import styled from "@emotion/styled";

/* .merge-title */
export const MergeTitle = styled.div`
   background-color: #252934;
   height: 50px;
   width: 100%;
   color: #fff;
   border-bottom: 1px solid #4c5667;
`;

/* .merge-list */
export const MergeList = styled.div`
   height: calc(100% - 135px);
   width: calc(100% - 20px);
   padding: 10px;
   align-items: center;
   justify-content: center;
`;

/* .merge-container */
export const MergeContainer = styled.div`
   width: 100%;
   //height: 85px;
`;

/* text-attention */
export const MergeInfo = styled.div`
   font-size: 13px;
   color: #fff;
   text-align: left;
   margin-left: 10px;
`;

/* .classA */
export const ButtonWrapper = styled.div`
   text-align: center;
   padding: 15px;
`;
