import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Box, Button, ButtonGroup, Modal } from "@mui/material";
import { ColDef, GridOptions, RowSelectedEvent } from "ag-grid-community";
import i18n from "../../../utils/i18n-utils";
import CommonUtils from "../../../../public/resource/js/utils/common";
import GridUtils from "../../../utils/grid-utils";
import store from "../../../redux/store";
import { CommonActionType } from "../../../redux/reducers/common";
import TextTypeField from "../../input/textfield/TextTypeField";
import NumberTypeField from "../../input/textfield/NumberTypeField";
import DownloadToast from "../../toast/DownloadToast";
import useDialogOpen from "../../../hooks/useDialogOpen";
import { DialogStyles } from "../styles/DialogStyles";
import { DicomSendStyles } from "./Styles/DicomSendStyles";
import OkBtn from "../../button/OkBtn";
import CancelBtn from "../../button/CancelBtn";

interface dicomSendProps {
   caseIds: Array<string>;
}

interface dicomSendState {
   id: string;
   remoteTitle: string;
   localTitle: string;
   host: string;
   port: string;
   name: string;
   updateDtime?: string;
   userId?: string;
}

export default function DicomSend(props : dicomSendProps) {
   const [open, setOpen] = useState(true);
   const [rowData, setRowData] = useState<any>([]);
   const [state, setState] = useState<dicomSendState>({
      id: "",
      remoteTitle: "",
      localTitle: "",
      host: "",
      port: "",
      name: "",
   });
   const [downloadToastState, setDownloadToastState] = useState({ open: false, msg: "" });
   const [sendButtonState, setSendButtonState] = useState({ disabled: false });
   const dialogRef = useRef(null);
   const { caseIds } = props;
   let eventSource: EventSource | null;
   useDialogOpen();

   useEffect(() => {
      getDicomScpList();
   }, []);

   const [gridOptions, setGridOptions] = useState<GridOptions>({
      defaultColDef: {
         suppressMenu: true,
         sortable: true,
         resizable: true,
         floatingFilter: true,
      },
      rowSelection: "single",
      animateRows: true,
      localeText: GridUtils.localeText(),
      onRowSelected(event: RowSelectedEvent) {
         if (!event.node.isSelected()) return;
         setState(event.data);
      },
   });

   const onGridReady = (params: { api: any; columnApi: any; }) => {
      setGridOptions({ ...gridOptions, api: params.api, columnApi: params.columnApi });
   };

   const columnDefs : ColDef[] = useMemo(() => [
      { field: "remoteTitle", headerName: i18n("label.remoteTitle"), headerTooltip: i18n("label.remoteTitle"), width: 155, filter: "agTextColumnFilter", floatingFilterComponentParams: { suppressFilterButton: true } },
      { field: "localTitle", headerName: i18n("label.localeTitle"), headerTooltip: i18n("label.localeTitle"), width: 155, filter: "agTextColumnFilter", floatingFilterComponentParams: { suppressFilterButton: true } },
      { field: "host", headerName: i18n("label.host"), headerTooltip: i18n("label.host"), width: 230, filter: "agTextColumnFilter", floatingFilterComponentParams: { suppressFilterButton: true } },
      { field: "port", headerName: i18n("label.portAlphabet"), headerTooltip: i18n("label.portAlphabet"), width: 80, filter: "agTextColumnFilter", floatingFilterComponentParams: { suppressFilterButton: true } },
      { field: "name", headerName: i18n("label.name"), headerTooltip: i18n("label.name"), width: 120, filter: "agTextColumnFilter", floatingFilterComponentParams: { suppressFilterButton: true } },
   ], []);

   function getDicomScpList() {
      fetch(`/api/dicom/send/info`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("jwt")!,
         },
      }).then((response) => {
         if (!response.ok) {
            throw new Error(`[${response.status}] ${response.statusText}`);
         }
         return response.json();
      }).then((json) => {
         setRowData(json);
      }).catch(err => console.error(err));
   }

   function handleClose() {
      setOpen(false);
      setState({
         id: "",
         remoteTitle: "",
         localTitle: "",
         host: "",
         port: "",
         name: "",
      });
      dialog_closed();
   }

   function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, stateField: string) {
      const { value } = event.target;
      setState({ ...state, [stateField]: value });
   }

   function openDownloadToast(msg: string) {
      setDownloadToastState({ open: true, msg });
   }

   function closeSendToast() {
      setDownloadToastState({ open: false, msg: "" });
   }

   function sendStart() {
      const key = CommonUtils.uuidv4();

      eventSource = new EventSource(`${__API_URL__}/events?key=${key}`);
      eventSource.onmessage = (result) => {
         const { data } = result;
         const { eventId, msg } = JSON.parse(data);

         switch (eventId) {
         case "DICOM_SEND": {
            openDownloadToast(msg);
            break;
         }
         case "IN_PROGRESS": {
            openDownloadToast(msg);
            break;
         }
         case "ERROR": {
            openDownloadToast(msg);
            stopServerSentEvent();
            break;
         }
         case "COMPLETE": {
            openDownloadToast(msg);
            stopServerSentEvent();
            break;
         }
         default:
         }
      };

      eventSource.onerror = (err) => {
         console.log("EventSource error: ", err);
         stopServerSentEvent();
      };

      setSendButtonState({ disabled: true });

      doSend(key);
   }

   function stopServerSentEvent() {
      if (eventSource) {
         eventSource.close();
         console.log("close EventSource! ");
         eventSource = null;
      }
   }

   function doSend(key: string) {
      const params = { ...state, caseIds, key };
      fetch(`/api/dicom/send`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("jwt")!,
         },
         body: JSON.stringify(params),
      }).then((response) => {
         if (!response.ok) throw new Error(`DICOM Send error. [${response.status}] ${response.statusText}`);

         setTimeout(() => closeSendToast(), 5000);
         return response.json();
      }).then((json: dicomSendState) => {
         console.log(json);
         const { id } = json;
         const index = indexOfRowDataById(id);

         if (index === -1) addRowData(json);  // Add row
         else updateRowData(index);      // Update row
      }).catch((err) => {
         console.error(err.message);
         openDownloadToast(err.message);
      }).finally(() => {
         stopServerSentEvent();
         setSendButtonState({ disabled: false });
      });
   }

   function indexOfRowDataById(id : string) {
      for (let i = 0; i < rowData.length; i++) {
         if (rowData[i].id === id) return i;
      }
      return -1;
   }

   function addRowData(row: dicomSendState) {
      rowData.push(row);
      gridOptions.api!.setRowData(rowData);
   }

   // 어떤 용도로 사용하는지 모르겠음
   function updateRowData(index : number) {
      // set(`rowData.${index}.id`, row.id);
      // set(`rowData.${index}.host`, row.host);
      // set(`rowData.${index}.localTitle`, row.localTitle);
      // set(`rowData.${index}.remoteTitle`, row.remoteTitle);
      // set(`rowData.${index}.port`, row.port);
      // set(`rowData.${index}.name`, row.name);
      // set(`rowData.${index}.updateDtime`, row.updateDtime);
      // set(`rowData.${index}.userId`, row.userId);

      const data = rowData[index];
      gridOptions.api!.redrawRows({ rowNodes: data });
   }

   function prevent_event_propagation(event: React.MouseEvent<HTMLDivElement>) {
      event.stopPropagation();
   }

   function dialog_closed() {
      // @ts-ignore
      window.dialog_closed();
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   const dialogStyles = DialogStyles();
   const dicomSendStyles = DicomSendStyles();
   return (
      <>
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css"></link>
         <Modal
            open={open}
            onClose={handleClose}
            onClick={prevent_event_propagation}
         >
            <Box
               className={dicomSendStyles.container}
               component={"form"}
               ref={dialogRef}>
               <TextTypeField
                  label={i18n("label.remoteTitle")}
                  shrink
                  variant="standard"
                  size={"small"}
                  value={state.remoteTitle || ""}
                  onChange={e => handleChange(e, "remoteTitle")}/>
               <TextTypeField
                  label={i18n("label.localeTitle")}
                  shrink
                  variant="standard"
                  size={"small"}
                  value={state.localTitle || ""}
                  onChange={e => handleChange(e, "localTitle")}/>
               <TextTypeField
                  label={i18n("label.host")}
                  shrink
                  variant="standard"
                  size={"small"}
                  sx={{ width: "25%" }}
                  value={state.host || ""}
                  onChange={e => handleChange(e, "host")}/>
               <NumberTypeField
                  label={i18n("label.portAlphabet")}
                  shrink
                  variant="standard"
                  size={"small"}
                  value={state.port || ""}
                  sx={{ width: "10%" }}
                  max={65535}
                  onChange={e => handleChange(e, "port")}/>
               <TextTypeField
                  label={i18n("label.name")}
                  shrink
                  variant="standard"
                  size={"small"}
                  value={state.name || ""}
                  onChange={e => handleChange(e, "name")}/>
               <div className={"ag-theme-balham-dark"} style={{ height: "430px" }}>
                  <AgGridReact
                     gridOptions={gridOptions}
                     columnDefs={columnDefs}
                     rowData={rowData}
                     onGridReady={onGridReady}
                  />
               </div>
               <Box className={dialogStyles.dialogBottomCenter}>
                  <Box className={dialogStyles.buttonGroup}>
                     <OkBtn onClick={sendStart} disabled={sendButtonState.disabled} text={i18n("button.send")}/>
                     <CancelBtn onClick={handleClose} text={i18n("button.cancel")}/>
                  </Box>
               </Box>
            </Box>
         </Modal>
         <DownloadToast open={downloadToastState.open} msg={downloadToastState.msg}/>
      </>
   );
}
