import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useToastDispatch, useToastState } from "../../context/ToastContext";

export default function Toast() {
   const state = useToastState();
   const dispatch = useToastDispatch();

   const handleClose = (event: React.SyntheticEvent<any> | Event, reason: string) => {
      if (reason === "clickaway") {
         return;
      }
      dispatch({ type:"CLOSE" });
   };

   return (
      <Snackbar
         anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
         open={state.open}
         autoHideDuration={2000}
         onClose={handleClose}
         disableWindowBlurListener={true}
      >
         {/* eslint-disable-next-line no-nested-ternary */}
         <Alert sx={{ bgcolor: (state.isErr ? "#e46159" : (state.isWarn ? "#ddaf25" : "#0087cb")), color: "white" }} icon={false}>{state.msg}</Alert>
      </Snackbar>
   );
}
