import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
   Box,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle, IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import TextTypeField from "../input/textfield/TextTypeField";
import i18n from "../../utils/i18n-utils";
import CountrySelect, { countryCodes, CountryType } from "../comboBox/CountrySelect";
import { is_esc } from "../../dialog/common-keyboard-handler";
import PasswordTypeField from "../input/textfield/PasswordTypeField";
import { useToastDispatch } from "../../context/ToastContext";
import commonuUtils from "../../../public/resource/js/utils/common";
import store from "../../redux/store";
import { CommonActionType } from "../../redux/reducers/common";
import useDialogOpen from "../../hooks/useDialogOpen";
import { DialogStyles } from "./styles/DialogStyles";
import OkBtn from "../button/OkBtn";
import CancelBtn from "../button/CancelBtn";

const GOOGLE_MAP_KEY = __GOOGLE_MAP_KEY__;
const IPSTACK_KEY = __IPSTACK_KEY__;

interface shareToPatientProps {
  caseIds: Array<string>;  // send 할때 사용 하는 값으로 바귈때마다 리렌더링 필요 X
}

interface shareToPatientState extends CountryType {
  [index: string]: string | object, // state를 접근할때 배열 형식으로 접근 가능하게 하기 위해 설정
  phoneNumber: string,
  password: string,
  validation: {
     phoneNumber?: boolean,
     password?: boolean }
}

export default function ShareToPatient(props: shareToPatientProps) {
   const DEFAULT_COUNTRY = "KR";
   const [open, setOpen] = useState(false);
   const [state, setState] = useState<shareToPatientState>({
      code: "",
      label:"",
      dialCode: "",
      phoneNumber: "",
      password: "",
      validation: { phoneNumber: false, password: false },
   });
   const toastAction = useToastDispatch();
   const dialogRef = useRef(null);
   useDialogOpen();

   useEffect(() => {
      getCurrentLocale().then((result) => {
         console.log(result);
         setState({ ...state, ...result });
         setOpen(true);
      }).catch((err) => {
         console.log("getCurrentLocale error", err);
         const result = getDialCode(DEFAULT_COUNTRY);
         setState({ ...state, ...result });
         setOpen(true);
      });
   }, []);

   function getCurrentLocale() {
      // HPACS 접속 시 위치정보를 요구해서 Google Map을 통한 국가코드 처리는 일단 제외한다.
      // return new Promise((resolve) => {
      //    if ("geolocation" in navigator) {
      //       navigator.geolocation.getCurrentPosition((position) => {
      //          // console.log(position, position.coords, "latitude", position.coords.latitude, "longitude", position.coords.longitude);
      //          this.getLocationFromGoogle(`${position.coords.latitude},${position.coords.longitude}`)
      //             .then(resolve)
      //             .catch((err) => {
      //                console.log("getLocationFromGoogle error", err);
      //                this.getLocationFromIPStack().then(resolve);
      //             });
      //       }, (error) => {
      //          console.log("An error has occured while retrieving location", error);
      //          this.getLocationFromIPStack().then(resolve);
      //       });
      //    } else {
      //       console.log("geolocation is not enabled on this browser");
      //       this.getLocationFromIPStack().then(resolve);
      //    }
      // });

      return new Promise<CountryType>((resolve: any) => {
         getLocationFromIPStack().then(resolve);
      });
   }

   function getLocationFromIPStack() {
      return fetch(`http://api.ipstack.com/check?access_key=${IPSTACK_KEY}&format=1`)
         .then((response) => {
            if (response.status === 200) return response.json();
            throw new Error(`Get ipstack api error. Status Code: ${response.status}`);
         })
         .catch((err) => {
            console.log("ipstack api error!", err);
            return { "country_code": DEFAULT_COUNTRY };
         }).then((result) => {
            if (result.error) {
               console.log(result.error.info, result);
               return DEFAULT_COUNTRY;
            }
            return result.country_code || DEFAULT_COUNTRY;
         })
         .then(result => getDialCode(result));
   }

   function getDialCode(code: string) {
      const country = code || DEFAULT_COUNTRY;
      const result = countryCodes.find(m => m.code === country.toUpperCase());
      if (result) return result;

      return { label:"Korea, Republic of South Korea", dialCode:"+82", code:"KR" };
   }

   // function getLocationFromGoogle(coords) {
   //    return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords}&data=location&key=${GOOGLE_MAP_KEY}`)
   //       .then((response) => {
   //          if (response.status === 200) return response.json();
   //          throw new Error(`Looks like there was a problem. Status Code: ${response.status}`);
   //       }).then((result) => {
   //          if (result.status === "OK") return this.getCountryCode(result.results);
   //          throw new Error(`${result.status} - ${result.error_message}`);
   //       }).then(result => this.getDialCode(result));
   // }
   //
   // function getCountryCode(results) {
   //    return ((results || [])
   //       .reduce((a, b) => (b.address_components ? a.concat(b.address_components) : a), [])
   //       .find(f => (f.types || []).includes("country")) || {}).short_name || this.DEFAULT_COUNTRY;
   // }

   const changeState = (values: object) => {
      setState({ ...state, ...values });
   };

   const handleClose = (event?: any, reason?: string) => {
      if (reason === "backdropClick") {
         return;
      }
      setOpen(false);
      setState({ ...state, password: "", phoneNumber: "", validation: { phoneNumber:false, password: false } });
      dialog_closed();
   };

   const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, stateField: string) => {
      const { value } = event.target;
      setState({ ...state, [stateField]: value, validation: { ...state["validation"], [stateField]: commonuUtils.isEmptyValue(value) } });
   };

   const handleSubmit = (event: React.KeyboardEvent) => {
      if (event.keyCode === 13) doShare();
   };

   function validate(): boolean {
      const formFields = Object.keys(state);
      let newFormValues = { ...state };
      let check = true;

      for (let index = 0; index < formFields.length; index++) {
         const field = formFields[index];
         const value = state[field];

         if (commonuUtils.isEmptyValue(value)) {
            check = false;
            newFormValues = {
               ...newFormValues,
               validation: {
                  ...newFormValues["validation"],
                  [field]: true },
            };
         }
      }
      setState(newFormValues);
      return check;
   }

   const doShare = () => {
      if (!validate()) return;

      const num = `${state.dialCode.replace(/[^\d]/, "")}${state.phoneNumber.replace(/^0/, "")}`;
      console.log(num, state.dialCode, state.phoneNumber, props.caseIds);

      const params = { phoneNumber: num, password: state.password, caseIds: props.caseIds };
      fetch(`/api/hscan/share`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("jwt")!,
         },
         body: JSON.stringify(params),
      }).then((response) => {
         if (response.status === 200) {
            handleClose();
            toastAction({ type: "SET_TOAST", open:true, msg:"Success share to patient.", isErr:false });
         } else {
            toastAction({ type: "SET_TOAST", open:true, msg:"Failure share to patient.", isErr:true });
            throw new Error(`[${response.status}] ${response.statusText} Failure share to patient.`);
         }
      }).catch(err => console.error(err));
   };

   // todo: dialog 마다 똑같은 로직이 있는데, 해당 로직들 hook으로 공통화 가능한지 확인 필요
   function prevent_event_propagation(event: React.MouseEvent) {
      event.stopPropagation();
   }

   function close_if_esc(e: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(e)) {
         handleClose();
         e.stopPropagation();
      }
   }

   function dialog_closed() {
      // @ts-ignore
      window.dialog_closed();
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   const dialogStyles = DialogStyles();

   return (
      <>
         <div>
            <Dialog
               open={open}
               onClose={handleClose}
               onClick={prevent_event_propagation}
               onKeyDown={close_if_esc}
               fullWidth
               maxWidth={"sm"}
               PaperProps={{ sx:{ backgroundColor: "#2d333f", borderTop: "5px #0087cb solid" } }} >
               <Box className={dialogStyles.container}>
                  <DialogTitle
                     className={dialogStyles.dialogTitle}
                     component={"p"}>Share To Patient</DialogTitle>
                  <IconButton sx={{ color: "#fff" }} onClick={handleClose}>
                     <Close/>
                  </IconButton>
               </Box>
               <DialogContent
                  dividers
                  className={dialogStyles.dialogContent}>
                  <Box
                     component="form"
                     className={dialogStyles.rowDirectionWrap}
                  >
                     <CountrySelect {...state} changeState={changeState}/>
                     <TextTypeField
                        margin="dense"
                        label={i18n("label.phoneNumberLabel")}
                        variant="standard"
                        shrink
                        sx={{ width:"75%" }}
                        placeholder={i18n("label.phoneNumberPlaceholder")}
                        error={state.validation.phoneNumber}
                        onKeyPress={handleSubmit}
                        onChange={e => handleChange(e, "phoneNumber")}
                     />
                     <PasswordTypeField
                        margin="dense"
                        label={i18n("label.passwordLabel")}
                        variant="standard"
                        shrink
                        fullWidth
                        placeholder={i18n("label.passwordPlaceholder")}
                        error={state.validation.password}
                        onKeyPress={handleSubmit}
                        onChange={e => handleChange(e, "password")}
                     />
                  </Box>
               </DialogContent>
               <DialogActions className={dialogStyles.dialogBottomCenter}>
                  <OkBtn onClick={doShare} text={i18n("button.share")}></OkBtn>
                  <CancelBtn onClick={handleClose} text={i18n("button.cancel")}/>
               </DialogActions>
            </Dialog>
         </div>
      </>
   );
}
