import styled from "@emotion/styled";
import { Radio } from "@mui/material";

/*
* DDP:      .setting-tab
* Shortcut: .container
* Macro:    .container
* Report:   .setting-tab
* Filmbox:  .setting-tab
* */
export const StyledSettingContainer = styled.div`
    padding: 10px;
    height: 100%;
    color: #aaaaaa;
    background-color: #262934;
`;

/*
* DDP:      .setting-tab
* Shortcut: .warp-shortcut
* Macro:    .warp-Macro
* Report:   .setting-tab
* Filmbox:  .setting-tab
* */
export const StyledWrapper = styled.div`
    margin-top: 5px;
`;

/*
* Filmbox: .settings-filmbox
* Report:  .settings-report
*  */
export const StyledSettingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #262934;
`;

/*
*
* Filmbox: .setting-option
* Report:  .setting-option
*/
export const StyledSettingContents = styled.div`
    padding: 5px;
    border: 1px solid #4c5667;
    margin-bottom: 5px;
`;

/*
* Shortcut: .page-context
* Macro:    .page-context
*  */
export const StyledPageContext = styled.div`
    height: 100%;
    border: 1px solid #4c5667;
    margin: 10px;
    padding: 5px;
`;

/*
* Shortcut: .wrap-context
* Macro:    .wrap-context
*/
export const StyledContextWrapper = styled.div`
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
`;

/*
* Shortcut: .wrap
* Macro:    .wrap
 */
export const StyledContext = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 3px;
`;

/*
* Shortcut: .text-font
* Macro:    .text-font
 */
export const StyledContextText = styled.div`
    width: 100px;
    padding: 0 7px;
    font-size: 10pt;
    color: #aaaaaa;
    margin: 5px 5px 0 5px;
`;

/*
* Shortcut:  .warp-list .list-name
* Macro:     .warp-list .list-name
 */
/* */
export const StyledGridWrapper = styled.div`
    border: 1px solid #4c5667;
    margin: 0 10px;
    min-height: 200px;
    height: calc(100vh - 620px);
    padding: 10px;
`;

// export const StyledSelect = styled(Select)`
export const StyledSelect = styled.select`
    margin-top: 5px;
    color: #aaaaaa;
    background-color: #262934;
    border: solid 0;
    border-bottom: solid 1px #aaaaaa;
    font-size: 11pt;
    text-align: center;
    &:focus {
        outline: none;
    }
    &:disabled {
        color: rgba(128, 128, 128, 0.63);
    }
`;

// export const StyledMenuItem = styled(MenuItem)`
export const StyledMenuItem = styled.option`
    color: #aaaaaa;
    background: #394451;
    &:focus {
        color: #394451;
        background: #aaaaaa;
    }
    &:hover {
        background: #394451;
    }
`;

/*
* globalTheme.tsx - 150 line
* 주석 시 css 적용됨
MuiSvgIcon: {
    styleOverrides: {
        root: {
            color: "#aaaaaa",
        },
    },
},
* */
export const StyledRadio = styled(Radio)`
    color: #aaaaaa;
    &.Mui-checked {
        color: #0087cb;
    }
    &.Mui-disabled {
        color: #6c6d73;
    }
`;
