import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { FormControl } from "@mui/material";
import { ColDef, GetContextMenuItemsParams, GridOptions, MenuItemDef } from "ag-grid-community";
import i18n from "../../../../utils/i18n-utils";
import {
   StyledContextButtonWrapper,
   StyledContextInputValidation,
   StyledContextTextShortcut,
} from "./styles/SettingShortcutStyles";
import { CustomTooltip } from "../../../../../public/resource/js/customTooltip";
import { ShortcutsSetting } from "./types/SettingShortcutTypes";
import {
   StyledContext,
   StyledContextText,
   StyledContextWrapper,
   StyledGridWrapper,
   StyledMenuItem,
   StyledPageContext,
   StyledSelect,
   StyledSettingContainer,
   StyledWrapper,
} from "./styles/SettomgStyles";
import TextTypeField from "../../../input/textfield/TextTypeField";
import { useToastDispatch } from "../../../../context/ToastContext";
import store from "../../../../redux/store";
import { QueryKey } from "../../../../redux/reducers/query";
import UserConfigAPI from "../../../../api/UserConfigAPI";
import OkBtn from "../../../button/OkBtn";
import CancelBtn from "../../../button/CancelBtn";

enum Category {
   FILMBOX = "filmbox",
   WORKLIST = "worklist"
}

export default function SettingShortcut() {
   const [category, setCategory] = useState<Category>(Category.WORKLIST);
   const [displayedName, setDisplayName] = useState<string>("");
   const [functionName, setFunctionName] = useState<string>("");
   const [shortcut, setShortcut] = useState<string>("");
   const [isValidateShortcut, setIsValidateShortcut] = useState<boolean>(true);
   const [rowData, setRowData] = useState<Array<any>>([]);
   const [shortcutsSetting, setShortcutsSetting] = useState<ShortcutsSetting | null>(null);
   // const [_shortcut, set_Shortcut] = useState<ShortcutsSetting>();
   const [selectedRow, setSelectedRow] = useState<any>(); // g_row_selected
   const [inputKey, setInputKey] = useState<Array<string>>(["alt", ""]);
   const toastDispatch = useToastDispatch();
   const notDeleteFunction = ["pageUp", "pageDown", "imageUp", "imageDown", "firstImage", "lastImage", "seriesPrev", "seriesNext"];
   const [notSupportedKey, setNotSupportedKey] = useState<string>("");
   const notSupportedShortcut = ["d", "e", "f"];
   const notSupportedFilmboxShortcut = ["q", "t", "p", "a", "s", "l", "n"];

   const columnDefs: ColDef[] = useMemo(() => [
      { headerName: i18n("label.function"), field: "displayedName", width: 350 },
      { headerName: i18n("label.shortcut"), field: "shortcut", width: 185 },
   ], []);

   /*
   *  https://www.ag-grid.com/react-data-grid/context-menu/
   * */
   const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) : (string | MenuItemDef)[] => {
      if (!params.node) return [];
      params.node.setSelected(true, true);
      const result = [
         "separator",
         {
            name: i18n("label.delete"),
            action() {
               const { shortcutsSetting, category } = params.context;
               const { functionName } = params.node.data;
               if (!notDeleteFunction.includes(functionName)) {
                  updateShortcut(functionName, "", shortcutsSetting, category);
               }
               else {
                  const msg = i18n("msg.settingDialog.shortcut.context.delete");
                  openToast(msg, true);
               }
            },
         },
         "separator",
      ];
      return result;
   }, []);

   const [gridOptions, setGridOptions] = useState<GridOptions>({
      defaultColDef: {
         sortable: true,
         suppressMenu: true, // 메뉴 막기
      },
      rowDragManaged: true,
      components: {
         customTooltip: CustomTooltip,
      },
      tooltipShowDelay: 0,
      animateRows: true,
      overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">${i18n("label.noRecordsFound")}</span>`,
      rowSelection: "single",
      onRowSelected: (e) => {
         setSelectedRow(e.node);
      },
      onRowDoubleClicked: (e) => {
         const { functionName, displayedName, shortcut } = e.data;
         setIsValidateShortcut(true);
         setFunctionName(functionName);
         setDisplayName(displayedName);
         setShortcut(shortcut.split("+")[1]);
         setInputKey(["alt", shortcut.split("+")[1]]);
      },
   });

   useEffect(() => {
      UserConfigAPI.getUserShortcutByUserId().then((resolve: ShortcutsSetting | any) => {
         shortcutSettingChange(resolve);
      });
   }, []);

   useEffect(() => {
      if (shortcut) setInputKey(["alt", shortcut]);
   }, [shortcut]);

   useEffect(() => {
      if (shortcutsSetting) setGridUpdate();
   }, [shortcutsSetting, category]);

   function setGridUpdate() {
      setShortcut("");
      if (shortcutsSetting) {
         if (category === Category.FILMBOX) {
            const filmboxFunctions = shortcutsSetting.filmboxFunction;
            gridOptions.api?.setRowData(filmboxFunctions);
            setRowData(filmboxFunctions);
         } else if (category === Category.WORKLIST) {
            const worklistFunctions = shortcutsSetting.worklistFunction;
            gridOptions.api?.setRowData(worklistFunctions);
            setRowData(worklistFunctions);
         }
      }
   }

   function openToast(msg: string, isErr: boolean) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   }

   function handleCategoryChange(e: any) {
      setIsValidateShortcut(true);
      setCategory(e.target.value);
   }

   function handleFunctionNameChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      setFunctionName(event.target.value);
   }

   /*  this.$.shortcut.addEventListener("keyup", (e) => { */
   function handleShortcutChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      setShortcut("");
      const { value } = event.target;
      const regex = /[a-z\s]/;
      let key = regex.test(value) ? value : "";
      let isValid = (value === "");

      // default not support shortcut
      if (notSupportedShortcut.includes(value)) key = "";

      // filmbox not support shortcut
      if (category === Category.FILMBOX || functionName === "filmbox") {
         if (notSupportedFilmboxShortcut.includes(value)) key = "";
      }

      if (key === value) isValid = true;

      setNotSupportedKey(value);
      setIsValidateShortcut(isValid);
      setShortcut(key.trim());
   }

   function clearShortcutContext() {
      setIsValidateShortcut(true);
      setDisplayName("");
      setFunctionName("");
      setShortcut("");
      setInputKey(["alt", ""]);
   }

   function doSave() {
      const isErr = true;
      if (functionName === "") {
         const msg = i18n("msg.settingDialog.shortcut.save.selectFunction");
         openToast(msg, isErr);
      } else if (functionName !== "" && notDeleteFunction.indexOf(functionName) !== -1) {
         const msg = i18n("msg.settingDialog.shortcut.save.cannotModifySelectedFunction");
         openToast(msg, isErr);
      } else if (functionName !== "" && !shortcut) {
         const msg = i18n("msg.settingDialog.shortcut.save.enterShortcut");
         openToast(msg, isErr);
      } else {
         let flag = false; // 입력한 단축키가 현제 기능하고 같은건지 확인 flag
         const inputShortcut =  `${inputKey[0]}+${inputKey[1]}`;
         const inputFunction = functionName;
         if (shortcutsSetting) {
            const worklistShortcuts = shortcutsSetting.worklistFunction;
            const filmboxShortcuts = shortcutsSetting.filmboxFunction;

            for (let i = 0; i < worklistShortcuts.length; i++) {
               const { shortcut, functionName } = worklistShortcuts[i];

               // filmbox, worklist 이동 숏컷은 공통으로 사용, 중복체크 x
               const skipFlag = functionName === "filmbox" &&  inputFunction === "worklist";
               if (inputShortcut === shortcut && inputFunction !== functionName && !skipFlag) {
                  flag = true;
                  break;
               }
            }

            if (!flag) {
               for (let i = 0; i < filmboxShortcuts.length; i++) {
                  const { shortcut, functionName } = filmboxShortcuts[i];

                  // filmbox, worklist 이동 숏컷은 공통으로 사용, 중복체크 x
                  const skipFlag = functionName === "filmbox" &&  inputFunction === "worklist";
                  if (inputShortcut === shortcut && inputFunction !== functionName && !skipFlag) {
                     flag = true;
                     break;
                  }
               }
            }

            if (flag) {
               const msg = i18n("msg.settingDialog.shortcut.save.existShortcut");
               openToast(msg, isErr);
            }
            else {
               const inputShortcut = `${inputKey[0]}+${inputKey[1]}`;
               console.log(`success !! ${inputShortcut}`);
               updateShortcut(functionName, inputShortcut, shortcutsSetting);
            }
         }
      }
   }

   /*
   * before: _shortcutChanged
   * */
   function shortcutSettingChange(shortcutData: ShortcutsSetting) {
      const data = shortcutData;
      const { worklistFunction, filmboxFunction } = data;
      if (worklistFunction) {
         data.worklistFunction = worklistFunction.map((item) => {
            const displayedName = i18n(`shortcut.worklist.${item.functionName}`);
            return {
               ...item,
               displayedName,
            };
         });
      }

      if (filmboxFunction) {
         data.filmboxFunction = filmboxFunction.map((item) => {
            const displayedName = i18n(`shortcut.filmbox.${item.functionName}`);
            return {
               ...item,
               displayedName,
            };
         });
      }

      setShortcutsSetting(data);
   }

   function updateShortcut(functionName: string, shortcut: string, shortcutsParams: ShortcutsSetting, categoryParams?: string) {
      const fn = functionName;
      const sc = shortcut;
      if (shortcutsParams) {
         const newShortcutSetting = shortcutsParams;
         const { worklistFunction, filmboxFunction } = newShortcutSetting;
         const categoryValue = categoryParams || category;
         if (categoryValue === Category.WORKLIST) {
            const targetObj = worklistFunction.find(item => item.functionName === fn);
            if (targetObj) targetObj.shortcut = sc;

            if (fn === "filmbox") {
               const filmboxObj = filmboxFunction.find(item => item.functionName === "worklist");
               if (filmboxObj) filmboxObj.shortcut = sc;
            }

            // this.popupPostMessage();
            UserConfigAPI.updateWorklistShortcuts(newShortcutSetting).then((result) => {
               if (result) {
                  const msg = i18n("msg.settingDialog.shortcut.save.success");// "Shortcut has been successfully updated!";
                  const isErr = false;
                  openToast(msg, isErr);
                  UserConfigAPI.getUserShortcutByUserId().then((result) => {
                     shortcutSettingChange(result);
                     store.dispatch({ type: QueryKey.GET_SHORTCUT_LIST, payload: { ...result } }); // update worklist shortcut
                  });
               }
               else {
                  const msg = i18n("msg.settingDialog.shortcut.save.fail");// "Update Shortcut is Failed";
                  const isErr = true;
                  openToast(msg, isErr);
               }
            });
            // if (worklistFunction) gridOptions.api?.applyTransaction(worklistFunction);
         } else {
            const targetObj = filmboxFunction.find(item => item.functionName === fn);
            if (targetObj) targetObj.shortcut = sc;

            if (fn === "worklist") {
               const worklistObj = worklistFunction.find(item => item.functionName === "filmbox");
               if (worklistObj) worklistObj.shortcut = sc;
            }

            // this.popupPostMessage();
            UserConfigAPI.updateFilmboxShortcuts(newShortcutSetting).then((result) => {
               if (result) {
                  const msg = i18n("msg.settingDialog.shortcut.save.filmboxSuccess");// "Shortcut has been successfully updated!";
                  const isErr = false;
                  openToast(msg, isErr);
                  UserConfigAPI.getUserShortcutByUserId().then((result) => {
                     shortcutSettingChange(result);
                     store.dispatch({ type: QueryKey.GET_SHORTCUT_LIST, payload: { ...result } }); // update filmbox shortcut
                  });
               }
               else {
                  const msg = i18n("msg.settingDialog.shortcut.save.filmboxFail");// "Update Hotkey is Failed";
                  const isErr =  true;
                  openToast(msg, isErr);
               }
            });
            // this.gridOptions.api.applyTransaction(filmboxFunction);
         }
      }
   }

   return (
      <>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.css"/>
         <link rel="stylesheet" href="/resource/style/theme/healthhub-default.css"/>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css"/>
         <StyledSettingContainer>
            <StyledWrapper>
               <StyledPageContext>
                  <StyledContextWrapper>
                     <StyledContext>
                        <StyledContextText>{i18n("label.category")}</StyledContextText>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                           <StyledSelect
                              id="select-category-standard"
                              value={category}
                              onChange={handleCategoryChange}
                           >
                              <StyledMenuItem value={"worklist"}>{i18n("label.worklist")}</StyledMenuItem>
                              <StyledMenuItem value={"filmbox"}>{i18n("label.filmbox")}</StyledMenuItem>
                           </StyledSelect>
                        </FormControl>
                     </StyledContext>

                     <StyledContext>
                        <StyledContextText>{i18n("label.function")}</StyledContextText>
                        <TextTypeField
                           sx={{ width: 300 }}
                           value={displayedName || ""}
                           onChange={handleFunctionNameChange}
                           placeholder={i18n("label.settingDialog.shortcut.placeholder.function")}
                           inputProps={{ readOnly: true, style: { fontSize: "16px", textAlign: "center", alignSelf: "center" } }}
                           variant="standard"
                           shrink
                        />
                     </StyledContext>

                     <StyledContext>
                        <StyledContextText>{i18n("label.shortcut")}</StyledContextText>
                        <StyledContextTextShortcut>Alt + </StyledContextTextShortcut>
                        <TextTypeField
                           value={shortcut || ""}
                           onChange={handleShortcutChange}
                           inputProps={{ maxLength: 1, style: { fontSize: "16px", textAlign: "center", alignSelf: "center" } }}
                           FormHelperTextProps={{ style: { fontSize: "11px" } }}
                           error={!isValidateShortcut}
                           helperText={!isValidateShortcut ? `Unsupported key:${notSupportedKey}, Please Check for supported keys.` : " "}
                           variant="standard"
                           placeholder="Enter Text"
                           shrink
                        />
                     </StyledContext>
                     <StyledContextInputValidation>{i18n("label.settingDialog.shortcut.shortcutNotSupportKeys")}</StyledContextInputValidation>
                     {
                        category === Category.FILMBOX
                           ? <StyledContextInputValidation>{i18n("label.settingDialog.shortcut.filmboxNotSupportKeys")}</StyledContextInputValidation>
                           : null
                     }

                  </StyledContextWrapper>
               </StyledPageContext>

               <StyledContextButtonWrapper>
                  <StyledContextInputValidation>{i18n("label.settingDialog.shortcut.shortcutCannotModifySign")}</StyledContextInputValidation>
                  <OkBtn onClick={doSave} text={"Save"}/>
                  <CancelBtn onClick={clearShortcutContext} text={"Clear"} />
                  {/* <StyledKindButton kind={"ok"} onClick={doSave}>Save</StyledKindButton> */}
                  {/* <StyledKindButton onClick={clearShortcutContext}>Clear</StyledKindButton> */}
               </StyledContextButtonWrapper>

               <StyledGridWrapper className={"ag-theme-balham-dark"}>
                  <AgGridReact
                     rowData={rowData}
                     columnDefs={columnDefs}
                     gridOptions={gridOptions}
                     getContextMenuItems={getContextMenuItems}
                     context={{ shortcutsSetting, category }}
                  />
               </StyledGridWrapper>
            </StyledWrapper>
         </StyledSettingContainer>
      </>
   );
}
