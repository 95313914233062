import React, { useEffect, useRef, useState } from "react";
import {
   Autocomplete, Dialog,
   IconButton, TextField,
} from "@mui/material";
import "../healthhub-confirm-dialog";
import { Close } from "@mui/icons-material";
import styled from "@emotion/styled";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { is_esc } from "../../dialog/common-keyboard-handler";
import store from "../../redux/store";
import { CommonActionType } from "../../redux/reducers/common";
import i18n from "../../utils/i18n-utils";
import { StyledBtnWrappers } from "./styles/CommonDialogStyles";
import OkBtn from "../button/OkBtn";
import CancelBtn from "../button/CancelBtn";

const DialogStyle = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   width: 400px;
   height: 205px;
   background-color: #2d333f;
`;

const VerifyHeader = styled.div`
   display: flex;
   flex-direction: row;
   height: 50px;
   border-bottom: 1px solid #4c5667;
   background-color: #252934;
   color: #fff;
   font-size: 14px;
   padding-left: 15px;
   align-items: center;
`;

const Assignee = styled.div`
   font-size: 15px; color: #aaaaaa; margin-top: 10px; white-space:nowrap;
   display: flex;
   flex-direction: row;
   margin: 15px;
   padding-left: 25px;
   align-items: center;
`;

const VerifyBtn = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`;

const theme = createTheme({
   components: {
      MuiOutlinedInput: {
         styleOverrides: {
            root: {
               "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2d333f",
               },
               "& label": {
                  color: "white",
               },
            },
         },
      },
   },
   typography: {
      body1: {
         fontSize: 15,
      },
   },
});

const useStyles = makeStyles(() => ({
   clearIndicator: {
      color: "#aaa !important",
   },
   popupIndicator: {
      color: "#aaa !important",
   },
}));

interface Message {
   selectedRows: Array<object>;
   callback: Function;
}

interface VerifyDialogProps {
   message: Message;
}

interface DropdownList {
   label: string,
   value: string,
}

const VerifyDialog = (props: VerifyDialogProps) => {
   const [open, setOpen] = useState(false);
   const [autoOpen, setAutoOpen] = useState(false);
   const [selectedRows, setSelectedRows] = useState(props.message.selectedRows);
   const [dropdownList, setDropdownList] = useState<DropdownList[]>([]);
   const [selectedDoctor, setSelectedDoctor] = useState<DropdownList | null>(null);
   const dialogRef = useRef(null);

   useEffect(() => {
      setOpen(true);
      getDoctorlist();
      dialog_opened();
   }, []);

   useEffect(() => {
      if (dropdownList?.length > 0) {
         setAutoOpen(true); // 초기 오픈시 리스트도 오픈 처리

         const verifyDoctorsName = selectedRows.map((row:any) => row.verify);
         const verifyDoctorsEmail = selectedRows.map((row:any) => row.verifyEmail);
         const verifyDoctorsId = selectedRows.map((row:any) => row.verifyInfo?.userId);
         if (verifyDoctorsName && verifyDoctorsName.length === 1
            && verifyDoctorsEmail && verifyDoctorsEmail.length === 1
            && verifyDoctorsId && verifyDoctorsId.length === 1) {
            const selected = dropdownList.find((doctor:any) => doctor.value === verifyDoctorsId[0]);
            if (selected) {
               setSelectedDoctor(selected);
            }
         }
      }
   }, [dropdownList]);

   function prevent_event_propagation(e: React.MouseEvent | React.KeyboardEvent) {
      e.stopPropagation();
   }

   function close_if_esc(e: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(e)) {
         handleClose();
         e.stopPropagation();
      }
   }

   function dialog_opened() {
      // @ts-ignore
      window.dialog_opened({ dialog:dialogRef.current, is_modal:true });
   }

   function dialog_closed() {
      // @ts-ignore
      window.dialog_closed();
   }

   function handleClose(event?:any, reason?:any) {
      if (reason && reason === "backdropClick") {
         if (autoOpen) setAutoOpen(false);
         return;
      }
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
      setOpen(false);
      dialog_closed();
   }

   function onClickAway() {
      setAutoOpen(false);
   }

   function doVerify() {
      const verifyInfo:any = {};
      if (selectedDoctor) {
         verifyInfo.userId = selectedDoctor.value;
      }
      const params = { verifyInfo };
      const rowsId = selectedRows.map((row:any) => row.id);
      setVerifyStudylist(rowsId, params).then((result) => {
         if (props.message.callback) props.message.callback(result);
      });
      handleClose();
   }

   function getDoctorlist() {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      getFacilityListByUserEmail(user.email).then((result:any) => {
         if (result) { // instanceof Error
            // 로그인 사용자가 판독의사 목록에 있는지 찾는다.
            // eslint-disable-next-line no-return-assign,no-param-reassign
            const loginDoctorIndex = result.findIndex((doctor: { email: any; }) => doctor.email === user.email);

            // 판독의사 목록에서 찾으면 로그인 사용자를 맨 앞으로 정렬시킨다.
            if (loginDoctorIndex !== -1) {
               result.unshift(result.splice(loginDoctorIndex, 1)[0]);
            }
            setDoctors(result);
         }
      });
   }

   function setDoctors(result: any) {
      const doctorList = result.map((doctor:any) => ({ label : `${doctor.name}(${doctor.email})`, value : doctor.id }));
      setDropdownList(doctorList);
   }

   function setVerifyStudylist(rowsId: any, params: any) {
      return new Promise((resolve, reject) => {
         fetch(`/api/verify/case/${rowsId}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(params.verifyInfo),
         }).then((response) => {
            if (response.ok) {
               resolve(true);
            } else {
               console.log("TechTab verify studylist failed!");
               resolve(false);
            }
         }).catch(err => reject(err));
      });
   }

   function getFacilityListByUserEmail(email: any) {
      const cases = selectedRows.map((row:any) => row.id);
      return new Promise((resolve, reject) => {
         fetch(`/api/facility/email/${email}/doctors`, {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(cases),
         }).then((response) => {
            if (response.ok) {
               if (response.status === 200) {
                  response.json().then((json) => {
                     resolve(json);
                  });
               } else {
                  resolve([]);
               }
            } else {
               console.log("Your doctor list is empty.");
               resolve(null);
            }
         }).catch(err => reject(err));
      });
   }

   const classes = useStyles();
   return (
      <Dialog
         open={open}
         // onClick={prevent_event_propagation}
         onKeyDown={close_if_esc}
         onKeyPress={prevent_event_propagation}
         onKeyUp={prevent_event_propagation}
         onClose={handleClose}
      >
         <DialogStyle>
            <VerifyHeader>
               <p style={{ alignItems: "center" }}>{i18n("button.verify")}</p>
               <IconButton sx={{ color: "#fff", marginLeft: "auto" }}
                  onClick={handleClose}
               >
                  <Close/>
               </IconButton>
            </VerifyHeader>
            <div>
               <Assignee>
                  <span>{i18n("label.assignee")} : </span>
                  <ThemeProvider theme={theme}>
                     <ClickAwayListener onClickAway={onClickAway}>
                        <Autocomplete
                           // freeSolo
                           ref={dialogRef}
                           id="combo-box-demo"
                           open={autoOpen}
                           openOnFocus
                           options={dropdownList}
                           getOptionLabel={option => option?.label}
                           value={selectedDoctor}
                           isOptionEqualToValue={(option, value) => option.value === value?.value}
                           classes={{
                              clearIndicator: classes.clearIndicator,
                              popupIndicator: classes.popupIndicator,
                           }}
                           sx={{ width: 320, paddingBottom: "8px" }}
                           // disableClearable
                           onChange={(event, value) => { setSelectedDoctor(value); setAutoOpen(false); }}
                           renderInput={params =>
                              <TextField
                                 sx={{
                                    input: {
                                       color: "#aaaaaa",
                                       marginLeft: "10px",
                                       marginTop: "13px",
                                    },
                                 }}
                                 {...params}
                                 // autoFocus
                                 onClick={() => setAutoOpen(true)}
                                 onChange={(e) => {
                                    if (e.target.value) {
                                       if (!autoOpen) setAutoOpen(true);
                                    } else if (autoOpen) setAutoOpen(false);
                                 }}
                                 variant={"standard"}
                                 InputProps={{ disableUnderline : true, autoFocus: true, ...params.InputProps }}
                                 InputLabelProps={{ shrink:false }}
                                 placeholder={i18n("label.verifyDialog.placeholder.assignee")}
                              />}
                        />
                     </ClickAwayListener>
                  </ThemeProvider>
               </Assignee>
            </div>
            <StyledBtnWrappers>
               <OkBtn onClick={doVerify} text={i18n("button.verify")}/>
               <CancelBtn onClick={handleClose} text={i18n("button.cancel")}/>
            </StyledBtnWrappers>
         </DialogStyle>
      </Dialog>
   );
};

export default VerifyDialog;
