import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { format, isValid, parse } from "date-fns";
import { ko } from "date-fns/locale";

/**
 * todo: 텍스트로 입력시 상황 해결 필요
 * @param value : Date 객체로 사용 필요 => material UI
 * @param onChange
 * @constructor
 */
export function CustomTimePicker({ value, onChange, valueFormat } : any): JSX.Element {
   const [formatValue, setFormatValue] = useState<Date>(useInitValue);
   const [open, setOpen] = useState(false);
   const locale = ko;

   useEffect(() => {
      setFormatValue(useInitValue);
   }, [value]);

   function useInitValue() {
      const newDate = parse(value, "HH:mm:ss", new Date());
      return newDate;
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
         <TimePicker
            showToolbar={true}
            toolbarTitle={"시간 선택"}
            openTo="hours"
            views={["hours", "minutes", "seconds"]}
            acceptRegex={/\d/gi}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
            disableMaskedInput={true}
            inputFormat={"a hh:mm:ss"}
            value={formatValue}
            onChange={(newValue) => {
               if (newValue !== null && isValid(newValue)) {
                  setFormatValue(newValue);
                  const result = format(newValue, valueFormat);
                  onChange(result);
               }
            }}

            renderInput={params =>
               <TextField
                  {...params}
                  autoComplete={"off"}
                  size={"large"}
                  variant={"standard"}
                  InputProps={{ ...params.InputProps, sx:{ width: "95%" } }}
                  onClick={e => setOpen(true)}
               />}
         />
      </LocalizationProvider>
   );
}
