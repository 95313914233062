import React, { useEffect, useState } from "react";
import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import i18n from "../../../utils/i18n-utils";
import { spiltImage } from "./hooks/useSplitExam";
import { FormStyles } from "./styles/FormStyle";
import { ImageListStyles } from "./styles/ImageListStyle";
import commonuUtils from "../../../../public/resource/js/utils/common";

interface CustomImageListProps {
  title: string,
  data: Array<spiltImage>,
  clickItem: Function,
  selectedIndex: number,
}

export default function ExamImageList(props: CustomImageListProps) {
   const { data } = props;
   const [isSelected, setIsSelected] = useState(false);

   const clickItem = (idx:number) => {
      data[idx].selected = !data[idx].selected;
      setIsSelected(!isSelected);
      const result = data?.filter((value:any) => value.selected)
         .map(value => value.contentID);
      props.clickItem("", result);
   };

   const formStyles = FormStyles();
   const imageListStyles = ImageListStyles();

   return (
      <>
         <div className={formStyles.titleContainer}>
            <div className={formStyles.titleContent}>{props.title}</div>
         </div>
         <div className={imageListStyles.examImageContainer}>
            <ImageList
               className={imageListStyles.listContainer}>
               { props.selectedIndex !== -1
              && <div className={imageListStyles.absoluteText}>{i18n("label.srs")}: {props.selectedIndex}</div>
               }
               {props.data!.map((item: any) => (
                  <ImageListItem
                     key={item.idx}
                     className={imageListStyles.listItem}
                     onClick={() => clickItem(item.idx)}>
                     <img
                        className={item.selected ? formStyles.selected : ""}
                        src={item.image}
                        alt={`${item.idx}`}
                        style={{ height: "100px", width: "auto" }}
                        loading="lazy" />
                     <ImageListItemBar
                        title={`${item.idx}`}
                        position="below" />
                  </ImageListItem>
               ))}
            </ImageList>
         </div>
      </>
   );
}
