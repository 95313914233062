import React, { useEffect, useRef, useState } from "react";
import { IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import { spiltSeries } from "../SplitExam/hooks/useSplitExam";
import { FormStyles } from "../SplitExam/styles/FormStyle";
import { ImageListStyles } from "../SplitExam/styles/ImageListStyle";
import { modifySeries } from "./hooks/useModifyExam";

interface CustomImageListProps {
   title: string,
   data: Array<modifySeries>,
   selectedIndex: number,
   clickItem(idx: number, select: boolean): void,
   deleteItem(idx: number): void,
   modifyItem(series: Array<modifySeries>): void,
}

export default function ModifySeriesList(props: CustomImageListProps) {
   const { data } = props;
   const [isSelected, setIsSelected] = useState(false);
   const [dragEnterIdx, setDragEnterIdx] = useState(-1);

   const clickItem = (e: any, idx:number) => {
      data[idx].selected = !data[idx].selected;
      setIsSelected(!isSelected);
      if (e.shiftKey) {
         const selected = data.filter(value => value.selected);
         const startIdx = selected.reduce((prev, current) => ((prev.idx < current.idx) ? prev : current)).idx;
         const endIdx = selected.reduce((prev, current) => ((prev.idx > current.idx) ? prev : current)).idx;
         const items = data.map((value, index) => {
            const temp = value;
            if (startIdx <= index && endIdx >= index) temp.selected = true;
            else temp.selected = false;
            return temp;
         });
         props.clickItem(idx, data[endIdx].selected);
      } else {
         data.forEach((obj, index) => {
            if (idx === index) {
               data[idx].selected = true;
            } else {
               data[index].selected = false;
            }
         });
         props.clickItem(idx, data[idx].selected);
      }
   };

   const deleteItem = (event:React.MouseEvent<SVGSVGElement, MouseEvent>, idx:number) => {
      event.stopPropagation();
      props.deleteItem(idx);
   };

   function dragStart(e: any, idx: number) {
      if (!data[idx].selected) {
         data[idx].selected = !data[idx].selected;
         props.clickItem(idx, data[idx].selected);
      }
   }

   function dragOver(e: any, idx: number) {
      e.preventDefault();
      if (!data[idx].selected) {
         if (idx > props.selectedIndex) {
            e.target.style.borderRight = "2px solid rgb(0,135,203)";
         } else {
            e.target.style.borderLeft = "2px solid rgb(0,135,203)";
         }
      }
   }

   function dragleave(e: any, idx: number) {
      setBoaderNone(e, idx);
   }

   function dragEnd(e: any, idx: number) {
      const selected = data.filter(value => value.selected);
      const selectedList = selected.flatMap(data => data.idx);
      if (!selectedList.includes(dragEnterIdx)) {
         const startIdx = selected.reduce((prev, current) => ((prev.idx < current.idx) ? prev : current)).idx;
         const nIndex = getDraggedNewIndex(startIdx, dragEnterIdx, selectedList, data.length);
         props.modifyItem(nIndex);
      }
   }

   function onDrop(e: any, idx: number) {
      setBoaderNone(e, idx);
   }

   function setBoaderNone(e: any, idx: number) {
      e.target.style.borderStyle = "";
   }

   function getDraggedNewIndex(startIndex:number, selectedIndex:number, selectedIndexList: Array<any>, length: number): Array<modifySeries> {
      const nIndex = [];
      for (let i = 0; i < length; i++) {
         if (selectedIndex === i) {
            if (startIndex > selectedIndex) {
               for (let j = 0; j < selectedIndexList.length; j++) {
                  data[selectedIndexList[j]].idx = nIndex.length;
                  nIndex.push(data[selectedIndexList[j]]);
               }
               data[i].idx = nIndex.length;
               nIndex.push(data[i]);
            } else {
               data[i].idx = nIndex.length;
               nIndex.push(data[i]);
               for (let j = 0; j < selectedIndexList.length; j++) {
                  data[selectedIndexList[j]].idx = nIndex.length;
                  nIndex.push(data[selectedIndexList[j]]);
               }
            }
         } else if (!selectedIndexList.includes(i)) {
            data[i].idx = nIndex.length;
            nIndex.push(data[i]);
         }
      }
      return nIndex;
   }

   // Drag 후 Drop 하는 태그에서 발생하는 이벤트
   function dragEnter(e: any, idx: number) {
      setDragEnterIdx(idx);
   }

   function setTitle(description: string, imageLength: string) {
      if (description) {
         return `${description}(${imageLength})`;
      }
      return `(${imageLength})`;
   }

   const formStyles = FormStyles();
   const imageListStyles = ImageListStyles();
   return (
      data
     && <>
        <div className={formStyles.titleContainer}>
           <div className={formStyles.titleContent}>{props.title}</div>
        </div>
        <div className={imageListStyles.examSeriesContainer}>
           <ImageList
              className={imageListStyles.listContainer}>
              {data.map((item: any) => (
                 <ImageListItem key={item.idx}
                    className={imageListStyles.listItem}
                    onClick={e => clickItem(e, item.idx)}
                 >
                    <img
                       className={item.selected ? formStyles.selected : formStyles.notSelected}
                       onDragStart={(e) => { dragStart(e, item.idx); }}
                       onDragOver={(e) => { dragOver(e, item.idx); }}
                       onDragLeave={(e) => { dragleave(e, item.idx); }}
                       onDragEnd={(e) => { dragEnd(e, item.idx); }}
                       onDragEnter={(e) => { dragEnter(e, item.idx); }}
                       onDrop={(e) => { onDrop(e, item.idx); }}
                       src={item.displayImage}
                       alt={`${item.idx}(/${item.imageLength})`}
                       loading="lazy"/>
                    {item.selected
                     && <Delete
                        className={imageListStyles.deleteIcon}
                        onClick={e => deleteItem(e, item.idx)}/>}
                    <ImageListItemBar
                       title={setTitle(item.seriesDescription, item.imageLength)}
                       position="below" />
                 </ImageListItem>
              ))}
           </ImageList>
        </div>
     </>
   );
}
