import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormControl } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GetContextMenuItemsParams, GridOptions, MenuItemDef } from "ag-grid-community";
import i18n from "../../../../utils/i18n-utils";
import {
   StyledContainer, StyledContextText, StyledLabel, StyledNumberTypeField, StyledShortcutLabel,
   StyledWWL, StyledWwlButtonWrapper, StyledWWLContext,
   StyledWWLContextWrapper, StyledWwlList, StyledWwlListContent,
} from "./styles/FilmboxWwlPresetStyles";
import { StyledKindButton } from "../../styles/EmotionStyles";
import { CustomTooltip } from "../../../../../public/resource/js/customTooltip";
import { StyledMenuItem, StyledSelect } from "./styles/SettomgStyles";
import { useToastDispatch } from "../../../../context/ToastContext";
import UserConfigAPI from "../../../../api/UserConfigAPI";
import TextTypeField from "../../../input/textfield/TextTypeField";
import OkBtn from "../../../button/OkBtn";
import CancelBtn from "../../../button/CancelBtn";

export interface WWL {
   id?: string;
   userId?: string;
   preset?: Array<WWLPreset>;
}

export interface WWLPreset {
   idx?: number;
   title?: string;
   modality?: string;
   level?: string;
   width?: string;
   keyCode?: string;
   key?: string;
   shortcut?: string;
}

export default function FilmboxWwlPreset() {
   const [rowData, setRowData] = useState<Array<any>>([]);
   const [modalityList, setModalityList] = useState<Array<any>>([]);
   const [selectedModality, setSelectedModality] = useState<string>("");
   const [state, setState] = useState<WWLPreset>({});
   const [wwl, setWwl] = useState<WWL>({});
   const toastDispatch = useToastDispatch();

   /*
   * https://www.ag-grid.com/react-data-grid/context-menu/
   * */
   const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) : (string | MenuItemDef)[] => {
      if (!params.node) return [];
      params.node.setSelected(true, true);
      const result = [
         "separator",
         {
            name: "delete",
            action() {
               UserConfigAPI.deletePreset(params.node.data).then(result => setWwl(result));
            },
         },
         "separator",
      ];
      return result;
   }, []);

   const columnDefs: ColDef[] = useMemo(() => [
      {
         headerName: i18n("label.idx"),
         field: "idx",
         width: 50,
         // rowDrag: true,
         cellRenderer: (param: any) =>
         // const span = param.eGridCell.querySelector("div .ag-row-drag");
         // const span = param.eGridCell.querySelector("div .ag-icon-grip");
         // span.classList.remove("ag-icon-grip");
         // span.classList.add("ag-icon-grip-custom");
            param.value,
         // suppressSorting: true
         sortable: false,
      },
      { headerName: i18n("label.title"), field: "title", width: 100 },
      { headerName: i18n("label.modality"), field: "modality", width: 95 },
      { headerName: i18n("label.level"), field: "level", width: 80 },
      { headerName: i18n("label.width"), field: "width", width: 80 },
      { headerName: i18n("menu.shortcut"), field: "key", width: 95 },
   ], []);

   const [gridOptions, setGridOptions] = useState<GridOptions>({
      defaultColDef: {
         sortable: true,
         suppressMenu: true, // 메뉴 막기
      },
      rowDragManaged: true,
      components: {
         customTooltip: CustomTooltip,
      },
      tooltipShowDelay: 0,
      animateRows: true,
      overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">${i18n("label.noRecordsFound")}</span>`,
      rowSelection: "single",
      onRowSelected: (e) => {
         // setSelectedRow(e.node);
      },
      onRowDoubleClicked: (e) => {
         setState({
            ...e.data,
            key: e.data.key ? e.data.key.split("+")[1] : "",
         });
      },
   });

   useEffect(() => {
      UserConfigAPI.getUserWWLPreset().then((result) => {
         setWwl(result);
         getAllModality();
      });
   }, []);

   useEffect(() => {
      if (wwl) setWWLList();
   }, [wwl]);

   useEffect(() => {
      if (wwl) setWWLList();
   }, [selectedModality]);

   function openToast(msg: string, isErr: boolean) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   }

   function doClearWWL() {
      setState({
         idx: undefined,
         title: "",
         level: "",
         width: "",
         key: "",
      });
   }

   function getAllModality() {
      UserConfigAPI.getColFilter("modality").then((result) => {
         const [firstModality] = result;
         setModalityList(result);
         setSelectedModality(firstModality);
      });
   }

   function setWWLList() {
      const presetList: Array<WWLPreset> = [];
      if (wwl.preset) {
         for (let i = 0; i < wwl.preset.length; i++) {
            const preset = wwl.preset[i];
            if (preset.modality === "ALL" || preset.modality === selectedModality) {
               const obj = {
                  ...preset,
                  keyCode: preset.keyCode ? `Alt+${String.fromCharCode(+preset.keyCode)}` : "",
               };
               presetList.push(obj);
            }
         }
      }

      setWWLPreset(presetList);
   }

   // function setWWLPreset(userWWLPreset: any, modality? : string) {
   function setWWLPreset(userWWLPreset: Array<WWLPreset>) {
      const row: Array<any> = [];
      if (userWWLPreset) {
         userWWLPreset.forEach((obj, i) => {
            const preset = {
               ...obj,
               idx: i + 1,
               rIdx: obj.idx,
               key: obj.key ? `Alt+${obj.key}` : "",
            };
            row.push(preset);
         });
      }

      setRowData(row);
   }

   function doOkWWL() {
      const row: WWLPreset = {};
      const { idx, title, level, width, key } = state;
      if (title) {
         if (level && width) {
            row.title = title;
            row.level = level;
            row.width = width;
            if (selectedModality && selectedModality.length > 0) row.modality = selectedModality;
            row.keyCode = `${key?.charCodeAt(0)}`;
            row.key = key;
            if (idx) {
               row.idx = idx;
               UserConfigAPI.updatePreset(row).then(result => setWwl(result));
            }
            else {
               UserConfigAPI.createPreset(row).then(result => setWwl(result));
            }

            doClearWWL();
            const msg = i18n("msg.wwlPreset.success");
            openToast(msg, false);
         } else {
            const msg = i18n("msg.wwlPreset.fail");
            openToast(msg, true);
         }
      } else {
         const msg = i18n("msg.wwlPreset.fail");
         openToast(msg, true);
      }
   }

   function handleSelectedModality(e: any) {
      const { value } = e.target;
      setSelectedModality(value);
   }

   function handleStateChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: string) {
      const { value } = e.target;
      setState(prevState => ({
         ...prevState,
         [field]: value,
      }));
   }

   return (
      <>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.min.css"/>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css"/>
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css"/>
         <StyledContainer>
            <StyledWWL>
               <StyledContainer>
                  {/* <StyledTitleWrapper> */}
                  {/*    <StyledTitleLabel>{i18n("menu.wwlPreset")}</StyledTitleLabel> */}
                  {/* </StyledTitleWrapper> */}
                  <StyledWWLContextWrapper>
                     <StyledWWLContext>
                        <StyledLabel>{i18n("label.windowLevel")}</StyledLabel>
                        <StyledNumberTypeField
                           shrink
                           variant="standard"
                           value={state.level ?? ""}
                           onChange={e => handleStateChange(e, "level")}
                        />
                        <StyledLabel style={{ marginLeft: "35px" }}>{i18n("label.windowWidth")}</StyledLabel>
                        <StyledNumberTypeField
                           shrink
                           variant="standard"
                           value={state.width ?? ""}
                           onChange={e => handleStateChange(e, "width")}
                        />
                     </StyledWWLContext>

                     <StyledWWLContext>
                        <StyledLabel style={{ marginTop: "15px" }}>{i18n("label.title")}</StyledLabel>
                        <TextTypeField
                           sx={{ width: 100 }}
                           style={{ marginTop: "15px" }}
                           inputProps={{ style: { fontSize: "16px", textAlign: "center", alignSelf: "center" } }}
                           shrink
                           variant="standard"
                           value={state.title ?? ""}
                           onChange={e => handleStateChange(e, "title")}
                        />
                        <StyledLabel style={{ marginTop: "15px", marginLeft: "35px" }}>{i18n("label.modality")}</StyledLabel>
                        <FormControl
                           style={{ width: "100px", textAlign: "center" }}
                           variant="standard"
                        >
                           <StyledSelect
                              style={{ width: "100px" }}
                              id="select-view-standard"
                              value={selectedModality}
                              onChange={handleSelectedModality}
                           >
                              { modalityList && modalityList.map((modality : any, index) => (
                                 <StyledMenuItem key={index} value={modality}>{modality}</StyledMenuItem>
                              ))}
                           </StyledSelect>
                        </FormControl>

                     </StyledWWLContext>
                     <StyledWWLContext>
                        <StyledLabel>{i18n("label.shortcut")}</StyledLabel>
                        <StyledShortcutLabel>Alt + </StyledShortcutLabel>
                        <StyledNumberTypeField
                           shrink
                           variant="standard"
                           value={state.key ?? ""}
                           onChange={e => handleStateChange(e, "key")}
                        />
                     </StyledWWLContext>
                     <StyledContextText>(* {i18n("label.shortcutOnlyNumber")})</StyledContextText>
                  </StyledWWLContextWrapper>
                  <StyledWwlList>
                     <StyledWwlListContent className="ag-theme-balham-dark">
                        <AgGridReact
                           rowData={rowData}
                           columnDefs={columnDefs}
                           gridOptions={gridOptions}
                           getContextMenuItems={getContextMenuItems}
                        />
                     </StyledWwlListContent>
                  </StyledWwlList>
                  <StyledWwlButtonWrapper>
                     <OkBtn onClick={doOkWWL} text={"Save"}/>
                     <CancelBtn onClick={doClearWWL} text={"Clear"} />
                     {/* <StyledKindButton kind={"ok"} onClick={doOkWWL}>Save</StyledKindButton> */}
                     {/* <StyledKindButton onClick={doClearWWL}>Clear</StyledKindButton> */}
                  </StyledWwlButtonWrapper>
               </StyledContainer>
            </StyledWWL>
         </StyledContainer>
      </>
   );
}
