import styled from "@emotion/styled";

/* .title */
export const Title = styled.div`
   background-color: #1d1e25;
   height: 40px;
   width: 100%;
   color: #fff;
   border-bottom: 1px solid #4c5667;
`;

/* .contents */
export const ContentsWrapper = styled.div`
   height: 100%;
   padding: 30px;
   background: #252934;

`;

/* .content-container */
export const Content = styled.div`
   border: 1px solid #4c5667;
   margin: 5px;
   padding: 15px;
`;

/* .input-container */
export const InputWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   color: #aaa;
   margin: 5px;
`;
