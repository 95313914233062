import { makeStyles } from "@mui/styles";

export const DialogStyles = makeStyles(() => ({
   container: {
      display: "flex",
      flexDirection: "row",
      m: "auto",
      backgroundColor: "#1d1e25",
      height: "40px",
      width: "100%",
      color: "#fff",
      borderBottom: "1px solid #4c5667",
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "hidden",
   },
   dialogTitle: {
      margin: 0,
      float: "left",
   },
   closeButton: {
      color: "#fff",
   },
   dialogContent: {
      padding: "30px !important",
   },
   rowDirection: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
   },
   rowDirectionWrap: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
   },
   rowDirectionWrapContainer: {
      display: "flex",
      flexDirection: "column",
      // flexWrap: "wrap",
      color: "white",
      justifyContent: "space-between",
      width: "75%",
   },
   formBoxInnerSmall: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "white",
      height: "100%",
   },
   formBoxInnerMedium: {
      flex: "2",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "white",
   },
   dialogBottomSpace: {
      display: "flex",
      justifyContent: "space-between !important",
      color: "#fff",
   },
   dialogBottomCenter: {
      marginTop: "10px",
      display: "flex",
      justifyContent: "center !important",
      color: "#fff",
   },
   buttonGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *:not(:last-child)": {
         marginRight: "10px",
      },
   },
   grayButton: {
      background: "#4c5667 !important",
   },
}));
