import styled from "@emotion/styled";
import { Checkbox, RadioGroup } from "@mui/material";
import HealthHubIcon from "../../global/HealthHubIcon";

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
`;

export const StyledTitleWrapper = styled.div`
    background-color: #252934;
    height: 40px;
    width: 100%;
    color: #fff;
    border-bottom: 1px solid #4c5667;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledTitle = styled.p`
    margin-left: 15px;
`;

export const StyledContentsWrapper = styled.div`
    width : calc(100% - 30px);
    height: 235px;
    color: #aaa;
    padding-left: 15px;
    padding-right: 15px;
`;

export const StyledFooter = styled.div`
    text-align: center;
    padding: 25px;
`;

export const StyledBtnWrappers = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const StyledCheckBox = styled(Checkbox)`
    color: #aaaaaa;

    &.Mui-checked {
        color: #0087cb !important;
    }
`;

export const StyledColumnRadioGroup = styled(RadioGroup)`
    flex-direction: column !important;
    display: flex;
    margin-left: 50px;
`;

export const StyledHealthhubIcon = styled(HealthHubIcon)`
    cursor: pointer;
`;
