import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

/*
* @Material
* */
interface StyledBoxProps {
    width?: number;
    height?: number;
}

export const StyledBox = styled(Box)<StyledBoxProps>`
    width: ${props => props.width ?? "auto"};
    height: ${props => props.height ?? "auto"};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #2d333f;
    outline: none;
`;

/*
* @Icon
* */
export const StyledWarnIcon = styled(WarningIcon)`
    padding: 7px 0;
    text-align: center;
    color: #aaaaaa;
    width: 30px;
`;

export const StyledCloseIconButton = styled(CloseIcon)`
    float: right;
    color: #aaaaaa;
    cursor: pointer;
    margin-right: 10px;
    margin: 0;
    padding: 15px;
`;

export const StyledInfoWrapper = styled.div`
    position: relative;
    .view-info {
        width: 100%;
        padding: 5px;
        position: fixed;
        display: none;
        opacity: 0;
        background-color: #2d3436;
        border: 1px solid #424242;
        color: #aaaaaa;
        font-size: 16px;
        max-width: 500px;
        min-width: 500px;
    }
    &:hover {
        .view-info {
            z-index: 99999 !important;
            display: block;
            opacity: 0.8;
        }
    }
`;

export const StyledInfoIcon = styled(InfoIcon)`
    padding: 7px 0;
    text-align: center;
    color: #aaaaaa;
    width: 30px;
    cursor: pointer;
`;

/*
* @Button
* */
interface StyledFocusButtonProps {
    focus: number
}

export const StyledFocusButton = styled(Button)<StyledFocusButtonProps>`
    background-color: ${({ focus }) => (focus ? "#0087cb" : "#4c5667")};
    color: #ccc;
    min-width: 145px;
    height: 35px;
    margin-left: 10px;
    border: 0;
    border-radius: 2px;
    font-size: 10pt;
    cursor: pointer;

    &:hover {
        background: #ccc;
        color: ${({ focus }) => (focus ? "#0087cb" : "#2d333f")};
    }
`;

interface StyledKindButtonProps {
    width?: number
    height?: number
    kind?: string
}

export const StyledKindButton = styled(Button)<StyledKindButtonProps>`
    width: ${({ width }) => (width ? `${width}px` : "122px")};
    height: ${({ height }) => (height ? `${height}px` : "35px")};
    border: 0;
    font-size: 12pt;
    color: #ffffff;
    background: ${({ kind }) => (kind === "ok" ? "#0087cb" : "#4c5667")};
    cursor: pointer;
    border-radius: 2px;
    margin-left: 10px;

    &:hover {
        background: #ccc;
        color: ${({ kind }) => (kind === "ok" ? "#0087cb" : "#4c5667")};
    }
`;
