import styled from "@emotion/styled";

/* .setting-report-option */
export const StyledSettingReportWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
`;

export const StyledLabel = styled.p`
    padding: 0 10px;
    margin: 5px 0 0 5px;
    font-size: 10pt;
    color: #aaaaaa;
`;

/* .setting-popup-options */
export const StyledSettingPopupWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
/* .setting-popup-option */
export const StyledSettingPopup = styled.div`
    display: flex;
    flex-direction: column;
`;

/* .check-box-wrap */
export const StyledCheckBoxWrapper = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
`;

/* .setting-report-option-signature */
export const StyledSettingSignatureWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

/* .setting-report-option-radio */
export const StyledSettingSignature = styled.div`
    display: block;
`;

export const StyledSignatureButtonWrapper = styled.div`
    display: flex !important;
    flex-direction: row !important;
`;

/* .switchOn */
export const StyledSwitchOn = styled.div`
    padding-top: 15px;
    color: #aaaaaa;
`;
