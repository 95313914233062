import { Box, DialogContent, styled, Typography } from "@mui/material";

export const ContentContainer = styled(DialogContent)({
   display: "flex",
   height: "100%",
   width: "100%",
   padding: 0,
   margin: 0,
});

export const LeftContainer = styled(Box)({
   height: "100%",
   display: "flex",
   flexDirection: "column",
   flexBasis: "300px",
});

export const LeftTitleContainer = styled(Box)({
   height: "50px",
   width: "100%",
   backgroundColor: "#0087cb",
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
});

export const FacilityListContainer = styled(Box)({
   backgroundColor: "#252934",
   height: "100%",
   overflow: "auto",
});

export const RightContainer = styled(Box)({
   height: "100%",
   display: "flex",
   flexDirection: "column",
   flexBasis: "calc(100% - 300px)",
});

export const RightTitleContainer = styled(Box)({
   height: "50px",
   width: "100%",
   display: "flex",
   alignItems: "center",
   backgroundColor: "#1d1e25",
   borderBottom: "1px solid #424242",
});

export const ReferralFormsContainer = styled(Box)({
   height: "calc(100% - 100px)",
   width: "100%",
   display: "flex",
   alignItems: "center",
   justifyContent: "left",
   flexDirection: "column",
   backgroundColor: "#252934",
});

export const ReferralListContainer = styled(Box)({
   backgroundColor: "#252934",
   width: "100%",
   height: "100%",
   overflow: "auto",
});

export const TitleTypography = styled(Typography)({
   marginLeft: "15px",
   fontSize: "12px",
   whiteSpace: "nowrap",
   overflow: "hidden",
   textOverflow: "ellipsis",
});

export const DetailItemBox = styled(Box)({
   width: "100%",
   backgroundColor: "#1d1e25",
   borderRadius: "5px",
   padding: "2px 10px",
   border: "1px solid #0087cb",
   boxShadow: "5px 7px 7px -3px #000",
});

export const ReferralBox = styled(Box)({
   display: "flex",
   justifyContent: "space-between",
});

export const RightFooterContainer = styled(Box)({
   height: "50px",
   width: "100%",
   display: "flex",
   alignItems: "center",
   justifyContent: "right",
   backgroundColor: "#2d333e",
});
