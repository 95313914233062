import React, { useEffect, useState } from "react";
import {
   Box,
   Dialog,
   DialogContent,
   DialogTitle,
   IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import { TechlistActionType } from "../../../redux/reducers/techlist";
import { is_esc } from "../../../dialog/common-keyboard-handler";
import { CommonActionType } from "../../../redux/reducers/common";
import i18n from "../../../utils/i18n-utils";
import ExamImageList from "./ExamImageList";
import ExamSeriesList from "./ExamSeriesList";
import useNewExam, { newImage, newSeries } from "./hooks/useNewExam";
import { useToastDispatch } from "../../../context/ToastContext";
import { DialogStyles } from "../styles/DialogStyles";
import useDialogOpen from "../../../hooks/useDialogOpen";
import Confirm, { ConfirmProps } from "../Confirm";
import OkBtn from "../../button/OkBtn";
import CancelBtn from "../../button/CancelBtn";
import EditForm from "../SplitExam/EditForm";

export default function NewExam(props: any) {
   const is = "new-exam-dialog";
   const [open, setOpen] = useState(false);
   const { state, examDispatch } = useNewExam();
   const [selectedRow, setSelectedRow] = useState<any>(null);
   const [confirmDialog, setConfirmDialog] = useState<any>(null);
   const toastAction = useToastDispatch();
   const dispatch = useDispatch();
   useDialogOpen();

   useEffect(() => {
      doOpen(props);
   }, [props]);

   function setRowData(row:any) {
      if (!row) return;

      const {
         patientID,
         patientName,
         patientBirthDate,
         patientSex,
         accessionNumber,
         modality,
         bodyPartExamined,
         studyDescription,
         requestPhysician,
      } = row;

      const patientInfo = [];
      patientInfo.push({ key:"patientId", label:i18n("label.id"), value: patientID });
      patientInfo.push({ key:"patientName", label:i18n("label.name"), value: patientName });

      let tempBirthDate = patientBirthDate;
      if (tempBirthDate && tempBirthDate.length >= 8) {
         const birthDate = `${tempBirthDate.substring(0, 4)}-${tempBirthDate.substring(4, 6)}-${tempBirthDate.substring(6, 8)}`;
         tempBirthDate = birthDate;
      }

      patientInfo.push({ key:"patientBirthDate", label:i18n("label.birthDate"), value: tempBirthDate, type: "date" });
      patientInfo.push({ key:"patientSex", label:i18n("label.gender"), value: patientSex });
      examDispatch({ type: "SET_PATIENT_INFO", data: patientInfo });

      const studyInfo = [];
      studyInfo.push({ key:"accessionNumber", label:i18n("label.accessionNo"), value: accessionNumber });
      studyInfo.push({ key:"modality", label:i18n("label.modality"), value: modality });
      studyInfo.push({ key:"bodypart", label:i18n("label.bodypart"), value: bodyPartExamined });
      studyInfo.push({ key:"studyDescription", label:i18n("label.studyDescription"), value: studyDescription });
      studyInfo.push({ key:"referringPhysiciansName", label:i18n("label.referringPhysiciansName"), value: requestPhysician });
      examDispatch({ type: "SET_STUDY_INFO", data: studyInfo });
   }

   function addSeriesFile(e:any) {
      const { target } = e;
      // console.log("seriesUpload===> ", target, target.files);
      if (!target?.files) return;

      for (let i = 0; i < target.files.length; i++) {
         // eslint-disable-next-line no-loop-func
         getBase64(target.files[i]).then((result) => {
            const file:any = result;
            setSeriesList(file);
         });
      }
   }

   function addImageFile(e:any) {
      const { target } = e;
      // console.log("imageUpload===> ", target, target.files);
      if (!target?.files) return;
      const { series, selectedSeriesIdx, selectedSeriesNumber } = state || [];
      if (!series.length) {
         toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.newExamDialog.newExamWarning"), isErr: true });
         return;
      }

      for (let i = 0; i < target.files.length; i++) {
         // eslint-disable-next-line no-loop-func
         getBase64(target.files[i]).then((result) => {
            const file:any = result;
            const newSeries:any = series;
            const newImage = {
               idx: newSeries[selectedSeriesIdx].imageList.length,
               type: "jpeg",
               image: file.image,
               name: file.name,
               displayOrder: newSeries[selectedSeriesIdx].imageList.length,
               seriesIdx: newSeries[selectedSeriesIdx].idx,
            };
            newSeries[selectedSeriesIdx].imageList.push(newImage);

            examDispatch({ type: "SET_NEW_EXAM_LIST",
               series: newSeries,
               images: newSeries[selectedSeriesIdx].imageList,
               selectedSeriesIdx,
               selectedSeriesNumber });
         });
      }
   }

   function getBase64(file:any) {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            const tempObj:any = {};
            tempObj.image = reader.result;
            tempObj.name = file.name;
            resolve(tempObj);
         };
         reader.onerror = (error) => {
            reject(error);
         };
      });
   }

   function setSeriesList(file:any) {
      // series Instance UID
      // study Instance UID -> dcm4che를 이용하여 RIS에서 생성
      // RIS 의 dcm4che 는 ver2 이기 때문에, Ray의 dcm4che 에서도 같은 방식인지 확인
      // dcm4che2는 prefix 값만 지정하고 나머지는 새로 생성하는 방식

      // new series && image
      if (!state.series || state.series.length === 0) {
         const newSeries: any = [];
         const seriesObj: any = {};
         seriesObj.idx = 0;
         seriesObj.seriesDescription = "";
         seriesObj.seriesNumber = 1;
         seriesObj.displayOrder = 1;
         seriesObj.seriesInstanceUID = "";
         seriesObj.displayImage = file.image;
         seriesObj.imageList = [{
            idx: 0,
            type: "jpeg",
            image: file.image,
            fileName: file.name,
            displayOrder: 0,
            seriesIdx: 0,
         }];
         newSeries.push(seriesObj);

         examDispatch({
            type: "SET_NEW_EXAM_LIST",
            series: newSeries,
            images: seriesObj.imageList,
            selectedSeriesIdx: 0,
            selectedSeriesNumber: 1,
         });
      } else {
         const { series } = state;
         const newSeries: any = series;
         const seriesObj: any = {};
         seriesObj.idx = newSeries.length;
         seriesObj.seriesDescription = "";
         seriesObj.seriesNumber = newSeries.length + 1;
         seriesObj.displayOrder = newSeries.length + 1;
         seriesObj.seriesInstanceUID = "";
         seriesObj.displayImage = file.image;
         seriesObj.imageList = [{
            idx: 0,
            type: "jpeg",
            image: file.image,
            fileName: file.name,
            displayOrder: 0,
            seriesIdx: newSeries.length,
         }];
         newSeries.push(seriesObj);

         examDispatch({
            type: "SET_NEW_EXAM_LIST",
            series: newSeries,
            images: newSeries[newSeries.length - 1].imageList,
            selectedSeriesIdx: newSeries.length - 1,
            selectedSeriesNumber: newSeries.length,
         });
      }
   }

   function setImageList(seriesIndex: number, series?: any) {
      if (!selectedRow) {
         console.warn(is, "selectedRow is null.");
         return;
      }
      if (seriesIndex === null || seriesIndex === undefined) return;

      const newSeries: any = series || state.series;

      // Set Images
      const images = newSeries[seriesIndex].imageList.map((img:any, idx:number) => {
         const image:any = img;
         if (img.type === "jpeg") {
            image.image = img.image;
            image.type = "jpeg";
         } else if (img.type === "dcm") {
            image.image = `${__CDN_URL__}/prefetch/download/image/${img.contentID}/large`;
            image.type = "url";
         }
         image.idx = idx;
         image.sopInstanceUID = img.sopInstanceUID;
         return image;
      });
      examDispatch({ type: "SET_NEW_EXAM_LIST", series: newSeries, images, selectedSeriesIdx: seriesIndex, selectedSeriesNumber: newSeries[seriesIndex].seriesNumber });
   }

   function resetImageList(seriesIndex: number) {
      const findIndex = state.series.findIndex((element: { idx: number; }) => element.idx === seriesIndex);
      const copyArray = [...state.series];
      if (findIndex !== -1) {
         copyArray[findIndex] = { ...copyArray[findIndex] };
      }
      examDispatch({ type: "SET_NEW_EXAM_LIST", images: [], series: copyArray, selectedSeriesIdx: -1, selectedSeriesNumber: -1 });
   }

   function clickSeries(idx: number, select: boolean) {
      // console.log(idx, select);
      if (select) {
         setImageList(idx);
      } else {
         resetImageList(idx);
      }
   }

   function clickImage(event: React.KeyboardEvent, images: any) {
      const findIndex = state.series.findIndex((element: { idx: number; }) => element.idx === state.selectedSeriesIdx);
      const copyArray = [...state.series];

      if (findIndex !== -1) {
         copyArray[findIndex] = { ...copyArray[findIndex], selected: true };
      }
      examDispatch({ type: "SET_NEW_EXAM_LIST", series: copyArray, selectedSeriesIdx: state.selectedSeriesIdx, selectedSeriesNumber: state.selectedSeriesNumber });
   }

   function deleteSeries() {
      const message: ConfirmProps["message"] = {
         contents: [i18n("msg.newExamDialog.removeSeries.0"), i18n("msg.newExamDialog.removeSeries.1")],
         title: i18n("label.removeSeries"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: () => removeSeries(),
      };
      setConfirmDialog({ message, open:true });
   }

   function deleteImage() {
      const message: ConfirmProps["message"] = {
         contents: [i18n("msg.newExamDialog.removeImage.0"), i18n("msg.newExamDialog.removeImage.1")],
         title: i18n("label.removeImage"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: () => removeImage(),
      };
      setConfirmDialog({ message, open:true });
   }

   function removeSeries() {
      const { series, selectedSeriesIdx, images } = state;
      const newSeries: any = series;
      newSeries.splice(selectedSeriesIdx, 1);
      newSeries.forEach((series:any, i:number) => {
         const temp = series;
         temp.idx = i;
         temp.seriesNumber = i + 1;
         temp.displayOrder = i + 1;
         temp.imageList.forEach((image:any) => {
            const temp = image;
            temp.seriesIdx = i;
         });
         return temp;
      });
      examDispatch({ type: "SET_NEW_EXAM_LIST", series: newSeries, images: [], selectedSeriesIdx: -1, selectedSeriesNumber: -1 });
   }

   function removeImage() {
      const newImages: any = state.images;
      const selectedIdx = newImages.findIndex((image:any) => image.selected);

      newImages.splice(selectedIdx, 1);
      if (newImages?.length) {
         newImages.forEach((image:any, i:number) => {
            const temp = image;
            temp.idx = i;
            return temp;
         });
         const newSeries: any = state.series;
         newSeries[state.selectedSeriesIdx].displayImage = newImages[0].image;
         newSeries[state.selectedSeriesIdx].imageList = newImages;

         examDispatch({ type: "SET_NEW_EXAM_LIST",
            series: newSeries,
            images: newImages,
            selectedSeriesIdx : state.selectedSeriesIdx,
            selectedSeriesNumber :state.selectedSeriesNumber });
      } else {
         examDispatch({ type: "SET_NEW_EXAM_LIST", series: [], images: [], selectedSeriesIdx: -1, selectedSeriesNumber: -1 });
      }
   }

   function modifySeries(newSeries: Array<newSeries>) {
      examDispatch({ type: "SET_NEW_EXAM_LIST", series: newSeries, images: [], selectedSeriesIdx: -1, selectedSeriesNumber: -1 });
   }

   function modifyImages(newImages: Array<newImage>) {
      const newSeries: any = state.series;
      newSeries[state.selectedSeriesIdx].imageList = newImages;
      newSeries[state.selectedSeriesIdx].displayImage = newImages[0].image;
      examDispatch({ type: "SET_NEW_EXAM_LIST",
         series: newSeries,
         images: newImages,
         selectedSeriesIdx: state.selectedSeriesIdx,
         selectedSeriesNumber: state.selectedSeriesNumber });
   }

   function setPatientInfo(data:any) {
      examDispatch({ type:"SET_PATIENT_INFO", data });
   }

   function setStudyInfo(data:any) {
      examDispatch({ type:"SET_STUDY_INFO", data });
   }

   function newExam() {
      const exam: any = {};
      exam.id = "new";
      exam.studyDate = moment().format("YYYYMMDD");
      exam.studyTime = moment().format("HHmmss");
      setParamsObj(exam, state.patientInfo);
      setParamsObj(exam, state.studyInfo);

      const newSeries = _.cloneDeep(state.series);
      newSeries.forEach((s:any) => {
         s.imageList.forEach((i:any) => {
            // eslint-disable-next-line no-param-reassign,prefer-destructuring
            i.image = i.image.split(",")[1];
         });
         exam.imageData = (exam.imageData || []).concat(s.imageList);
      });
      setModifiedSeries(exam);
      createExamByObjectId(exam).then((result) => {
         if (result) {
            dispatch({ type: TechlistActionType.REFRESH_AND_SELECT_ROWS, payload: [{ id: "NewExam" }] }); // TODO: 구조 변경 필요
            toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.techTabExam.new.success"), isErr: false });
            doClose();
         } else {
            toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.techTabExam.new.fail"), isErr: true });
         }
      });
   }

   function createExamByObjectId(exam:any) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${exam.id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(exam),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            }
            else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         }).catch(err => reject(err));
      });
   }

   function replaceAll(str: string, searchStr: string, replaceStr: string) {
      return str.split(searchStr).join(replaceStr);
   }

   function setParamsObj(requestObj: any, dataObj: any) {
      const paramObj = dataObj;
      Object.keys(paramObj).forEach((v) => {
         if (paramObj[v].type) {
            switch (paramObj[v].type) {
            case "date": {
               paramObj[v].value = replaceAll(paramObj[v].value, ".", "");
               paramObj[v].value = replaceAll(paramObj[v].value, "-", "");
               break;
            }
            case "time": {
               paramObj[v].value = replaceAll(paramObj[v].value, ":", "");
               break;
            }
            default: {
               break;
            }
            }
         }
         // eslint-disable-next-line no-param-reassign
         requestObj[paramObj[v].key] = paramObj[v].value;
      });
      return requestObj;
   }

   function setModifiedSeries(exam: any) {
      // 삭제된 시리즈를 서버에 보낼 파라미터에 저장
      // 시리즈의 삭제는 안의 이미지도 모두 삭제, 해당 케이스 processingStatus = temp, 케이스 생성
      const series = [];
      const tempExam = state.series;
      for (let i = 0; i < tempExam.length; i++) {
         const seriesObj: any = {};
         const image = [];

         for (let j = 0; j < tempExam[i].imageList.length; j++) {
            const imageObj: any = {};
            imageObj.sopInstanceUID = "";
            imageObj.contentID = "";
            imageObj.fileType = "jpeg";
            image.push(imageObj);
            seriesObj.seriesInstanceUID = "";
         }
         seriesObj.image = image;
         series.push(seriesObj);
      }
      // eslint-disable-next-line no-param-reassign
      exam.series = series;
   }

   function doOk() {
      if (state?.series.length) {
         const message: ConfirmProps["message"] = {
            contents: [i18n("msg.newExamDialog.newExam")],
            title: i18n("label.newExam"),
            ok: i18n("button.yes"),
            cancel: i18n("button.no"),
            onOk: () => newExam(),
         };
         setConfirmDialog({ message, open:true });
      } else {
         toastAction({ type: "SET_TOAST", open:true, msg:i18n("msg.newExamDialog.newExamWarning"), isErr: true });
      }
   }

   function doOpen(selectedRow: any) {
      setSelectedRow(selectedRow);
      setRowData(selectedRow);
      setOpen(true);
   }

   function doClose() {
      dialog_closed();
   }

   function doClear() {
      setConfirmDialog(null);
   }

   const handleClose = (event?: any, reason?: string) => {
      if (reason === "backdropClick") {
         return;
      }
      doClear();
      setOpen(false);
      dialog_closed();
   };

   function prevent_event_propagation(event: React.MouseEvent) {
      event.stopPropagation();
   }

   function close_if_esc(event: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(event)) {
         handleClose();
         event.stopPropagation();
      }
   }

   function dialog_closed() {
      doClear();
      // @ts-ignore
      window.dialog_closed();
      dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   const classes = DialogStyles();
   return (
      <>
         <Dialog
            open={open}
            onClose={handleClose}
            onClick={prevent_event_propagation}
            onKeyDown={close_if_esc}
            fullWidth
            maxWidth={"xl"}
            PaperProps={{ sx:{ height: "100%" } }}
         >
            <Box
               className={classes.container}>
               <DialogTitle className={classes.dialogTitle} component={"p"}>{i18n("label.newExam")}</DialogTitle>
               <IconButton sx={{ color: "#fff" }} onClick={handleClose}>
                  <Close/>
               </IconButton>
            </Box>
            <DialogContent >
               <Box className={classes.rowDirection}>
                  <Box className={classes.formBoxInnerSmall}>
                     <ExamSeriesList
                        title={i18n("label.series")}
                        data={state.series}
                        clickItem={clickSeries}
                        deleteItem={deleteSeries}
                        modifyItem={modifySeries}
                        addFile={addSeriesFile}
                        selectedIndex={state.selectedSeriesIdx}/>
                  </Box>
                  <Box className={classes.formBoxInnerMedium}>
                     <ExamImageList
                        title={i18n("label.image")}
                        data={state.images}
                        clickItem={clickImage}
                        deleteItem={deleteImage}
                        modifyItem={modifyImages}
                        addFile={addImageFile}
                        selectedSeriesNumber={state.selectedSeriesNumber}/>
                  </Box>
                  <Box className={classes.formBoxInnerSmall}>
                     <Box sx={{
                        height: "calc(100% - 60px)",
                        width: "100%",
                     }}>
                        <Box sx={{
                           lineHeight: "2em",
                           height: "40%",
                        }}>
                           <EditForm title={i18n("label.patientInfo")} data={state.patientInfo} changeValue={setPatientInfo}/>
                        </Box>
                        <Box sx={{
                           lineHeight: "2em",
                           height: "60%",
                        }}>
                           <EditForm title={i18n("label.studyInfo")} data={state.studyInfo} changeValue={setStudyInfo}/>
                        </Box>
                     </Box>
                     <Box className={classes.buttonGroup} sx={{
                        height: "60px",
                     }}>
                        <OkBtn onClick={doOk} text={i18n("button.save")}></OkBtn>
                        <CancelBtn onClick={handleClose} text={i18n("button.cancel")}/>
                     </Box>
                  </Box>
               </Box>
            </DialogContent>
         </Dialog>
         {
            confirmDialog
           && <Confirm
              message={confirmDialog.message}
              open={confirmDialog.open}
              inner={true}
              onClose={() => setConfirmDialog({ ...confirmDialog, open:false })}/>
         }
      </>
   );
}
