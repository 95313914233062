import React, { useEffect, useMemo, useState } from "react";
import { Box, Dialog, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CancelBtn from "../button/CancelBtn";
import CommonUtils from "../../../public/resource/js/utils/common";
import { useToastDispatch } from "../../context/ToastContext";
import { closeIfEsc, dialogClosed, preventEventPropagation } from "../../utils/fleader-common";
import OkBtn from "../button/OkBtn";
import {
   ContentContainer,
   DetailItemBox,
   FacilityListContainer,
   LeftContainer,
   LeftTitleContainer,
   ReferralBox,
   ReferralFormsContainer,
   ReferralListContainer,
   RightContainer,
   RightFooterContainer,
   RightTitleContainer,
   TitleTypography,
} from "./styles/ReferralFormDialogStyles";

type dataType = {
   id: string,
   studyDtime: string,
   studyDescription: string,
   patientName: string,
   patientID: string,
}

type facilityType = {
   address: string,
   apiKey : string,
   creator: string,
   facilityCode: string,
   hid: string,
   id: string,
   isRefer: boolean
   manager: string,
   members: Array<object>,
   name: string,
   phoneNo: string,
   representative: string,
   status: string
}

type ReferralFormType = {
   pk?: string,
   state?: string,
   userToken?: string,
   patientId?: string,
   cosign?: string,
   hospitalName?: string,
   patientName?: string,
   patientPhone?: string,
   viewHospital?: string,
   viewAdmin?: string,
   dynCatePk?: string,
   dynContent?: any,
   dynDate?: string,
   studyDate?: string,
   requestDate?: string,
   modifyDate?: string,
   patientBirth?: string,
   isInputHosp?: string,
   insertType?: string,
   rpRequestUseJinsKey?: any,
   studyDateTime?: string
}

export default function ReferralFormDialog(props: any) {
   // State
   const [open, setOpen] = useState(true);
   const [facilities, setFacilities] = useState<Array<facilityType>>([]);
   const [data, setData] = useState<dataType>(props.message);
   const [facility, setFacility] = useState<facilityType>();
   const [referralForms, setReferralForms] = useState<Array<object>>();
   const [selectedIndex, setSelectedIndex] = React.useState(-1);  // Facility 선택된 index
   const [referralEvent, setReferralEvent] = useState<"none" | "query" | "create" | "update">("none");

   // Context
   const toastDispatch = useToastDispatch();

   useEffect(() => {
      getFetchReferralFacilities(data.id)
         .then((r:any) => {
            setFacilities(r);
         });
   }, []);

   // Referral Form Data 관련하여 이벤트가 있을때 DB에서 조회하여 화면에 노출
   useEffect(() => {
      setReferralFormsList();
      setReferralEvent("none");
   }, [referralEvent]);

   const handleListItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      index: number,
      facility: facilityType,
   ) => {
      setSelectedIndex(index);
      setFacility(facility);
      setReferralEvent("query");
   };

   const handleClose = (event?: any, reason?: string) => {
      if (reason === "backdropClick") {
         return;
      }
      setOpen(false);
      dialogClosed();
   };

   function setReferralFormsList() {
      getFetchReferralFormsFacility()
         .then((r:any) => {
            const forms = r.map((v: any) => {
               const referralForm = v;
               referralForm.studyDate = CommonUtils.convertTimestampToDate(v.studyDate);
               return referralForm;
            });
            setReferralForms(forms);
         });
   }

   function doCreate() {
      if (facility?.id) {
         createFetchReferralFormsFacility()
            .then(() => {
               setReferralEvent("create");
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Referral Form created successfully.", isErr: false });
            }).catch(() => {
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Failed to create referral form.", isErr: true });
            });
      }
   }

   function handleDetailItemClick(form: ReferralFormType) {
      const { pk, patientId, patientName } = form;
      const referralForm = { pk, patientId, patientName };
      updateFetchReferralForms(referralForm)
         .then(() => {
            setReferralEvent("update");
            toastDispatch({ type: "SET_TOAST", open: true, msg: "Referral Form updated successfully.", isErr: false });
         }).catch(() => {
            toastDispatch({ type: "SET_TOAST", open: true, msg: "Failed to update referral form.", isErr: true });
         });
   }

   const getFacilityList = useMemo(() =>
      facilities.map((obj, idx) =>
         <List component="nav" key={obj.id} >
            <ListItemButton
               selected={selectedIndex === idx}
               onClick={event => handleListItemClick(event, idx, obj)}>
               <ListItemIcon>
                  <ListItemText primary={obj.name} />
               </ListItemIcon>
            </ListItemButton>
            <Divider />
         </List>), [facilities, selectedIndex]);

   const getReferralForms = useMemo(() =>
      referralForms?.map((obj: any, idx) =>
         <List component="nav" key={idx} >
            <ListItemButton
               onClick={() => handleDetailItemClick(obj)}>
               <ListItemIcon sx={{ width: "100%" }}>
                  <DetailItemBox>
                     <ReferralBox>
                        <Typography>Patient Name: {obj.patientName}</Typography>
                        <Typography>Patient ID: {obj.patientId}</Typography>
                     </ReferralBox>
                     <Typography>Study Date: {obj.studyDate}</Typography>
                     {obj.rpRequestUseJinsKey?.map((rpObj: any, idx: any) =>
                        <ReferralBox key={idx}>
                           <Typography>Study Description: {rpObj.alias}</Typography>
                           <Typography>Status: {rpObj.status}</Typography>
                        </ReferralBox>)}
                  </DetailItemBox>
               </ListItemIcon>
            </ListItemButton>
            <Divider />
         </List>), [referralForms]);

   function getFetchReferralFacilities(id: string) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${id}/referralfacilities`, {
            method: "GET",
            headers: headers(),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then(result => resolve(result));
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   function updateFetchReferralForms(referralForm: ReferralFormType) {
      const facilityId  = facility?.id;
      const { id } = data;
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${id}/referralforms/facilities/${facilityId}`, {
            method: "PATCH",
            headers: headers(),
            body: JSON.stringify(referralForm),
         }).then((response) => {
            if (response.ok && response.status === 200) response.json().then(result => resolve(result));
            else reject(new Error(`${response.status} ${response.statusText}`));
         });
      });
   }

   function createFetchReferralFormsFacility() {
      const facilityId  = facility?.id;
      const { id } = data;
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${id}/referralforms/facilities/${facilityId}`, {
            method: "POST",
            headers: headers(),
         }).then((response) => {
            if (response.ok && response.status === 200) response.json().then(result => resolve(result));
            else reject(new Error(`${response.status} ${response.statusText}`));
         });
      });
   }

   function getFetchReferralFormsFacility() {
      const facilityId = facility?.id;
      const { id } = data;
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${id}/referralforms/facilities/${facilityId}`, {
            method: "GET",
            headers: headers(),
         }).then((response) => {
            if (response.ok && response.status === 200) response.json().then(result => resolve(result));
            else reject(new Error(`${response.status} ${response.statusText}`));
         });
      });
   }

   function headers() {
      return {
         "Authorization": localStorage.getItem("jwt")!,
         "Content-Type": "application/json",
      };
   }

   return (
      <Dialog
         open={open}
         onClose={handleClose}
         onClick={preventEventPropagation}
         onKeyDown={closeIfEsc}
         fullWidth
         maxWidth={"md"}
         PaperProps={{ style:{ backgroundColor: "#2d333e",
            height: "100%",
            overflow: "hidden" } }}
      >
         <ContentContainer>
            {/* Left */}
            <LeftContainer>
               <LeftTitleContainer>
                  <Typography>Referral facilities</Typography>
               </LeftTitleContainer>
               <FacilityListContainer>{getFacilityList}</FacilityListContainer>
            </LeftContainer>
            {/* Right */}
            <RightContainer>
               <RightTitleContainer>
                  <Box>
                     <TitleTypography>Study Date: {data.studyDtime}</TitleTypography>
                     <TitleTypography>Study Description: {data.studyDescription}</TitleTypography>
                  </Box>
                  <Box>
                     <TitleTypography>Patient Name: {data.patientName}</TitleTypography>
                     <TitleTypography>Patient ID: {data.patientID}</TitleTypography>
                  </Box>
               </RightTitleContainer>
               <ReferralFormsContainer>
                  <ReferralListContainer>{getReferralForms}</ReferralListContainer>
                  <Box>
                     <OkBtn onClick={doCreate} text={"Create new Referral forms"}/>
                  </Box>
               </ReferralFormsContainer>
               <RightFooterContainer>
                  <CancelBtn onClick={handleClose} text={"Close"}/>
               </RightFooterContainer>
            </RightContainer>
         </ContentContainer>
      </Dialog>
   );
}
