import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, ImageList, ImageListItem, ImageListItemBar, TextField, Typography } from "@mui/material";
import { Delete, KeyboardArrowDown } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import i18n from "../../../utils/i18n-utils";
import commonuUtils from "../../../../public/resource/js/utils/common";
import { spiltImage } from "../SplitExam/hooks/useSplitExam";
import { FormStyles } from "../SplitExam/styles/FormStyle";
import { ImageListStyles } from "../SplitExam/styles/ImageListStyle";
import { modifyImage, modifySeries } from "./hooks/useModifyExam";

interface CustomImageListProps {
  title: string,
  data: Array<modifyImage>,
  clickItem: Function,
  selectedSeriesNumber: number,
  deleteItem(data:any): void,
  modifyItem(series: Array<spiltImage>): void,
}

export default function ModifyImageList(props: CustomImageListProps) {
   const { data } = props;
   const [selectedIdx, setSelectedIdx] = useState(-1);
   const [isSorted, setIsSorted] = useState(true); // true : 오름 차순, false : 내림 차순
   const [dragEnterIdx, setDragEnterIdx] = useState(-1);
   const imageListRef = useRef<any>();

   useEffect(() => {
      imageListRef.current.scrollTo(0, 0);
   }, [props.data]);

   const clickItem = (e:any, idx:number) => {
      data[idx].selected = !data[idx].selected;
      if (data[idx].selected) {
         setSelectedIdx(idx);
      } else {
         setSelectedIdx(-1);
      }

      if (e.shiftKey) {
         const selected = data.filter(value => value.selected);
         const startIdx = selected.reduce((prev, current) => ((prev.idx < current.idx) ? prev : current)).idx;
         const endIdx = selected.reduce((prev, current) => ((prev.idx > current.idx) ? prev : current)).idx;
         const items = data.map((value, index) => {
            const temp = value;
            if (startIdx <= index && endIdx >= index) temp.selected = true;
            else temp.selected = false;
            return temp;
         });
      } else {
         data.forEach((obj, index) => {
            if (idx === index) {
               data[idx].selected = true;
            } else {
               data[index].selected = false;
            }
         });
         props.clickItem(idx, data[idx].selected);
      }

      // const result = data.filter((value:any) => value.selected)
      //    .map(value => value.contentID);
      // props.clickItem(result);
   };

   const deleteItem = (event:React.MouseEvent<SVGSVGElement, MouseEvent>, idx:number) => {
      event.stopPropagation();
      const selected = data.filter(value => value.selected);
      props.deleteItem(selected);
   };

   function dragStart(e: any, idx: number) {
      if (!data[idx].selected) {
         data[idx].selected = !data[idx].selected;
         props.clickItem(idx, data[idx].selected);
      }
   }

   function dragOver(e: any, idx: number) {
      e.preventDefault();
      if (!data[idx].selected) {
         if (idx > selectedIdx) {
            e.target.style.borderRight = "2px solid rgb(0,135,203)";
         } else {
            e.target.style.borderLeft = "2px solid rgb(0,135,203)";
         }
      }
   }

   function dragleave(e: any, idx: number) {
      e.preventDefault();
      setBoaderNone(e, idx);
   }

   function dragEnd(e: any, idx: number) {
      const selected = data.filter(value => value.selected);
      const selectedList = selected.flatMap(data => data.idx);
      if (!selectedList.includes(dragEnterIdx)) {
         const startIdx = selected.reduce((prev, current) => ((prev.idx < current.idx) ? prev : current)).idx;
         const nIndex = getDraggedNewIndex(startIdx, dragEnterIdx, selectedList, data.length);
         props.modifyItem(nIndex);
      }
   }

   function onDrop(e: any, idx: number) {
      setBoaderNone(e, idx);
   }

   function setBoaderNone(e: any, idx: number) {
      e.target.style.borderStyle = "";
   }

   function getDraggedNewIndex(startIndex:number, selectedIndex:number, selectedIndexList: Array<any>, length: number): Array<spiltImage> {
      const nIndex = [];
      for (let i = 0; i < length; i++) {
         if (selectedIndex === i) {
            if (startIndex > selectedIndex) {
               for (let j = 0; j < selectedIndexList.length; j++) {
                  data[selectedIndexList[j]].idx = nIndex.length;
                  nIndex.push(data[selectedIndexList[j]]);
               }
               data[i].idx = nIndex.length;
               nIndex.push(data[i]);
            } else {
               data[i].idx = nIndex.length;
               nIndex.push(data[i]);
               for (let j = 0; j < selectedIndexList.length; j++) {
                  data[selectedIndexList[j]].idx = nIndex.length;
                  nIndex.push(data[selectedIndexList[j]]);
               }
            }
         } else if (!selectedIndexList.includes(i)) {
            data[i].idx = nIndex.length;
            nIndex.push(data[i]);
         }
      }
      return nIndex;
   }

   // Drag 후 Drop 하는 태그에서 발생하는 이벤트
   function dragEnter(e: any, idx: number) {
      setDragEnterIdx(idx);
   }

   const sortRender = () => {
      if (!commonuUtils.isEmptyArr(props.data)) {
         if (isSorted) {
            return <IconButton onClick={setSort}>
               <KeyboardArrowUpIcon/>
               <Typography>{i18n("label.ascending")}</Typography>
            </IconButton>;
         }
         return <IconButton onClick={setSort}>
            <KeyboardArrowDown/>
            <Typography>{i18n("label.descending")}</Typography>
         </IconButton>;
      }
   };

   function setSort() {
      let result: any;
      if (isSorted) {
         setIsSorted(false);
         result = data.sort((a, b) => b.idx - a.idx);
         result.forEach((image: any, idx: number) => {
            const temp = image;
            temp.idx = idx;
            return temp;
         });
      } else {
         setIsSorted(true);
         result = data.sort((a, b) => b.idx - a.idx);
         result.forEach((image:any, idx: number) => {
            const temp = image;
            temp.idx = idx;
            return temp;
         });
      }
      props.modifyItem(result);
   }

   const formStyles = FormStyles();
   const imageListStyles = ImageListStyles();

   return (
      <>
         <Box className={formStyles.titleContainer}>
            <Box className={formStyles.titleContent}>
               <Typography sx={{ margin: "auto" }}>{props.title}</Typography>
               { sortRender() }
            </Box>
         </Box>
         <Box className={imageListStyles.examImageContainer}>
            <ImageList
               className={imageListStyles.listContainer}
               ref={imageListRef}
            >
               { props.selectedSeriesNumber !== -1
              && <Box className={imageListStyles.absoluteText}>{i18n("label.srs")}: {props.selectedSeriesNumber}</Box>
               }
               {props.data!.map((item: any) => (
                  <ImageListItem
                     key={item.idx}
                     className={imageListStyles.bigListItem}
                     onClick={e => clickItem(e, item.idx)}>
                     <img
                        className={item.selected ? formStyles.selectedBorder : ""}
                        onDragStart={(e) => { dragStart(e, item.idx); }}
                        onDragOver={(e) => { dragOver(e, item.idx); }}
                        onDragLeave={(e) => { dragleave(e, item.idx); }}
                        onDragEnd={(e) => { dragEnd(e, item.idx); }}
                        onDragEnter={(e) => { dragEnter(e, item.idx); }}
                        onDrop={(e) => { onDrop(e, item.idx); }}
                        src={item.image}
                        alt={`${item.idx}`}
                        style={{ height: "215px", width: "auto" }}
                        loading="lazy" />
                     {item.selected
                      && <Delete
                         sx={{
                            top: "190px",
                            right: "0",
                            position: "absolute",
                            color: "#0087cb !important",
                         }}
                         onClick={e => deleteItem(e, item.idx)}/>}
                     <ImageListItemBar
                        title={`${item.idx}`}
                        position="below" />
                  </ImageListItem>
               ))}
            </ImageList>
         </Box>
      </>
   );
}
