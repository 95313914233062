import React from "react";
import { Box } from "@mui/material";
import { FormStyles } from "./styles/FormStyle";

interface InfoFormProps {
  title: string,
  data: Array<object>
}

export default function InfoForm(props: InfoFormProps) {
   const classes = FormStyles();
   return (
      <>
         <Box className={classes.info}>
            <Box className={classes.titleContainer}>
               <Box className={classes.titleContent}>{props.title}</Box>
            </Box>
            <Box className={classes.infoContainer} sx={{ overflowY: "auto", overflowX: "hidden" }}>
               { props.data && props.data.map((obj:any, index) => (
                  <Box className={classes.inputContainer} key={index} >
                     <Box className={classes.font}>{obj.label} :</Box>
                     <Box className={classes.content}>{obj.value}</Box>
                  </Box>
               ))}
            </Box>
         </Box>
      </>
   );
}
