import React from "react";
import { Box } from "@mui/material";
import TextTypeField from "../../input/textfield/TextTypeField";
import { FormStyles } from "../SplitExam/styles/FormStyle";

type inputData = {
  key: string,
  label: string,
  value: string,
}

interface InfoFormProps {
  title: string,
  data: Array<inputData>,
  changeValue?(data:any): void,
}

export default function EditMultiLineForm(props: InfoFormProps) {
   const { data, changeValue } = props;

   function onChange(e: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>, key: string) {
      const copyObj = [...data];
      const idx = data.findIndex((obj:any) => obj.key === key);
      if (idx !== -1) {
         copyObj[idx] = { ...copyObj[idx], value: e.target.value };
      }
      if (changeValue) {
         changeValue(copyObj);
      }
   }
   const classes = FormStyles();
   return (
      <>
         <Box className={classes.container}>
            <Box className={classes.info}>
               <Box className={classes.titleContainer}>
                  <Box className={classes.titleContent}>{props.title}</Box>
               </Box>
               <Box className={classes.infoContainer}>
                  { data && data.map((obj:any, index:number) => (
                     <Box className={classes.inputContainer} key={index}>
                        <TextTypeField
                           size={"medium"}
                           fullWidth={true}
                           multiline
                           rows="3"
                           value={obj.value}
                           onChange={e => onChange(e, obj.key)}
                        />
                     </Box>
                  ))}
               </Box>
            </Box>
         </Box>
      </>
   );
}
