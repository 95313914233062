import { ko } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { format, isValid, parse } from "date-fns";
import TextTypeField from "../input/textfield/TextTypeField";

/**
 * todo: 텍스트로 입력시 상황 해결 필요
 * @param value : Date 객체로 사용 필요 => material UI
 * @param onChange
 * @constructor
 */
export function CustomDatePicker({ value, onChange, valueFormat } : any): JSX.Element {
   const [formatValue, setFormatValue] = useState<Date>(useInitValue);
   const [open, setOpen] = useState(false);
   const locale = ko;

   useEffect(() => {
      setFormatValue(useInitValue);
   }, []);

   function useInitValue() {
      const newDate = parse(value, "yyyy-MM-dd", new Date());
      return newDate;
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
         <DatePicker
            value={formatValue}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            // openTo="year"
            // views={["year", "month", "day"]}
            open={open}
            mask="____.__.__"
            onChange={(newValue) => {
               if (newValue !== null && isValid(newValue)) {
                  // console.log("newValue : ", newValue);
                  setFormatValue(newValue);
                  const result = format(newValue, valueFormat);
                  onChange(result);
               }
            }}
            renderInput={(params: any) =>
               <TextTypeField
                  {...params}
                  size={"large"}
                  variant={"standard"}
                  InputProps={{ ...params.InputProps, sx:{ width: "95%" } }}
                  shrink
                  onClick={e => setOpen(true)}
               />
            }
         />
      </LocalizationProvider>
   );
}
