import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, FormGroup, RadioGroup, Switch } from "@mui/material";
import { useSelector } from "react-redux";
import {
   StyledCheckBoxWrapper,
   StyledLabel,
   StyledSettingPopup, StyledSettingPopupWrapper,
   StyledSettingReportWrapper, StyledSettingSignature, StyledSettingSignatureWrapper,
   StyledSignatureButtonWrapper, StyledSwitchOn,
} from "./styles/SettomgReportStyles";
import i18n from "../../../../utils/i18n-utils";
import store from "../../../../redux/store";
import { CommonActionType } from "../../../../redux/reducers/common";
import { Layout, UserStyleType } from "../../../../types/UserStyleType";
import { StyledRadio, StyledSettingContainer, StyledSettingContents, StyledSettingWrapper } from "./styles/SettomgStyles";
import { RootState } from "../../../../redux/reducers";
import { ReportActionType } from "../../../../redux/reducers/report";
import { StyledCheckBox, StyledColumnRadioGroup } from "../../styles/CommonDialogStyles";

export default function SettingReport() {
   // button Disable State [true == dislabled, false == notDisabled] - start
   const [checkLogin, setCheckLogin] = useState<boolean>(false);
   const [checkEverytime, setCheckEverytime] = useState<boolean>(false);
   const [signatureSwitch, setSignatureSwitch] = useState<boolean>(false);
   // button Disable State [true == dislabled, false == notDisabled] - end
   const [signatureSwitchCheck, setSignatureSwitchCheck] = useState<boolean>(false);
   const [statusOn, setStatusOn] = useState<string>("");
   const userConfig = useSelector((state : RootState) => state.common.userConfig);

   const { doCert } = JSON.parse(localStorage.getItem("user")!);

   useEffect(() => {
      btnSwitch(doCert);
      setSignatureSwitchCheck(doCert);
      signatureOption(JSON.parse(localStorage.getItem(__CERT_STORAGE__)!));
   }, []);

   // #19221
   function btnSwitch(doCert: boolean) {
      if (doCert) {
         // switch 버튼 활성화
         setSignatureSwitch(false);
         setStatusOn(i18n("label.on"));
      } else {
         // switch 버튼 비활성화
         setSignatureSwitch(true);
         setStatusOn(i18n("label.off"));
      }
   }

   // #19221
   function signatureOption(storage : boolean) {
      if (storage) {
         // switch 버튼이 on일때, signature 버튼들 활성화
         setCheckLogin(false);
         setCheckEverytime(false);
         setStatusOn(i18n("label.on"));
         setSignatureSwitchCheck(true);
      } else {
         // switch 버튼이 off일때, signature 버튼들 비 활성화
         setCheckLogin(true);
         setCheckEverytime(true);
         setStatusOn(i18n("label.off"));
         setSignatureSwitchCheck(false);
      }
   }

   function handleScrollTypeChange(e : React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.value === "true";
      updateSplitLayout({ isIndividualScroll : value });
   }

   function handlePopupPinChange(e :  React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.checked;
      updateSplitLayout({ isPopupPin : value });
   }

   function handleReadingTemplateCheckBoxChange(e :  React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.checked;
      updateSplitLayout({ isPopupReadingTemplate : value });
   }

   function handleRelatedReportCheckBoxChange(e :  React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.checked;
      updateSplitLayout({ isPopupRelatedReport : value });
   }

   function updateSplitLayout(layout: Layout) {
      new Promise<UserStyleType>((resolve, reject) => {
         fetch(`/api/user/option/style/layout`, {
            method: "PATCH",
            headers: {
               "Content-Type": "application/json",
               "Authorization": localStorage.getItem("jwt")!,
            },
            body: JSON.stringify(layout),
         }).then((response) => {
            if (!response.ok) {
               console.debug(new Error(`${response.status} ${response.statusText}`));
               reject(new Error(`${response.status} ${response.statusText}`));
            }
            response.json().then((rows: UserStyleType) => {
               resolve(rows);
            });
         });
      }).then((result) => {
         if (result) {
            const { layout, certAtLogin } = result;
            store.dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { ...userConfig, layout, certAtLogin } });
         }
      });
   }

   function updateCertAtLogin(cert: string) {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style/certAtLogin?certAtLogin=${cert}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
                  store.dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { ...userConfig, certAtLogin : cert } });
               });
            } else {
               reject(new Error(`status: ${response.status}, message: ${response.statusText}`));
            }
         });
      });
   }

   return (
      <>
         <StyledSettingContainer>
            <StyledSettingWrapper>
               {/* report */}
               <StyledSettingContents>
                  <StyledSettingReportWrapper>
                     <StyledLabel>{i18n("label.reportScroll")}</StyledLabel>
                     <FormControl>
                        <RadioGroup
                           aria-labelledby="radio-buttons-group-label"
                           value={userConfig.layout.isIndividualScroll}
                           onChange={handleScrollTypeChange}
                           name="radio-buttons-group"
                           row
                        >
                           <FormControlLabel
                              style={{ padding: "5px 20px" }}
                              value="false"
                              control={<StyledRadio  />}
                              color="success"
                              label={i18n("label.wholeScroll")}
                           />
                           <FormControlLabel
                              style={{ padding: "5px 20px" }}
                              value="true"
                              control={<StyledRadio />}
                              label={i18n("label.individualScrolls")}
                           />
                        </RadioGroup>
                     </FormControl>
                  </StyledSettingReportWrapper>
               </StyledSettingContents>

               {/* Pop up */}
               <StyledSettingContents>
                  <StyledSettingPopupWrapper>
                     <StyledLabel>{i18n("label.popupReport")}</StyledLabel>
                     <StyledSettingPopup>
                        <StyledCheckBoxWrapper>
                           <FormGroup>
                              <FormControlLabel control={<StyledCheckBox onChange={handlePopupPinChange} checked={userConfig.layout.isPopupPin}/>} label={i18n("label.alwaysAtTheTop")} />
                              {/* <FormControlLabel control={<StyledCheckBox onChange={handlePopupPinChange} checked={popupPin}/>} label={i18n("label.alwaysAtTheTop")} /> */}
                           </FormGroup>
                        </StyledCheckBoxWrapper>
                        <StyledCheckBoxWrapper>
                           <FormGroup>
                              <FormControlLabel control={<StyledCheckBox onChange={handleReadingTemplateCheckBoxChange} checked={userConfig.layout.isPopupReadingTemplate} />} label={i18n("label.readingTemplate")} />
                              {/* <FormControlLabel control={<StyledCheckBox onChange={handleReadingTemplateCheckBoxChange} checked={readingTemplateCheckBox} />} label={i18n("label.readingTemplate")} /> */}
                           </FormGroup>
                           <FormGroup>
                              <FormControlLabel control={<StyledCheckBox onChange={handleRelatedReportCheckBoxChange} checked={userConfig.layout.isPopupRelatedReport} />} label={i18n("label.relatedReportList")} />
                              {/* <FormControlLabel control={<StyledCheckBox onChange={handleRelatedReportCheckBoxChange} checked={relatedReportCheckBox} />} label={i18n("label.relatedReportList")} /> */}
                           </FormGroup>
                        </StyledCheckBoxWrapper>
                     </StyledSettingPopup>
                  </StyledSettingPopupWrapper>
               </StyledSettingContents>

               {/* Digital Signature */}
               <StyledSettingContents>
                  <StyledSettingSignatureWrapper>
                     <StyledLabel>{i18n("label.digitalSignature")}</StyledLabel>
                     <StyledSettingSignature>
                        <StyledSignatureButtonWrapper>
                           <StyledLabel style={{ padding: "0px 20px 0px 20px", marginTop: "20px" }}>{i18n("label.ApproveWithSignatureOption")}</StyledLabel>
                           <StyledSwitchOn>
                              <FormGroup>
                                 <FormControlLabel control={
                                    <Switch
                                       disabled={signatureSwitch}
                                       checked={signatureSwitchCheck}
                                       onChange={(event, checked) => {
                                          signatureOption(checked);
                                       }}/>} label={statusOn} />
                              </FormGroup>
                           </StyledSwitchOn>
                        </StyledSignatureButtonWrapper>

                        <StyledLabel style={{ marginLeft: "15px", fontSize: "10pt" }}>{i18n("label.checkOption")}</StyledLabel>
                        <FormControl>
                           <StyledColumnRadioGroup
                              aria-labelledby="radio-buttons-group-label"
                              value={userConfig.certAtLogin}
                              name="radio-buttons-group"
                              onChange={(event, value) => {
                                 // #19627 Check at Login으로 판독 중 Check at everytime으로 변경해도 계속 check at login으로 적용되는 오류
                                 if (value === "false") {
                                    store.dispatch({ type: ReportActionType.REMOVE_USERDN });
                                 }
                                 updateCertAtLogin(value);
                              }}
                              // row
                           >
                              <FormControlLabel
                                 value="true"
                                 control={<StyledRadio  disabled={checkLogin}/>}
                                 label={i18n("label.checkAtLogin")}
                              />
                              <FormControlLabel
                                 value="false"
                                 control={<StyledRadio disabled={checkEverytime} />}
                                 label={i18n("label.checkAtEverytime")}
                              />
                           </StyledColumnRadioGroup>
                        </FormControl>
                     </StyledSettingSignature>
                  </StyledSettingSignatureWrapper>
               </StyledSettingContents>
            </StyledSettingWrapper>
         </StyledSettingContainer>
      </>
   );
}
