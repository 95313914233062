import React from "react";
import { useSelector } from "react-redux";
import $ from "cash-dom";
import { DialogType } from "../../redux/reducers/common";
import { RootState } from "../../redux/reducers";
import DicomSend from "../dialog/DicomSend/DicomSend";
import ShareToPatient from "../dialog/ShareToPatient";
import ReportConfirmDialog from "../dialog/ReportConfirmDialog";
import SplitExam from "../dialog/SplitExam/SplitExam";
import Confirm from "../dialog/Confirm";
import VerifyDialog from "../dialog/VerifyDialog";
import PasswordDialog from "../dialog/PasswordDialog";
import UserInfoDialog from "../dialog/UserInfoDialog";
import ModifyExam from "../dialog/ModifyExam/ModifyExam";
import SupportDialog from "../dialog/SupportDialog";
import SettingDialog from "../dialog/Setting/SettingDialog";
import TeleRequestDialog from "../dialog/TeleRequestDialog";
import ReferralFormDialog from "../dialog/ReferralFormDialog";
import "../../dialog/techtab-new-exam-dialog";
import MergeExam from "../dialog/MergeExam/MergeExam";
import NewExam from "../dialog/NewExam/NewExam";
// import "../../dialog/boneage-report";
// import BoneAgeReport from "../dialog/BoneAgeReport/BoneAgeReport";
import BoneAgeReportDialog from "../dialog/BoneAgeReport/BoneAgeReportDialog";

export default function Dialog() {
   const dialog = useSelector((state : RootState) => state.common.dialog);

   function changeDialogState() {
      if (dialog) {
         const { type, message } = dialog;
         switch (type) {
         case DialogType.DICOM_SEND_DIALOG: {
            return <DicomSend caseIds={message.detail.caseIds} />;
         }
         case DialogType.SHARE_TO_PATIENT_DIALOG: {
            return <ShareToPatient caseIds={message.detail.caseIds}/>;
         }
         case DialogType.SPLIT_EXAM_DIALOG: {
            return <SplitExam {...message.detail}/>;
         }
         case DialogType.MODIFY_EXAM_DIALOG: {
            return <ModifyExam {...message.detail}/>;
         }
         case DialogType.CONFIRM_DIALOG: {
            return <Confirm message={message}/>;
         }
         case DialogType.REPORT_CONFIRM_DIALOG: {
            return <ReportConfirmDialog message={message} />;
         }
         case DialogType.VERIFY_DIALOG: {
            return <VerifyDialog message={message}/>;
         }
         case DialogType.PASSWORD_DIALOG: {
            return <PasswordDialog />;
         }
         case DialogType.USERINFO_DIALOG: {
            return <UserInfoDialog message={message} />;
         }
         case DialogType.SUPPORT_DIALOG: {
            return <SupportDialog />;
         }
         case DialogType.SETTING_DIALOG: {
            return <SettingDialog key={message.type} type={message.type} open={message.open}/>;
         }
         case DialogType.NEW_EXAM_DIALOG: {
            return <NewExam {...message.detail} />;
         }
         case DialogType.TELE_REQUEST_DAILOG: {
            return <TeleRequestDialog message={message} />;
         }
         case DialogType.REFERRAL_DAILOG: {
            return <ReferralFormDialog message={message}/>;
         }
         case DialogType.BONEAGE_REPORT_DIALOG: {
            // React 변환 진행이 안된 Polymer 컴포넌트, Worklist.tsx 에서 렌더링해서 CashDom으로 호출해서 사용
            // @ts-ignore
            // $("#boneageReport")[0].open(message.detail);
            // break;
            return <BoneAgeReportDialog {...message} />;
         }
         case DialogType.MERGE_EXAM_DIALOG: {
            return <MergeExam />;
         }
         default:
            return null;
         }
      }
   }
   return (
      <React.Fragment>
         {changeDialogState()}
      </React.Fragment>
   );
}
