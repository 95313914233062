import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@vaadin/vaadin-icons/vaadin-icons";
import "@polymer/paper-icon-button/paper-icon-button";
import "@polymer/paper-checkbox/paper-checkbox";
import "@polymer/paper-toast/paper-toast";
import "@polymer/iron-selector/iron-selector";

import "ag-grid-polymer";
import GridUtils from "../utils/grid-utils";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
import {QueryKey} from "../redux/reducers/query";
import {CommonActionType, CustomContextMenuType, DialogActionType, DialogType} from "../redux/reducers/common";
import {ReportActionType} from "../redux/reducers/report";
import RadiologyUtils from "../utils/radiology-utils";
import i18n from "../utils/i18n-utils";

class HhReadingTemplate extends mixinCommons(PolymerElement) {
   static get is() {
      return "hh-reading-template";
   }

   static get template() {
      return html`
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.css">
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css">
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css">
         <style>
         :host {
            display: block;
            height: 100%;
            width: 100%;
         }

         .container::-webkit-scrollbar-thumb {
            background-color: #c2c2c2;
            border-radius: 6px;
         }

         .container::-webkit-scrollbar
         {
            width: 7px;
            background-color: rgba(0, 0, 0, 0.14);
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
         }

         .container {
            height: 100%;
            width: 100%;
         }

         .context {
            height: 100%;
            width: 100%;
         }

         .template-configure {
            display: flex;
            height: 75px;
            width: 100%;
         }

         .template-configure-sensitive {
            width: calc(100% - 75px);
            height: 100%;
         }

         .template-list {
            height: calc(100% - 75px);
         }

         .template-configure-setting-btn {
            width: 75px;
            height: 100%;
         }

         .class-sensitiveCheck{
            width: 100%;
            font-size: 13px;
            color: #aaaaaa;
         }

         .checkBox-style{
            font-size: 13px;
            color: #aaaaaa;
            --paper-checkbox-checked-color: #0087cb;
            --paper-checkbox-label-color: #aaaaaa;
            --paper-checkbox-unchecked-color: #4c5667;
            padding: 1px;
         }

         .icon-style {
            color: #aaaaaa;
            height: 25px;
            width: 25px;
            padding: 5px;
         }

         ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
         }

         ::-webkit-scrollbar-thumb {
            background-color: #6b6b6b;
            border-radius: 10px;
            background-clip: content-box;
            border: 3px solid rgba(255,255,255,0);
         }

         paper-toast {
            --paper-toast-color: white;
         }

         paper-tooltip  {
             --paper-tooltip-duration-in: 0;
             --paper-tooltip-duration-out: 0;
         }

         .ag-row-drag1 {
            background-image: url("../resource/icons/unsorted.png");
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px 16px;
            height: 16px;
            opacity: 0.87;
            width: 16px;
            background-position-x: left;
            background-position-y: 4px;
            float: left;
            height: 100%;
            width: 15px;
            background-position-y: center;
            color: #ffffff;
         }
         </style>

         <div class="container">
            <paper-toast id="toast" duration="1000" class="class-toast"></paper-toast>
            <div class="context">
               <div class="template-configure">
                  <div class="template-configure-sensitive">
                     <div class="class-sensitiveCheck">
                        <iron-selector attr-for-selected="value" id="sensitive" selected-values="{{selectSensitive}}" multi>
                           <paper-checkbox value="modality" id="modalitySensitive" class="checkBox-style" noink>{{t("label.modality")}}</paper-checkbox>
                           <paper-checkbox value="bodypart" id="bodypartSensitive" class="checkBox-style" noink>{{t("label.bodypart")}}</paper-checkbox>
                           <paper-checkbox value="studyDescription" id="studyDescSensitive" class="checkBox-style" noink>{{t("label.studyDescription")}}</paper-checkbox>
                        </iron-selector>
                     </div>
                  </div>
                  <div class="template-configure-setting-btn">
                     <iron-icon icon="vaadin:line-v" style="color: #aaaaaa; height: 15px; width: 15px"></iron-icon>
                     <paper-icon-button id="newTemplateBtn" icon="vaadin:plus-circle" class="icon-style">
                     </paper-icon-button>
                     <paper-tooltip for="newTemplateBtn" offset="0" animation-delay="0"style="padding-left: 10px;">{{t("label.newTemplate")}}</paper-tooltip>
                     <paper-icon-button id="settingBtn" icon="vaadin:vaadin:cog" class="icon-style" style="color: #282d37" disabled>
                     </paper-icon-button>
                     <iron-icon icon="vaadin:line-v" style="color: #aaaaaa; height: 15px; width: 15px"></iron-icon>
                     <paper-icon-button id="iconFileBtn" icon="vaadin:cloud-upload" class="icon-style" slot="add-button">
                     </paper-icon-button>
                     <paper-tooltip for="iconFileBtn" offset="0" animation-delay="0"style="padding-left: 10px;">{{t("label.uploadTemplate")}}</paper-tooltip>
                     <input id="importBtn" type="file" accept="text/plain" style="display:none" />
                     <paper-icon-button id="exportBtn" icon="vaadin:cloud-download" class="icon-style">
                     </paper-icon-button>
                     <paper-tooltip for="exportBtn" offset="0" animation-delay="0" style="padding-left: 10px;">{{t("label.downloadTemplate")}}</paper-tooltip>
                  </div>
               </div>
               <div class="template-list">
                  <ag-grid-polymer
                     class="ag-theme-balham-dark"
                     rowData="{{rowData}}" ,
                     columnDefs="{{columnDefs}}"
                     gridOptions="{{gridOptions}}"
                  ></ag-grid-polymer>
               </div>
            </div>
         </div>
      `;
   }

   static get properties() {
      return {
         g_SelectedRow: {
            type: Object,
            value: {},
            observer: "onSelectRow"
         },
         g_facilitySensitive: {
            type: Object,
            value: {},
         },
         g_readingTemplateList: {
            type: Array,
            value: [],
         },
         g_popup: {
            type: Object,
            value: {},
         },
         message: {
            type: Object,
            value: {},
         },
         selectSensitive: {
            type: Array,
            value: () => {
               return [];
            }
         },
         customContextMenuState: {
            type: Number
         },
         modality: {
            type: Array,
            value: [],
            observer: "onChangeModailtyList"
         },
         bodypart: {
            type: Array,
            value: [],
            observer: "onChangeBodypartList"
         },
         g_readingTemplateCheckbox: {
            type: Array,
            observer: "syncSensitive"
         }
      };
   }

   static get observers() {
      return [
         "onChangeSensitive(selectSensitive.*)"
      ];
   }

   constructor() {
      super();

      this.isReportWindow = window.name === "reportWindow";

      this.gridOptions = {
         defaultColDef: {
            sortable: true,
            resizable: true,
            filterParams: {
               newRowsAction: "keep"
            },
            suppressMenu: true // 메뉴 막기
         },
         rowDragManaged: true,
         animateRows: true,
         rowSelection: "single",
         overlayLoadingTemplate: `<span style='font-size: 13px'>${this.t("label.noRecordsFound")}</span>`,
         overlayNoRowsTemplate: `<span style='font-size: 13px'>${this.t("label.noRecordsFound")}</span>`,
      };

      this.gridOptions.onGridReady = () => {
         this.initGridColumns();
         // readingTemplate list setting
         this.getReadingTemplateUser();
      };

      this.rowData = [];

      this.gridOptions.getContextMenuItems = (params) => {

         if(!params.node) return;

         // 마우스 우클릭으로 row가 선택되게끔 추가
         params.node.setSelected(true, true);

         let result = [];
         if(params.node){
            result = [
               {
                  name: this.t("label.newTemplate"),
                  action: () => {
                     this.openReadingTemplatePopup();
                  }
               },
               {
                  name: this.t("label.updateTemplate"),
                  action: () => {
                     if(this.g_readingTemplateList.length > 0){
                        const {data} = params.node;
                        data.g_readingTemplateList = this.g_readingTemplateList;
                     }
                     const struct = params.node.data;
                     if(!this.g_popup.window){
                        this.g_popup  = window.open("/readingTemplate", "rt_popup", "height=777, width=888,resizable=yes,toolbar=no,status=0,location=no,menubar=no,scrollbars=0,dependent=yes");
                        struct.facilitySensitive = this.g_facilitySensitive;
                        this.g_popup.struct = struct;
                        this.g_popup.focus();
                     }

                     else if(!this.g_popup.window.closed){
                        this.g_popup.close();
                        this.g_popup  = window.open("/readingTemplate", "rt_popup", "height=777, width=888,resizable=yes,toolbar=no,status=0,location=no,menubar=no,scrollbars=0,dependent=yes");
                        struct.facilitySensitive = this.g_facilitySensitive;
                        this.g_popup.struct = struct;
                        this.g_popup.focus();
                        // g_popup.reload();
                     } else {
                        this.g_popup  = window.open("/readingTemplate", "rt_popup", "height=777, width=888,resizable=yes,toolbar=no,status=0,location=no,menubar=no,scrollbars=0,dependent=yes");
                        struct.facilitySensitive = this.g_facilitySensitive;
                        this.g_popup.struct = struct;
                        this.g_popup.focus();
                     }
                  }
               },
               {
                  name: this.t("label.deleteTemplate"),
                  action: () => {
                     this.deleteReadingTemplate(params.node.data);
                  }
               }
            ];
         }else{
            this.gridOptions.api.deselectAll();
            result = [
               {
                  name: "new template",
                  action: () => {
                     this.openReadingTemplatePopup();
                  }
               }
            ];
         }

         if (this.customContextMenuState !== undefined) {
            store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: CustomContextMenuType.SYSTEM });
         }

         return result;
      };

      this.gridOptions.onRowDoubleClicked = (event) => {
         const opinion = {};
         opinion.finding = event.data.finding;
         opinion.conclusion = event.data.conclusion;
         opinion.recommendation = event.data.recommendation;

         // report로 opinion값 전달
         store.dispatch({ type: ReportActionType.SET_READING_TEMPLATE_OPINION, payload: opinion });
         this.gridOptions.api.deselectAll();
      };

      this.gridOptions.onRowDragEnd = () => {
         const templateList = [];

         this.gridOptions.api.forEachNode((node) => {
            templateList.push(node.data);
         });

         this.g_readingTemplateList = templateList;
         store.dispatch({ type: QueryKey.GET_READING_TEMPLATE, payload: {result: templateList} });
         this.updateReadingTemplates(this.g_readingTemplateList);
      };

      // worklist에서만 컬럼 이동,resize,숨김 처리
      if(!this.isReportWindow) {
         this.gridOptions.sideBar = {
            toolPanels: [
               {
                  id: "columns",
                  labelDefault: this.t("label.showHideColumns"),
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                  toolPanelParams: {
                     suppressRowGroups: true,
                     suppressValues: true,
                     suppressPivots: true,
                     suppressPivotMode: true,
                     suppressSideButtons: true,
                     suppressColumnFilter: true,
                     suppressColumnSelectAll: true,
                     suppressColumnExpandAll: true,
                  }
               }
            ]
         };

         this.gridOptions.onColumnResized = (evt) => {
            if(evt.finished) {
               const tempArr = evt.columnApi.columnController.gridColumns;
               const headerArr = tempArr.reduce((acc, {colDef, actualWidth}) => {
                  const newColDef = colDef;
                  newColDef.width = actualWidth;
                  acc.push(newColDef);
                  return acc;
               }, []);

               this.updateGridHeader(headerArr);
            }
         };

         this.gridOptions.onColumnVisible = (evt) => {
            const tempArr = evt.columnApi.columnController.gridColumns;
            const headerArr = tempArr.reduce((acc, {colDef, visible}) => {
               const isHide = visible ? Boolean(false) : Boolean(true);
               const newColDef = colDef;
               newColDef.hide = isHide;
               acc.push(newColDef);
               return acc;
            }, []);


            this.updateGridHeader(headerArr);
         };

         this.gridOptions.onDragStopped = (evt) => {
            const tempArr = evt.columnApi.columnController.gridColumns;
            const headerArr = tempArr.reduce((acc, {colDef}) => {
               acc.push(colDef);
               return acc;
            }, []);

            this.updateGridHeader(headerArr);
         };
      }
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.g_SelectedRow = store.getState().worklist.row?.detail;
         const modalityResult = store.getState().query[QueryKey.GET_MODALITY_LIST];
         if(modalityResult?.result) {
            this.modality = modalityResult?.result;
         }
         const bodypartResult = store.getState().query[QueryKey.GET_BODYPART_LIST];
         if(bodypartResult?.result) {
            this.bodypart = bodypartResult?.result;
         }
         this.customContextMenuState = store.getState().common.customContextMenu;
         this.g_readingTemplateCheckbox = store.getState().report.readingTemplateSensitive;
      });

      window.addEventListener("beforeunload", () => {
         if (this.g_popup.struct) {
            this.g_popup.close();
         }
      });

      document.addEventListener("click", () => {
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      window.addEventListener("message", (event) => {
         switch ((event.data || {}).event) {
         case "CHANGE_READING_TEMPLATE": {
            this.updateReadingTemplate(event.data.readingTemplate);
            break;
         }
         default:
            break;
         }
      });

      this.$.sensitive.addEventListener("selected-items-changed",(e) => {
         // reportWindow readingTemplate 실행
         if(e.detail.value.length){
            // #17149[HPACS] 생성된 리딩템플릿 목록을 드래그 앤 드랍으로 변경할 수 있게끔 요청
            this.gridOptions.rowDragManaged = false;
            // eslint-disable-next-line no-unused-expressions
            this.gridOptions.api?.setSuppressRowDrag(true);

            if(this.g_SelectedRow && this.g_SelectedRow.id){
               const detail = {};
               if(this.selectSensitive.includes("modality")){
                  detail.modality = this.g_SelectedRow.modality;
               }
               if(this.selectSensitive.includes("bodypart")){
                  detail.bodypart = this.g_SelectedRow.bodypart;
               }
               if(this.selectSensitive.includes("studyDescription")){
                  detail.studyDescription = this.g_SelectedRow.studyDescription;
               }

               this.getSensitiveTemplate(detail);
            }
         } else{
            // #17149[HPACS] 생성된 리딩템플릿 목록을 드래그 앤 드랍으로 변경할 수 있게끔 요청
            this.gridOptions.rowDragManaged = true;
            this.gridOptions.api.setSuppressRowDrag(false);

            this.rowData = this.g_readingTemplateList;
         }
      });

      this.$.newTemplateBtn.addEventListener("click", () => {
         this.openReadingTemplatePopup();
      });

      this.$.iconFileBtn.addEventListener("click",() => {
         this.$.importBtn.click();
      });

      this.$.importBtn.addEventListener("change", (e) => {
         const curFiles = this.$.importBtn.files;
         if(curFiles.length === 1 && curFiles[0].type === "text/plain") {
            this.importRedingTemplateToJson(curFiles[0]);
         }
         e.target.value = "";
      });

      this.$.exportBtn.addEventListener("click", () => {
         this.exportRedingTemplateToJson();
      });
   }

   setSensitiveCheckedExpand(param = []) {
      const detail = {};
      this.selectSensitive = param;

      if (this.selectSensitive.includes("modality")) {
         this.$.modalitySensitive.checked = true;
         detail.modality = this.g_SelectedRow?.modality;
      } else if (!this.selectSensitive.includes("modality")) {
         this.$.modalitySensitive.checked = false;
      }

      if (this.selectSensitive.includes("bodypart")) {
         this.$.bodypartSensitive.checked = true;
         detail.bodypart = this.g_SelectedRow?.bodypart;
      } else if (!this.selectSensitive.includes("bodypart")) {
         this.$.bodypartSensitive.checked = false;
      }

      if (this.selectSensitive.includes("studyDescription")) {
         this.$.studyDescSensitive.checked = true;
         detail.studyDescription = this.g_SelectedRow?.studyDescription;
      } else if (!this.selectSensitive.includes("studyDescription")) {
         this.$.studyDescSensitive.checked = false;
      }

      this.getSensitiveTemplate(detail);
   }

   getReadingTemplateList(params = {}){
      const {readingTemplateList} = params;
      if(readingTemplateList) {
         this.g_readingTemplateList = readingTemplateList;
         this.rowData = this.g_readingTemplateList;
      } else {
         this.g_readingTemplateList = [];
         this.rowData = null;
      }
   }

   changeReadingTemplateList(params = {}){
      const {readingTemplateList} = params;
      if(readingTemplateList) {
         this.rowData = readingTemplateList;
      }else{
         this.rowData = null;
      }
   }

   deleteReadingTemplate(data){
      // this.g_readingTemplateList.pop(data.seqNo);
      this.fetchUserMacro().then((result = []) => {
         // 삭제 대상 template이 사용중인 Macro에 포함되어 있는지 체크
         const hasTemplate =  result                              // Macro list
            .map(val => val.functionList)                         // Macro의 functionList를 배열로 빼냄
            .reduce((prev, curr) =>  prev.concat(curr), [])       // functionLis의 배열을 하나로 만든다.
            .map(val => val.functionName)                         // functionName을 배열로 빼냄
            .some(val => val === data.title);

         const deleteReadingTemplate = () => {
            this.deleteUserReadingTemplateByUserId({ data }).then((result) => {
               this.g_readingTemplateList = result;
               store.dispatch({ type: QueryKey.GET_READING_TEMPLATE, payload: {result: this.g_readingTemplateList} });
            });
         };
         const message = {
            contents: RadiologyUtils.makeDeleteReadingTemplateMessage({ hasTemplate }),
            title: i18n("label.delete"),
            ok: i18n("button.ok"),
            cancel: i18n("button.cancel"),
            onOk: deleteReadingTemplate,
         };
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.DELETE_READING_TEMPLATE, message, open: true } });
      });
   }

   updateReadingTemplate(readingTemplate){
      this.g_readingTemplateList = readingTemplate;
      store.dispatch({ type: QueryKey.GET_READING_TEMPLATE, payload: {result: this.g_readingTemplateList} });
   }

   exportRedingTemplateToJson(){
      if(this.g_readingTemplateList.length>0){
         this.writeJsonFile(this.g_readingTemplateList);
      }else{
         this.openToast({ msg: "No reading template.",  isErr: true});
      }
   }

   /**
    * Import ReadingTemplate from Json
    *
    * Create by BohyunJang on 2019-01-24 오후 12:30
    * Update by joohyukjung on 2020-08-11 오전 10:31
    * */
   importRedingTemplateToJson(readFile) {
      const reader = new FileReader();
      reader.readAsText(readFile);
      reader.onload = () => {
         const result = JSON.parse(reader.result);

         this.g_readingTemplateList.map((v) => {
            result.map((m) => {
               const rt = m;
               // NOTE: 2020/08/11 중복 shortcut 제거
               if(rt.shortcut === v.shortcut) rt.shortcut = "";
               return rt;
            });
            return v;
         });

         result.map(m => this.g_readingTemplateList.push(m));

         let cnt = 1;
         const readingTemplateList = [];
         this.g_readingTemplateList.map((m) => {
            const rt = m;
            // NOTE: 2020/08/11 seqNo, displayOrder 초기화
            rt.seqNo = cnt;
            rt.displayOrder = cnt;
            cnt++;

            // NOTE: 2020/08/11 중복 title 비교하여 suffix 추가
            let word = rt.title;
            let int = 1;
            while ( this.strArr(word, readingTemplateList) >= 0 )
               word = `${rt.title}(${int++})`;
            readingTemplateList.push(word);
            rt.title = word;
            return rt;
         });

         this.gridOptions.api.setRowData(this.g_readingTemplateList);

         store.dispatch({ type: QueryKey.GET_READING_TEMPLATE, payload: {result: this.g_readingTemplateList} });
         this.createAllReadingTemplateByUserId(this.g_readingTemplateList);
      };
   }

   /**
    * array를 순회하면서 str 비교
    * @param s 비교할 str
    * @param a 비교대상 array
    * @returns {number}
    */
   strArr(s,a){
      for ( let j = 0; j < a.length; j++ )
         if ( a[ j ] === s ) return j;
      return -1;
   }

   /**
    * Export ReadingTemlate to Josn
    *
    * Create by BohyunJang on 2019-01-24 오후 12:33
    * */
   writeJsonFile(readingTemplateList){
      const contents = JSON.stringify(readingTemplateList);
      this.saveToFile_Chrome("ReadingTemplate",contents);
   }

   saveToFile_Chrome(fileName, content) {
      const blob = new Blob([content], { type: "text/plain" });
      const objURL = window.URL.createObjectURL(blob);
      if (window.__Xr_objURL_forCreatingFile__) {
         window.URL.revokeObjectURL(window.__Xr_objURL_forCreatingFile__);
      }
      window.__Xr_objURL_forCreatingFile__ = objURL;
      const a = document.createElement("a");
      a.download = fileName;
      a.href = objURL;
      a.click();
   }

   openToast(detail){
      this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
   }

   clearSelectedRow() {
      this.gridOptions.api.deselectAll();
   }

   openReadingTemplatePopup(){
      if(Object.keys(this.g_popup).length){
         this.g_popup.close();
      }

      this.g_popup = window.open("/readingTemplate", "rt_popup", "height=777, width=888,resizable=yes,toolbar=no,status=0,location=no,menubar=no,scrollbars=0,dependent=yes");

      let struct = {};
      if(this.g_readingTemplateList.length > 0){
         const param = {};
         const data = {};
         data.g_readingTemplateList = this.g_readingTemplateList;
         param.data = data;
         struct = param.data;
         struct.facilitySensitive = this.g_facilitySensitive;
         this.g_popup.struct = struct;
      }else{
         struct.facilitySensitive = this.g_facilitySensitive;
         this.g_popup.struct = struct;
      }

      this.g_popup.focus();
   }

   deleteUserReadingTemplateByUserId(param) {
      const {data} = param;
      return new Promise((resolve) => {
         fetch(`/api/preforms/${data.seqNo}`, {
            method: "DELETE",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
            }
         }).then((response) => {
            if (!response.ok) {
               resolve(response.error());
            } else {
               response.json().then( (result) => {
                  resolve(result);
               });
            }
         });
      });
   }

   updateReadingTemplates(preforms) {
      return new Promise((resolve) => {
         const param = { request : preforms };
         fetch(`/api/user/option/preforms`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(param.request)
         }).then((response) => {
            if (!response.ok) {
               resolve(response.error());
            }else {
               resolve(response.ok);
            }
         });
      });
   }

   getReadingTemplateListByUserId() {
      return new Promise((resolve,reject) => {
         fetch(`/api/user/option/preforms`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else if(response.status === 204){
               resolve(null);
            }else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   cellRender(param){
      param.eGridCell.querySelector("span").classList.remove("ag-icon");
      param.eGridCell.querySelector("span").classList.remove("ag-icon-grip");
      param.eGridCell.querySelector("span").classList.add("ag-row-drag1");
      return param.value;
   }

   async initGridColumns() {
      // #17652 [HPACS > 대경 경산점] Reading Teamplate 제목, 숏컷만 표시
      if(this.isReportWindow) {
         this.columnDefs = [
            {headerName: this.t("label.title"), field: "title", width: 100, rowDrag: true, cellRenderer: params => this.cellRender(params)},
            {headerName: this.t("label.shortcut"), field: "shortcut"}
         ];
      } else {
         const userStyle = await this.getUserStyle();
         const defaultColumnDefs = [
            {headerName: this.t("label.title"), field: "title", width: 100, rowDrag: true, cellRenderer: params => this.cellRender(params)},
            {headerName: this.t("label.shortcut"), field: "shortcut", width: 90},
            {headerName: this.t("label.modality"), field: "modality", width: 100},
            {headerName: this.t("label.bodypart"), field: "bodypart", width: 100},
            {headerName: this.t("label.studyDescription"), field: "studyDescription", width: 100},
            {headerName: this.t("label.finding"), field: "finding"},
            {headerName: this.t("label.conclusion"), field: "conclusion"},
            {headerName: this.t("label.recommendation"), field: "recommendation"}
         ];

         if (!(userStyle instanceof Error) && userStyle.grid && userStyle.grid.readingTemplate && userStyle.grid.readingTemplate.length > 0) {
            const { readingTemplate } = userStyle.grid;
            this.columnDefs = GridUtils.mergeFilterParams(readingTemplate, defaultColumnDefs);
         } else {
            this.columnDefs = defaultColumnDefs;
         }
      }
   }

   updateGridHeader(headerArr) {
      return new Promise((resolve) => {
         const type = "readingTemplate";
         fetch(`/api/user/option/gridheader/${type}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json",
            },
            body: JSON.stringify(headerArr)
         }).then((response) => {
            if (response.ok) {
               response.json().then((res) => {
                  resolve(res);
               });
            } else {
               console.debug(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   getUserStyle() {
      return fetch(`/api/user/option/style`, {
         method: "GET",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json",
         },
      }).then((response) => {
         if (response.ok && response.status === 200) return response.json();
         return new Error(`[${response.status}] Get user style error!`);
      }).catch((err) => {
         console.error("Get user style error!", err);
         return err;
      });
   }

   initSensitiveReportWindow(){
      const modalityList = this.modality;
      const bodypartList = this.bodypart;

      if(modalityList.length && bodypartList.length) {
         const detail = {modalityList, bodypartList};

         // readigTemplate modality, bodypart 세팅
         this.g_facilitySensitive = detail;
      }
   }

   getReadingTemplateUser(){
      this.getReadingTemplateListByUserId().then((result) => {
         const param = {};
         param.readingTemplateList = result;
         store.dispatch({ type: QueryKey.GET_READING_TEMPLATE, payload: { result } });
         // this.getReadingTemplateList(param);
      });
   }

   getSensitiveTemplate(v = {}) {
      this.getReadingTemplateListBySensitive(v).then((result)=>{
         const param = {};
         param.readingTemplateList = result;
         this.changeReadingTemplateList(param);
      });
   }

   getReadingTemplateListBySensitive(params){
      return new Promise((resolve, reject) => {
         const param = {};
         if(params.modality){
            param.modality = params.modality;
         }
         if(params.bodypart){
            param.bodypart = params.bodypart;
         }
         if(params.studyDescription){
            param.studyDescription = params.studyDescription;
         }

         fetch(`/api/preforms/`, {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((result) => {
                  resolve(result);
               });
            } else if(response.status === 204){
               resolve(null);
            } else{
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   createAllReadingTemplateByUserId(preforms) {
      return new Promise((resolve) => {
         const param = { request : preforms };
         fetch(`/api/preforms/all`, {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(param.request)
         }).then((response) => {
            if (!response.ok) {
               resolve(response.error());
            }else {
               resolve(response.ok);
            }
         });
      });
   }

   fetchUserMacro() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/shortcuts`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok) {
               response.json().then((setting) => {
                  resolve(setting.macroFunction);
               });
            } else {
               reject(new Error(`status: ${response.status}, message: ${response.statusText}`));
            }
         });
      });
   }

   onSelectRow(row = {}) {
      if(row && row.id) {
         this.setSensitiveCheckedExpand(this.selectSensitive);
      }
   }

   onChangeModailtyList() {
      // modality, bodypart list setting
      this.initSensitiveReportWindow();
   }

   onChangeBodypartList() {
      // modality, bodypart list setting
      this.initSensitiveReportWindow();
   }

   onChangeSensitive(l) {
      // observer에서 selectSensitive.length로 변경사항 감지함
      if(l?.path === "selectSensitive.length") {
         // select checkbox 상태값 전달
         store.dispatch({ type: ReportActionType.SET_READING_TEMPLATE_SENSITIVE, payload: l?.base });
      }
   }

   syncSensitive(l) {
      // checkBox 동기화
      if(l !== this.selectSensitive){
         // console.log(l, this.selectSensitive);
         this.selectSensitive = l;
         this.setSensitiveCheckedExpand(this.selectSensitive);
      }
   }
}

window.customElements.define(HhReadingTemplate.is, HhReadingTemplate);
