import React from "react";
import { TextField } from "@mui/material";
import { DefaultTextFieldTypes } from "./types/DefaultTextFieldTypes";

export default function PasswordTypeField(props: DefaultTextFieldTypes):JSX.Element {
   // Received `true` for a non-boolean attribute 에러로 인해 새로 정의한 custom attribute는 따로 처리
   const { shrink, placeholder, inputProps, ...textFieldProps } = props;
   return (
      <TextField
         {...textFieldProps}
         type={"password"}
         autoComplete={"off"}
         inputProps={{ ...inputProps, placeholder }}
         InputLabelProps={{ shrink }}
      />
   );
}
